import React, { useState, useEffect } from 'react';
import { LeftMenu, MainHeader, Footer, SurveyDropDown } from '../../components';
import { ActiveSurvey, UpcomingTasks, DashboardComments } from './index';
import { AssistanceWrapper } from '../../resources/styling/appStyle';
import ScoreTiles from './ScoreTiles';
import DashboardWelcomePopup from './DashboardWelcomePopup';
import CreateSurveyButton from './CreateSurveyButton';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import {
  getQueryStringVal,
  getTransformedSurveyRecordList,
  setTextLengthLimit,
} from '../../utils/CommonFunctions';
import {
  IsActionAllowed,
  QueryStringTokens,
  RestrictedActions,
  Roles,
  WebStorageNames,
} from '../../utils';
import Grid from '@mui/material/Grid';
import { ExtendedDashboardMainWrapper } from './DashboardStyle';
import moment from 'moment';
import { storageService, surveyService } from '../../services';

export default function SingleSurveyDashboard(
  props,
  titleText,
  bodyText,
  footer,
  styles
) {
  const {
    schoolName,
    activeSurveyDetails,
    strongestScore,
    weakestScore,
    comments,
    commentsLoader,
    actionResults,
    hasAdditionalQuestions,
  } = props.data;
  const [isActionAllowed, setIsActionAllowed] = useState(true);
  const [showUpgradeMsg, setShowUpgradeMsg] = useState('');
  const [isQuestionBankAvailable, setIsQuestionBankAvailable] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [latestSurveyOption, setLatestSurveyOption] = useState({
    value: '',
    label: 'Latest survey',
  });
  //const [surveyOptions, setSurveyOptions] = useState([]);

  const { isFirstLogin, isOpen, onClose, onOk, onSurveyChange, surveyOptions } =
    props;
  const isHeatMap =
    activeSurveyDetails !== null && activeSurveyDetails !== ''
      ? activeSurveyDetails.IsHeatMap
      : false;

  useEffect(() => {
    const res =
      comments &&
      comments
        .flat()
        .some(
          (x) =>
            moment(x.UpdatedDate).format(moment.HTML5_FMT.DATE) >=
            moment().subtract(1, 'months').format(moment.HTML5_FMT.DATE)
        );
    setShowComments(res);
  }, [comments]);

  useEffect(() => {
    getSubscriptionForDashboard();
    //getSurveysNameAndTokenBySchool();
    console.log(isActionAllowed);
  }, []);

  const handleSurveyValueChange = (latestSurveyOption) => {
    const role = storageService.getItem(WebStorageNames.Role);
    setLatestSurveyOption(latestSurveyOption);
    // let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    // if (role === Roles.GroupDirector || schoolToken !== null) {
    //   window.location.search =
    //     '?token=' + latestSurveyOption.value + '&schoolToken=' + schoolToken;
    // } else {
    //   window.location.search = '?token=' + latestSurveyOption.value;
    // }
    //getSurveyCommentsData(latestSurveyOption.value);
    onSurveyChange(latestSurveyOption.value);
  };

  const getSubscriptionForDashboard = () => {
    IsActionAllowed(RestrictedActions.DashboardDecile)
      .then((response) => {
        setIsActionAllowed(response.data);
        if (response.data == false)
          setShowUpgradeMsg(
            'You can display your comparative scores against the School and Health and Safety Executive benchmarks by upgrading your account.'
          );
      })
      .catch();
    IsActionAllowed(RestrictedActions.QuestionBank)
      .then((response) => setIsQuestionBankAvailable(response.data))
      .catch();
  };
  return (
    <div>
      {isFirstLogin ? (
        <DashboardWelcomePopup isOpen={isOpen} onClose={onClose} onOk={onOk} />
      ) : (
        <></>
      )}
      <ExtendedDashboardMainWrapper>
        <div className='left-container'>
          <LeftMenu />
        </div>
        <div className='right-container'>
          <MainHeader />

          <div className='dd-survey'>
            <SurveyDropDown
              // isRole={isRole}
              latestSurveyOptions={surveyOptions}
              latestSurveyOption={latestSurveyOption}
              handleSurveyValueChange={handleSurveyValueChange}
            />
          </div>

          <Grid container spacing={2}>
            {activeSurveyDetails !== null &&
              activeSurveyDetails.surveyToken === null && (
                <div className='dashboard-ui-blocked'>
                  <>
                    <h1 className='survey-in-progress-message'>
                      Please wait whilst we populate your dashboard. Depending
                      on the number of schools, this can take up to 5 minutes.
                    </h1>
                    <div id='loading'></div>
                  </>
                </div>
              )}

            <Grid
              item
              xl={showComments ? 9 : 12}
              lg={showComments ? 8 : 12}
              md={12}
              xs={12}
            >
              <div className='inner-left-container'>
                <ScoreTiles
                  strongestScore={strongestScore}
                  weakestScore={weakestScore}
                  IsHeatMap={
                    isHeatMap ||
                    activeSurveyDetails === null ||
                    activeSurveyDetails === ''
                  }
                  isActionAllowed={isActionAllowed}
                  isQuestionBankAvailable={isQuestionBankAvailable}
                />
                <div className='survey-tiles-outer'>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      className='active-survey-outer'
                    >
                      <div className='active-survey-wrapper'>
                        {activeSurveyDetails != null ? (
                          <ActiveSurvey
                            activeSurveyDetails={activeSurveyDetails}
                            score={strongestScore}
                          />
                        ) : (
                          <div className='survey-button-wrapper'>
                            <CreateSurveyButton />
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      className='upcoming-task-outer'
                    >
                      <div className='upcoming-task-wrapper'>
                        <UpcomingTasks actionResults={actionResults} />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
            {showComments && (
              <Grid item xl={3} lg={4} md={12} xs={12} zeroMinWidth>
                <div className='inner-right-container'>
                  <DashboardComments
                    comments={comments}
                    commentsLoader={commentsLoader}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </div>

        <div className='header-text'>{showUpgradeMsg}</div>
      </ExtendedDashboardMainWrapper>
    </div>
  );
}
