import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { SubContainer } from '../../resources/styling/appStyle';
import { media } from 'styled-bootstrap-grid';
import { NewSurveyInputWrapper } from '../../resources/styling';
import { WrapperMain } from '../../resources/styling/formStyle';

const {
  pictonBlue,
  whiteColor,
  lighterGrey,
  barleyWhite,
  purple,
  mandy,
  blizzBlue,
  lightGray,
  teal,
  twilightBlue,
  outrageousOrange,
  bordergrey,
  blackColor,
} = colors;

/*** Add School Question ***/
export const FooterButtonsWrapper = styled.div`
  float: left;
  width: 100%;
  box-sizing: border-box;
  button {
    background: ${outrageousOrange};
  }
`;
export const CreateActionPlanWrapper = styled.div`
  .create-action-container {
    padding: 70px 0 15px;
    float: left;
    width: 100%;
    min-height: 30vh;
    border-radius: 6px;
    background: rgba(83, 181, 224, 0.05);
    border: 1px solid ${pictonBlue};
    text-align: center;
    h2 {
      padding: 0 40px;
      margin: 14px 0 10px 0;
      color: ${pictonBlue};
      font-size: 16px;
    }
    p {
      color: ${pictonBlue};
      font-size: 12px;
      padding: 0 293px 30px;
    }
  }
`;

/*** Autocomplete ***/
export const AutocompleteWrapper = styled.div`
  position: relative;
  input {
    border: 1px solid transparent;
    padding: 0.5rem;
    width: 300px;
  }

  .no-suggestions {
    color: ${mandy};
    position: absolute;
    top: 23px;
    font-size: 11px;
    font-weight: 900;
    padding: 11px 19px;
  }

  .suggestions {
    background: ${whiteColor};
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    padding-left: 0;
    border-radius: 6px;
    min-width: 126px;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1;
    padding: 12px 0 12px;
    overflow-y: auto;
    width: 100%;
  }

  .suggestions li {
    padding: 0.8rem 0.5rem;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: ${teal};
    color: ${whiteColor};
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
  }
`;

/*** School Additional Question ***/
/*** Survey Additional Question ***/
export const ExtendedSubContainer = styled(SubContainer)`
  .subcontainer-inner {
    width: 900px;
    margin: auto;
  }
  float: left;
  padding: 0 0;
  ${media.md`
  width: 100%;
`}
  td {
    word-break: break-word;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      word-break: break-all;
    }
  }
  .search-data-container {
    width: 100%;
    float: left;
  }
  .search-inner-container {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 14px;
    .suggested-actions-wrapper {
      .suggested-action-list-main {
        //float: left;
        margin: 0 auto 7px auto;
        width: 98%;
      }
      .options {
        max-height: inherit;
      }
      .category-inner-wrapper {
        border: 2px solid ${pictonBlue};
        border-radius: 8px;
        background: #e3f2fd;
        .selected-option {
          font-size: 18px;
          padding-left: 39px;
          padding: 15px;
          label {
            font-weight: bold;
          }
        }
      }
      .accordion-detail {
        padding: 0px 0px 22px 0px;
        float: left;
      }
      .action-category-heading {
        color: ${blackColor};
      }
      .accordion {
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid ${pictonBlue};
        box-shadow: none;
        border-radius: 4px;
      }
      .Mui-expanded {
        background: ${twilightBlue};
        border-radius: 4px;
      }
      .accordion-header {
        min-height: 20px;
      }
      .question-list {
        width: 100%;
        float: left;
        :hover {
          background: ${blizzBlue};
        }
      }
    }
  }

  .create-action-container {
    padding: 70px 0 15px;
    float: left;
    width: 100%;
    min-height: 30vh;
    border-radius: 6px;
    background: rgba(83, 181, 224, 0.05);
    border: 1px solid ${pictonBlue};
    text-align: center;
    h2 {
      padding: 0 40px;
      margin: 14px 0 10px 0;
      color: ${pictonBlue};
      font-size: 16px;
      font-weight: 900;
    }
    p {
      color: ${pictonBlue};
      font-size: 12px;
      padding: 0 293px 30px;
    }
  }

  .search-inner-sub-container {
    padding: 15px;
    float: left;
    width: 100%;
    border-radius: 8px;
    background: ${whiteColor};
    border: 1px solid ${bordergrey};
    h2 {
      font-size: 16px;
      font-weight: 900;
      margin: 0;
    }
    .add-btn {
      float: right;
      border-radius: 50%;
      border: 0;
      width: 35px;
      height: 35px;
      color: ${whiteColor};
      font-size: 25px !important;
      background: ${mandy};
      cursor: pointer;
      margin: -7px 0 0 0;
    }
    .action-plan-header {
      border-top: 1px solid ${lighterGrey};
      border-bottom: 1px solid ${lighterGrey};

      box-sizing: border-box;
      width: 100%;
      float: left;

      ul {
        list-style: none;
        padding: 0;
        width: 100%;
        display: flex;
        margin: 0;
        justify-content: space-between;

        li {
          display: inline-block;
          font-size: 12px;
          font-weight: 900;
          flex: 1;
          text-align: left;
          color: ${lightGray};
        }
        li:nth-child(1) {
          flex: 3;
          text-align: left;
        }
      }
    }

    .action-plan-body {
      box-sizing: border-box;
      width: 100%;
      float: left;
      margin: 0;
      .action-plan-date {
        float: left;
        margin-top: 5px;
      }
      .alert-date {
        color: ${outrageousOrange};
        font-weight: 900;
      }
      .archived {
        cursor: default !important;
      }
      .circle-parent-wrapper {
        height: 29px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        :hover {
          background: rgba(83, 181, 224, 0.2);
        }
      }
      .tick-mark {
        position: absolute;
      }

      .circle-tick {
        display: inline-block;
        width: 26px;
        height: 26px;
        color: ${whiteColor};
        padding-left: 0px;

        span {
          width: 26px;
          height: 26px;
          background: ${pictonBlue};
          display: block;
          line-height: 27px;
        }
      }

      ul {
        position: relative;
        list-style: none;
        padding: 5px 0 5px 20px;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        li {
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          flex: 1;
          text-align: left;
          padding-left: 3px;
        }

        li:nth-child(1) {
          flex: 3;
          text-align: left;
          padding-left: 0;
          padding-right: 15px;
        }
        :hover {
          background: ${twilightBlue};
          border-radius: 5px;
        }
        :hover .circle-parent-wrapper {
          display: block;
          :hover .mark-done {
            display: block;
            background: ${pictonBlue};
            border-radius: 5px;
            color: ${whiteColor};
            font-size: 10px;
            font-weight: 400;
            padding: 10px 8px;
            position: absolute;
            font-family: 'Avenir';
            min-width: 86px;
            left: -57px;
            top: 31px;
            z-index: 2;
            border: 0;
            cursor: pointer;
            box-shadow: 2px 3px 8px rgba(83, 181, 224, 0.8);
          }
        }
        .mark-done {
          display: none;
        }

        :hover .action-category {
          color: ${teal};
          font-weight: 900;
        }
      }
    }
  }

  .action-plan-options {
    width: 100%;
  }
`;
export const QuestionsInnerWrapper = styled.div`
  .questions-not-available {
    padding: 30px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    font-weight: bold;
    border: 1px solid ${bordergrey};
    border-radius: 8px;
    font-size: 15px;
  }
`;

/*** Additional Question Form ***/
export const ActionPlanFormWrapper = styled.div`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff5cc inset;
  }
  h2 {
    color: ${blackColor};
    padding: 0;
    margin-bottom: 0;
  }
  .DayPickerInput {
    position: relative;
    width: 100%;
    input {
      width: 100%;
      border: 1px solid ${purple};
      border-radius: 6px;
      background: transparent;
      padding: 15px;
    }
  }
  .DayPickerInput-OverlayWrapper {
    width: 150px;
    right: 65px;
    position: absolute;
  }
  .option-list {
    position: absolute;
    top: 21px;
    right: 15px;
    background: ${whiteColor};
    border-radius: 5px;
    z-index: 2;
    ul {
      list-style: none;
      padding: 0;
      li {
        display: block;
        text-align: left;
        color: ${teal};
        font-size: 12px;
        padding: 8px 10px;
        font-weight: 400;
        cursor: pointer;
        :hover {
          background: ${teal};
          color: ${whiteColor};
          padding: 8px 10px;
        }
      }
    }
  }
  .DayPicker-NavButton--prev {
    /* left: 20px; */
    background-image: none;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid ${teal};
    left: 1.2em;
  }

  .category-inner-wrapper {
    position: absolute;
    top: 0;
    margin: 0;
    border: 0;
  }

  .selected-option {
    background: transparent;
  }
  width: 100%;
  color: ${blackColor};

  .action-plan-instructions {
    font-size: 16px;
    line-height: 24px;
    color: ${blackColor};
    margin-bottom: 30px;
  }
`;
export const InputWrapper = styled(NewSurveyInputWrapper)`
  padding: 0;
`;
export const ExtendedFieldWrapper = styled(WrapperMain)`
  float: left;
  width: 100%;
`;
export const NewSurveyInner = styled.div`
  /* // display: table-cell; */
  vertical-align: middle;
`;
export const ModalInnerWrapper = styled.div`
  .form-column-wrapper {
    box-sizing: border-box;
    float: left;
    padding-left: 0;
    padding-right: 0;

    :nth-child(2),
    :nth-child(4) {
      padding-right: 7px;
    }
    :nth-child(3),
    :nth-child(5) {
      padding-left: 7px;
    }
  }
  .form-inner-wrapper {
    box-sizing: border-box;

    input {
      box-sizing: border-box;
      font-size: 16px;
    }
  }
  .dropdown-arrow {
    position: absolute;
    right: 17px;
    top: 22px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${purple};
  }

  ${media.xs`
    width: 100%;
`}
`;
export const ErrorWrapper = styled.div`
  position: absolute;
  /* margin-top: -18px; */
  bottom: 13px;
  left: 21px;
  color: ${mandy};
  font-size: 14px;
`;
export const RadioGroupWrapper = styled.div`
  text-align: left;
  span {
    color: ${blackColor};
    font-size: smaller;
  }
`;

export const ExtendedModalInnerWrapper = styled(ModalInnerWrapper)`
  h2 {
    margin: 15px 10px;
  }
  .survey-created-wrapper {
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.6px;
  }
`;

export const customStyles = {
  overlay: {
    background: 'rgba(255, 255,  255, 0.8',
  },
  modal: {
    background: `${barleyWhite}`,
    color: `${purple}`,
    borderRadius: '10px',
    boxShadow: '0 6px 15px 0 rgba(0, 0, 0, 0.1)',
  },
  closeIcon: {
    fill: `${purple}`,
  },
  closeIconSize: {
    width: 15,
    height: 15,
  },
};

const AdditionalQuestionStyle = {
  FooterButtonsWrapper,
  CreateActionPlanWrapper,
  AutocompleteWrapper,
  ExtendedSubContainer,
  QuestionsInnerWrapper,
  ActionPlanFormWrapper,
  InputWrapper,
  ExtendedFieldWrapper,
  NewSurveyInner,
  ModalInnerWrapper,
  ErrorWrapper,
  RadioGroupWrapper,
  ExtendedModalInnerWrapper,
  customStyles,
};
export default AdditionalQuestionStyle;
