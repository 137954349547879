import request from '../request';
import { SchoolSubscriptionAPI } from './urls';

export const getSchoolSubscriptionsByToken = (token) => {
  return request({
    url: SchoolSubscriptionAPI.getSchoolSubscriptionsByToken(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getMatSchoolSubscriptionsByToken = (token) => {
  token = token === null ? '' : token;
  return request({
    url: SchoolSubscriptionAPI.GetMatSchoolsSubcription(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getSchoolSubscriptionsById = (schoolId) => {
  return request({
    url: SchoolSubscriptionAPI.getSchoolSubscriptionsById(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const schoolSubscriptionService = {
  getSchoolSubscriptionsByToken,
  getMatSchoolSubscriptionsByToken,
  getSchoolSubscriptionsById,
};

export default schoolSubscriptionService;
