import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React from 'react';
import AccessLevel from '../../components/common/AccessLevel';
import { HeadTeacherAccess } from '../../utils';
import {
  AccessPermissions
} from '../../utils/Constants';

const ActionPlanDropDownOptionsWithButton = ({
  options,
  onChange,
  selectedOption,
  accessLevel,
  region,
}) => {
  return (
    <div className='options'>
      {options.map((category) => {
        return (
          <div className='suggested-action-list-main'>
            <Accordion className='accordion'>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className='accordion-header'
              >
                <div className='action-category-heading'>
                  <b>{category.SuggestedActionCategory}</b>
                </div>
              </AccordionSummary>
              <AccordionDetails className='accordion-detail'>
                {category.SuggestedActions.map((option) => {
                  return (
                    option.value !== -1 && (
                      <div className='question-list'>
                        <div
                          style={{
                            width: '88%',
                            float: 'left',
                            paddingLeft: '39px',
                          }}
                        >
                          <span
                            onClick={() => {
                              if (accessLevel === HeadTeacherAccess.Full)
                                onChange(option);
                            }}
                            key={option.value}
                            className='action-plan-detail'
                            style={{
                              cursor:
                                accessLevel === HeadTeacherAccess.Full
                                  ? 'cursor'
                                  : 'normal',
                            }}
                          >
                            {option.Title}
                            {accessLevel === HeadTeacherAccess.Full && (
                              <i className='add-action-plan'>
                                {/* {option.Title} */}
                              </i>
                            )}
                          </span>
                        </div>
                        <div style={{ float: 'left' }}>
                          <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.Admin]} >
                            <Tooltip title='click to add' aria-label='add'>

                              <i>
                                <i
                                  style={{
                                    'text-decoration': 'underline',
                                    'font-weight': 'bold',
                                  }}
                                  onClick={() => {
                                    onChange(option);
                                  }}
                                >
                                  Add to your plan
                                </i>
                              </i>
                            </Tooltip>
                          </AccessLevel>
                        </div>
                      </div>
                    )
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};
export default ActionPlanDropDownOptionsWithButton;
