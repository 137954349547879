import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { amplitudeLog, getInvoicePriceForUpgrade } from '../../../utils/CommonFunctions';
import { AppButton, LoginFormWrapper, ErrorWrapper, CheckBox, WelbeeTextField } from '../../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import { colors } from '../../../resources/theme/colors';
import { AppInputField } from '../../../components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { storageService, schoolService } from '../../../services';
import { loaderAction } from '../../../store/loaderStore';
import { firstLoginAction } from '../../../store/userStore';
import { InputWrapper, FieldWrapper } from '../../../components/common/loginStyle';
import { WrapperMain } from '../../../resources/styling/formStyle';
import { WebStorageNames, WelbeeTypes } from '../../../utils';
import { ExtendedLoginFormInner } from './UpgradePriceStyle';

const { mandy, lightGray } = colors;

function RegisterFee(props) {
  const [isInvoiceInfoChecked, setIsInvoiceInfoChecked] = useState(false);
  const [schoolRecord, setSchoolRecord] = useState(null);
  const [welbeeType] = useState(props.welbeeType);
  const [isInvoiceEnable, setInvoiceEnable] = useState(true);

  useEffect(() => {
    amplitudeLog('PageView', 'Upgrade_Register_Fee', '');
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    schoolService
      .getInfo(schoolId)
      .then((response) => {
        if (response.success) {
          setSchoolRecord(response.data);
        }
      })
      .catch(() => { });
  }, {});
  return (
    <Formik
      initialValues={{
        PurchaseOrderNo: '',
        InvoiceAddressName: '',
        InvoiceAddressEmail: '',
        SchoolId: storageService.getItem(WebStorageNames.SchoolId),
        WelbeeType: welbeeType,
        IsUpgradeRequest: true,
        UpgradeFrom: storageService.getItem(WebStorageNames.SubscriptionId),
        UpgradeTo:storageService.getItem(WebStorageNames.NewSubscriptionId)
      }}
      onSubmit={(values, actions) => {
        props.loaderActions.dataLoading(true);
        setTimeout(() => {
          actions.setSubmitting(false);
          schoolService
            .sendUpgradeInvoice(values)
            .then((response) => {
              const { success } = response;
              props.loaderActions.dataLoading(false);
              if (success) {
                props.onCompletion();
              }
            })
            .catch((error) => console.log(error));
        }, 1000);
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={
        isInvoiceInfoChecked
          ? Yup.object().shape({
            InvoiceAddressName: Yup.string().required(
              'Full name field is required.'
            ),
            InvoiceAddressEmail: Yup.string()
              .trim()
                .max(60)
              .email('Provide email address like example@g.com.')
              .required('Email is required.'),
          })
          : Yup.object().shape({})
      }
      render={({ values, handleChange, touched, errors, handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.keyCode === 13) handleSubmit();
          }}
        >
          <LoginFormWrapper>
            <ExtendedLoginFormInner>
              <h2>
                Price for {schoolRecord !== null ? schoolRecord.Name : ''}
              </h2>
              <div className='fee-big-number'>
                {schoolRecord !== null &&
                  <span>
                    &#xa3;{getInvoicePriceForUpgrade(schoolRecord.NumberOfPupil, storageService.getItem(WebStorageNames.SubscriptionId), welbeeType)}{' '}
                    <i className='price-vat'>+VAT</i>
                  </span>}
              </div>
              <span><p className='fee-detail'>
                          The price to upgrade from {storageService.getItem(WebStorageNames.SubscriptionId) === WelbeeTypes.WelbeeVoiceFree ? 'Voice Free' :
                              storageService.getItem(WebStorageNames.SubscriptionId) === WelbeeTypes.WelbeeSurveyFree ? 'Survey Free' :
                                  storageService.getItem(WebStorageNames.SubscriptionId) === WelbeeTypes.WelbeeVoicePro ? 'Voice Pro' :
                                      storageService.getItem(WebStorageNames.SubscriptionId) === WelbeeTypes.WelbeeSurveyPro ? 'Survey Pro' : 'Survey Premium'} to {storageService.getItem(WebStorageNames.NewSubscriptionId) === WelbeeTypes.WelbeeVoicePro ? 'Voice Pro' :
                                          storageService.getItem(WebStorageNames.NewSubscriptionId) === WelbeeTypes.WelbeeSurveyPro ? 'Survey Pro' : 'Survey Premium'}
                {' '}is linked to the size of your school and therefore the value you will receive.
              </p>
                <p className='fee-detail'>
                  If you are happy to proceed, please click the ‘Agree to be invoiced’ button below.
                  You can enter a Purchase Order number and an alternative email address for the invoice (both optional).
                </p>
                <p className='fee-detail'>
                  Once you click the button your account will be upgraded, and the invoice sent to you or the person you designated.
                </p>
                <p className='fee-detail'>
                  Thank you.    
                </p>
              </span>
              <InputWrapper>
                <WrapperMain>
                  <WelbeeTextField
                    fullWidth
                    label='Purchase order no.'
                    autoComplete='new-purchase-order'
                    type='text'
                    name='PurchaseOrderNo'
                    value={values.PurchaseOrderNo}
                    onChange={handleChange}
                  />
                </WrapperMain>
                <WrapperMain>
                  <CheckBox style={{marginBottom: '0px'}}>
                    <input
                      class='styled-checkbox'
                      id='styled-checkbox-1'
                      type='checkbox'
                      value='value1'
                      onChange={() =>
                        setIsInvoiceInfoChecked(!isInvoiceInfoChecked)
                      }
                    />
                    <label for='styled-checkbox-1'>
                      <span className='checkbox-wrapper'>
                        Please tick to use a different name and email for the
                        invoice.
                      </span>
                    </label>
                  </CheckBox>
                </WrapperMain>
                {isInvoiceInfoChecked ? (
                  <Fragment>
                    <WrapperMain className='admin-fields'>
                      <WelbeeTextField
                        fullWidth
                        label='Full name'
                        autoComplete='new-name'
                        type='text'
                        name='InvoiceAddressName'
                        value={values.InvoiceAddressName}
                        onChange={handleChange}
                      />
                      <ErrorWrapper>
                        {touched.InvoiceAddressName &&
                          errors.InvoiceAddressName &&
                          errors.InvoiceAddressName}
                      </ErrorWrapper>
                    </WrapperMain>
                    <WrapperMain>
                      <WelbeeTextField
                        fullWidth
                        label='Email'
                        autoComplete='new-email'
                        type='text'
                        name='InvoiceAddressEmail'
                        value={values.InvoiceAddressEmail}
                        onChange={handleChange}
                      />
                      <ErrorWrapper>
                        {touched.InvoiceAddressEmail &&
                          errors.InvoiceAddressEmail &&
                          errors.InvoiceAddressEmail}
                      </ErrorWrapper>
                    </WrapperMain>
                  </Fragment>
                ) : (
                  <Fragment />
                )}

                <FieldWrapper>
                  <Grid container>
                    <Grid item
                      lg={12}
                      sm={12}
                      xs={12}
                      md={12}
                      className='agree-button-wrapper'
                    >
                      <AppButton
                        color={isInvoiceEnable ? mandy : lightGray}
                        type='submit'
                        className={
                          !isInvoiceEnable ? 'disable-btn' : ''
                        }
                        onClick={() => {
                          setInvoiceEnable(false);
                          handleSubmit();
                        }}
                        disabled={!isInvoiceEnable}
                      >
                        Agree to be invoiced
                      </AppButton>
                    </Grid>
                  </Grid>
                </FieldWrapper>
              </InputWrapper>
            </ExtendedLoginFormInner>
          </LoginFormWrapper>
        </form>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});
export default connect(null, mapDispatchToProps)(withRouter(RegisterFee));
