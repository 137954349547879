import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { colors } from '../../resources/theme/colors';
import {
  
  errorpagebackground,
} from '../../resources/images';

const { pictonBlue } = colors;
export const ErrorPageWrapper = styled.div`
  
  .error-main-div {
    min-height: 100vh;
    backgroung: #f0fbff;
  
    display: flex;
    width: 100%;
  }
   .image{

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-image: url(${errorpagebackground});  
    width: 100%;
    display: flex;
    margin-top: 0px;

   }  
    .error-left-div {
      float: right;
      align-items: center;
      justify-content: left;
           

      h1 {
        color: #ff5939;
        font-size: 48px;
        margin-bottom: 4px;
        ${media.xs`
         font-size: 36px;
         font-weight: 900;
         margin-top:0px;
       `}
      }
      h2 {
        color: #ff5939;
        font-size: 36px;
        font-weight: 400;
        width: 100%;
    
        margin-top: 0;
        ${media.xs`
      line-height: 31px;
         width: 90%;
         font-size: 24px;
       `}
      }
      p {
        color: #007a7a;
        width: 100%;
        font-weight: 400;
        font-size: 20px;
        margin: 25px 0 15px 0;
        ${media.xs`
         width: 90%;
         line-height: 31px;
         font-size:18px;
         margin: 31px 0 0 0;
       `}
      }
      
    a {
        justify-content: left;
        align-items: center;
        color: ${pictonBlue};
        text-decoration: underline;
        font-weight: 900;
        font-size: 16px;
        margin-top: 15px;
        margin: 0;
          cursor: pointer;
        }
        a.active {
           
             border-left: 0px !important;

        }
    }
  width: 100%;
`;