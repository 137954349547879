import { surveyVoiceCommentAPI } from './urls';
import request from '../request';

export const submitSurveyVoiceCommentStaffReply = (data) => {
  return request({
    url: surveyVoiceCommentAPI.SubmitVoiceCommentStaffReply,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};

const surveyVoiceCommentsService = {
  submitSurveyVoiceCommentStaffReply,
};

export default surveyVoiceCommentsService;
