import request from '../request';
import { surveyActionPlanAPI } from './urls';
//////////// USER ACTIONS ///////////////

export const getActionPlan = (token, schoolToken, currentSchoolId) => {
  schoolToken = schoolToken === null ? '' : schoolToken;
  return request({
    url: surveyActionPlanAPI.getActionsPlan(
      token,
      schoolToken,
      currentSchoolId
    ),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const addActionPlanAction = (data) => {
  return request({
    url: surveyActionPlanAPI.api,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const markActionAsDone = (id) => {
  return request({
    url: surveyActionPlanAPI.markActionAsDone(id),
    method: 'POST',
  })
    .then((response) => response)
    .catch((error) => error);
};

const actionPlanService = {
  getActionPlan,
  addActionPlanAction,
  markActionAsDone,
};

export default actionPlanService;
