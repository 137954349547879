import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import {
  LeftMenu,
  Dropdown,
  SurveyDropDown,
  MainHeader,
} from '../../components';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames, Roles, MenuItems } from '../../utils/';
import {
  SurveyTypes,
  HeadTeacherAccess,
  QueryStringTokens,
} from '../../utils/Constants';
import { DateFormat } from '../../utils/CommonFunctions';
import { navigationActions } from '../../store/headerStore';
import ActionsPlanForm from './ActionsPlanForm';
import CreateActionPlan from './CreateActionPlan';
import {
  actionPlanService,
  storageService,
  surveyService,
  suggestedActionsService,
} from '../../services';
import {
  AssistanceWrapper,
  ActionPlanMainWrapper,
} from '../../components/common/ActionPlanStyle';
import { SearchFilterWrapper } from '../../resources/styling/appStyle';
import {
  parseJwt,
  getTransformedSurveyRecordList,
} from '../../utils/CommonFunctions';
import { actionPlanActions } from '../../store/actionPlanStore';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import {
  ExtendedSubContainer,
  CreateActionPlanWrapper,
} from './ActionPlanStyle';
import ActionPlanDropdown from './ActionPlanDropdown';
import { toast } from 'react-toastify';

/*** Table Style ***/
const purple = {
  50: '#45338C',
  100: '#45338C',
};

const Root = styled('div')(
  ({ theme }) => `
	table {	  
	  font-size: 14px !important;
	  border-collapse: collapse;
	  width: 100%;
	  font-weight: 600 !important;
	  margin-top: 30px;
	  margin-bottom: 0 !important;
	}
  
	td,
	th {	  	 
	  padding: 8px !important; 
	  font-size: 14px !important; 
	  text-align: left;
	}

	tr:nth-of-type(odd){
		background-color: ${theme.palette.action.hover};
	}
  
	th {
	  background-color: ${theme.palette.mode === 'dark' ? purple[50] : purple[100]};
	  color: ${theme.palette.common.white};
	}
	`
);

const SortOrder = {
  DueEarly: 'DueEarlier',
  DueLate: 'DueLate',
};

const actionTypeOptions = [
  { value: false, label: 'Open actions' },
  {
    value: true,
    label: 'Archived',
  },
];

const sortingOptions = [
  { value: SortOrder.DueEarly, label: 'Due Earlier' },
  { value: SortOrder.DueLate, label: 'Due Later' },
];

class actionPlan extends Component {
  constructor(props) {
    super(props);
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);
    this.state = {
      comments: [],
      message: 'Loading...',
      sortingByTypeOption: { value: false, label: 'Open actions' },
      latestSurveyOption: { value: '', label: 'Latest survey' },
      sortingByDateOption: { value: SortOrder.DueEarly, label: 'Due Date' },
      suggestedActions: { value: '', label: 'Suggested actions' },
      suggestedActionOptions: [],
      createActionFormDialogOpen: false,
      actionPlanData: [],
      latestSurveyOptions: [{ value: SurveyTypes.any, label: 'Latest survey' }],
      isRole: userInfo.role,
      isMarkAsDoneDialog: false,
      markAsDoneActionId: null,
      editActionRecord: null,
      isEdit: false,
      assigneeData: [],
      emailData: [],
      accessLevel: 0,
    };
  }

  componentDidMount() {
    this.getActionPlanList();
    this.getSuggestedActions();
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    const token = window.location.search.split('?token=')[1];
    this.props.navigationActions.activeItemAction({
      item: MenuItems.Actionplan,
      showReportSubMenu: false,
      disableReportMenu: false,
    });
    this.setState({ accessLevel: accessLevel });

    surveyService
      .getSurveysNameAndToken(token)
      .then((response) => {
        const surveyRecordList = getTransformedSurveyRecordList(response.data);
        this.setState({ latestSurveyOptions: surveyRecordList });

        const token = window.location.search;
        var selectedRecord = surveyRecordList.filter(
          (item) => '?token=' + item.value === token
        );
        if (selectedRecord.length > 0) {
          this.setState({ latestSurveyOption: selectedRecord[0] });
        }
      })
      .catch();
  }

  handleMarkAsDoneEvent = (actionId) => {
    this.setState({
      isMarkAsDoneDialog: true,
      markAsDoneActionId: actionId,
    });
  };

  getActionPlanList() {
    const { loaderActions } = this.props;
    loaderActions.dataLoading(true);
    const surveyToken = getQueryStringVal(QueryStringTokens.token);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    actionPlanService
      .getActionPlan(surveyToken, schoolToken)
      .then((response) => {
        if (response.success) {
          var rec = response.data;

          // get actions with unique email and assignee
          let uniqueActions = this.getUniqueActions(rec);

          const emailRec = uniqueActions
            .filter((x) => x.Email !== '' && x.Email !== null)
            .map((x) => {
              return { id: x.Id, name: x.Email };
            });
          const assigneeRec = uniqueActions
            .filter((x) => x.Assignee !== '' && x.Assignee !== null)
            .map((x) => {
              return { id: x.Id, name: x.Assignee };
            });

          this.setState({
            actionPlanData: rec,
            assigneeData: assigneeRec,
            emailData: emailRec,
          });
        }
        loaderActions.dataLoading(false);
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  }

  getUniqueActions(actionRecords) {
    let filteredActions = [];
    actionRecords.map((element) => {
      let existingRecords = filteredActions.filter(
        (item) =>
          (element.Email === item.Email) & (element.Assignee === item.Assignee)
      );
      if (existingRecords.length === 0) filteredActions.push(element);
    });

    return filteredActions;
  }

  getSuggestedActions() {
    suggestedActionsService
      .getSuggestedActions()
      .then((response) => {
        if (response.success) {
          this.setState({ suggestedActionOptions: response.data });
        }
      })
      .catch((error) => {});
  }

  handleSearchChange = () => {
    let searchInputField = document.getElementById('searchInputField');
    const searchText = searchInputField.value;

    this.setState({ searchValue: searchText });
  };

  handleSortingByTypeChange = (sortingByTypeOption) => {
    this.setState({ sortingByTypeOption });
  };

  handleSurveyValueChange = (latestSurveyOption) => {
    this.setState({ latestSurveyOption });
    const role = storageService.getItem(WebStorageNames.Role);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (role === Roles.GroupDirector || schoolToken !== null) {
      window.location.search =
        '?token=' + latestSurveyOption.value + '&schoolToken=' + schoolToken;
    } else window.location.search = '?token=' + latestSurveyOption.value;
    this.getSurveyReportData(latestSurveyOption.value);
  };

  filterByActionType = (sortingByTypeOption, actionsList) => {
    return actionsList.filter(
      (item) => item.IsCompleted === sortingByTypeOption.value
    );
  };

  handleSuggestionValueChange = (suggestedActions) => {
    this.setState({ suggestedActions });
  };

  handleAddSurveySubmit = (values) => {
    actionPlanService
      .addActionPlanAction(values)
      .then((response) => {
        this.setState({ createActionFormDialogOpen: false });
        toast.success('Action are added successfully.');
        this.getActionPlanList();
      })
      .catch((error) => console.log(error));
  };

  handleSortingByDateChange = (sortingByDateOption) => {
    this.setState({ sortingByDateOption });
  };

  handleSuggestedActionAddToPlan = (suggestedAction) => {
    const token = getQueryStringVal('token');
    token === 'null'
      ? toast.warning('Need to create survey first.')
      : this.setState({
          createActionFormDialogOpen: true,
          editActionRecord: suggestedAction,
          isEdit: false,
        });
  };

  filterSortByDateOrder = (sortingSelectedOption, comments) => {
    comments.sort((a, b) => {
      const firstDate = new Date(a.DueDate);
      const secondDate = new Date(b.DueDate);
      if (a.DueDate === null) {
        return 1;
      }
      if (b.DueDate === null) {
        return -1;
      }
      if (sortingSelectedOption.value === SortOrder.DueLate) {
        return secondDate - firstDate; //newest sort
      }
      return firstDate - secondDate; //oldest sort
    });
  };

  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }

  handleCreateAction = () => {
    const token = getQueryStringVal('token');
    token === 'null'
      ? toast.warning('Need to create survey first.')
      : this.setState({
          createActionFormDialogOpen: true,
          editActionRecord: null,
          isEdit: false,
        });
  };

  suggestedActionsCancel = (event) => {
    event.preventDefault();
    this.setState({ createActionFormDialogOpen: false });
  };

  onMarkAsDoneDialogOkClick = () => {
    actionPlanService
      .markActionAsDone(this.state.markAsDoneActionId)
      .then((response) => {
        if (response.success) {
          this.setState({ isMarkAsDoneDialog: false });
          this.getActionPlanList();
        }
      })
      .catch((error) => error);
  };

  closeMarkAsDoneDialog = () => {
    this.setState({ isMarkAsDoneDialog: false });
  };

  handleActionEdit = (selectedAction) => {
    this.setState({
      editActionRecord: selectedAction,
      createActionFormDialogOpen: true,
      isEdit: true,
    });
  };

  render() {
    const {
      createActionFormDialogOpen,
      sortingByTypeOption,
      sortingByDateOption,
      suggestedActions,
      suggestedActionOptions,
      latestSurveyOption,
      actionPlanData,
      latestSurveyOptions,
      isRole,
      isMarkAsDoneDialog,
      markAsDoneActionId,
      editActionRecord,
      isEdit,
      accessLevel,
    } = this.state;

    let filteredActions = actionPlanData;

    if (filteredActions.length > 0) {
      if (sortingByDateOption) {
        this.filterSortByDateOrder(sortingByDateOption, filteredActions);
      }
      if (sortingByTypeOption) {
        filteredActions = this.filterByActionType(
          sortingByTypeOption,
          filteredActions
        );
      }
    }

    let actionsHeading = sortingByTypeOption.value
      ? 'Archived Actions'
      : 'Open Actions';

    return (
      <ActionPlanMainWrapper>
        <div className='left-container'>
          <LeftMenu />
        </div>

        <div className='right-container'>
          <MainHeader />
          <Grid container>
            <Grid item md={6} sm={5} xs={12}>
              <AssistanceWrapper>
                <h2>Action Plan</h2>
              </AssistanceWrapper>
            </Grid>

            <Grid item md={6} sm={7} xs={12}>
              <AssistanceWrapper>
                {/* <div className='btn-request-assistance'>       
								<RequestAssistanceButton />
							</div> */}
                <div className='dd-survey'>
                  <SurveyDropDown
                    isRole={isRole}
                    latestSurveyOptions={latestSurveyOptions}
                    latestSurveyOption={latestSurveyOption}
                    handleSurveyValueChange={this.handleSurveyValueChange}
                  />
                </div>
              </AssistanceWrapper>
            </Grid>

            <Grid item md={12} xs={12}>
              <ExtendedSubContainer>
                {actionPlanData.length > 0 ? (
                  <SearchFilterWrapper>
                    <div>
                      <div className='search-filters'>
                        <Dropdown
                          name='SortByTypeFilter'
                          options={actionTypeOptions}
                          selectedOption={sortingByTypeOption}
                          onChange={this.handleSortingByTypeChange}
                          value={sortingByTypeOption}
                        />
                      </div>
                      <div className='search-sorting'>
                        <Dropdown
                          name='SortByDateFilter'
                          options={sortingOptions}
                          selectedOption={sortingByDateOption}
                          onChange={this.handleSortingByDateChange}
                          value={sortingByDateOption}
                        />
                      </div>
                    </div>
                  </SearchFilterWrapper>
                ) : null}
                <div className='search-data-container'>
                  <div className='search-inner-container'>
                    {filteredActions.length > 0 ? (
                      <CreateActionPlanWrapper>
                        <div className='search-inner-sub-container'>
                          <h2>{actionsHeading}</h2>
                          {accessLevel !== HeadTeacherAccess.View && (
                            <button
                              onClick={this.handleCreateAction}
                              className='add-btn'
                            >
                              +
                            </button>
                          )}
                          <Root>
                            <table>
                              <thead>
                                <tr>
                                  <th>Action</th>
                                  <th>Category</th>
                                  <th>Assignee</th>
                                  <th>Date</th>
                                  <th style={{ textAlign: 'center' }}>
                                    Mark As Done
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredActions.map((actiondata, key) => (
                                  <tr key={key}>
                                    <td
                                      onClick={() => {
                                        if (
                                          accessLevel === HeadTeacherAccess.Full
                                        ) {
                                          this.handleActionEdit(actiondata);
                                        }
                                      }}
                                    >
                                      {actiondata.Title}
                                    </td>
                                    <td
                                      className='action-category'
                                      onClick={() => {
                                        if (
                                          accessLevel === HeadTeacherAccess.Full
                                        ) {
                                          this.handleActionEdit(actiondata);
                                        }
                                      }}
                                    >
                                      {actiondata.CategoryName}
                                    </td>
                                    <td
                                      onClick={() => {
                                        if (
                                          accessLevel === HeadTeacherAccess.Full
                                        ) {
                                          this.handleActionEdit(actiondata);
                                        }
                                      }}
                                    >
                                      {actiondata.Assignee}
                                    </td>
                                    <td>
                                      <span
                                        onClick={() => {
                                          if (
                                            accessLevel ===
                                            HeadTeacherAccess.Full
                                          ) {
                                            this.handleActionEdit(actiondata);
                                          }
                                        }}
                                        className={
                                          actiondata.category === 'Role'
                                            ? 'action-plan-date alert-date'
                                            : 'action-plan-date'
                                        }
                                      >
                                        {DateFormat(actiondata.DueDate)}
                                      </span>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      {sortingByTypeOption.value === false &&
                                        (this.state.isRole ===
                                          Roles.HeadTeacher ||
                                          this.state.isRole ===
                                            Roles.SuperAdmin) &&
                                        accessLevel !==
                                          HeadTeacherAccess.View && (
                                          <span>
                                            <i
                                              onClick={() =>
                                                this.handleMarkAsDoneEvent(
                                                  actiondata.Id
                                                )
                                              }
                                            >
                                              {isMarkAsDoneDialog === true &&
                                              markAsDoneActionId ===
                                                actiondata.Id ? (
                                                <Checkbox defaultChecked />
                                              ) : (
                                                <div className='action-plan-body'>
                                                  <Checkbox
                                                    style={{ padding: '0px' }}
                                                  />
                                                </div>
                                              )}
                                            </i>
                                          </span>
                                        )}
                                      {sortingByTypeOption.value === true &&
                                        (this.state.isRole ===
                                          Roles.HeadTeacher ||
                                          this.state.isRole ===
                                            Roles.SuperAdmin) &&
                                        accessLevel !==
                                          HeadTeacherAccess.View && (
                                          <div className='action-plan-body'>
                                            <span
                                              className='circle-parent-wrapper archived '
                                              title='Already Marked'
                                            >
                                              <i className='circle-tick'>
                                                <span>&#10003;</span>
                                              </i>
                                            </span>
                                          </div>
                                        )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Root>
                        </div>
                      </CreateActionPlanWrapper>
                    ) : (
                      <CreateActionPlan
                        isOpen={this.handleCreateAction}
                        isArchived={sortingByTypeOption.value}
                        accessLevel={accessLevel}
                      />
                    )}

                    <div className='suggested-actions-wrapper'>
                      <ActionPlanDropdown
                        name='SuggestedActions'
                        options={suggestedActionOptions}
                        selectedOption={suggestedActions}
                        onChange={this.handleSuggestedActionAddToPlan}
                        value={suggestedActions}
                        withButton={true}
                      />
                    </div>
                  </div>
                </div>
              </ExtendedSubContainer>
            </Grid>
          </Grid>
        </div>

        {createActionFormDialogOpen ? (
          <ActionsPlanForm
            title={'Create action'}
            submitButtonText={'Add Survey'}
            isOpen={createActionFormDialogOpen}
            onClose={this.suggestedActionsCancel}
            handleSubmit={this.handleAddSurveySubmit}
            editActionRecord={editActionRecord}
            isEdit={isEdit}
            className={'xyz'}
            emailRecord={this.state.emailData}
            assigneeRecord={this.state.assigneeData}
            actionPlanData={this.state.actionPlanData}
          />
        ) : null}
        {isMarkAsDoneDialog ? (
          <ConfirmPopupForm
            isOpen={isMarkAsDoneDialog}
            successPopupMessage='Are you sure you want to mark this action as complete?'
            onOk={this.onMarkAsDoneDialogOkClick}
            onClose={this.closeMarkAsDoneDialog}
          />
        ) : null}
      </ActionPlanMainWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  actionPlanActions: bindActionCreators(actionPlanActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(actionPlan));
