import React from 'react';

const LearnWelbee = () => {
  return (
    <div>
      {/* <span className='report-pictures'>
        <img className='screenshots' src={welbeesurvey} alt='' />
      </span> */}
      <p>
        Welbee has been created to support busy school leaders who want to
        tackle the challenges of poor staff wellbeing and achieve the associated
        benefits of higher staff retention, lower staff absences, improved
        motivation, better outcomes and lower costs.
      </p>
      <p>
        It uses an evidence created survey (the Health and Safety Executive’s
        Indicator tool), with a detailed and personalised reporting framework
        built with the support and feedback of a panel of cross phase and sector
        schools. The aim is to make measuring and improving staff wellbeing
        easier for busy school leaders and their staff.
      </p>
      <p>
        The focus is not simply on measurement, though this is important to give
        you a starting or current position. More importantly we highlight
        strengths, areas for focus and recommend actions, as that is what school
        leaders told us they wanted most.
      </p>
      <p>
        The results allow you to engage with staff in having open and honest
        conversations and in creating solutions. You can even anonymously
        respond to comments that staff make to better address concerns, find out
        more about what they really think and to increase the likelihood of
        their buy-in and engagement.
      </p>
    </div>
  );
};

export default LearnWelbee;
