import React from 'react';
import { withRouter } from 'react-router-dom';

const VideoButton = ({ onClick }) => {
  return (
    <div className='video-button'>
      <button onClick={onClick}>Play the quick start video</button>
    </div>
  );
};

export default withRouter(VideoButton);
