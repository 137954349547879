import request from '../request';
import { schoolStaffAPI } from './urls';

const addSchoolStaff = (model) => {
  return request({
    url: schoolStaffAPI.AddSchoolStaff,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const saveSchoolStaff = (model) => {
  return request({
    url: schoolStaffAPI.saveSchoolStaff,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const ManuallySendLink = (model) => {
  return request({
    url: schoolStaffAPI.ManuallySendLink,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const grantSchoolStaffAccessLevel = (model) => {
  return request({
    url: schoolStaffAPI.SchoolStaffAccessLevel,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const revokeStaffAccessLevel = (id) => {
  return request({
    url: schoolStaffAPI.RevokeStaffAccess(id),
    method: 'POST',
  })
    .then((response) => response)
    .catch((error) => error);
};

const schoolStaffExist = (id, schoolToken = null) => {
  schoolToken = schoolToken === null ? '' : schoolToken;
  return request({
    url: schoolStaffAPI.SchoolStaffExist(id, schoolToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const exportSchoolStaff = (schoolId, SubscriptionId, isTemplate) => {
  return request({
    url: schoolStaffAPI.exportSchoolStaff(schoolId, SubscriptionId, isTemplate),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};

const uploadStaffExcel = (model) => {
  return request({
    url: schoolStaffAPI.uploadStaffExcel,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const getNumberOfEmployees = (schoolId) => {
  return request({
    url: schoolStaffAPI.getNumberOfEmployees(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const getBySchoolId = (id) => {
  return request({
    url: schoolStaffAPI.getBySchoolId(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const getById = (id) => {
  return request({
    url: schoolStaffAPI.getById(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const ValidateSchoolExcel = (model) => {
  return request({
    url: schoolStaffAPI.ValidateSchoolExcel,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const getFiltersBySchoolId = (schoolId) => {
  return request({
    url: schoolStaffAPI.getFiltersBySchoolId(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const getFilterValuesBySchoolId = (schoolId) => {
  return request({
    url: schoolStaffAPI.getFilterValuesBySchoolId(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const getFilterLibrary = () => {
  return request({
    url: schoolStaffAPI.getFilterLibrary(),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getSchoolStaff = (model) => {
  return request({
    url: schoolStaffAPI.getSchoolStaff,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((response) => response);
};

const exportStaffExcelTemplate = (schoolId) => {
  return request({
    url: schoolStaffAPI.exportStaffExcelTemplate(schoolId),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};

const exportStaffExcel = (schoolId) => {
  return request({
    url: schoolStaffAPI.exportStaffExcel(schoolId),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getStaffHeatMapFilters = (schoolId) => {
  return request({
    url: schoolStaffAPI.GetStaffFiltersBySchool(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const CheckStaffAccess = (staffId) => {
  return request({
    url: schoolStaffAPI.CheckStaffAccess(staffId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const schoolStaffService = {
  getBySchoolId,
  schoolStaffExist,
  addSchoolStaff,
  saveSchoolStaff,
  ManuallySendLink,
  grantSchoolStaffAccessLevel,
  revokeStaffAccessLevel,
  exportSchoolStaff,
  getNumberOfEmployees,
  ValidateSchoolExcel,
  getById,
  getFiltersBySchoolId,
  getFilterValuesBySchoolId,
  getFilterLibrary,
  getSchoolStaff,
  uploadStaffExcel,
  exportStaffExcelTemplate,
  exportStaffExcel,
  getStaffHeatMapFilters,
  CheckStaffAccess,
};

export default schoolStaffService;
