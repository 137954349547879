import React, { Component } from 'react';
import { Steps } from 'intro.js-react';
import Grid from '@mui/material/Grid';
import { AppModel } from '../../components';
import {
  WelbeeTour,
  AppButton,
  LinkButton,
} from '../../resources/styling/appStyle';
import {
  tourdashboard,
  tourcomments,
  touropencomments,
  touranalytics,
  tourvoicedashboard,
  tourvoicemsgs,
  tourcreatesurvy,
  tourwelcome,
  welbeeLogoRed,  
  tourreport,
  tourthankyou,
  touractionplan,
  tourquestionbank,
  tourpresentation,
  touruserguide,
  tourstaffmanagement,
} from '../../resources/images/index';
import 'intro.js/introjs.css';
import { colors } from '../../resources/theme/colors';
const { outrageousOrange, teal } = colors;

class GuidedTourPremium extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      options: {
        showBullets: false,
        exitOnOverlayClick: false,
        hidePrev: true,
        doneLabel: 'Finish',
        tooltipClass: 'guided-tour',
        buttonClass: 'tour-btn',
        highlightClass: 'tour-highlight',
      },
      stepsEnabled: false,
      initialStep: 0,

      steps: [
        {
          element: '.menu-dashboard',
          title: 'Your Dashboard',
          intro: (
            <div>
              <img src={tourdashboard} alt='Dashbaord' />
              <div>
              This is where you will see a summary of all scores against the Health and Safety Executive’s (HSE) Management Standards. Scores are out of five and each one is benchmarked against other schools who have taken the survey, as well as the HSE’s own cross-sector benchmark.<br/><br/>Your performance against the benchmark is shown by your Decile, with the 1st Decile being the highest (your staff have scored in the top 10%) and the 10th Decile being the lowest (more than 90% of those taking the survey have scored higher than your staff).
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.menu-dashboard',
          title: 'Why Benchmark',
          intro: (
            <div>
              <img src={tourdashboard} alt='Dashbaord' />
              <div>
              The school benchmark for each of the Standards is important, as it allows you to quickly see the comparative performance, rather than simply looking at your score. This means you can properly identify your current position.<br/><br/>Your scores are shown from the comparative strongest (top line left) to the comparative weakest (second line right). For second and future years a graph will also show your progress.
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.menu-report',
          title: 'Your Report',
          intro: (
            <div>
              <img src={tourreport} alt='Dashbaord' />
              <div>
              This is where you can see comprehensive results. These share your scores, benchmarks, strengths and possible areas for focus.<br/><br/>This is also where you are presented with suggested actions based on the feedback from your staff. There can be up to three for each Standard and as well as suggesting what to do, you can see a chart of those staff that have scored the highest and lowest against the relevant questions. This will help you to target action where it will provide the greatest support and reduce your workload. 
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.menu-heatmaps',
          title: 'Heatmaps',
          intro:
            'While the report does highlight those staff groups likely to require the greatest support, heatmaps allow you to quickly see all filters and compare scores. This means you can celebrate and learn from areas with higher scores, as well as target action to those areas with lower scores.<br/>As a premium customer you have some choice in deciding what filters you want to use and you will see additional heatmaps for these.<br/><Strong>Question Heatmaps</Strong><br/>Welbee does the hard work for you in analysing and suggesting actions. However, for those that love data, you can look behind the dashboard and see heatmaps and benchmarks for every question, together with how many staff answered. To access these simply click on each of the scores in your dashboard.',
          position: 'right',
        },
        {
          element: '.menu-comments',
          title: 'Comments',
          intro: (
            <div>
              <img src={tourcomments} alt='Comments' />
              <div>
              Comments to open questions are shown here. As well as being able to word search, filter and flag comments, so you can easily review only those you want to go back to…
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.menu-comments',
          title: 'Comments',
          intro: (
            <div>
              <img src={touropencomments} alt='Comments' />
              <div>
              …you can respond to comments while staff remain anonymous. You can thank staff, seek clarification or deal directly with more challenging issues. You will not know who you are writing to or who responds, important for receiving open feedback and in maintaining trust.
              </div>
            </div>
          ),
          position: 'right',
        },        
        {
          element: '.menu-analytics',
          title: 'Analytics',
          intro: (
            <div>
              <img src={touranalytics} alt='Analytics' />
              <div>                
                Here you can track performance and results between surveys and for every filter, including those you have chosen. This makes it easier to understand the impact of actions you take and to build on them over time. It also means you will be meeting commitment 11 of the DfE’s Staff Wellbeing Charter. Simply use the boxes at the top to choose the results you want to review. Those areas with changes greater than 0.2% will also be shown in your report.
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.menu-actionplan',
          title: 'Action Plan',
          intro:(
            <div>
              <img src={touractionplan} alt='Action Plan' />
              <div>
                We recommend any actions you take are included in your development plan, as wellbeing has to be an integral part of the school culture. You can record and assign specific actions from within your dashboard to support this.
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.menu-presentation',
          title: 'Presentation',
          intro:(
            <div>            
              <img src={tourpresentation} alt='Presentation' />
              <div>
                Quickly sharing results with staff is important to show you are listening and to engage them in solutions. You will probably want to include other stakeholders too. A downloadable and fully editable PowerPoint is provided to make this easy for you.
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.menu-feature',
          title: 'Voice',
          intro: (
            <div>
              <img src={tourvoicedashboard} alt='Voice Dashbaord' />
              <div>
              A second online feedback tool is also included to help you keep wellbeing alive throughout the year. You can use it to follow up on post-survey actions, during consultations and anytime you want to know what staff might be thinking on a specific issue or opportunity.<br/><br/>Most importantly you can engage only those staff of interest, sending invitations to groups of 8 or more staff, as well as to all staff. This means you can have ongoing anonymous two-way conversations.
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.menu-feature',
          title: 'Voice Conversation',
          intro: (
            <div>
              <img src={tourvoicemsgs} alt='Voice Dashbaord' />
              <div>
              Starting a conversation is very easy - simply type in the subject and message in the box provided and select to invite all staff or choose a smaller group of staff that you would like to invite.
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.menu-staff',
          title: 'Staff Management',
          intro: (
            <div>
              <img src={tourstaffmanagement} alt='Staff Management'/>
              <div>
              To use all of Welbee’s platform, analysis and suggested actions, you will need to carry out a small amount of administration to help set up your account. We will need to upload your staff records, to make sure we can manage invitations for you and boost participation, as well as effectively filtering your scores. This will make it much easier to take action. We will also need to make sure you choose the most effective filters for you. Details are shown in this <a href='https://knowledge.welbee.co.uk/uploading-your-staff-records' target='_blank'>user guide</a>.
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.menu-question',
          title: 'Question Bank',
          intro:(
            <div>
              <img src={tourquestionbank} alt='Question Bank' />
              <div>
                This is where you can add your own additional questions. Choose your own or use the question bank provided to select them. When using Likert scales, you will need to ensure you choose the right scoring (whether 5 or 1 is the highest or lowest score). You can also amend the two default open questions or add your own.<br/><br/>We would recommend that you ideally add no more than 5 additional questions, to make sure the survey does not become a burden – the core survey will take around 10 minutes and feedback suggests it is well received by most staff.
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.menu-userguide',
          title: 'User Guide',
          intro:(
            <div>
              <img src={touruserguide} alt='User Guide' />
              <div>
                Finally you can see full instructions in the user guide and also access the Welbee Toolkit from there too. This user guide also provides advice on achieving higher participation and a suggested message you can use to engage staff. The toolkit has resources available to support you, your leaders and staff.
              </div>
            </div>
          ),
          position: 'right',
        },
        {
          element: '.dashboard',
          title: 'Dashboard',
          intro:
            'Once you are all set up you will want to agree your survey start date and brief your staff on why you are doing the survey and what you will do with the results.<br/><br/>You will also need to add survey@welbee.co.uk and admin@welbee.co.uk to your safe senders list as this will mean high levels of invitation delivery are likely. We send via AWS and your IT support should be able to do this relatively quickly.<br/><br/>There is further guidance on all these in your user guide and welcome email.',
        },
        {
          element: '.dashboard',
          title: 'Dashboard',
          intro: (
            <div>
              <img src={tourcreatesurvy} alt='Create Survey' />
              <div>
              Then you can create your survey and set your start date and time. Everything is then largely automated, with staff sent up to three invitations. Staff who participate will not receive further invitations and you can also send additional invites, where needed, directly from your dashboard.<br/><br/>Depending on how you organise how staff will take the survey, for example giving them specific time in schools, you are likely to keep it open for one to two weeks.
              </div>
            </div>
          ),
        },
        {
          element: '.dashboard',
          title: '',
          intro: (
            <div className='tour-thankyou'>               
              <img src={tourthankyou} alt='Thank You' />
              <div>
                Thank you for taking the tour and being a Welbee customer. We love having you here.<br/><br/>If you have questions or need any support you can click the green email icon top right in your dashboard or simply email us at <a href="mailto:support@welbee.co.uk">support@welbee.co.uk</a>.
              </div>
            </div>
          ),
        },
      ],
      hintsEnabled: false,
      showModal: true,      
    };
  }

  componentDidMount() {
    if (!this.props.showQuestionBank) {
      this.setState({
        steps: this.state.steps.filter(function (obj) {
          return obj.title !== 'Questions';
        }),
      });
    }
  }

  onAfterChange = nextStepIndex => {
    if (nextStepIndex === 4 || nextStepIndex === 5 || nextStepIndex === 12  || nextStepIndex === 13 || nextStepIndex === 7 || nextStepIndex === 11) {
      this.setState({
        options: {
          tooltipClass: 'guided-tour comments-box',
        },
      })
    }
    else if (nextStepIndex === 14 || nextStepIndex === 15 || nextStepIndex === 16) {
      this.setState({
        options: {
          tooltipClass: 'guided-tour dashboard-box',
        },
      })
    } else {
      this.setState({
        options: {
          tooltipClass: 'guided-tour',
        },
      })
    }
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));    
    this.setState({ openGuidedTour: false });    
  };

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
    this.setState({ showModal: false });
  };

  isShowPopup = () => {
    this.setState({ showModal: false });
  };  

  render() {
    const { stepsEnabled, steps, initialStep, options } = this.state;

    return (
      <div>
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={this.onExit}
          onAfterChange={this.onAfterChange}
          options={options}
        />

        <AppModel
          open={this.state.showModal}
          onClose={this.isShowPopup}
          bodyText={
            <WelbeeTour>
              <Grid container>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  <img
                    src={welbeeLogoRed}
                    alt='Welbee'
                    style={{ margin: '0' }}
                  />
                  <h2>Welcome to Welbee</h2>
                  <p>
                    Take a quick tour to see how easy it is to use Welbee
                    <br />
                    and realise the evidence proven benefits of improving staff
                    wellbeing.
                  </p>

                  <img
                    src={tourwelcome}
                    alt='Welbee'
                    style={{ margin: '20px 0' }}
                  />
                </Grid>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  <AppButton
                    color={outrageousOrange}
                    type='button'
                    onClick={this.toggleSteps}
                  >
                    Start Tour
                  </AppButton>
                  

                  {/* <AppButton
                    color={teal}
                    type='button'
                    //onClick={this.toggleSteps}
                    style={{ marginLeft: '10px' }}
                  >
                    Watch Video Tour
                  </AppButton> */}
                </Grid>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  <LinkButton
                    type='button'
                    onClick={this.isShowPopup}
                    style={{ marginTop: '10px' }}
                  >
                    Maybe Later
                  </LinkButton>
                </Grid>
              </Grid>
            </WelbeeTour>
          }
        />        

      </div>
    );
  }
}
export default GuidedTourPremium;