import React, { Component } from 'react';
import { LeftMenu, MainHeader } from '../../components';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { AssistanceWrapper } from '../../resources/styling/appStyle';
import { ExtendedVoiceDashboardWrapper, AdminDashboardBody } from './SchoolSubscriptionsStyle';

export default class NotSubscribed extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <ExtendedVoiceDashboardWrapper>
                <div className='left-container'>
                    <LeftMenu />
                </div>
                <div className='right-container'>
                    <MainHeader />
                    <AdminDashboardBody>
                        <div className='content-body-inner-wrapper' style={{textAlign:'center'}}>
                            You can display and download the Presentation by upgrading your account.
                        </div>
                    </AdminDashboardBody>
                </div>
            </ExtendedVoiceDashboardWrapper>
        );
    }
}
