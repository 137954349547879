import React, { Component, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme';
import {
  LeftMenu,
  SurveyDropDown,
  HeatmapCell,
  MainHeader,
} from '../../components';
import { heatmapthumbnail } from '../../resources/images/index';
import { AppButton } from '../../resources/styling';
import { routesObj } from '../../routes';
import GraphPopup from '../dashboard/GraphPopup';
import CloseIcon from '@mui/icons-material/Close';
import { iconDownload, arrowDown } from '../../resources/images/index';
import Tooltip from '@mui/material/Tooltip';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  SurveyTypes,
  MenuItems,
  QueryStringTokens,
  WebStorageNames,
  Roles,
  AccessPermissions,
} from '../../utils/Constants';
import {
  staffResultServices,
  surveyService,
  storageService,
} from '../../services';
import { limitAverageValue } from '../../utils/roundScore';
import {
  DownloadButtonStyle,
  ExtendedSubContainer,
} from '../../resources/styling/appStyle';
import { loaderAction } from '../../store/loaderStore';
import {
  getTransformedSurveyRecordList,
  getQueryStringVal,
} from '../../utils/CommonFunctions';
import { navigationActions } from '../../store/headerStore';
import { toast } from 'react-toastify';
import IndividualHeatmapSelectionForm from '../staffRoleHeatmap/IndividualHeatmapSelectionForm';
import { IsActionAllowed, RestrictedActions } from '../../utils';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
  CommentsMainWrapper,
  AssistanceWrapperHeadingExt,
  ExtendedHeatMapAssistanceWrapper,
} from './StaffRoleHeatmapStyle';
import CoreFilterSearchBox from './customHeatmap/CoreFilterSearchBox';
import CustomHeatmap from './customHeatmap/CustomHeatmap';
import AccessLevel from '../../components/common/AccessLevel';
import BackdropLoader from '../../components/loader/BackdropLoader';

const { whiteColor, minttulip, yourpink, lightminttulip, teal, lightyourpink } =
  colors;

const SortOrder = {
  Newest: 'Support staff',
  Oldest: 'Teaching staff',
};
class StaffRoleHeatmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortingSelectedOption: {
        value: SortOrder.Newest,
        label: 'Filter by staff type',
      },
      latestSurveyOption: { value: '', label: 'Latest survey' },
      latestSurveyOptions: [{ value: SurveyTypes.any, label: 'Latest survey' }],
      allStaff: null,
      jobRole: [],
      timeAtSchool: [],
      employmentType: [],
      additionalFilters: [],
      errorMessage: null,
      collapseJobRole: false,
      collapseTimeAtSchool: false,
      collapseEmploymentType: false,
      collapseAdditionalFilters: [],
      isOpen: false,
      isLoading: false,
      downloadList: [
        { id: 1, value: true, label: 'Job Role' },
        { id: 2, value: true, label: 'Time At School' },
        { id: 3, value: true, label: 'Employment Type' },
      ],
      selectAll: true,
      isAllowed: false,
      isAllowedDownload: false,
      open: false,
      dropdownText: 'Heatmap Filters',
      Customheatmap: [],
      isCustomHeatmap: false,
      isCustomHeatmapLoading: false,
      customHeatmapError: '',
      schoolHeatmaps: [],
      mainHeatMapDownloadVisible: true,
    };
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onSelectionAll = this.onSelectionAll.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
    this.routeToAccount = this.routeToAccount.bind(this);
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    const token = window.location.search.split('token=')[1];

    this.props.navigationActions.activeItemAction({
      item: MenuItems.Staff,
    });
    Promise.all([
      IsActionAllowed(RestrictedActions.ColorHeatMaps),
      IsActionAllowed(RestrictedActions.DownloadReport),
      staffResultServices.getStaffResultHeatMap(token),
      surveyService.getSurveysNameAndToken(token),
    ])
      .then(([colorHeatMapsResponse, downloadReportResponse, staffResultResponse, surveysResponse]) => {
        this.setState({ isAllowed: colorHeatMapsResponse.data });
        this.setState({ isAllowedDownload: downloadReportResponse.data });
        loaderAction.dataLoading(true);
        const { success, data, message } = staffResultResponse;
        const surveyRecordList = getTransformedSurveyRecordList(surveysResponse.data);
        this.setState({ latestSurveyOptions: surveyRecordList });

        if (success) {
          var filterCollapse = [];
          var additionalFilterDownloadList = [];
  
          additionalFilterDownloadList.push(...this.state.downloadList);
          this.setState({ heatMap: data });

          const region = storageService.getItem(WebStorageNames.UsRegion);
          const addFilters = data?.AdditionalFilters?.map((obj) => {
            if (obj.FilterName === 'Phase Or Year') {
              if (region === 'us') return { ...obj, FilterName: 'Grade' };
              else return { ...obj, FilterName: 'Year Or Key Stage' };
            }
            return obj;
          });
  
          this.setState({
            schoolHeatmaps: data,
            allStaff: data.AllStaff,
            jobRole: data.JobRole,
            timeAtSchool: data.TimeAtSchool,
            employmentType: data.EmploymentType,
            additionalFilters: addFilters,
          });
          addFilters?.map((item, index) => {
            filterCollapse[item.FilterName] = false;
            additionalFilterDownloadList.push({
              id: 4 + index,
              value: true,
              label: item.FilterName,
            });
          });
  
          this.setState({
            collapseAdditionalFilters: filterCollapse,
            downloadList: additionalFilterDownloadList,
          });
        } else {
          this.setState({ errorMessage: message });
        }
    
        const token = window.location.search;
        var selectedRecord = surveyRecordList.filter(
          (item) => '?token=' + item.value === token
        );
  
        if (selectedRecord.length < 1) {
          var newToken = token.split('&schoolToken=');
          selectedRecord = surveyRecordList.filter(
            (item) => '?token=' + item.value === newToken[0]
          );
        }
  
        if (selectedRecord.length > 0) {
          this.setState({ latestSurveyOption: selectedRecord[0] });
        }    
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.error(error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }
  routeToAccount() {
    this.props.history.push(routesObj.Account.path);
  }

  downloadPdf() {
    const { loaderAction } = this.props;
    const token = getQueryStringVal(QueryStringTokens.token);
    let heatmaps = '';
    this.state.downloadList.forEach((h) => {
      if (h.value) heatmaps += h.id + ',';
    });
    if (heatmaps === '') {
      toast.error('Please select atleast one heatmap to download.');
      return;
    }
    loaderAction.dataLoading(true);
    staffResultServices
      .getStaffResultHeatMapDownload(token, heatmaps)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'welbee-heatmap.pdf');
        document.body.appendChild(link);
        link.click();
        this.setState({ isOpen: false });
        loaderAction.dataLoading(false);
      })
      .catch(loaderAction.dataLoading(false));
  }

  onSelectionChange(obj, value) {
    let newList = this.state.downloadList;
    let item = newList.find((x) => x.id == obj.id);
    item.value = value;
    let selectAll =
      newList.filter((i) => {
        if (i.value === true) return true;
        else return false;
      }).length === newList.length;
    this.setState({ selectAll: selectAll });
    this.setState({ downloadList: newList });
  }

  onSelectionAll(value) {
    let newList = this.state.downloadList;
    newList.map((item) => {
      item.value = value;
    });
    let selectAll =
      newList.filter((i) => {
        if (i.value === true) return true;
        else return false;
      }).length === newList.length;
    this.setState({ selectAll: selectAll });
    this.setState({ downloadList: newList });
    this.setState({ downloadList: newList });
  }
  
  boxColor(colorValue, boxValue) {
    if (!this.state.isAllowed) return whiteColor;
    if (colorValue >= 1 && colorValue <= 3 && boxValue !== -1) {
      return minttulip;
    } else if (colorValue >= 7 && colorValue <= 10 && boxValue !== -1) {
      return yourpink;
    }
    return whiteColor;
  }

  staffBoxColor(colorValue, boxValue) {
    if (!this.state.isAllowed) return whiteColor;
    if (colorValue >= 1 && colorValue <= 3 && boxValue !== -1) {
      return lightminttulip;
    } else if (colorValue >= 7 && colorValue <= 10 && boxValue !== -1) {
      return lightyourpink;
    }
    return whiteColor;
  }

  checkStaffValue(value) {
    if (value === -1) {
      return <span className='blank-entry'>-</span>;
    }
    return limitAverageValue(value);
  }

  handleSortingValueChange = (sortingSelectedOption) => {
    this.setState({ sortingSelectedOption });
  };

  handleSurveyValueChange = (latestSurveyOption) => {
    const role = storageService.getItem(WebStorageNames.Role);
    this.setState({ latestSurveyOption });
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (role === Roles.GroupDirector || schoolToken !== null) {
      window.location.search =
        '?token=' + latestSurveyOption.value + '&schoolToken=' + schoolToken;
    } else {
      window.location.search = '?token=' + latestSurveyOption.value;
    }
    this.getSurveyCommentsData(latestSurveyOption.value);
  };

  handleCloseSurveysSubmit = (values) => { };

  handleFilterChange = (filterName, IsFilterBenchMarksAvailable) => {
    let collapseState = this.state.collapseAdditionalFilters;
    collapseState[filterName] =
      !collapseState[filterName] && IsFilterBenchMarksAvailable;
    this.setState({
      collapseAdditionalFilters: collapseState,
    });
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClickAway = () => {
    this.setState({ open: false });
  };

  handleCustomHeatmap = (data, errorMsg) => {
    if (errorMsg !== '') {
      this.setState({ customHeatmapError: errorMsg });
      this.setState({ isCustomHeatmap: true });
    } else if (data?.length > 0) {
      const sortedData = [...data].sort((a, b) =>
        a.StaffType.localeCompare(b.StaffType)
      );
      this.setState({ Customheatmap: sortedData });
      this.setState({ customHeatmapError: '' });
      this.setState({ isCustomHeatmap: true });
    } else {
      this.setState({ mainHeatMapDownloadVisible: true });
      this.setState({ customHeatmapError: '' });
      this.setState({ isCustomHeatmap: false });
    }
  };
  handleSetCustomheatmap = (data) => {
    this.setState({ isCustomHeatmap: false });
  };

  handleSetLoading = (isLoading) => {
    loaderAction.dataLoading(isLoading);
    this.setState({ isCustomHeatmapLoading: isLoading });
  };

  handleMainHeatmapDownloadVisible = (isVisible) => {
    this.setState({ mainHeatMapDownloadVisible: isVisible });
  };

  render() {
    const {
      latestSurveyOptions,
      latestSurveyOption,
      allStaff,
      jobRole,
      timeAtSchool,
      employmentType,
      additionalFilters,
      errorMessage,
      open,
      isCustomHeatmap,
      Customheatmap,
      isCustomHeatmapLoading,
      customHeatmapError,
      schoolHeatmaps,
    } = this.state;

    const styles = {
      position: 'absolute',
      top: 50,
      right: 0,
      left: 0,
      zIndex: 1,
      width: '20%',
      boxShadow: '0 0 8px rgba(0,0,0,0.35)',
    };
    return (
      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.View]} >
        <CommentsMainWrapper>
          <div className='containerClass'>
            <div className='left-container'>
              <LeftMenu />
            </div>

            <div className='right-container'>
              <MainHeader />
              <Grid container>
                <Grid item xl={7} lg={6} sm={12} md={12} xs={12}>
                  <ExtendedHeatMapAssistanceWrapper>
                    <AssistanceWrapperHeadingExt>
                      <h2 style={{ margin: '0' }}>
                        Staff roles &amp; characteristics
                      </h2>
                      <span>
                        Compare survey results across all staff types, but note
                        that scores are only reported where there are 5 or more
                        participants in that category.
                      </span>
                    </AssistanceWrapperHeadingExt>
                  </ExtendedHeatMapAssistanceWrapper>
                </Grid>

                <Grid item xl={5} lg={6} sm={12} md={12} xs={12}>
                  <ExtendedHeatMapAssistanceWrapper>
                    <div className='header-bottom'>
                      <Tooltip title='Download' followCursor>
                        <div className='btn-download'>
                          {this.state.errorMessage == null &&
                            this.state.mainHeatMapDownloadVisible === true ? (
                            <DownloadButtonStyle
                              data-id-div='reportDownload'
                              className={
                                this.state.isAllowedDownload ? '' : 'tooltip'
                              }
                              onClick={() => {
                                this.state.isAllowedDownload
                                  ? this.setState({ isOpen: true })
                                  : this.setState({ isOpen: false });
                              }}
                            >
                              <img src={iconDownload} alt='' />{' '}
                              <span>Download</span>
                              {this.state.isAllowedDownload ? (
                                <></>
                              ) : (
                                <span className='tooltiptext'>
                                  You can download the heatmaps by upgrading your
                                  account.
                                </span>
                              )}
                            </DownloadButtonStyle>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Tooltip>
                      <div className='dd-survey'>
                        <SurveyDropDown
                          // isRole={isRole}
                          latestSurveyOptions={latestSurveyOptions}
                          latestSurveyOption={latestSurveyOption}
                          handleSurveyValueChange={this.handleSurveyValueChange}
                        />
                      </div>
                    </div>
                  </ExtendedHeatMapAssistanceWrapper>
                </Grid>
              </Grid>
             {this.state.isLoading && <BackdropLoader isShow={true}> </BackdropLoader>}            
              {errorMessage !== null ? (
                <ExtendedSubContainer>
                  <div className='search-data-container'>
                    <div className='staff-result-innercontainer'>
                      <div className='search-inner-sub-container'>
                        <div className='staff-type-grid'>
                          <div className='heatmap-error-msg'>{errorMessage}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ExtendedSubContainer>
              ) : (
               
                allStaff !== null && (                 
                  <Fragment>
                    <ExtendedSubContainer>
                      <CoreFilterSearchBox
                        handleCustomHeatmap={this.handleCustomHeatmap}
                        SchoolHeatmaps={schoolHeatmaps}
                        isSchoolFilter={true}
                        isMatFilters={false}
                        setCustomHeatmap={this.handleSetCustomheatmap}
                        handleSetLoading={this.handleSetLoading}
                        selectedFilter={
                          this.state.dropdownText === 'Heatmap Filters'
                            ? 'All Staff'
                            : this.state.dropdownText
                        }
                        SetMainDownloadVisisble={
                          this.handleMainHeatmapDownloadVisible
                        }
                      />
                      <div className='search-data-container'>
                        <div className='staff-result-innercontainer'>
                          <>
                            <ClickAwayListener
                              mouseEvent='onMouseDown'
                              touchEvent='onTouchStart'
                              onClickAway={this.handleClickAway}
                            >
                              <Box sx={{ position: 'relative' }}>
                                <button
                                  className='filter-dropdown'
                                  type='button'
                                  onClick={this.handleClick}
                                >
                                  {this.state.dropdownText}{' '}
                                  <span>
                                    <img src={arrowDown} alt='' />
                                  </span>
                                </button>
                                {open ? (
                                  <Box sx={styles} onClick={this.handleClickAway}>
                                    <div className='heatmap-filters'>
                                      <ul>
                                        <li
                                          className={`job-role-heading ${!this.state.collapseJobRole
                                            ? ''
                                            : 'active'
                                            }`}
                                          onClick={() => {
                                            this.setState({
                                              collapseJobRole: false,
                                              collapseTimeAtSchool: false,
                                              collapseEmploymentType: false,
                                              isCustomHeatmap: false,
                                              dropdownText: 'All Staff',
                                            });
                                            additionalFilters.forEach((items) => {
                                              let collapseState =
                                                this.state
                                                  .collapseAdditionalFilters;
                                              collapseState[
                                                items.FilterName
                                              ] = false;
                                              this.setState({
                                                collapseAdditionalFilters:
                                                  collapseState,
                                              });
                                            });
                                          }}
                                        >
                                          All Staff
                                        </li>
                                        <li
                                          className={`job-role-heading ${!this.state.collapseJobRole
                                            ? ''
                                            : 'active'
                                            }`}
                                          onClick={() => {
                                            this.setState({
                                              collapseJobRole: true,
                                              collapseTimeAtSchool: false,
                                              collapseEmploymentType: false,
                                              dropdownText: 'Job Role',
                                            });

                                            additionalFilters.forEach((items) => {
                                              let collapseState =
                                                this.state
                                                  .collapseAdditionalFilters;
                                              collapseState[
                                                items.FilterName
                                              ] = false;
                                              this.setState({
                                                collapseAdditionalFilters:
                                                  collapseState,
                                              });
                                            });
                                          }}
                                        >
                                          Job Role
                                        </li>
                                        <li
                                          className={`job-role-heading ${!this.state.collapseTimeAtSchool
                                            ? ''
                                            : 'active'
                                            }`}
                                          onClick={() => {
                                            this.setState({
                                              collapseJobRole: false,
                                              collapseTimeAtSchool: true,
                                              collapseEmploymentType: false,
                                              dropdownText: 'Time at School',
                                            });

                                            additionalFilters.forEach((items) => {
                                              let collapseState =
                                                this.state
                                                  .collapseAdditionalFilters;
                                              collapseState[
                                                items.FilterName
                                              ] = false;
                                              this.setState({
                                                collapseAdditionalFilters:
                                                  collapseState,
                                              });
                                            });
                                          }}
                                        >
                                          Time at School
                                        </li>
                                        <li
                                          className={`job-role-heading ${!this.state.collapseEmploymentType
                                            ? ''
                                            : 'active'
                                            }`}
                                          onClick={() => {
                                            this.setState({
                                              collapseJobRole: false,
                                              collapseTimeAtSchool: false,
                                              collapseEmploymentType: true,
                                              dropdownText: 'Employment Type',
                                            });

                                            additionalFilters.forEach((items) => {
                                              let collapseState =
                                                this.state
                                                  .collapseAdditionalFilters;
                                              collapseState[
                                                items.FilterName
                                              ] = false;
                                              this.setState({
                                                collapseAdditionalFilters:
                                                  collapseState,
                                              });
                                            });
                                          }}
                                        >
                                          Employment Type
                                        </li>
                                        <span>
                                          {additionalFilters.map(
                                            (items, index) => {
                                              return items.IsFilterBenchMarksAvailable ? (
                                                <>
                                                  <li
                                                    className={`job-role-heading ${!this.state
                                                      .collapseAdditionalFilters[
                                                      items.FilterName
                                                    ]
                                                      ? ''
                                                      : 'active'
                                                      }`}
                                                    onClick={() => {
                                                      this.setState({
                                                        collapseJobRole: false,
                                                        collapseTimeAtSchool: false,
                                                        collapseEmploymentType: false,
                                                        collapseAdditionalFilters: false,
                                                        dropdownText:
                                                          items.FilterName,
                                                      });

                                                      additionalFilters.forEach(
                                                        (items) => {
                                                          let collapseState =
                                                            this.state
                                                              .collapseAdditionalFilters;
                                                          collapseState[
                                                            items.FilterName
                                                          ] = false;
                                                          this.setState({
                                                            collapseAdditionalFilters:
                                                              collapseState,
                                                          });
                                                        }
                                                      );
                                                      let collapseState =
                                                        this.state
                                                          .collapseAdditionalFilters;
                                                      collapseState[
                                                        items.FilterName
                                                      ] =
                                                        !collapseState[
                                                        items.FilterName
                                                        ] &&
                                                        items.IsFilterBenchMarksAvailable;
                                                      this.setState({
                                                        collapseAdditionalFilters:
                                                          collapseState,
                                                      });
                                                    }}
                                                  >
                                                    {items.FilterName}
                                                  </li>
                                                </>
                                              ) : (
                                                <></>
                                              );
                                            }
                                          )}
                                        </span>
                                      </ul>
                                    </div>
                                  </Box>
                                ) : null}
                              </Box>
                            </ClickAwayListener>

                            <div className='search-inner-sub-container'>
                              <div className='staff-type-grid'>
                                <span className='heat-map-heading'>
                                  Staff Roles &amp; Characteristics
                                </span>

                                <span className='heat-map-grid-heading'>
                                  <ul className='upper-heading'>
                                    <li>Management standards</li>
                                  </ul>
                                  <ul>
                                    <li>Demands</li>
                                    <li>Control</li>
                                    <li>Manager support</li>
                                    <li>Peer support</li>
                                    <li>Relationships</li>
                                    <li>Role</li>
                                    <li>Change</li>
                                  </ul>
                                </span>
                              </div>
                              <div className='staff-type-grid-data staff-type-grid'>
                                <span className='grid-role-heading'>
                                  <ul>
                                    <li>All staff</li>
                                  </ul>
                                </span>
                                <span className='grid-data grid-data-heading '>
                                  {allStaff && (
                                    <ul>
                                      <HeatmapCell
                                        Decile={allStaff.DemandColor}
                                        Value={allStaff.Demand}
                                        Allow={this.state.isAllowed}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.ControlColor}
                                        Value={allStaff.Control}
                                        Allow={this.state.isAllowed}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.ManagerSupportColor}
                                        Value={allStaff.ManagerSupport}
                                        Allow={this.state.isAllowed}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.PeerSupportColor}
                                        Value={allStaff.PeerSupport}
                                        Allow={this.state.isAllowed}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.RelationshipsColor}
                                        Value={allStaff.Relationships}
                                        Allow={this.state.isAllowed}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.RoleColor}
                                        Value={allStaff.Role}
                                        Allow={this.state.isAllowed}
                                        AllStaff={true}
                                      />
                                      <HeatmapCell
                                        Decile={allStaff.ChangeColor}
                                        Value={allStaff.Change}
                                        Allow={this.state.isAllowed}
                                        AllStaff={true}
                                      />
                                    </ul>
                                  )}
                                </span>
                                {isCustomHeatmap ? ( // Custom Heatmap
                                  <CustomHeatmap
                                    staffHeatmap={Customheatmap}
                                    isCustomHeatmap={isCustomHeatmap}
                                    isLoading={isCustomHeatmapLoading}
                                    customHeatmapError={customHeatmapError}
                                  />
                                ) : (
                                  <Scrollbars
                                    style={{ height: 400, width: '100%' }}
                                  >
                                    {this.state.collapseJobRole &&
                                      jobRole.IsFilterBenchMarksAvailable ? (
                                      <>
                                        <span
                                          className={`job-role-heading ${!this.state.collapseJobRole &&
                                            jobRole.IsFilterBenchMarksAvailable
                                            ? ''
                                            : 'greyStrep'
                                            }`}
                                          onClick={() => {
                                            this.setState({
                                              // collapseJobRole: (!this.state.collapseJobRole && jobRole.IsFilterBenchMarksAvailable),
                                              collapseJobRole: true,
                                              collapseSchoolName: false,
                                              collapseTimeAtSchool: false,
                                              collapseEmploymentType: false,
                                              collapseAdditionalFilters: false,
                                            });
                                          }}
                                        >
                                          Job role{' '}
                                          {!jobRole.IsFilterBenchMarksAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {this.state.collapseJobRole &&
                                      jobRole.IsFilterBenchMarksAvailable ? (
                                      <>
                                        <span className='grid-role-heading'>
                                          <ul>
                                            {jobRole.HeatMapsModel.map((item) => {
                                              return (
                                                <>
                                                  {item.IsBenchMarkAvailable ? (
                                                    <li>
                                                      {item.StaffType}{' '}
                                                      {item.ClassRoom}{' '}
                                                      {item.StaffCount}
                                                      {/* <span>
                                                      {item.ClassRoom}
                                                      {item.StaffCount}
                                                    </span> */}
                                                    </li>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              );
                                            })}
                                          </ul>
                                        </span>
                                        <span className='grid-data'>
                                          {jobRole.HeatMapsModel.map(
                                            (items, index) => {
                                              return (
                                                <>
                                                  {items.IsBenchMarkAvailable ? (
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={items.DemandColor}
                                                        Value={items.Demand}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ControlColor
                                                        }
                                                        Value={items.Control}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ManagerSupportColor
                                                        }
                                                        Value={
                                                          items.ManagerSupport
                                                        }
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.PeerSupportColor
                                                        }
                                                        Value={items.PeerSupport}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.RelationshipsColor
                                                        }
                                                        Value={
                                                          items.Relationships
                                                        }
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                        AllStaff={false}
                                                      />
                                                      <HeatmapCell
                                                        Decile={items.RoleColor}
                                                        Value={items.Role}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={items.ChangeColor}
                                                        Value={items.Change}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                    </ul>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {this.state.collapseTimeAtSchool &&
                                      timeAtSchool.IsFilterBenchMarksAvailable ? (
                                      <>
                                        <span
                                          className={`job-role-heading ${!this.state.collapseTimeAtSchool &&
                                            timeAtSchool.IsFilterBenchMarksAvailable
                                            ? ''
                                            : 'greyStrep'
                                            }`}
                                          onClick={() => {
                                            this.setState({
                                              // collapseTimeAtSchool: (!this.state.collapseTimeAtSchool && timeAtSchool.IsFilterBenchMarksAvailable),
                                              collapseJobRole: false,
                                              collapseSchoolName: false,
                                              collapseTimeAtSchool: true,
                                              collapseEmploymentType: false,
                                              collapseAdditionalFilters: false,
                                            });
                                          }}
                                        >
                                          Time at school{' '}
                                          {!timeAtSchool.IsFilterBenchMarksAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {this.state.collapseTimeAtSchool &&
                                      timeAtSchool.IsFilterBenchMarksAvailable ? (
                                      <>
                                        <span className='grid-role-heading'>
                                          <ul>
                                            {timeAtSchool.HeatMapsModel.map(
                                              (item) => {
                                                return (
                                                  <>
                                                    {item.IsBenchMarkAvailable ? (
                                                      <li>
                                                        {item.StaffType}{' '}
                                                        {item.StaffCount}
                                                      </li>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </span>
                                        <span className='grid-data'>
                                          {timeAtSchool.HeatMapsModel.map(
                                            (items, index) => {
                                              return (
                                                <>
                                                  {items.IsBenchMarkAvailable ? (
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={items.DemandColor}
                                                        Value={items.Demand}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ControlColor
                                                        }
                                                        Value={items.Control}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ManagerSupportColor
                                                        }
                                                        Value={
                                                          items.ManagerSupport
                                                        }
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.PeerSupportColor
                                                        }
                                                        Value={items.PeerSupport}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.RelationshipsColor
                                                        }
                                                        Value={
                                                          items.Relationships
                                                        }
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={items.RoleColor}
                                                        Value={items.Role}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={items.ChangeColor}
                                                        Value={items.Change}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                    </ul>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {this.state.collapseEmploymentType &&
                                      employmentType.IsFilterBenchMarksAvailable ? (
                                      <>
                                        <span
                                          className={`job-role-heading ${!this.state.collapseEmploymentType &&
                                            employmentType.IsFilterBenchMarksAvailable
                                            ? ''
                                            : 'greyStrep'
                                            }`}
                                          onClick={() => {
                                            this.setState({
                                              // collapseEmploymentType: (!this.state.collapseEmploymentType && employmentType.IsFilterBenchMarksAvailable),
                                              collapseJobRole: false,
                                              collapseSchoolName: false,
                                              collapseTimeAtSchool: false,
                                              collapseEmploymentType: true,
                                              collapseAdditionalFilters: false,
                                            });
                                          }}
                                        >
                                          Employment type{' '}
                                          {!employmentType.IsFilterBenchMarksAvailable ? (
                                            <em className='nodata'>
                                              No data is available to display
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {this.state.collapseEmploymentType &&
                                      employmentType.IsFilterBenchMarksAvailable ? (
                                      <>
                                        <span className='grid-role-heading'>
                                          <ul>
                                            {employmentType.HeatMapsModel.map(
                                              (item) => {
                                                return (
                                                  <>
                                                    {item.IsBenchMarkAvailable ? (
                                                      <li>
                                                        {item.StaffType}{' '}
                                                        {item.StaffCount}
                                                      </li>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </span>
                                        <span className='grid-data'>
                                          {employmentType.HeatMapsModel.map(
                                            (items, index) => {
                                              return (
                                                <>
                                                  {items.IsBenchMarkAvailable ? (
                                                    <ul>
                                                      <HeatmapCell
                                                        Decile={items.DemandColor}
                                                        Value={items.Demand}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ControlColor
                                                        }
                                                        Value={items.Control}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.ManagerSupportColor
                                                        }
                                                        Value={
                                                          items.ManagerSupport
                                                        }
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.PeerSupportColor
                                                        }
                                                        Value={items.PeerSupport}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={
                                                          items.RelationshipsColor
                                                        }
                                                        Value={
                                                          items.Relationships
                                                        }
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={items.RoleColor}
                                                        Value={items.Role}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                      <HeatmapCell
                                                        Decile={items.ChangeColor}
                                                        Value={items.Change}
                                                        Allow={
                                                          this.state.isAllowed
                                                        }
                                                      />
                                                    </ul>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                        </span>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    <span>
                                      {additionalFilters.map((items, index) => {
                                        return (
                                          <span>
                                            {this.state.collapseAdditionalFilters[
                                              items.FilterName
                                            ] &&
                                              items.IsFilterBenchMarksAvailable ? (
                                              <>
                                                <span
                                                  className={`job-role-heading ${!this.state
                                                    .collapseAdditionalFilters[
                                                    items.FilterName
                                                  ] &&
                                                    items.IsFilterBenchMarksAvailable
                                                    ? ''
                                                    : 'greyStrep'
                                                    }`}
                                                // onClick={this.handleFilterChange.bind(this, items.FilterName, items.IsFilterBenchMarksAvailable)}
                                                >
                                                  {items.FilterName}{' '}
                                                  {!items.IsFilterBenchMarksAvailable ? (
                                                    <em className='nodata'>
                                                      No data is available to
                                                      display
                                                    </em>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </span>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            {this.state.collapseAdditionalFilters[
                                              items.FilterName
                                            ] &&
                                              items.IsFilterBenchMarksAvailable ? (
                                              <>
                                                <span className='grid-role-heading'>
                                                  <ul>
                                                    {items.HeatMapModel.map(
                                                      (filter, index) => {
                                                        return (
                                                          <>
                                                            {filter.IsBenchMarkAvailable ? (
                                                              <li>
                                                                {filter.StaffType}{' '}
                                                                {
                                                                  filter.StaffCount
                                                                }
                                                              </li>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </span>
                                                <span className='grid-data'>
                                                  {items.HeatMapModel.map(
                                                    (item, index) => {
                                                      return (
                                                        <>
                                                          {item.IsBenchMarkAvailable ? (
                                                            <ul>
                                                              <HeatmapCell
                                                                Decile={
                                                                  item.DemandColor
                                                                }
                                                                Value={
                                                                  item.Demand
                                                                }
                                                                Allow={
                                                                  this.state
                                                                    .isAllowed
                                                                }
                                                              />
                                                              <HeatmapCell
                                                                Decile={
                                                                  item.ControlColor
                                                                }
                                                                Value={
                                                                  item.Control
                                                                }
                                                                Allow={
                                                                  this.state
                                                                    .isAllowed
                                                                }
                                                              />
                                                              <HeatmapCell
                                                                Decile={
                                                                  item.ManagerSupportColor
                                                                }
                                                                Value={
                                                                  item.ManagerSupport
                                                                }
                                                                Allow={
                                                                  this.state
                                                                    .isAllowed
                                                                }
                                                              />
                                                              <HeatmapCell
                                                                Decile={
                                                                  item.PeerSupportColor
                                                                }
                                                                Value={
                                                                  item.PeerSupport
                                                                }
                                                                Allow={
                                                                  this.state
                                                                    .isAllowed
                                                                }
                                                              />
                                                              <HeatmapCell
                                                                Decile={
                                                                  item.RelationshipsColor
                                                                }
                                                                Value={
                                                                  item.Relationships
                                                                }
                                                                Allow={
                                                                  this.state
                                                                    .isAllowed
                                                                }
                                                              />
                                                              <HeatmapCell
                                                                Decile={
                                                                  item.RoleColor
                                                                }
                                                                Value={item.Role}
                                                                Allow={
                                                                  this.state
                                                                    .isAllowed
                                                                }
                                                              />
                                                              <HeatmapCell
                                                                Decile={
                                                                  item.ChangeColor
                                                                }
                                                                Value={
                                                                  item.Change
                                                                }
                                                                Allow={
                                                                  this.state
                                                                    .isAllowed
                                                                }
                                                              />
                                                            </ul>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </span>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </span>
                                        );
                                      })}
                                    </span>
                                  </Scrollbars>
                                )}
                              </div>
                              {!this.state.isAllowedDownload ? (
                                <div className='search-data-container upgrade-plan'>
                                  <div className='staff-result-innercontainer'>
                                    <div className='staff-type-grid'>
                                      <Grid container>
                                        <Grid
                                          item
                                          lg={2}
                                          style={{
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <h4
                                            onClick={() =>
                                              this.setState({
                                                showEnlargeImage: true,
                                              })
                                            }
                                            style={{
                                              cursor: 'pointer',
                                              fontSize: 12,
                                              marginTop: 10,
                                            }}
                                          >
                                            Click to enlarge
                                          </h4>
                                        </Grid>

                                        <Grid item lg={10}></Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        style={{ textAlign: 'center' }}
                                      >
                                        <Grid item lg={2}>
                                          <img
                                            src={heatmapthumbnail}
                                            style={{ width: '160px' }}
                                          />
                                        </Grid>
                                        <Grid item lg={8}>
                                          <b>
                                            {' '}
                                            You can convert your table of scores
                                            to a heatmap to see how your staff
                                            scores compare with other schools by
                                            upgrading your account.{' '}
                                            <a
                                              href='javascript:void();'
                                              onClick={() =>
                                                this.setState({
                                                  showEnlargeImage: true,
                                                })
                                              }
                                              style={{
                                                cursor: 'pointer',
                                                fontSize: 12,
                                                marginTop: 10,
                                                textDecoration: 'none',
                                              }}
                                            >
                                              Click to see an example
                                            </a>
                                          </b>
                                        </Grid>
                                        <Grid item lg={2}>
                                          <AppButton
                                            color={teal}
                                            type='button'
                                            onClick={this.routeToAccount}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            Upgrade Options
                                          </AppButton>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        </div>
                      </div>
                    </ExtendedSubContainer>
                  </Fragment>
                )
              )}
             
            </div>
          </div>

          <IndividualHeatmapSelectionForm
            title={'Download Heatmaps'}
            submitButtonText={'Download'}
            isOpen={this.state.isOpen}
            downloadList={this.state.downloadList}
            onClose={() => {
              this.setState({ isOpen: false });
            }}
            onSelectionChange={this.onSelectionChange}
            isLoading={this.state.isLoading}
            allSelected={this.state.selectAll}
            onSelectionAll={this.onSelectionAll}
            onSubmit={this.downloadPdf}
          />

          {this.state.showEnlargeImage ? (
            <GraphPopup>
              <div className='graph-container' id='large-graph'>
                <div className='redCrossButtom'>
                  <CloseIcon
                    className='closeIcon'
                    onClick={() => this.setState({ showEnlargeImage: false })}
                  />
                </div>
                <div>
                  {' '}
                  <img src={heatmapthumbnail} style={{ width: '98%' }} />
                </div>
              </div>
            </GraphPopup>
          ) : (
            <></>
          )}
        </CommentsMainWrapper>
      </AccessLevel>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loaderAction: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(StaffRoleHeatmap));
