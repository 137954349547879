import React from 'react';
import { Roles } from '../utils/Constants';
import {
  SurveyResult,
  Survey,
  SurveyWelcome,
  SurveyComments,
  SurveyComplete,
  RequestAssistance,
  SurveyReport,
  LearnMore,
  SurveyReportPresentation,
  OtherSuggestion,
  Login,
  Surveys,
  Schools,
  Register,
  Dashboard,
  ActionPlan,
  MultiSurveyDashboard,
  ManagementStandards,
  GroupManagementStandards,
  ForgotPasswordForm,
  ResetPasswordForm,
  HeadTeacherSurveyResult,
  SuperAdminDashboard,
  SchoolDetails,
  Account,
  HeadTeacherCommentsReply,
  StaffCommentReply,
  StaffResults,
  StaffRoleHeatmap,
  VoiceDashboard,
  WelbeeVoice,
  HeadteacherVoiceConversation,
  StaffVoiceConversation,
  SurveyVoiceStaffReply,
  VoiceUpgrade,
  StaffManagement,
  WelbeeVoiceNotification,
  GroupDashboard,
  StaffRegister,
  AdminDashboard,
  SchoolDetailForm,
  SurveyWithVoiceSchoolRegistration,
  OnlyVoiceSchoolRegistration,
  MatAccounts,
  RegisterMat,
  RegisterDirector,
  MatSchools,
  MatHeatMap,
  EditMat,
  MatPresentation,
  AccountRegistrations,
  NotSubscribed,
  AdditionalQuestions,
  Invoices,
  SentEmails,
  Analytics,
  MatStaff,
  MATAnalytics,
  MatAdditionalQuestions,
  MatReport,
  SummaryReport,
  FilterLibrary,
  LoginRedirect,
  HomeRedirect,
} from '../views';

import ThankYouMessage from '../components/common/ThankYouMessage';
import NotFound from '../views/PageNotFound/NotFound';
import SubscriptionNotAvailable from '../views/schoolSubscriptions/SubscriptionNotAvailable';
import MatCycles from '../views/mat/matCycle/MatCycles';
import DetailCycle from '../views/mat/matCycle/DetailCycle';
import SchoolSubscriptions from '../views/superAdmin/SchoolSubscriptions';
import AccountSubscription from '../views/account/AccountSubscription';
import MatAccount from '../views/mat/matAccount/MatAccount';
import SuperDashboard from '../views/superDashboard';

const routesObj = {
  Root: {
    path: '/',
    component: <Login />,
    roles: [],
    fallback: null,
  },
  SuperDashboard: {
    path: '/super-dashboard',
    component: <SuperDashboard />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  NotSubscribed: {
    path: '/not-subscribed',
    component: <NotSubscribed />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher],
    fallback: null,
  },
  SurveyWelcome: {
    path: '/survey-welcome',
    component: <SurveyWelcome />,
    roles: [],
    fallback: null,
  },
  SurveyResult: {
    path: '/survey-result',
    component: <SurveyResult />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher],
    fallback: null,
  },
  Survey: {
    path: '/survey',
    component: <Survey />,
    roles: [],
    fallback: null,
  },
  SurveyComplete: {
    path: '/survey-complete',
    component: <SurveyComplete />,
    roles: [],
    fallback: null,
  },
  SurveyComments: {
    path: '/survey-comments',
    component: <SurveyComments />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  SurveyReport: {
    path: '/survey-report',
    component: <SurveyReport />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  RequestAssistance: {
    path: '/request-assistance',
    component: <RequestAssistance />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  SurveyUserGuide: {
    path: '/user-guide',
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  StaffPresentation: {
    path: '/staff-presentation',
    component: <SurveyReportPresentation />,
    roles: [Roles.SuperAdmin, Roles.GroupDirector, Roles.HeadTeacher],
    fallback: null,
  },
  OtherSuggestion: {
    path: '/other-suggestion',
    component: <OtherSuggestion />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher],
    fallback: null,
  },
  Login: {
    path: '/login',
    component: <Login />,
    roles: [],
    fallback: null,
  },
  Schools: {
    path: '/schools',
    component: <Schools />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  SchoolSurveyList: {
    path: '/school-surveys',
    component: <Surveys />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  // Register: {
  //   path: '/school-register',
  //   component: <SchoolRegisterNew />,
  //   roles: [],
  //   fallback: null,
  // },
  StaffRegister: {
    path: '/staff-register',
    component: <StaffRegister />,
    roles: [],
    fallback: null,
  },
  SchoolDetail: {
    path: '/school-detail',
    component: <Register />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  ThankYouMessage: {
    path: '/thankyou-message',
    component: <ThankYouMessage />,
    roles: [],
    fallback: null,
  },
  Dashboard: {
    path: '/dashboard',
    component: <Dashboard />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  MultiSurveyDashboard: {
    path: '/multi-survey-dashboard',
    component: <MultiSurveyDashboard />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  ActionPlan: {
    path: '/action-plan',
    component: <ActionPlan />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  ManagementStandards: {
    path: '/management-standards',
    component: <ManagementStandards />,
    roles: [Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  GroupManagementStandards: {
    path: '/group-management-standards',
    component: <GroupManagementStandards />,
    roles: [Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  ForgotPassword: {
    path: '/forgot-password',
    component: <ForgotPasswordForm />,
    roles: [],
    fallback: null,
  },
  ResetPassword: {
    path: '/reset-password',
    component: <ResetPasswordForm />,
    roles: [],
    fallback: null,
  },
  HeadTeacherSurveyResult: {
    path: '/head-teacher-survey-result',
    component: <HeadTeacherSurveyResult />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  SuperAdminDashboard: {
    path: '/super-admin-dashboard',
    component: <SuperAdminDashboard />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  Invoices: {
    path: '/Invoices',
    component: <Invoices />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  SentEmails: {
    path: '/sent-emails',
    component: <SentEmails />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  SchoolDetails: {
    path: '/school-details',
    component: <SchoolDetails />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  Account: {
    path: '/account',
    component: <Account />,
    roles: [Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  SurveyCommentsReply: {
    path: '/staff-comments-reply',
    component: <StaffCommentReply />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  HeadTeacherCommentsReply: {
    path: '/head-teacher-reply',
    component: <HeadTeacherCommentsReply />,
    roles: [Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  StaffResults: {
    path: '/staff-results',
    component: <StaffResults />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  StaffRoleHeatmap: {
    path: '/staff-role-heatmap',
    component: <StaffRoleHeatmap />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  VoiceDashboard: {
    path: '/voice-dashboard',
    component: <VoiceDashboard />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  WelbeeVoice: {
    path: '/welbee-voice',
    component: <WelbeeVoice />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  HeadteacherVoiceConversation: {
    path: '/headteacher-voice-conversation',
    component: <HeadteacherVoiceConversation />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  StaffVoiceConversation: {
    path: '/staff-voice-conversation',
    component: <StaffVoiceConversation />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  SurveyVoiceStaffReply: {
    path: '/survey-voice-staff-reply',
    component: <SurveyVoiceStaffReply />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  VoiceUpgrade: {
    path: '/voice-upgrade',
    component: <VoiceUpgrade />,
    //roles: [],
    fallback: null,
  },
  VoiceUserGuide: {
    path: '/voice-instructions',
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  StaffManagement: {
    path: '/staff-management',
    component: <StaffManagement />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  WelbeeVoiceNotification: {
    path: '/welbee-voice-notification',
    component: <WelbeeVoiceNotification />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  GroupDashboard: {
    path: '/group-dashboard',
    component: <GroupDashboard />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  AdminDashboard: {
    path: '/admin-dashboard',
    component: <AdminDashboard />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  SchoolDetailForm: {
    path: '/school-detail-form',
    component: <SchoolDetailForm />,
    // roles: [Roles.HeadTeacher, Roles.SuperAdmin],
    fallback: null,
  },
  SurveyFreeUrl: {
    path: '/register-survey-free',
    component: <OnlyVoiceSchoolRegistration />,
    fallback: null,
  },
  SurveyProUrl: {
    path: '/register-survey-pro',
    component: <SurveyWithVoiceSchoolRegistration />,
    fallback: null,
  },
  SurveyPremiumUrl: {
    path: '/register-survey-premium',
    component: <SurveyWithVoiceSchoolRegistration />,
    fallback: null,
  },
  VoiceFreeUrl: {
    path: '/register-voice-free',
    component: <OnlyVoiceSchoolRegistration />,
    fallback: null,
  },
  VoiceProUrl: {
    path: '/register-voice-pro',
    component: <SurveyWithVoiceSchoolRegistration />,
    fallback: null,
  },
  MatAccounts: {
    path: '/mat-accounts',
    component: <MatAccounts />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  RegisterMat: {
    path: '/register-mat',
    component: <RegisterMat />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  MatCycles: {
    path: '/mat-cycles',
    component: <MatCycles />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  MatCycleDetail: {
    path: '/mat-cycle-detail',
    component: <DetailCycle />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  RegisterDirector: {
    path: '/register-director',
    component: <RegisterDirector />,
    fallback: null,
  },
  MatSchools: {
    path: '/mat-schools',
    component: <MatSchools />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  MatHeatMap: {
    path: '/mat-heat-map',
    component: <MatHeatMap />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  MatPresentation: {
    path: '/mat-presentation',
    component: <MatPresentation />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  MatReport: {
    path: '/mat-report',
    component: <MatReport />,
    roles: [Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  SummaryReport: {
    path: '/summary-report',
    component: <SummaryReport />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  MatStaffManagement: {
    path: '/mat-staff',
    component: <MatStaff />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  MatAccount: {
    path: '/mat-account',
    component: <MatAccount />,
    roles: [Roles.HeadTeacher, Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  EditMat: {
    path: '/edit-mat',
    component: <EditMat />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  AccountRegistrations: {
    path: '/account-registrations',
    component: <AccountRegistrations />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  AdditionalQuestions: {
    path: '/additional-questions',
    component: <AdditionalQuestions />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  SubscriptionNotAvailable: {
    path: '/subscription-not-available',
    component: <SubscriptionNotAvailable />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  SchoolSubscriptions: {
    path: '/SchoolSubscriptions',
    component: <SchoolSubscriptions />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  SchoolAnalytics: {
    path: '/Analytics',
    component: <Analytics />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  AccountSubscription: {
    path: '/Account-Subscription',
    component: <AccountSubscription />,
    roles: [Roles.SuperAdmin, Roles.HeadTeacher, Roles.GroupDirector],
    fallback: null,
  },
  MATAnalytics: {
    path: '/mat-analytics',
    component: <MATAnalytics />,
    roles: [Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  MatAdditionalQuestions: {
    path: '/mat-question-bank',
    component: <MatAdditionalQuestions />,
    roles: [Roles.SuperAdmin, Roles.GroupDirector],
    fallback: null,
  },
  FilterLibrary: {
    path: '/filter-library',
    component: <FilterLibrary />,
    roles: [Roles.SuperAdmin],
    fallback: null,
  },
  LoginRedirect: {
    path: '/login-redirect',
    component: <LoginRedirect />,
    roles: [],
    fallback: null,
  },
  HomeRedirect: {
    path: '/home-redirect',
    component: <HomeRedirect />,
    roles: [],
    fallback: null,
  },
  NotFound: {
    component: <NotFound />,
    fallback: null,
  },
};
export default routesObj;
