import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { AppButton } from '../../resources/styling';
import { loaderAction } from '../../store/loaderStore';
import {
  Header,
  SchoolsGroupDropDown,
  FloatingInputLabel,
} from '../../components';
import {
  MainFooter,
  ErrorWrapper,
  FieldWrapper,
  RegisterInnerMain,
  CheckBox,
  RegisterWrapper,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { footerLogo } from '../../resources/images';
import { accountService, matGroupService } from '../../services';
import { routesObj } from '../../routes';
import { WrapperMain } from '../../resources/styling/formStyle';
import { storageService } from '../../services';
import { WebStorageNames } from '../../utils/Constants';
import { getGroupNameCollection } from '../../components/common/GroupNames';
import { PasswordViewIcon } from '../../components/common/loginStyle';
import { loginNormalEye, loginShowEye } from '../../resources/images/index';
import { UsernameReg } from '../../utils/Constants';
import { PasswordReg } from '../../utils';
import { RegisterInner, ExtendedAssitanceForm, InputWrapper } from './MatStyle';

const { mandy } = colors;

function RegisterMat(props) {
  const [groupId] = useState(
    storageService.getItem(WebStorageNames.MatGroupId)
  );
  let [Mat, setMat] = useState(null);
  let [canCreateSurvey, setCanCreateSurvey] = useState(false);
  const [isOtherGroup, setisOtherGroup] = useState(false);
  const [SchoolGroupName, setSchoolGroupName] = useState('');
  let [isPasswordShow, setIsPasswordShow] = useState(true);
  const [reInitialize, setReInitialize] = useState(true);
  const [isAnnonymised, setIsAnnonymised] = useState(false);
  let [isDisplayMatHeatMap, setIsDisplayMatHeatMap] = useState(false);
  const [selectTestAccount, setSelectTestAccount] = useState(false);

  useEffect(() => {
    getRegions();
    getMat();
  }, []);

  const getRegions = () => {
    accountService
      .checkCurrentRegion()
      .then((response) => {
        storageService.setItem(WebStorageNames.UsRegion, response.data);
        getGroupNameCollection();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getMat() {
    getRegions();
    matGroupService.getMatById(groupId).then((response) => {
      setMat(response.data);
      const Group = response.data.GroupName;
      setCanCreateSurvey(response.data.LastSurveyCreationDate == null);
      setIsDisplayMatHeatMap(response.data.IsDisplayMatHeatMap);
      setIsAnnonymised(response.data.IsAnnonymised);
      setSelectTestAccount(response.data.IsTestAccount);
      if (
        Group !== 'MAT' &&
        Group !== 'Group' &&
        Group !== 'District' &&
        Group !== 'County' &&
        Group !== 'State'
      )
        setisOtherGroup(true);
      setSchoolGroupName(Group);
    });
  }
  function setGroupName(name) {
    if (name !== 'Other') {
      setisOtherGroup(false);
      setSchoolGroupName(name);
    } else {
      setSchoolGroupName('');
      setisOtherGroup(true);
    }
  }
  async function updateMatUsers(data, loaderActions) {
    await CanCreateSurvey(data, loaderActions);
    await matGroupService
      .updateMatUsers(data)
      .then((response) => {
        const { success, message } = response;
        if (success) {
          toast.success('Record has been updated successfully!');
          props.history.push(routesObj.MatAccounts.path);
        } else toast.error(message);
        loaderActions.dataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        loaderActions.dataLoading(false);
      });
  }
  async function CanCreateSurvey(data, loaderActions) {
    await matGroupService
      .setCanCreateSurvey(data)
      .then((response) => {
        const { success, message } = response;
        if (success) {
        } else toast.error(message);
        loaderActions.dataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        loaderActions.dataLoading(false);
      });
  }

  return (
    <Formik
      enableReinitialize={reInitialize}
      initialValues={{
        Id: Mat ? Mat.Id : 0,
        Name: Mat ? Mat.Name : '',
        FirstName: Mat ? Mat.FirstName : '',
        LastName: Mat ? Mat.LastName : '',
        SchoolId: 0,
        Email: Mat
          ? Mat.GroupDirectors[0].User !== null
            ? Mat.GroupDirectors[0].User.Email
            : ''
          : '',
        GroupName: SchoolGroupName,
        Group: Mat
          ? Mat.GroupName === 'MAT' ||
            Mat.GroupName === 'Group' ||
            Mat.GroupName === 'County' ||
            Mat.GroupName === 'District' ||
            Mat.GroupName === 'State'
            ? Mat.GroupName
            : 'Other'
          : '',
        CustomGroup: Mat
          ? Mat.GroupName === 'MAT' ||
            Mat.GroupName === 'Group' ||
            Mat.GroupName === 'County' ||
            Mat.GroupName === 'District' ||
            Mat.GroupName === 'State'
            ? Mat.GroupName
            : ''
          : '',
        Username: Mat ? (Mat.Username !== null ? Mat.Username : '') : '',
        Password: '',
        IsTestAccount: selectTestAccount,
        File: null,
        FileName: null
      }}
      onSubmit={(values, actions) => {
        const { loaderActions } = props;
        const data = {
          Id: Mat.Id,
          canCreateSurvey,
          IsDisplayMatHeatMap: isDisplayMatHeatMap,
          Name: values.Name,
          GroupName: values.GroupName,
          Email: values.Email,
          FirstName: values.FirstName,
          LastName: values.LastName,
          SchoolId: 0,
          UserId: Mat.UserId,
          Username: values.Username,
          Password: values.ConfirmPassword,
          isAnnonymised: isAnnonymised,
          IsTestAccount: selectTestAccount,
          File: values.File,
          FileName: values.FileName
        };
        updateMatUsers(data, loaderActions);
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        Name: Yup.string()
          .trim()
          .max(50, 'MAT group name must be at most 50 characters.')
          .required('MAT group name is required.'),
        GroupName: Yup.string()
          .trim()
          .max(50, 'Custom group name must be at most 50 characters.')
          .required('Custom group name is required.'),
        Group: Yup.string().trim().required('Group Name is required.'),
        Email: Yup.string()
          .trim()
          .max(60)
          .email('Provide email address.')
          .required('Email is required.'),
        FirstName: Yup.string()
          .trim()
          .max(50, 'First name must be at most 50 characters.')
          .required('First Name is required.'),
        LastName: Yup.string()
          .trim()
          .max(50, 'Last name must be at most 50 characters.')
          .required('Last Name is required.'),
        Username: Yup.string()
          .trim()
          .max(20, 'Username must be at most 20 characters.')
          .required('Username is required.')
          .matches(
            UsernameReg,
            'Username must contain only letters and numbers.'
          ),
        Password: Yup.string()
          .trim()
          .min(12, 'Password must be at least 12 characters.')
          .max(50, 'Password must be at most 50 characters.')
          .matches(
            PasswordReg,
            'Your password must contain at least 1 uppercase letter, lowercase letter, number and special character from @£!#$%^&+="\',-.'
          ),
        ConfirmPassword: Yup.string().trim(),
      })}
      render={({
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        handleBlur,
      }) => (
        <>
          <Header />

          <RegisterWrapper>
            <Grid container>
              <Grid
                item
                lg={12}
                sm={12}
                md={12}
                xs={12}
                className='mobile-wrapper'
              >
                <RegisterInnerMain>
                  <RegisterInner>
                    <ExtendedAssitanceForm>
                      <h2>Edit Group</h2>
                      <InputWrapper>
                        <WrapperMain>
                          <SchoolsGroupDropDown
                            name='Group'
                            label='Groups'
                            value={values.Group}
                            onChange={(e) => {
                              setFieldValue('Group', e.target.value);
                              setFieldValue('GroupName', e.target.value);
                              setFieldValue('CustomGroup', '');
                              setGroupName(e.target.value);
                              setReInitialize(false);
                            }}
                            options={getGroupNameCollection()}
                          />
                          <ErrorWrapper>
                            {touched.Group && errors.Group}
                          </ErrorWrapper>
                        </WrapperMain>
                        {isOtherGroup ? (
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Custom Group Name'
                              autoComplete='new-mat-group-name'
                              name='CustomGroup'
                              value={values.CustomGroup}
                              onChange={(e) => {
                                setFieldValue('CustomGroup', e.target.value);
                                setFieldValue('GroupName', e.target.value);
                              }}
                            />
                            <ErrorWrapper>
                              {touched.GroupName && errors.GroupName}
                            </ErrorWrapper>
                          </WrapperMain>
                        ) : (
                          <div />
                        )}
                        <WrapperMain>
                          <FloatingInputLabel
                            label='Group Name'
                            autoComplete='new-mat-group-name'
                            name='Name'
                            value={values.Name}
                            onChange={handleChange}
                          />
                          <ErrorWrapper>
                            {touched.Name && errors.Name}
                          </ErrorWrapper>
                        </WrapperMain>

                        <WrapperMain>
                          <FloatingInputLabel
                            label='First Name'
                            autoComplete='new-name'
                            name='FirstName'
                            value={values.FirstName}
                            onChange={handleChange}
                          />
                          <ErrorWrapper>
                            {touched.FirstName &&
                              errors.FirstName &&
                              errors.FirstName}
                          </ErrorWrapper>
                        </WrapperMain>
                        <WrapperMain>
                          <FloatingInputLabel
                            label='Last Name'
                            name='LastName'
                            value={values.LastName}
                            onChange={handleChange}
                          />
                          <ErrorWrapper>
                            {touched.LastName &&
                              errors.LastName &&
                              errors.LastName}
                          </ErrorWrapper>
                        </WrapperMain>
                        <WrapperMain>
                          <FloatingInputLabel
                            label='Username'
                            name='Username'
                            value={values.Username}
                            onChange={handleChange}
                          />
                          <ErrorWrapper>
                            {touched.Username && errors.Username}
                          </ErrorWrapper>
                        </WrapperMain>
                        <WrapperMain>
                          <FloatingInputLabel
                            label='Email'
                            name='Email'
                            value={values.Email}
                            autoComplete='new-password'
                            onChange={handleChange}
                          />

                          <ErrorWrapper>
                            {touched.Email && errors.Email}
                          </ErrorWrapper>
                        </WrapperMain>

                        <WrapperMain>
                          <FloatingInputLabel
                            label='Password'
                            autoComplete='new-password'
                            type={isPasswordShow ? 'password' : 'text'}
                            name='Password'
                            value={values.Password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                            <img
                              src={
                                isPasswordShow ? loginNormalEye : loginShowEye
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setIsPasswordShow(!isPasswordShow);
                              }}
                              alt=''
                            />
                          </PasswordViewIcon>
                          <ErrorWrapper>
                            {touched.Password &&
                              errors.Password &&
                              errors.Password}
                          </ErrorWrapper>
                        </WrapperMain>
                        <WrapperMain>
                          <FloatingInputLabel
                            label='Confirm Password'
                            autoComplete='new-password'
                            type={isPasswordShow ? 'password' : 'text'}
                            name='ConfirmPassword'
                            value={values.ConfirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                            <img
                              src={
                                isPasswordShow ? loginNormalEye : loginShowEye
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setIsPasswordShow(!isPasswordShow);
                              }}
                              alt=''
                            />
                          </PasswordViewIcon>
                          <ErrorWrapper>
                            {touched.ConfirmPassword &&
                              errors.ConfirmPassword &&
                              errors.ConfirmPassword}
                          </ErrorWrapper>
                        </WrapperMain>

                        <WrapperMain>
                          <div className='box'>
                            <input
                              type='file'
                              name='file-7[]'
                              id='file-7'
                              accept='image/x-png,image/jpeg'
                              className='inputfile inputfile-6'
                              data-multiple-caption='{count} files selected'
                              onChange={(event) => {
                                var files = event.target.files;
                                var file = files[0];
                                var splitFileName = file.name.split('.');
                                var imageName = splitFileName[0];
                                var trancateString =
                                  imageName.length > 23
                                    ? imageName.substr(imageName, 23, 0) + '...'
                                    : file.name;
                                if (files && file) {
                                  var reader = new FileReader();
                                  reader.onload = function (readerEvt) {
                                    var binaryString = readerEvt.target.result;
                                    setFieldValue('File', btoa(binaryString));
                                    setFieldValue('FileName', file.name);
                                    setFieldValue(
                                      'TrancateFileName',
                                      trancateString
                                    );
                                  };
                                  reader.readAsBinaryString(file);
                                }
                              }}
                            />
                            <label>
                              <span className='no-file-upload '>
                                {values.TrancateFileName
                                  ? values.TrancateFileName
                                  : 'Upload a logo'}
                              </span>
                              <strong>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='20'
                                  height='17'
                                  viewBox='0 0 20 17'
                                >
                                  <path d='M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z' />
                                </svg>{' '}
                                Choose a file&hellip;
                              </strong>
                            </label>
                          </div>
                          <ErrorWrapper>
                            {touched.File && errors.File && errors.File}
                          </ErrorWrapper>
                        </WrapperMain>

                        <WrapperMain>
                          <CheckBox>
                            <input
                              class='styled-checkbox'
                              id='isDisplayMatHeatMap'
                              type='checkbox'
                              value='value1'
                              checked={isDisplayMatHeatMap}
                              onChange={(e) =>
                                setIsDisplayMatHeatMap(e.target.checked)
                              }
                            />
                            <label
                              for='isDisplayMatHeatMap'
                              style={{ left: 0 }}
                            >
                              <span className='checkbox-wrapper'>
                                Please tick to allow individual Schools within
                                the Group to view other School scores.
                              </span>
                            </label>
                          </CheckBox>
                        </WrapperMain>
                        <WrapperMain>
                          <CheckBox>
                            <input
                              class='styled-checkbox'
                              id='isAnnonymised'
                              type='checkbox'
                              value='value2'
                              checked={isAnnonymised}
                              onChange={(e) =>
                                setIsAnnonymised(e.target.checked)
                              }
                            />
                            <label for='isAnnonymised' style={{ left: 0 }}>
                              <span className='checkbox-wrapper'>
                                Please tick to anonymize school names, when they
                                are allowed to view other school scores.
                              </span>
                            </label>
                          </CheckBox>
                        </WrapperMain>
                        <WrapperMain>
                          <CheckBox>
                            <input
                              class='styled-checkbox'
                              id='styled-checkbox-1'
                              type='checkbox'
                              value='value1'
                              checked={canCreateSurvey}
                              onChange={(e) =>
                                setCanCreateSurvey(e.target.checked)
                              }
                            />
                            <label for='styled-checkbox-1' style={{ left: 0 }}>
                              <span className='checkbox-wrapper'>
                                Please tick to enable the Group to create
                                surveys for their individual Schools.
                              </span>
                            </label>
                          </CheckBox>
                        </WrapperMain>

                        <WrapperMain>
                          <CheckBox>
                            <input
                              class='styled-checkbox'
                              id='selectTestAccount'
                              type='checkbox'
                              value={values.isTestAccount}
                              checked={selectTestAccount}
                              onChange={(e) =>
                                setSelectTestAccount(e.target.checked)
                              }
                            />
                            <label for='selectTestAccount' style={{ left: 0 }}>
                              <span className='checkbox-wrapper'>
                                Is Test Account
                              </span>
                            </label>
                          </CheckBox>
                        </WrapperMain>

                        <WrapperMain>
                          <FieldWrapper>
                            <AppButton
                              color={mandy}
                              type='submit'
                              onClick={(values) => {
                                setReInitialize(false);
                                handleSubmit(values);
                              }}
                            >
                              Submit
                            </AppButton>
                          </FieldWrapper>
                        </WrapperMain>
                      </InputWrapper>
                    </ExtendedAssitanceForm>
                  </RegisterInner>
                </RegisterInnerMain>
              </Grid>
            </Grid>
            <MainFooter>
              <span>
                <img src={footerLogo} alt='logo' />
              </span>
              <span> Survey provided by</span>
            </MainFooter>
          </RegisterWrapper>
        </>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(RegisterMat));
