import React, { useEffect, useState } from 'react';
import Header from './Header';
import ProductCard from './ProductCard';
import { Grid, Container, Modal, Box, Typography } from '@mui/material';
import { storageService } from '../../services';
import { Roles, WebStorageNames } from '../../utils';
import { parseJwt } from '../../utils/CommonFunctions';
import { routesObj } from '../../routes';
import { withRouter } from 'react-router-dom';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import { QueryStringTokens } from '../../utils';
import { config } from '../../utils/config';
import {
  iconSurvey,
  iconEduInt,
  iconInsignts,
  iconToolkit,
  iconParent,
} from '../../resources/images/index';
import schoolProductService from '../../services/api/schoolProductService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  //border: '1px solid #000',
  boxShadow: 5,
  p: 4,
};

const SuperDashboard = (props) => {
  const [open, setOpen] = useState(false);
  const loadMainApp = () => {
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);

    var userInfo = parseJwt(storageService.getToken(WebStorageNames.Token));
    if (userInfo.role === Roles.GroupDirector) {
      props.history.push(routesObj.GroupDashboard.path);
    } else {
      props.history.push(
        routesObj.Dashboard.path + '?schoolToken=' + schoolToken
      );
    }
  };

  const loadToolKit = () => {
    window.open('https://toolkit.welbee.co.uk', '_blank');
  };
  const loadPeopleManagement = () => {};
  const loadpulse = () => {};
  const loadInsights = () => {
    var userInfo = parseJwt(storageService.getToken(WebStorageNames.Token));
    let ismat = storageService.getToken(WebStorageNames.IsMat);
    let name = storageService.getToken(WebStorageNames.SchoolName);
    let id = userInfo.id;
    window.open(
      `${config.INSIGHT_URL}?name=${btoa(name)}&email=${btoa(
        userInfo.email
      )}&id=${id}&ismat=${ismat}&access_token=${storageService.getItem(
        WebStorageNames.Token
      )}&firstName=${userInfo.firstName} &lastName=${userInfo.lastName}`,
      '_blank'
    );
  };

  const requestProduct = (product) => {
    let n = storageService.getToken(WebStorageNames.SchoolName);
    let isMAt = storageService.getItem(WebStorageNames.IsMat);
    let schoolName = (isMAt ? 'Group Name: ' : 'School Name: ') + n;
    schoolProductService
      .requestProduct(schoolName, product)
      .then((e) => setOpen(true))
      .catch()
      .finally();
  };

  const [products, setProducts] = useState([
    {
      image: iconSurvey,
      title: 'Staff Wellbeing',
      description: 'Create and manage surveys',
      clickEvent: loadMainApp,
    },
    {
      image: iconToolkit,
      title: 'Welbee ToolKit',
      description: 'Create and manage surveys',
      clickEvent: loadToolKit,
    },
    {
      image: iconInsignts,
      title: 'Insights',
      description: 'View insights',
      clickEvent: null,
    },
    {
      image: iconEduInt,
      title: 'Edu Intelligence',
      description: 'Manage people',
      clickEvent: null,
    },
    // {
    //   image: iconEduInt,
    //   title: 'Pulse',
    //   description: 'Pulse Surveys',
    //   clickEvent: null,
    // },
    // {
    //   image: iconParent,
    //   title: 'Parent Insights',
    //   description: ' Manage and view parent insights',
    //   clickEvent: null,
    // },
  ]);

  const loadPulse = () => {
    let matId = storageService.getItem(WebStorageNames.MatGroupId);
    let schoolId = storageService.getItem(WebStorageNames.SchoolId);
    let userInfo = parseJwt(storageService.getItem(WebStorageNames.Token));
    let n = storageService.getToken(WebStorageNames.SchoolName);

    let ismat = storageService.getItem(WebStorageNames.IsMat);
    window.open(
      `https://pulse.welbee.co.uk/#/superadmin?name=${btoa(n)}&email=${btoa(
        userInfo.email
      )}&id=${
        ismat ? matId : schoolId
      }&ismat=${ismat}&access_token=${storageService.getItem(
        WebStorageNames.Token
      )}`,
      '_blank'
    );
  };

  useEffect(() => {
    let matId = storageService.getItem(WebStorageNames.MatGroupId);
    let schoolId = storageService.getItem(WebStorageNames.SchoolId);

    schoolProductService
      .hasPulseSurvey(schoolId ? schoolId : 0, matId ? matId : 0)
      .then((response) => {
        if (response) {
          setProducts((prev) => [
            ...prev,
            {
              image: iconEduInt,
              title: 'Pulse',
              description: 'Pulse Surveys',
              clickEvent: loadPulse,
            },
          ]);
        }
      });

    schoolProductService.get(schoolId ?? 0, matId ?? 0).then((response) => {
      if (response.success) {
        let myProdudcts = products;
        response.data.map((p) => {
          if (p.Product === 2) {
            myProdudcts[3].clickEvent = loadInsights;
          }
          if (p.Product === 3) {
            myProdudcts[4].clickEvent = loadPeopleManagement;
          }
          if (p.Product === 4) {
            myProdudcts[5].clickEvent = loadpulse;
          }
        });
        setProducts((prev) => [...prev, ...myProdudcts]);
      }
    });
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth='lg'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} mt={1}>
            <h2>My Products</h2>
          </Grid>
          {products.map((product) => (
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <ProductCard
                image={product.image}
                title={product.title}
                description={product.description}
                loadProduct={product.clickEvent}
                onRequest={requestProduct}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Modal open={open} onClose={(e) => setOpen(false)}>
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Product Request sent
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            Welbee will contact you shortly
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default withRouter(SuperDashboard);
