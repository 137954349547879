import React, { Component } from 'react';
import DropDownOptions from './DropDownOptions';
import DropDownOptionsWithButton from './DropDownOptionsWithButton';
import { DropdownStyle } from '../../resources/styling/appStyle';

class FloatinLabelDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResults: false
    };
    this.dropDownRef = React.createRef();
  }

  handleChange = () => {
    this.setState({
      showResults: !this.state.showResults
    });
  };

  handleOptionChange(option) {
    this.setState({ showOption: option });
  }

  handleClick = e => {
    if (!this.dropDownRef.current.contains(e.target)) {
      this.setState({ showResults: false });
    }
  };

  render() {
    const {
      options,
      selectedOption,
      onChange,
      withButton = false,
      placeholder,
      label
    } = this.props;
    return (
      <DropdownStyle className='floating-label'>
        <input
          onClick={this.handleChange}
          autoComplete='new-password'
          placeholder=' '
          selectedOption={selectedOption}
          value={selectedOption.label}
          ref={this.dropDownRef}
          className='category-inner-wrapper floating-input'
          type='text'
          name={selectedOption.label}
          style={{cursor: 'pointer'}}
        />
       <label>{label ? label : placeholder}</label>
        <div
          className={
            this.state.showResults
              ? 'active-option selected-option'
              : 'selected-option'
          }
          data-test-id='selectOption'
        >
          {/* {!this.state.showResults && <img src={arrowDown} alt='Arrow down' />}
          {this.state.showResults && <img src={arrowUp} alt='Arrow up' />} */}
        </div>

        {this.state.showResults &&
          (withButton ? (
            <DropDownOptionsWithButton
              options={options}
              onChange={e => {
                onChange(e);
                this.handleClick(e);
              }}
            />
          ) : (
            <DropDownOptions
              options={options}
              onChange={e => {
                onChange(e);
                this.handleClick(e);
              }}
            />
          ))}
      </DropdownStyle>
    );
  }
}

export default FloatinLabelDropDown;
