import { Formik } from 'formik';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DayPicker from 'react-day-picker';
import MenuItem from '@mui/material/MenuItem';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import {
  AppModel,
  FloatingInputLabel,
  FloatinLabelDropDown,
  DatePickerCustom,
} from '../../components';
import MuiDropDown from '../../components/common/MuiDropDown';
import FooterButtons from '../../components/common/FooterButtons';
import {
  AssitanceForm,
  ErrorWrapper,
  ModalInnerWrapper,
  NewSurveyInner,
  NewSurveyInnerMain,
  NewSurveyInputWrapper,
  NewSurveyWrapper,
} from '../../resources/styling';
import { DatePickerWrapper } from '../../resources/styling/formStyle';
import { DateFormat } from '../../utils/CommonFunctions';
import { routesObj } from '../../routes';
import { accountService } from '../../services';
import {
  LoaderOverlay,
  ModalCustomStyles,
} from '../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import {
  ExtendedFieldWrapper,
  ExtendedDateWrapper,
  ExtendedHourWrapper,
  ExtendedMinWrapper,
} from './SchoolSurveyStyle';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { SurveysDisabledSchools } from '../../utils/Constants';
import { storageService } from '../../services';
import { WebStorageNames } from '../../utils';

const defaultTime = {
  value: -1,
  label: '',
  Selected: false,
};

const NewSurveyForm = (props) => {
  const {
    Name,
    StartDate,
    EmployeesCount,
    SchoolId,
    onSubmit,
    title,
    submitButtonText,
    isOpen,
    onClose,
    EndDate,
    surveyName,
    isLoading,
    getTempSurveyName,
    isAddtionalQuestionFiltersIncluded,
    isUsRegion,
  } = props;

  const [isStartDateCalenderOpen, setIsStartDateCalendarOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState(defaultTime);
  const [selectedMin, setSelectedMin] = useState([]);
  const [hourOptions, setHourOptions] = useState([]);
  const [minOptions, setMinOptions] = useState([]);
  const handleStartDateCalendar = () => {
    setIsStartDateCalendarOpen(true);
  };

  const closeCalendarPopups = () => {
    setIsStartDateCalendarOpen(false);
  };

  const getTimeOptions = (date) => {
    let hoptions = [];
    if (isUsRegion) {
      for (let i = 0; i < 24; i++) {
        let label = i < 10 ? '0' + i : i;
        hoptions.push({ label: label, value: i });
      }
      setSelectedHour(defaultTime);
      setHourOptions(hoptions);
    } else {
      let startFromHour =
        new Date().toDateString() === date.toDateString()
          ? new Date().getHours()
          : 0;
      if (new Date().getMinutes() > 54) startFromHour++;
      if (startFromHour > 23) return;
      for (let i = startFromHour; i <= 23; i++) {
        let label = i < 10 ? '0' + i : i;
        hoptions.push({ label: label, value: i });
      }

      setSelectedHour(defaultTime);
      setHourOptions(hoptions);
    }
  };

  const getDisableValue = () => {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    switch (parseInt(schoolId)) {
      case SurveysDisabledSchools.WelbeeDemonstrationPrimarySchool:
        return true;
      case SurveysDisabledSchools.WelbeeDemonstrationPrimarySchoolPremium:
        return true;
      default:
        return false;
    }
  };

  const getMinOptions = (date, hOption) => {
    let moptions = [];
    let startFromMin =
      new Date().getMinutes() > 54
        ? 5
        : date.toDateString() == new Date().toDateString() &&
          hOption.target.value === new Date().getHours()
        ? new Date().getMinutes() + 5
        : 0;
    for (let i = startFromMin; i <= 59; i++) {
      let label = i < 10 ? '0' + i : i;
      moptions.push({ label: label, value: i });
    }

    setSelectedMin(defaultTime);
    setMinOptions(moptions);
  };

  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    getTimeOptions(new Date());
    getTempSurveyName(new Date());
  }, []);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        Name: surveyName || Name,
        StartDate: StartDate,
        EmployeesCount: EmployeesCount,
        SchoolId: SchoolId,
        EndDate: EndDate,
        isCalenderOpen: false,
        UtcOffset: new Date().getTimezoneOffset(),
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        values.StartDate.setHours(selectedHour);
        values.StartDate.setMinutes(selectedMin);
        values.UtcOffset = new Date().getTimezoneOffset();
        onSubmit(values);
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        Name: Yup.string().trim().max(150).required('Survey Name is required.'),
        StartDate: Yup.date('Date is not in correct format!').required(
          'Start date is required.'
        ),
        EmployeesCount: Yup.number()
          .max(10000, 'Max number is 10000')
          .min(1, 'Enter number greater than 0')
          .integer()
          .required('Number of Employees is required.'),
        Hour: Yup.number().min(0, 'Hour is required'),
        Minutes: Yup.number().min(0, 'Minutes are required'),
      })}
      render={({
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        selectedDay,
      }) => {
        let todayDate = moment().toDate();
        return (
          <AppModel
            open={isOpen}
            onClose={onClose}
            bodyText={
              <ModalInnerWrapper>
                <NewSurveyWrapper>
                  <Grid container>
                    <Grid item lg={12} sm={12} md={12} xs={12}>
                      <NewSurveyInnerMain>
                        <NewSurveyInner>
                          <AssitanceForm>
                            <h2>{title}</h2>
                            {!props.staffExist && (
                              <span className='create-survey-direction'>
                                Please add your staff in{' '}
                                <span
                                  style={ModalCustomStyles.accountTextStyle}
                                  onClick={() =>
                                    props.history.push(
                                      routesObj.StaffManagement.path
                                    )
                                  }
                                >
                                  staff management
                                </span>{' '}
                                before creating a survey.
                              </span>
                            )}
                            {isAddtionalQuestionFiltersIncluded !==
                              'included' &&
                              isAddtionalQuestionFiltersIncluded !== '' && (
                                <Alert severity='warning'>
                                  <strong>
                                    {isAddtionalQuestionFiltersIncluded}
                                  </strong>
                                </Alert>
                              )}
                            {isLoading && (
                              <LoaderOverlay>
                                <div className='comment-loader'>
                                  <div id='loading' />
                                </div>
                              </LoaderOverlay>
                            )}
                            <NewSurveyInputWrapper>
                              <ExtendedDateWrapper>
                                <FloatingInputLabel
                                  label='Start Date'
                                  value={DateFormat(startDate)}
                                  onClick={handleStartDateCalendar}
                                  onChange={(date) => {
                                    setStartDate(date);
                                  }}
                                />
                                <ErrorWrapper>
                                  {touched.StartDate &&
                                    errors.StartDate &&
                                    errors.StartDate}
                                </ErrorWrapper>
                              </ExtendedDateWrapper>

                              <ExtendedHourWrapper>
                                <MuiDropDown
                                  name='Hour'
                                  label='Start Time - Hour'
                                  //options={hourOptions}
                                  selectedOption={selectedHour}
                                  onClick={closeCalendarPopups}
                                  onChange={(option) => {
                                    setSelectedHour(option.target.value);
                                    setFieldValue(
                                      'Start Time - Hour',
                                      option.target.value
                                    );
                                    getMinOptions(values.StartDate, option);
                                  }}
                                  values={values.Hour}
                                >
                                  {hourOptions.map((item) => (
                                    <MenuItem
                                      key={item.value}
                                      value={item.value}
                                    >
                                      {item.value}
                                    </MenuItem>
                                  ))}
                                </MuiDropDown>

                                <ErrorWrapper>
                                  {touched.Hour && errors.Hour && errors.Hour}
                                </ErrorWrapper>
                              </ExtendedHourWrapper>
                              <span
                                style={{
                                  float: 'left',
                                  width: '2%',
                                  textAlign: 'center',
                                  'font-weight': 'bold',
                                  'font-size': '25px',
                                  'padding-top': '10px',
                                }}
                              >
                                :
                              </span>
                              <ExtendedMinWrapper>
                                <MuiDropDown
                                  name='Minutes'
                                  label='Start Time - Minutes'
                                  selectedOption={selectedMin}
                                  value={values.Minutes}
                                  onChange={(option) => {
                                    setFieldValue(
                                      'Start Time - Minutes',
                                      option.target.value
                                    );
                                    setSelectedMin(option.target.value);
                                  }}
                                >
                                  {minOptions.map((item) => {
                                    return (
                                      <MenuItem
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.value}
                                      </MenuItem>
                                    );
                                  })}
                                </MuiDropDown>
                                <ErrorWrapper>
                                  {touched.Minutes &&
                                    errors.Minutes &&
                                    errors.Minutes}
                                </ErrorWrapper>
                              </ExtendedMinWrapper>

                              <DatePickerWrapper style={{ left: '30%' }}>
                                {isStartDateCalenderOpen && (
                                  <DayPicker
                                    showOutsideDays
                                    disabledDays={{ before: todayDate }}
                                    onDayClick={(date) => {
                                      getTempSurveyName(date);
                                      setFieldValue(
                                        'EmployeesCount',
                                        EmployeesCount
                                      );
                                      setFieldValue('StartDate', date);
                                      setIsStartDateCalendarOpen(false);
                                      getTimeOptions(date);
                                      setStartDate(date);
                                    }}
                                    dayPickerProps={{
                                      selectedDays: selectedDay,
                                      disabledDays: {
                                        daysOfWeek: [0, 6],
                                      },
                                    }}
                                  />
                                )}
                              </DatePickerWrapper>

                              <ExtendedFieldWrapper>
                                <FloatingInputLabel
                                  label='Survey Name'
                                  name='Name'
                                  value={surveyName}
                                  onChange={(e) => {
                                    setFieldValue('Name', e.target.value);
                                    closeCalendarPopups();
                                  }}
                                  onFocus={closeCalendarPopups}
                                />

                                <ErrorWrapper>
                                  {touched.Name && errors.Name && errors.Name}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>

                              <ExtendedFieldWrapper>
                                <FloatingInputLabel
                                  label='Number of Employees'
                                  name='Number of Employees'
                                  type='number'
                                  value={values.EmployeesCount}
                                  onChange={(e) => {
                                    setFieldValue(
                                      'EmployeesCount',
                                      e.target.value
                                    );
                                  }}
                                  onFocus={closeCalendarPopups}
                                />
                                <ErrorWrapper>
                                  {touched.EmployeesCount &&
                                    errors.EmployeesCount &&
                                    errors.EmployeesCount}
                                </ErrorWrapper>
                              </ExtendedFieldWrapper>
                            </NewSurveyInputWrapper>
                          </AssitanceForm>
                        </NewSurveyInner>
                      </NewSurveyInnerMain>
                    </Grid>
                  </Grid>
                </NewSurveyWrapper>
              </ModalInnerWrapper>
            }
            footer={
              <FooterButtons
                isDisabled={getDisableValue()}
                submitButtonText={submitButtonText}
                handleSubmit={() => {
                  closeCalendarPopups();
                  handleSubmit();
                }}
                onClose={onClose}
              />
            }
          />
        );
      }}
    />
  );
};

export default withRouter(NewSurveyForm);
