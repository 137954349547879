import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  AppButton,
  LoginFormWrapper,
  ErrorWrapper,
  WelbeeTextField,
} from '../../resources/styling/appStyle';
import { loginNormalEye, loginShowEye } from '../../resources/images/index';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme/colors';
import { AppInputField } from '../../components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { accountService, storageService } from '../../services';
import { routesObj } from '../../routes';
import { loaderAction } from '../../store/loaderStore';
import { firstLoginAction } from '../../store/userStore';
import {
  PasswordReg,
  WebStorageNames,
  Roles,
  WelbeeType,
  toggleOption,
} from '../../utils/Constants';
import { getQueryStringVal, parseJwt } from '../../utils/CommonFunctions';
import { QueryStringTokens, HeadTeacherAccess } from '../../utils/';
import {
  LoginFormInner,
  InputWrapper,
  FieldWrapper,
  PasswordViewIcon,
} from '../../components/common/loginStyle';
import { WrapperMain } from '../../resources/styling/formStyle';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

const { mandy } = colors;

function RegisterDirectorPassword(props) {
  let [isPasswordShow, setIsPasswordShow] = useState(true);
  let [token] = useState(getQueryStringVal(QueryStringTokens.token));
  let [isRegistrationEnable, setRegistrationEnable] = useState(false);
  let [isPasswordFormEnable, setPasswordFormEnable] = useState(false);
  useEffect(() => {
    storageService.clear();
    accountService
      .isDirectorRegistrationEnable(token)
      .then((response) => {
        const { success } = response;
        setRegistrationEnable(success);
        setPasswordFormEnable(success);
        if (!success) props.history.push(routesObj.Login.path);
      })
      .catch((error) => console.log(error));
  }, {});
  return (
    <Formik
      initialValues={{
        Password: '',
        ConfirmPassword: '',
        Token: token,
      }}
      onSubmit={(values, actions) => {
        props.loaderActions.dataLoading(true);
        setTimeout(() => {
          accountService
            .registerDirector(values)
            .then((response) => {
              const { success, message } = response;
              props.loaderActions.dataLoading(false);
              if (success) {
                storageService.setToken({
                  token: response.data.access_token,
                });
                const userInfo = parseJwt(response.data.access_token);
                storageService.setItem(
                  WebStorageNames.UserInfo,
                  response.data.userInfo
                );
                storageService.setItem(
                  WebStorageNames.SelectedTab,
                  response.data.WelbeeType === WelbeeType.WelbeeVoice
                    ? toggleOption.WelbeeVoice
                    : toggleOption.WelbeeSurvey
                );
                if (userInfo.role === Roles.GroupDirector) {
                  storageService.setItem(WebStorageNames.IsMat, true);
                  props.history.push(routesObj.GroupDashboard.path);
                  storageService.setItem(
                    WebStorageNames.Role,
                    Roles.GroupDirector
                  );
                }
                storageService.setItem(
                  WebStorageNames.SchoolId,
                  response.data.schoolId
                );
                storageService.setItem(
                  WebStorageNames.SchoolName,
                  response.data.schoolName
                );
                const token = storageService.getItem(WebStorageNames.Token);
                if (!token) {
                  return <Redirect to={routesObj.Login.path} />;
                }
                if (userInfo.role === Roles.SuperAdmin) {
                  props.history.push(routesObj.SuperAdminDashboard.path);
                }
                storageService.setItem(
                  WebStorageNames.AccessLevel,
                  response.data.AccessLevel
                );
                storageService.setItem(
                  WebStorageNames.IsStaff,
                  response.data.IsStaff
                );
                storageService.setItem(
                  WebStorageNames.WelbeeVoiceAccountType,
                  response.data.WelbeeVoiceAccountType
                );
                storageService.setItem(
                  WebStorageNames.IsFirstLogin,
                  response.data.IsFirstLogin
                );
                storageService.setItem(
                  WebStorageNames.IsVoiceFirstLogin,
                  response.data.IsVoiceFirstLogin
                );
                storageService.setItem(
                  WebStorageNames.FreeTrialEndDate,
                  response.data.FreeTrialEndDate
                );
                storageService.setItem(
                  WebStorageNames.SelectedTab,
                  response.data.WelbeeType === WelbeeType.WelbeeVoice
                    ? toggleOption.WelbeeVoice
                    : toggleOption.WelbeeSurvey
                );
                storageService.setItem(
                  WebStorageNames.IsUpgradeDisplay,
                  response.data.IsUpgradeDisplay
                );
                storageService.setItem(
                  WebStorageNames.IsUpgradBannderDisplayed,
                  false
                );
                props.history.push(routesObj.GroupDashboard.path);
              } else {
                toast.error(message);
              }
            })
            .catch((error) => console.log(error));
        }, 1000);
      }}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        Password: Yup.string()
          .trim()
          .min(12, 'Password must be at least 12 characters.')
          .matches(
            PasswordReg,
            'Your password must contain at least 1 uppercase, lowercase, number and special character from @£!#$%^&+="\',-.'
          )
          .required('Password is required.'),
        ConfirmPassword: Yup.string()
          .trim()
          .oneOf([Yup.ref('Password'), null], 'Passwords must match.')
          .required('Confirm password is required.'),
      })}
      render={({
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        handleSubmit,
      }) => (
        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.keyCode === 13) handleSubmit();
          }}
        >
          {isPasswordFormEnable && (
            <LoginFormWrapper>
              {isRegistrationEnable ? (
                <LoginFormInner>
                  <h2>Setup Your Password</h2>
                  <p>
                    To complete the setup process, please create a password.
                  </p>
                  <InputWrapper>
                    <WrapperMain>
                      <WelbeeTextField
                        fullWidth
                        label='Password'
                        autoComplete='new-password'
                        type={isPasswordShow ? 'password' : 'text'}
                        name='Password'
                        value={values.Password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                        <img
                          src={isPasswordShow ? loginNormalEye : loginShowEye}
                          onClick={(e) => {
                            e.preventDefault();
                            setIsPasswordShow(!isPasswordShow);
                          }}
                          alt=''
                        />
                      </PasswordViewIcon>
                      <ErrorWrapper>
                        {touched.Password && errors.Password && errors.Password}
                      </ErrorWrapper>
                    </WrapperMain>
                    <WrapperMain>
                      <WelbeeTextField
                        fullWidth
                        label='Confirm Password'
                        autoComplete='new-password'
                        type={isPasswordShow ? 'password' : 'text'}
                        name='ConfirmPassword'
                        value={values.ConfirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                        <img
                          src={isPasswordShow ? loginNormalEye : loginShowEye}
                          onClick={(e) => {
                            e.preventDefault();
                            setIsPasswordShow(!isPasswordShow);
                          }}
                          alt=''
                        />
                      </PasswordViewIcon>
                      <ErrorWrapper>
                        {touched.ConfirmPassword &&
                          errors.ConfirmPassword &&
                          errors.ConfirmPassword}
                      </ErrorWrapper>
                    </WrapperMain>

                    <FieldWrapper>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          lg={3}
                          sm={12}
                          xs={12}
                          md={12}
                          className='forget-password'
                        ></Grid>

                        <Grid item lg={9} sm={12} xs={12} md={12}>
                          <AppButton
                            color={mandy}
                            type='submit'
                            onClick={handleSubmit}
                            disabled={!isRegistrationEnable}
                            isDisable={!isRegistrationEnable}
                            style={{
                              cursor: isRegistrationEnable ? 'pointer' : 'auto',
                            }}
                          >
                            Go to welbee
                          </AppButton>
                        </Grid>
                      </Grid>
                    </FieldWrapper>
                  </InputWrapper>
                </LoginFormInner>
              ) : (
                <div style={{ padding: 20 }}>
                  You do not currently have access. Please contact the person
                  who sent you the email invitation for details.
                </div>
              )}
            </LoginFormWrapper>
          )}
        </form>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});
export default connect(
  null,
  mapDispatchToProps
)(withRouter(RegisterDirectorPassword));
