import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { amplitudeLog, getQueryStringVal } from '../../utils/CommonFunctions';
import { storageService, schoolService, accountService } from '../../services';
import SchoolRegisterWrapper from '../../components/common/SchoolRegisterWrapper';
import HeadTecherDetailForm from './UserDetailForm';
import RegisterAdminPassword from './RegisterAdminPassword';
import SchoolDetailForm from './SchoolDetailForm';
import { loaderAction } from '../../store/loaderStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegisterConfirmEmailSent from './RegisterConfirmEmailSent';

function OnlyVoiceSchoolRegistration(props) {
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showEmailSend, setEmailSend] = useState(false);
    const [schoolInfo, setSchoolInfo] = useState(null);
    const [headTeacherEmail, setHeadTeacherEmail] = useState(null);
    const [schoolId, setSchoolId] = useState(-1);
    const [
        isHeadTeacherDetailCompleted,
        setIsHeadTeacherDetailCompleted,
    ] = useState(false);
    const [isSchoolDetailCompleted, setIsSchoolDetailCompleted] = useState(false);
    const [isBackButtonEnable, setIsBackButtonEnable] = useState(false);

    useEffect(() => {
        amplitudeLog('PageView', 'Register_SchoolDetails', '');
        storageService.clear();
        const token = getQueryStringVal('token');
        const userId = getQueryStringVal('userid');
        if (userId) {//incase user comes from the email verification
            let { loaderActions } = props;
            loaderActions.dataLoading(true);
            let response = accountService.VerifyEmailConfirmationToken(userId).then((response) => {
                loaderActions.dataLoading(false);
                if (response.success) {
                    setHeadTeacherEmail(response.data);
                    setIsHeadTeacherDetailCompleted(true);
                    setIsSchoolDetailCompleted(true);
                    setEmailSend(true);
                    if (response.message === "Email confirmed Already")
                        setIsEmailVerified(true);
                    setShowPassword(false);
                }
            }).catch((error) => loaderActions.dataLoading(false));
        }
        else
            if (token) {
                let { loaderActions } = props;
                loaderActions.dataLoading(true);
                schoolService
                    .getSchoolDetailByRegistrationToken(token)
                    .then((response) => {
                        loaderActions.dataLoading(false);
                        if (response.success) {
                            let schoolInfoObj = response.data;
                            setSchoolInfo(schoolInfoObj);
                            // School details part of school registration is complete
                            if (schoolInfoObj.SignUpStatus === 5) {
                                onHeadTeacherDetailFormCompletion(
                                    schoolInfoObj,
                                    schoolInfoObj.Id
                                );
                            }
                            if (schoolInfoObj.SignUpStatus === 1) {
                                onSchoolDetailFormCompletion(schoolInfoObj, schoolInfoObj.Id);
                            }
                            if (schoolInfoObj.SignUpStatus === 4) {
                                setHeadTeacherEmail(schoolInfoObj.Email);
                                setSchoolId(schoolInfoObj.Id);
                                setIsHeadTeacherDetailCompleted(true);
                                setIsSchoolDetailCompleted(true);
                            }
                        }
                    })
                    .catch((error) => loaderActions.dataLoading(false));
            }
    }, {});

    const onSchoolDetailFormCompletion = (schoolInfoObj, schoolId) => {
        setSchoolInfo(schoolInfoObj);
        setSchoolId(schoolId);
        setIsHeadTeacherDetailCompleted(true);
        setIsSchoolDetailCompleted(true);
        setIsBackButtonEnable(true);
    };

    const onHeadTeacherDetailFormCompletion = (schoolInfoObj, schoolId) => {
        if (schoolInfoObj.Email) setHeadTeacherEmail(schoolInfoObj.Email);
        setSchoolInfo(schoolInfoObj);
        setSchoolId(schoolId);
        setIsHeadTeacherDetailCompleted(true);
        setIsBackButtonEnable(true);
    };

    const onBackButtonPress = () => {
        if (isHeadTeacherDetailCompleted && isSchoolDetailCompleted) {
            setIsSchoolDetailCompleted(false);
        } else if (isHeadTeacherDetailCompleted && !isSchoolDetailCompleted) {
            setIsHeadTeacherDetailCompleted(false);
            setIsBackButtonEnable(false);
        }
    };
    return (
        <SchoolRegisterWrapper
            onBack={isBackButtonEnable ? onBackButtonPress : null}
        >
            {!isHeadTeacherDetailCompleted ? (
                <HeadTecherDetailForm
                    onCompletion={onHeadTeacherDetailFormCompletion}
                    schoolId={schoolId}
                    SchoolInfo={schoolInfo}
                />
            ) : !isSchoolDetailCompleted ? (
                <SchoolDetailForm
                    onCompletion={onSchoolDetailFormCompletion}
                    schoolId={schoolId}
                    SchoolInfo={schoolInfo}
                />
                ) : !showEmailSend ? (
                    <RegisterConfirmEmailSent AccType='V'
                        Email={headTeacherEmail}
                    />) : showPassword ? (<div>Invalid request</div>): (
                        <RegisterAdminPassword
                            Email={headTeacherEmail}
                            SchoolInfo={schoolInfo}
                                schoolId={schoolId}
                                isEmailVerified={isEmailVerified}
                        />
                    )}
        </SchoolRegisterWrapper>
    );
}

const mapDispatchToProps = (dispatch) => ({
    loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
    null,
    mapDispatchToProps
)(withRouter(OnlyVoiceSchoolRegistration));
