import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { AppModel } from '../../../components';
import MuiDropDown from '../../../components/common/MuiDropDown';
import { WrapperMain } from '../../../resources/styling/formStyle';
import {
  AppButton,
  AssitanceForm,
  ErrorWrapper,
  FooterButtonsWrapper,
  ModalInnerWrapper,
  NewSurveyInner,
  NewSurveyInnerMain,
  NewSurveyWrapper,
} from '../../../resources/styling';
import { colors } from '../../../resources/theme/colors';
import { matCycleService, surveyService } from '../../../services';
import { loaderAction } from '../../../store/loaderStore';
import { InputWrapper } from './MatCycleStyle';

const { purple, outrageousOrange } = colors;

const EditCycleSurvey = (props) => {
  const [schoolSurvey, setSchoolSurvey] = useState('');
  const [disabled, setDisable] = useState(false);
  const [surveyItems, setSurveyItems] = useState([]);

  useEffect(() => {
    initialValues();
    if (props.isOpen) {
      getSchoolSurveys();
    }
  }, [props.isOpen]);

  const getSchoolSurveys = () => {
    surveyService
      .getSchoolSurveys(props.schoolId)
      .then((response) => {
        const { success } = response;
        if (success) {
          setSurveyItems(response.data);
          setSchoolSurvey(props.surveyId);
        }
      })
      .catch((error) => {
        toast.error('Error loading mat cycle survey');
      });
  };

  const handleSurveyChange = (event) => {
    setSchoolSurvey(event.target.value);
  };

  const surveys =
    surveyItems !== null
      ? surveyItems.map((item) => (
        <MenuItem key={item.Id} value={item.Id}>
          {item.Name}
        </MenuItem>
      ))
      : null;

  const initialValues = (data) => {
    setDisable(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        Id: props.matCycleSchoolId,
        Survey: schoolSurvey !== null ? schoolSurvey : '',
        SchoolId: props.schoolId,
        MatCycleId: props.matCycleId,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        SchoolId: Yup.string().required('school is required.').nullable(),
        MatCycleId: Yup.string().required('mat cycle is required.').nullable(),
        Survey: Yup.string().required('Survey is required.').nullable(),
      })}
      onSubmit={(values, formProps) => {
        setDisable(true);
        let model = {
          Id: values.Id,
          SurveyId: values.Survey,
          SchoolId: values.SchoolId,
          MatCycleId: values.MatCycleId,
          IsIncluded: true,
        };
        matCycleService
          .saveCycleSchool(model)
          .then((response) => {
            if (response.success) {
              formProps.resetForm();
              toast.success('survey updated successfully.');
              props.handleSubmit();
              initialValues();
            } else {
              toast.error('survey not updated. Please try again later.');
              setDisable(false);
            }
          })
          .catch(() => {
            setDisable(false);
          });
      }}
      render={({
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <div>
            <AppModel
              open={props.isOpen}
              onClose={props.onClose}
              titleText='Edit Survey'
              bodyText={
                <ModalInnerWrapper>
                  <NewSurveyWrapper>
                    <Grid container className='form-inner-wrapper'>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        md={12}
                        xs={12}
                        mt='15px'
                        className='form-column-wrapper'
                      >
                        <InputWrapper>
                          <WrapperMain>
                            <MuiDropDown
                              label='Survey'
                              labelId='survey-label'
                              id='survey'
                              name='Survey'
                              value={schoolSurvey}
                              onChange={handleSurveyChange}
                            >
                              {surveys}
                            </MuiDropDown>
                            <ErrorWrapper>
                              {touched.Survey && errors.Survey}
                            </ErrorWrapper>
                          </WrapperMain>
                          <AppButton
                            style={{
                              cursor: disabled
                                ? 'not-allowed'
                                : 'pointer',
                            }}
                            disabled={disabled}
                            color={outrageousOrange}
                            type='submit'
                            onClick={handleSubmit}
                          >
                            {disabled ? 'Saving...' : 'Save'}
                          </AppButton>
                        </InputWrapper>
                      </Grid>
                    </Grid>
                  </NewSurveyWrapper>
                </ModalInnerWrapper>
              }
              footer={<FooterButtonsWrapper></FooterButtonsWrapper>}
            />
          </div>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(EditCycleSurvey);
