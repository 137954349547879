import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { limitAverageValue } from '../../utils/roundScore';
import { routesObj } from '../../routes';
import { storageService } from '../../services';
import { WebStorageNames, QueryStringTokens } from '../../utils';
import { amplitudeLog, getQueryStringVal } from '../../utils/CommonFunctions';
import { navigationActions } from '../../store/headerStore';
import { loaderAction } from '../../store/loaderStore';
import Tooltip from '@mui/material/Tooltip';
import { StrongestWrapper, HeaderWrapper } from './DashboardStyle';

class StrongestTiles extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, count: 1 };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
  }

  //To re run loader
  componentDidUpdate(prevProps, prevState) {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    const showloader = async () => {
      await delay(1000);
      prevProps.loaderActions.dataLoading(true);
    };
    const stopLoader = async () => {
      await delay(3000);
      prevProps.loaderActions.dataLoading(false);
    };
    if (
      this.props.score?.length >= 1 &&
      this.props.score?.length < 4 &&
      this.props.isShowResults
    ) {
      showloader();
    } else {
      stopLoader();
    }
  }

  render() {
    const {
      score,
      history,
      navigationActions,
      IsHeatMap,
      IsDirector,
      isGroupDashboard,
      isActionAllowed,
      isQuestionBankAvailable,
      hasAdditionalQuestions,
    } = this.props;
    return (
      <StrongestWrapper>
        {score && score !== 0 ? (
          score.map(function (item, index) {
            if (item != null)
              console.log(
                'previous overall is ' + item.PreviousOverallCategoryAverage
              );
            return (item != null &&
              isQuestionBankAvailable &&
              item.CategoryName === 'Additional Questions' &&
              hasAdditionalQuestions) ||
              (item != null && item.CategoryName !== 'AdditionalQuestions') ? (
              <div
                className={`tiles-box ${
                  !isFinite(item.OverallCategoryAverage) ? '' : 'ms-tiles-box'
                }`}
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (
                    item.CategoryName !== 'Staff Heat Map' &&
                    item.OverallCategoryAverage !== 'N/A' &&
                    item.SchoolDecilesCategoryAverage !== 'N/A' &&
                    item.HseDecilesCategoryAverage !== 'N/A' &&
                    !isGroupDashboard
                  ) {
                    let surveyToken = storageService.getItem(
                      WebStorageNames.SurveyToken
                    );

                    let schoolToken = getQueryStringVal(
                      QueryStringTokens.schoolToken
                    );
                    const path = `${routesObj.ManagementStandards.path}?token=${surveyToken}&schoolToken=${schoolToken}&categoryId=${item.Category}`;
                    history.push(path);
                    amplitudeLog(
                      'Dashboard',
                      'ManagementStandard' + item.CategoryName,
                      ''
                    );
                  } else if (
                    item.CategoryName !== 'Staff Heat Map' &&
                    item.OverallCategoryAverage !== 'N/A' &&
                    item.SchoolDecilesCategoryAverage !== 'N/A' &&
                    item.HseDecilesCategoryAverage !== 'N/A' &&
                    isGroupDashboard
                  ) {
                    let token = getQueryStringVal(QueryStringTokens.token);
                    token = token !== null && token !== 'null' ? token : '';
                    const searchParams = new URLSearchParams();
                    searchParams.append('categoryId', item.Category);
                    if (token !== null && token !== '')
                      searchParams.append('token', token);

                    history.push({
                      pathname: routesObj.GroupManagementStandards.path,
                      search: '?' + searchParams.toString(),
                    });
                    amplitudeLog(
                      'GroupDashboard',
                      'GroupManagementStandard' + item.CategoryName,
                      ''
                    );
                  } else if (
                    item.CategoryName === 'Staff Heat Map' &&
                    item.OverallCategoryAverage !== 'N/A' &&
                    item.SchoolDecilesCategoryAverage !== 'N/A' &&
                    item.HseDecilesCategoryAverage !== 'N/A' &&
                    IsHeatMap
                  ) {
                    navigationActions.activeItemAction({
                      item: 'Staff',
                    });
                    let surveyToken = storageService.getItem(
                      WebStorageNames.SurveyToken
                    );
                    let schoolToken = getQueryStringVal(
                      QueryStringTokens.schoolToken
                    );
                    if (IsDirector) {
                      history.push(
                        routesObj.MatHeatMap.path + window.location.search
                      );
                    } else if (schoolToken) {
                      history.push(
                        routesObj.StaffRoleHeatmap.path +
                          '?token=' +
                          surveyToken +
                          '&schoolToken=' +
                          schoolToken
                      );
                    } else {
                      history.push(
                        routesObj.StaffRoleHeatmap.path +
                          '?token=' +
                          surveyToken
                      );
                    }
                  }
                }}
              >
                <Tooltip
                  title='Click to see results for each question within this management standard.'
                  followCursor
                >
                  <HeaderWrapper value={`${item.CategoryColorName}`}>
                    {item.CategoryName === 'Staff Heat Map'
                      ? IsHeatMap
                        ? item.CategoryName
                        : 'Coming soon'
                      : item.CategoryName}

                    {item.CategoryName === 'Staff Heat Map' ? (
                      <div className='survey-results heat-map-container'>
                        <span>
                          {IsHeatMap
                            ? 'Heatmap of results by school and staff characteristics'
                            : 'Coming soon'}
                        </span>
                      </div>
                    ) : (
                      <div>
                        {!isFinite(item.OverallCategoryAverage) ? (
                          <div className='survey-results'>
                            {item.OverallCategoryAverage}{' '}
                            {item.PreviousOverallCategoryAverage ? (
                              <span className='previous-overall-score'>
                                ({item.PreviousOverallCategoryAverage})
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          <div className='big-number'>
                            {limitAverageValue(item.OverallCategoryAverage)}{' '}
                            {item.PreviousOverallCategoryAverage ? (
                              <span className='previous-overall-score'>
                                (
                                {limitAverageValue(
                                  item.PreviousOverallCategoryAverage
                                )}
                                )
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                        <div className='percentage'>
                          <span className='arrow-up' />
                          <span>
                            {item.CategoryName === 'Staff Heat Map'
                              ? ''
                              : '45%'}
                          </span>
                        </div>
                        {item.Category === 8 ? null : (
                          <div className='school-decile'>
                            <span>School Decile/Benchmark</span>
                            {isActionAllowed === true ? (
                              <span>
                                {item.SchoolDecilesCategoryAverage}{' '}
                                {item.PreviousSchoolDecilesCategoryAverage ? (
                                  <span>
                                    ({item.PreviousSchoolDecilesCategoryAverage}
                                    )
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                            ) : (
                              '---'
                            )}
                          </div>
                        )}
                        {item.Category === 8 ? null : (
                          <div className='hse-decile'>
                            <span>HSE Decile/Benchmark</span>
                            {isActionAllowed === true ? (
                              <span>
                                {item.HseDecilesCategoryAverage}{' '}
                                {item.PreviousHseDecilesCategoryAverage ? (
                                  <span>
                                    ({item.PreviousHseDecilesCategoryAverage})
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                            ) : (
                              '---'
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {/* {item.HseDecilesCategoryAverage !== 'N/A' && !isGroupDashboard && (                    
                        <div className='tiles-hover'>
                          Click to see results for each question within this management standard.
                        </div>                    
                    )} */}
                  </HeaderWrapper>
                </Tooltip>
              </div>
            ) : (
              <></>
            );
          })
        ) : (
          <span className='not-available'>Not available</span>
        )}
      </StrongestWrapper>
    );
  }
}
const mapStateToProps = ({ header: { activeItem, newCommentCount } }) => ({
  activeItem,
  newCommentCount,
});
const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StrongestTiles));
