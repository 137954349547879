import React from 'react';

const LearnStaff = () => {
  return (
    <div>
      <p>
        The office of National Statistics has a wellbeing dashboard that covers
        43 different measures of wellbeing, including such things as where
        people live and their access to green space. Being able to provide
        support across all of these is simply not possible.
      </p>
      <p>
        As a school leader it is important to prioritise those areas of
        wellbeing that a staff member perceives to be as a result of their work
        environment and can be improved through workplace interventions.
      </p>
      <p>
        We have used an evidence based approach to assessing the risks of poor
        staff wellbeing and workplace stress with a focus on working conditions
        and leadership, and in providing recommended actions so you are able to
        address these in the most effective way.
      </p>
      <p>
        Any employer with five or more staff should already be doing this and
        have in place a process for identifying the pressures on staff and that
        allows them to take appropriate action. For English schools that are
        inspected by Ofsted, this is now part of their Leadership and Management
        Judgement in the September 2019 framework.
      </p>
      <p>
        Many schools we work with are unsure where to focus to achieve the best
        impact and as a result are often working on symptoms and not causes.
        Like building a house, if you do not get the foundations right, then you
        are likely to always be fire-fighting.
      </p>
      <p>
        While there is nothing wrong in having wellbeing days, providing fitness
        sessions, cakes in the staff room and other treats and they may deliver
        some short term benefit, these efforts are likely to be wasted without
        establishing strong foundations first. If a staff member is not enjoying
        themselves or feeling valued then what is likely to change simply
        through offering any of the above.
      </p>
      <p>
        Addressing the culture and making staff wellbeing just part of what
        happens each day is where to start and using the Management Standards
        approach will deliver long-term and positive change.
      </p>
    </div>
  );
};

export default LearnStaff;
