import request from '../request';
import { additionalQuestionsAPI, likertAPI, matLikertAPI } from './urls';

export const getQuestionBankBySchoolId = (schoolId) => {
  return request({
    url: additionalQuestionsAPI.getQuestionBankBySchoolId(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getQuestionsBySchoolId = (schoolId) => {
  return request({
    url: additionalQuestionsAPI.getQuestionsBySchoolId(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

export const setSurveyQuestions = (question) => {
  return request({
    url: additionalQuestionsAPI.api,
    method: 'POST',
    data: question,
  })
    .then((response) => response)
    .catch((response) => response);
};

//LIKERT STARTS
export const setCustomLikert = (options) => {
  return request({
    url: likertAPI.api,
    method: 'POST',
    data: options,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const setMatCustomLikert = (options) => {
  return request({
    url: matLikertAPI.api,
    method: 'POST',
    data: options,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const deleteCustomLikert = (id) => {
  return request({
    url: likertAPI.deleteLikertById(id),
    method: 'Get',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getLikertsBySchoolId = (schoolId) => {
  return request({
    url: likertAPI.getLikertBySchoolId(schoolId),
    method: 'Get',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getLikertsByMatGroupId = (matGroupId) => {
  return request({
    url: matLikertAPI.getLikertByMatGroupId(matGroupId),
    method: 'Get',
  })
    .then((response) => response)
    .catch((response) => response);
};

//LIKERT ENDS

export const deleteSurveyQuestions = (question) => {
  return request({
    url: additionalQuestionsAPI.api,
    method: 'DELETE',
    data: question,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const downloadHseQuestions = () => {
  return request({
    url: additionalQuestionsAPI.downloadHseQuestions(),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};

const additionalQuestionService = {
  setSurveyQuestions,
  deleteSurveyQuestions,
  getQuestionsBySchoolId,
  getQuestionBankBySchoolId,
  downloadHseQuestions,
  setCustomLikert,
  setMatCustomLikert,
  deleteCustomLikert,
  getLikertsBySchoolId,
  getLikertsByMatGroupId,
};

export default additionalQuestionService;
