import React from 'react';
import dashboard from '../../resources/images/dashboard.jpg';

const LearnResults = () => {
  return (
    <div>
      <p className='email-anchor'>
        Your results will be provided to you through your on-line dashboard.
        This is always available at&nbsp;
        <a
          href='https://app.welbee.co.uk'
          target='_blank'
          rel='noopener noreferrer'
        >
          https://app.welbee.co.uk
        </a>
        and you will need your email and password.
      </p>
      <p>
        Once logged-in you will be taken to your dashboard. This shows the
        individual scores and benchmarks for all six management standards that
        are measured. There are seven scores as Support is broken down into that
        provided by Managers and Peers.
      </p>
      <p>
        The eighth box will allow you to drill down into the filters that we
        provide so you can see which groups of staff have stronger and weaker
        scores across each of the management standards. These are shown as heat
        maps for each of the filters (staff role and characteristics). These
        will be highlighted when 5 or more staff are represented within each
        filter (otherwise you will see a dash and this is to maintain
        anonymity).
      </p>
      <p>
        This will mean you can more quickly address causes and focus your energy
        and attention on those that need it – this is important within a busy
        school.
      </p>
      <p className='copy-link-date'>
        These are available for surveys run from the 1st April 2020.
      </p>
      <p style={{ fontWeight: 'bold' }}>Your Dashboard</p>
      <img src={dashboard} alt='' style={{ marginBottom: '20px' }} />
      <p>
        The top line shows your strongest scores in decreasing order from left
        to right and these are based on your comparative performance against
        other schools. It is possible for all scores to be strong or weak. By
        clicking on each of these scores you will be taken to the individual
        questions relating to the relevant Management Standard and can view the
        number of staff that have answered.
      </p>
      <p>
        You will also see heat maps for each question to allow you to quickly
        identify where there may be specific strengths or opportunities for
        development
      </p>
      <p>
        Trends against previous surveys will also be shown, if and when they
        have been undertaken, so you can see how your scores change over time.
      </p>
      <p>
        While this is a measure of progress it will also be influenced by a
        range of factors, including some that may be external to the school or
        outside your control.
      </p>
      <p>
        Your results may be disappointing in some areas and you may even feel
        hurt by them or some of the comments made. It is important that you do
        not take anything personally. They will help with creating a baseline
        and by sharing these results and other school data with staff, you can
        involve them in finding and implementing solutions for the benefit of
        all those at the school.
      </p>
    </div>
  );
};

export default LearnResults;
