import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { MediaQuery } from '../../resources/styling/appStyle';

const {
  pictonBlue, whiteColor
} = colors;

/*** Account Registration ***/
export const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        backgroundColor: '#53B5E0',
        padding: '15px 20px',
        color: '#ffffff',
        borderRight: '1px solid ${lightBlue}',
  
        fontSize: '16px',
        width: '11.1%',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        fontWeight: '600'
      },
    },
};

/*** Activity Report Header ***/
export const ActivityReportWrapper = styled.div`
  float: left;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  ${MediaQuery}

  .email-link {
    color: ${pictonBlue};
    text-decoration: underline;
    cursor: pointer;
  }

  .status-filter-dropdown {
    width: 240px;
    float: right;
    position: relative;
    margin-top: -15px;
    margin-right: -5px;
    .category-inner-wrapper {
      margin: 16px 0 4px 0 !important;
      .options {
        position: absolute;
        z-index: 1;
        width: 100%;
        box-shadow: 3px 3px 10px rgba(0, 122, 122, 0.12);
        background: ${whiteColor};
        span {
          font-size: 14px !important;
        }
      }
      label {
        color: #ffffff !important;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .comments-search-filters {
      position: relative !important;
      width: 100% !important;
      left: 0;
      right: 0 !important;
      .search-filed {
        width: 100% !important;
        img {
          margin: 15px 0 0 11px !important;
        }
      }
      .search-filters {
        width: 35% !important;
      }
    }

    .status-filter-dropdown {
      width: 100% !important;
      .category-inner-wrapper {
        background: transparent !important;
        margin: 16px 0 20px 0 !important;
      }
    }
  }
`;
export const ActivityReportInnerMain = styled.div`
  width: 100%;
  min-height: calc(100vh - 216px);
`;
export const ActivityReportInner = styled.div`
  display: block;
`;
export const ButtonWrapper = styled.div`
  width: 100px;
  height: 48px;
`;

/*** Survey Expandable Component ***/
export const SurveyCustomStyles = {
    rows: {
      style: {
        backgroundColor: '#C0C0C0',
      },
    },
    headCells: {
      style: {
        backgroundColor: '#DADADA',
        color: '#000',
        fontSize: '16px',
      },
    },
  };


const ActivityReportStyle = {
    customStyles,
    ActivityReportWrapper,
    ActivityReportInnerMain,
    ActivityReportInner,
    ButtonWrapper,
    SurveyCustomStyles
}
export default ActivityReportStyle;