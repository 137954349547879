import React, { useState, useEffect, Fragment } from 'react';
import NewSurveyForm from '../schoolSurvey/NewSurveyForm';
import {
  surveyService,
  storageService,
  schoolStaffService,
} from '../../services';
import { WebStorageNames, HeadTeacherAccess, WelbeeTypes } from '../../utils';
import FooterButtons from '../../components/common/FooterButtons';
import { colors } from '../../resources/theme/colors';
import { AppModel } from '../../components';
import { DateFormat, DateFormatLocalTime } from '../../utils/CommonFunctions';
import { ModalCustomStyles } from '../../resources/styling/appStyle';
import { accountService } from '../../services';
import AccessLevel from '../../components/common/AccessLevel';
import {
  AccessPermissions,
  Roles
} from '../../utils/Constants';
const CreateSurveyButton = ({ openCreateForm }) => {
  const [isCreateSurveyDialog, setIsCreateSurveyDialog] = useState(false);
  const [createdSurvey, setCreatedSurvey] = useState(null);
  const [isCreated, setIsCreated] = useState(false);
  const [surveyName, setSurveyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [staffExist, setStaffExist] = useState(false);
  const [isCreateSurveyConfirmation, setisCreateSurveyConfirmation] =
    useState(false);
  const [StartDate, setStartDate] = useState('');
  const [accessLevel] = useState(
    storageService.getItem(WebStorageNames.AccessLevel)
  );
  const [employeesCount, setEmployeesCount] = useState('');
  const [
    isAddtionalQuestionFiltersIncluded,
    setIsAddtionalQuestionFiltersIncluded,
  ] = useState('');
  const { barleyWhite, purple } = colors;

  useEffect(() => {
    if (openCreateForm != null && openCreateForm == true)
      setIsCreateSurveyDialog(true);
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    schoolStaffService
      .schoolStaffExist(schoolId)
      .then((response) => {
        if (response.success && response.total > 0) {
          setStaffExist(true);
        }
      })
      .catch((response) => { });

    getRegions();
  }, []);

  const handleCancelCreateSurveyConfirm = () => {
    setisCreateSurveyConfirmation(false);
  };

  const handleCloseCreateSurveyConfirm = () => {
    setIsCreateSurveyDialog(false);
    setisCreateSurveyConfirmation(false);
  };

  const handleNewSurveyCancel = () => {
    setIsCreateSurveyDialog(false);
  };

  const handleAddSurveySubmit = (values) => {
    setisCreateSurveyConfirmation({
      ...isCreateSurveyConfirmation,
      isOpen: false,
    });
    //values.StartDate = DateFormatLocalTime(values.StartDate);
    setIsLoading(true);
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    values.SchoolId = schoolId;
    setCreatedSurvey(null);
    surveyService
      .addSurvey(values)
      .then((response) => {
        if (response.success) {
          const surveyObj = response.data;
          if (surveyObj.SurveyToken) {
            setCreatedSurvey(surveyObj);
            setIsCreated(true);
          } else window.location.reload();
          setIsLoading(false);
          onCreateSurvey(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });

    setisCreateSurveyConfirmation(false);
    setIsCreateSurveyDialog(false);
  };

  const onCreateSurvey = (isReload = false) => {
    if (isReload) window.location.reload();
  };

  function getTempSurveyName(date) {
    let startdate = ' (' + DateFormat(date) + ')';
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    setIsLoading(true);
    surveyService
      .getNewSurveyName(schoolId)
      .then((response) => {
        GetNumberOfEmployees();
        setSurveyName(response.data + startdate);
        setStartDate(date);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function GetNumberOfEmployees() {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    setIsLoading(true);
    schoolStaffService
      .getNumberOfEmployees(schoolId)
      .then((response) => {
        setEmployeesCount(response.data === 0 ? '' : response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function AddtionalQuestionFiltersIncluded() {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    setIsLoading(true);
    surveyService
      .hasAddtionalQuestionFiltersIncluded(schoolId)
      .then((response) => {
        setIsAddtionalQuestionFiltersIncluded(
          response.data.hasAddtionalQuestionFiltersIncluded
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  //checking if the call from US region then remove survey time limitation
  const [isUsRegion, setIsUsRegion] = useState(false);
  const getRegions = () => {
    accountService
      .checkCurrentRegion()
      .then((response) => {
        if (response.data === 'us') {
          setIsUsRegion(true);
        } else {
          setIsUsRegion(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {isCreateSurveyDialog ? (
        <div>
          <NewSurveyForm
            title={'Create New Survey'}
            submitButtonText={'Create Survey'}
            isOpen={isCreateSurveyDialog}
            onClose={handleNewSurveyCancel}
            isUsRegion={isUsRegion}
            onSubmit={(values) => {
              setStartDate(DateFormat(values.StartDate));
              setisCreateSurveyConfirmation({
                isOpen: true,
                onConfirm: () => {
                  handleAddSurveySubmit(values);
                },
              });
            }}
            surveyName={surveyName}
            StartDate={StartDate}
            isLoading={isLoading}
            staffExist={staffExist}
            getTempSurveyName={getTempSurveyName}
            EmployeesCount={employeesCount}
            isAddtionalQuestionFiltersIncluded={
              isAddtionalQuestionFiltersIncluded
            }
          />
        </div>
      ) : (
        <Fragment />
      )}
      {isCreateSurveyConfirmation ? (
        <AppModel
          onClose={handleCloseCreateSurveyConfirm}
          styles={ModalCustomStyles}
          open={true}
          titleText={'Confirmation'}
          bodyText={
            <>
              You are creating your survey {<b>{surveyName}</b>} with a launch
              date of {<b>{StartDate}</b>}. Invitations to staff members will be
              sent at the specified time.
            </>
          }
          footer={
            <FooterButtons
              submitButtonText={'Confirm'}
              handleSubmit={isCreateSurveyConfirmation.onConfirm}
              onClose={handleCancelCreateSurveyConfirm}
            />
          }
        />
      ) : (
        <div />
      )}
      <AccessLevel permissions={[AccessPermissions.Full, AccessPermissions.HeadTeacher, AccessPermissions.Admin]} >
        <button
          className='create-survey-button'
          onClick={() => {
            AddtionalQuestionFiltersIncluded();
            GetNumberOfEmployees();
            setIsCreateSurveyDialog(true);
          }}
        >
          Create Survey
        </button>
      </AccessLevel>
    </>
  );
};

export default CreateSurveyButton;
