import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import schoolService from '../../services/api/schoolService';
import { AdminHeader, Paging, FloatingInputLabel } from '../../components';
import { colors } from '../../resources/theme/colors';
import { searchIconWhite } from '../../resources/images';
import Grid from '@mui/material/Grid';
import { iconDownload } from '../../resources/images/index';
import {
  SchoolListMainWrapper,
  DownloadButtonStyle,
  TableStyle,
} from '../../resources/styling/appStyle';
import {
  SchoolSurveyWrapper,
  SchoolSurveyInnerMain,
  SchoolSurveyInner,
  PaginationWrapper,
} from './SuperAdminStyle';
import { DateFormat } from '../../utils/CommonFunctions';
import Stack from '@mui/material/Stack';

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { subDays } from 'date-fns';
import { DateFormats } from '../../utils';

class SentEmails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageOfItems: [],
      sentEmailsList: [],
      filteredList: [],
      nameSearchValue: '',
      noDataFound: false,
      selectedOption: {
        value: `${DateFormat(
          subDays(new Date(), 30),
          DateFormats.format_31_12_2019
        )} - ${DateFormat(
          subDays(new Date(), 1),
          DateFormats.format_31_12_2019
        )}`,
      },
      isDateRangeOpen: false,
      selectionRange: {
        startDate: subDays(new Date(), 30),
        endDate: subDays(new Date(), 1),
        key: 'selection',
      },
    };
    this.applyFilters = this.applyFilters.bind(this);
    this.handleNameSearchChange = this.handleNameSearchChange.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.dowmloadExcel = this.dowmloadExcel.bind(this);
  }

  componentDidMount() {
    const from = this.state.selectedOption.value;
    this.getSentEmailsWithDateTime(from);
  }
  dowmloadExcel() {
    schoolService
      .downloadSESEmails(
        this.state.nameSearchValue,
        this.state.selectedOption.value
      )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
      });
  }
  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems });
  }
  showData = (response) => {
    if (response.data === null || response.data.length == 0)
      this.setState({ noDataFound: true });
    else this.setState({ noDataFound: false });
    if (response.data != null) {
      this.setState(
        {
          sentEmailsList: response.data,
        },
        this.applyFilters
      );
    }
  };

  getSentEmails() {
    schoolService.getSentEmails().then((response) => {
      this.showData(response);
    });
  }
  getSentEmailsWithDateTime = (val) => {
    schoolService.GetSentEmailsWithDateTime(val).then((response) => {
      this.showData(response);
    });
  };
  applyFilters() {
    const { nameSearchValue, sentEmailsList } = this.state;
    let searchList = sentEmailsList.filter(
      (x) =>
        x.School.Name.toLocaleLowerCase().includes(
          nameSearchValue.toLocaleLowerCase()
        ) ||
        x.EmailTypeDescription.toLocaleLowerCase().includes(
          nameSearchValue.toLocaleLowerCase()
        ) ||
        x.Subject.toLocaleLowerCase().includes(
          nameSearchValue.toLocaleLowerCase()
        ) ||
        x.To.toLocaleLowerCase().includes(nameSearchValue.toLocaleLowerCase())
    );
    this.setState({ filteredList: searchList });
  }
  handleNameSearchChange = (e) => {
    this.setState({ nameSearchValue: e.target.value }, this.applyFilters);
  };

  handleSelect = (ranges) => {
    console.log('ranges', ranges);
    this.setState({
      selectionRange: ranges.selection,
      //isDateRangeOpen: !this.state.isDateRangeOpen,
    });
    const startDate = DateFormat(
      ranges.selection.startDate,
      DateFormats.format_31_12_2019
    );
    const endDate = DateFormat(
      ranges.selection.endDate,
      DateFormats.format_31_12_2019
    );
    //if (startDate !== endDate) {
    const dateRange = `${startDate} - ${endDate}`;
    console.log('dateRange', dateRange);
    this.getSentEmailsWithDateTime(dateRange);
    this.setState({
      selectedOption: {
        value: dateRange,
      },
      //isDateRangeOpen: !this.state.isDateRangeOpen,
    });
    //}
  };
  render() {
    return (
      <div>
        <AdminHeader />
        <DownloadButtonStyle
          style={{
            float: 'right',
            position: 'relative',
            margin: '10px 30px',
          }}
          data-id-div='reportDownload'
          onClick={this.dowmloadExcel}
        >
          <img src={iconDownload} alt='' /> Download
        </DownloadButtonStyle>
        <SchoolSurveyWrapper>
          <SchoolSurveyInnerMain>
            <div>
              <SchoolListMainWrapper>
                <Grid container>
                  <Grid
                    col={12}
                    xl={3}
                    lg={3}
                    md={12}
                    sm={12}
                    className='px-0'
                    style={{ height: '60px' }}
                  >
                    <h1 style={{ 'max-height': '60px', margin: '0px' }}>
                      Sent Emails
                    </h1>
                  </Grid>

                  <Grid col={12} xl={9} lg={9} md={12} sm={12} className='px-0'>
                    <h1
                      style={{ minHeight: '60px' }}
                      className='super-admin-header'
                    >
                      <div className='comments-search-filters'>
                        <Stack direction='row' spacing={2}>
                          <div className='subcontainer-inner school-list-header'>
                            <div className='subcontainer-inner school-list-header'>
                              <div className='search-filed sent-email-search'>
                                <img
                                  src={searchIconWhite}
                                  alt='search button'
                                />
                                <input
                                  id='searchInputField'
                                  placeholder='Enter a keyword'
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.handleNameSearchChange(e);
                                  }}
                                  icon='search'
                                  className='searchField'
                                />
                              </div>
                            </div>

                            {this.state.isDateRangeOpen && (
                              <div
                                style={{
                                  position: 'absolute',
                                  marginTop: '106px',
                                  right: '0px',
                                }}
                              >
                                <DateRangePicker
                                  editableDateInputs={true}
                                  ranges={[this.state.selectionRange]}
                                  onChange={this.handleSelect}
                                  maxDate={new Date()}
                                  direction='horizontal'
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={2}
                                  endDatePlaceholder='Continuo'
                                  rangeColors={[
                                    '#f33e5b',
                                    '#3ecf8e',
                                    '#fed14c',
                                  ]}
                                />
                              </div>
                            )}
                          </div>

                          <div className='subcontainer-inner school-list-header'>
                            <div className='search-filed sent-email-search'>
                              <input
                                value={this.state.selectedOption.value}
                                onChange={(e) => {
                                  e.preventDefault();
                                }}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  this.setState({
                                    isDateRangeOpen:
                                      !this.state.isDateRangeOpen,
                                  });
                                }}
                                icon='search'
                                className='searchField'
                                readOnly
                              />
                            </div>
                          </div>
                        </Stack>
                      </div>
                    </h1>
                  </Grid>
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} px='0px'>
                  <TableStyle>
                    <table>
                      <thead>
                        <tr
                          style={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: 'White',
                            zIndex: 999,
                          }}
                        >
                          <th className='list-heading list-action'>Date</th>
                          <th className='list-heading list-action'>
                            School Name
                          </th>
                          <th className='list-heading list-action'>Subject</th>
                          <th className='list-heading list-action'>
                            Email Type
                          </th>
                          <th className='list-heading list-action'>To</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.pageOfItems.map((item, i) => (
                          <tr key={item.Id} style={{ width: '10%' }}>
                            <td>
                              {new Date(
                                Date.parse(item.CreatedOn)
                              ).toDateString()}
                            </td>
                            <td>{item.School.Name}</td>
                            <td>{item.Subject}</td>
                            <td>{item.EmailTypeDescription}</td>
                            <td>{item.To}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TableStyle>
                  {this.state.noDataFound
                    ? 'No emails found. Please change filters.'
                    : ''}
                </Grid>
                <PaginationWrapper>
                  <Paging
                    items={this.state.filteredList}
                    onChangePage={this.onChangePage}
                    isChanged={this.state.isChanged}
                  />
                </PaginationWrapper>
              </SchoolListMainWrapper>
            </div>
          </SchoolSurveyInnerMain>
        </SchoolSurveyWrapper>
      </div>
    );
  }
}

export default withRouter(SentEmails);
