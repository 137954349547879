import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AppButton } from '../../resources/styling';
import { TextArea, Messages, AppModel, FloatingInputLabel } from '../../components';
import Grid from '@mui/material/Grid';
import { iconRequest } from '../../resources/images/index';
import { MainFooter } from '../../resources/styling/appStyle';
import { WrapperMain } from '../../resources/styling/formStyle';
import { colors } from '../../resources/theme/colors';
import { footerLogo } from '../../resources/images';
import { requestAssistanceService, storageService } from '../../services';
import { AdminHeader, LeftMenu, DirectorLeftMenu } from '../../components';
import { navigationActions } from '../../store/headerStore';
import { WebStorageNames } from '../../utils/Constants';
import { AssistanceWrapper, ErrorWrapper } from '../../resources/styling/appStyle';
import { RequestAssistanceWrapper, RequestAssistanceInnerMain, RequestAssistanceInner, AssitanceForm, FormWrapper } from './RequestAssistanceStyle';
import FloatingLabelTextArea from '../../components/common/FloatingLabelTextArea';

const { mandy } = colors;
const { requestAssistanceSuccess } = Messages;

class RequestAssistance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestAssistanceDialogOpen: false,
      menuType: storageService.getItem(WebStorageNames.MenuType)
    };
  }

  componentDidMount() {
    this.props.navigationActions.activeItemAction({
      showReportSubMenu: false,
    });
  }

  handleCancel = () => {
    this.setState({ requestAssistanceDialogOpen: false });
  };

  render() {
    const { requestAssistanceDialogOpen } = this.state;
    return (
      <Formik
        initialValues={{
          Name: '',
          SchoolName: '',
          PhoneNumber: '',
          Email: '',
          Message: '',
        }}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            this.setState({ requestAssistanceDialogOpen: true });
            actions.setSubmitting(false);
            requestAssistanceService
              .requestAssistance(values)
              .then((response) => {
                console.log(response.success);
              })
              .catch((error) => console.log(error));
            actions.resetForm();
          }, 1000);
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={Yup.object().shape({
          Name: Yup.string()
            .trim()
            .max(50)
            .matches(/^[A-Za-z_ ]+$/, {
              message: 'Full name can only contain alphabet.',
              excludeEmptyString: false,
            })
            .required('Full name is required.'),
          SchoolName: Yup.string()
            .trim()
            .max(50)
            .required('School name is required.'),
          PhoneNumber: Yup.string()
            .trim()
            .max(50)
            .matches(/^[0-9]{1,50}$/, {
              message: 'Phone number can only contain numbers',
              excludeEmptyString: false,
            })
            .required('Phone number is required.'),
          Email: Yup.string()
            .trim()
            .max(60)
            .email('Provide email address like example@g.com')
            .required('Email is required.'),
          Message: Yup.string()
            .trim()
            .max(500)
            .required('Description is required.'),
        })}
        render={({ values, handleChange, touched, errors, handleSubmit }) => (
          <div>
            <AdminHeader />

            <RequestAssistanceWrapper>
              
                  <div className='left-container'>
                    {(() => {
                      if (this.state.menuType === 'Group') {
                        return (
                          <DirectorLeftMenu />
                        )
                      } else if (this.state.menuType === 'Survey') {
                        return (
                          <LeftMenu />
                        )
                      }
                    })()}
                  </div>
                 
                  <div className='right-container' style={{marginTop: '0px'}}>
                     <AssistanceWrapper>
                     <div className='btn-request-assistance'>
                      <button
                      // onClick={() =>
                      //   this.redirectToRoute(routesObj.RequestAssistance.path)
                      // }
                      >
                        <img src={iconRequest} alt='' />
                      </button>
                    </div>
                  </AssistanceWrapper>
                    <RequestAssistanceInnerMain>
                      <RequestAssistanceInner>
                        <Grid container>
                          <Grid item xl={7} lg={10} md={12} sm={12} xs={12} mx="auto">
                            <AssitanceForm>
                              <h2>Request Assistance</h2>
                              <FormWrapper onSubmit={handleSubmit}>
                                <WrapperMain>
                                  <FloatingInputLabel
                                    label='Name'
                                    placeholder='Name'
                                    name='Name'
                                    value={values.Name}
                                    onChange={handleChange}
                                  />
                                  <ErrorWrapper>
                                    {touched.Name && errors.Name && errors.Name}
                                  </ErrorWrapper>
                                </WrapperMain>

                                <WrapperMain>
                                  <FloatingInputLabel
                                    label='School name'
                                    placeholder='School name'
                                    name='SchoolName'
                                    value={values.SchoolName}
                                    onChange={handleChange}
                                  />
                                  <ErrorWrapper>
                                    {touched.SchoolName &&
                                      errors.SchoolName &&
                                      errors.SchoolName}
                                  </ErrorWrapper>
                                </WrapperMain>

                                <WrapperMain>
                                  <FloatingInputLabel
                                    label='Phone number'
                                    placeholder='Phone number'
                                    name='PhoneNumber'
                                    value={values.PhoneNumber}
                                    onChange={handleChange}
                                  />
                                  <ErrorWrapper>
                                    {touched.PhoneNumber &&
                                      errors.PhoneNumber &&
                                      errors.PhoneNumber}
                                  </ErrorWrapper>
                                </WrapperMain>

                                <WrapperMain>
                                  <FloatingInputLabel
                                    label='Email address'
                                    placeholder='Email address'
                                    name='Email'
                                    value={values.Email}
                                    onChange={handleChange}
                                  />
                                  <ErrorWrapper>
                                    {touched.Email && errors.Email && errors.Email}
                                  </ErrorWrapper>
                                </WrapperMain>

                                <WrapperMain>
                                  <FloatingLabelTextArea
                                    label='Please add any details that will help us resolve your request...'
                                    placeholder='Please add any details that will help us resolve your request...'
                                    name='Message'
                                    value={values.Message}
                                    onChange={handleChange}
                                  />
                                  <ErrorWrapper>
                                    {touched.Message &&
                                      errors.Message &&
                                      errors.Message}
                                  </ErrorWrapper>
                                </WrapperMain>

                                <WrapperMain>
                                  <AppButton
                                    color={mandy}
                                    type='submit'
                                    onClick={handleSubmit}
                                  >
                                    Submit request
                                  </AppButton>
                                </WrapperMain>
                              </FormWrapper>
                            </AssitanceForm>
                          </Grid>
                        </Grid>
                      </RequestAssistanceInner>
                    </RequestAssistanceInnerMain>
                  </div>
               
              <MainFooter>
                <span>
                  <img src={footerLogo} alt='logo' />
                </span>
                <span> Survey provided by</span>
              </MainFooter>
            </RequestAssistanceWrapper>
            <AppModel
              open={requestAssistanceDialogOpen}
              onClose={this.handleCancel}
              titleText='Request status'
              bodyText={requestAssistanceSuccess}              
              footer={
                <AppButton
                  color={mandy}
                  type='button'
                  onClick={this.handleCancel}
                >
                  OK
                </AppButton>
              }
            />
          </div>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(RequestAssistance));
