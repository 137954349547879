import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { VoiceDashboardWrapper } from '../../resources/styling/appStyle';

const {
 pictonBlue, whiteColor, softGrey, bordergrey
} = colors;

/*** Admin Dashboard ***/
export const ExtendedVoiceDashboardWrapper = styled(VoiceDashboardWrapper)`
  .content-body-inner-wrapper {
    width: 100%;
    margin: auto;
    padding: 20px;
    border-radius: 6px;
    background: ${whiteColor};
    border: ${bordergrey} 1px solid;   
    margin-top: 20px; 
  }
`;

export const AdminDashboardBody = styled.div`
  
  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    font-size: 13px;
    border-bottom: 1px solid ${softGrey};
    padding-bottom: 15px;
    li:first-child {
      flex: 1;
    }
    li {
      a {
        color: ${pictonBlue};
        text-decoration: underline;
        margin-left: 10px;
        display: inline-block;
      }
    }
  }
`;


const AdminDashboardStyle = {
    ExtendedVoiceDashboardWrapper,
    AdminDashboardBody   
}
export default AdminDashboardStyle;