import React from 'react';
import {
  DashboardWelcomePopupWrapper,
  AppButton,
  ExtendedFooterButtonsWrapper  
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { AppModel } from '../../components';
import { dashboardwelcome } from '../../resources/images/index';

const { purple } = colors;

const DashboardWelcomePopup = ({ isOpen, onClose, onOk, children }) => {
  return (
    <DashboardWelcomePopupWrapper className='model-main-wrapper'>
      <AppModel        
        open={isOpen}
        onClose={onClose}
        titleText={
          <div className='dashboard-welcome-image'>
            <img src={dashboardwelcome} alt='' />
          </div>
        }
        bodyText={
          <div className='dashboard-welcome-wrapper'>
            Thank you for choosing Welbee and for letting us support you in
            measuring and improving staff wellbeing and your workplace culture.
            You can visit the User Guide by clicking the link in your dashboard menu.
          </div>
        }        
        footer={
          <ExtendedFooterButtonsWrapper>
            <AppButton color={purple} type='button' onClick={onOk}>
            Go To Dashboard
            </AppButton> 
          </ExtendedFooterButtonsWrapper>
        }
      />
      {children}
    </DashboardWelcomePopupWrapper>
  );
};
export default DashboardWelcomePopup;
