import React from 'react';
import { Route } from 'react-router-dom';
import { PageWrapper } from '../resources/styling/appStyle';
import { BaseCSS } from 'styled-bootstrap-grid';
import { colors } from '../resources/theme/colors';
import { storageService } from '../services';
import { WebStorageNames } from '../utils';

const { pictonBlue } = colors;
const customCSS = `
@-ms-viewport {
  width: device-width;
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
.main-outer-wrapper {
  float: left;
  width: 100%;
}

.welcome-content{
  display: flex;
  align-items: center;
}
.school-name {
  font-weight: 600;
  color: ${pictonBlue} !important;
}
.mobile-wrapper {
  padding: 0;
  padding-bottom: 3.5rem;

}
`;

function PublicRoute(props) {
  debugger;
  const skipStorage = ["/login", "/login-redirect"];
  if (props.location && props.location.search && !skipStorage.includes(props.location.pathname)) {
    var url = props.location.pathname + props.location.search;
    storageService.setItem(WebStorageNames.SuperadminRedirectUrl, url);
  }

  return (
    <PageWrapper>
        <BaseCSS css={customCSS} />
        <Route {...props} />
    </PageWrapper>);
}

export default PublicRoute;