import React from 'react';
import {
  WrapperStyle,
  DescriptionStyle,
  Title,
  ResultsStyle,
  SupportScore,
} from '../../resources/styling/surveyReportStyle';
import ReportScores from './ReportScores';
import RoleWiseReportScores from './RoleWiseReportScores';
import { ViewIndividualAnswers } from './Constants';
import {
  limitAverageValue,
  scoreNotAvailableValue,
} from '../../utils/roundScore';
import { useTranslation } from 'react-i18next';
const SupportManagerReport = ({
  reportManagerSupport,
  onManagersButtonClick,
}) => {
  const { t } = useTranslation();
  return (
    <WrapperStyle>
      <h1 className='report-title'>3. Support From {t('managersCW')}</h1>
      <p id='supportTitle'>
        This includes the encouragement, sponsorship and resources provided by
        the school, line management and colleagues.
      </p>

      <div id='supportResults'>
        <ResultsStyle className='comp-strength'>
          <button
            onClick={onManagersButtonClick}
            className='support-individual-answer'
          >
            {ViewIndividualAnswers}
          </button>
          <SupportScore>
            <p
              id='demandsTitle'
              style={{
                'font-size': '20px',
                'font-weight': '700',
                'text-align': 'center',
              }}
            >
              Overall Scores and Deciles
            </p>
            <ReportScores
              Score={reportManagerSupport.Score}
              id='supportScore'
              className='report-score'
            />
            {scoreNotAvailableValue(
              limitAverageValue(
                reportManagerSupport.Score.SupportStaffClassCatAvg
              )
            ) === '-1.00' &&
            scoreNotAvailableValue(
              limitAverageValue(
                reportManagerSupport.Score.SupportStaffNonClassCatAvg
              )
            ) === '-1.00' &&
            scoreNotAvailableValue(
              limitAverageValue(
                reportManagerSupport.Score.TeachingStaffClassCatAvg
              )
            ) === '-1.00' &&
            scoreNotAvailableValue(
              limitAverageValue(
                reportManagerSupport.Score.TeachingStaffNonClassCatAvg
              )
            ) === '-1.00' &&
            scoreNotAvailableValue(
              limitAverageValue(reportManagerSupport.Score.MiddleLeaderCatAvg)
            ) === '-1.00' &&
            scoreNotAvailableValue(
              limitAverageValue(reportManagerSupport.Score.SeniorLeaderCatAvg)
            ) === '-1.00' ? (
              ''
            ) : (
              <>
                <br />
                <p
                  id='demandsTitle'
                  style={{
                    'font-size': '20px',
                    'font-weight': '700',
                    'text-align': 'center',
                  }}
                >
                  Scores by Job Role
                </p>
                <RoleWiseReportScores
                  Score={reportManagerSupport.Score}
                  id='RoleWiseSupportManagerScore'
                  className='report-score'
                />
              </>
            )}
          </SupportScore>
        </ResultsStyle>
      </div>

      <DescriptionStyle id='supportDescription'>
        <h1 className='report-list-wrapper support-list-wrapper'>
          Description
        </h1>
        <Title className='description-title'>
          The Management Standard is that:
        </Title>
        <ul className='report-list-wrapper'>
          <li>
            Staff indicate that they receive adequate information and support
            from their colleagues and {t('managers')}.
          </li>
          <li>
            Systems are in place locally to respond to any individual concerns.
          </li>
        </ul>
        <Title className='description-title'>
          This means that where possible:
        </Title>
        <ul className='report-list-wrapper'>
          <li>
            The school has policies and procedures to adequately support staff.
          </li>
          <li>
            Systems are in place to enable and encourage {t('managers')} to
            support their staff.
          </li>
          <li>
            Systems are in place to enable and encourage staff to support their
            colleagues.
          </li>
          <li>
            Staff know what support is available and how and when to access it.
          </li>
          <li>
            Staff know how to access the required resources to do their job.
          </li>
          <li>Staff receive regular and constructive feedback.</li>
        </ul>
      </DescriptionStyle>

      <div id='supportPeer' />
    </WrapperStyle>
  );
};

export default SupportManagerReport;
