import React from 'react';
import {
  WrapperStyle,
  DescriptionStyle,
  Title,
  SeparatorStyle,
} from '../../resources/styling/surveyReportStyle';
import ReportScores from './ReportScores';
import RoleWiseReportScores from './RoleWiseReportScores';
import SuggestedPriorityActionsView from './SuggestedPriorityActionsView';
import ReportResultsSection from './ReportResultsSection';
import {
  limitAverageValue,
  scoreNotAvailableValue,
} from '../../utils/roundScore';
import {
  ViewIndividualAnswers,
  AreasForReviewAndPossibleFocus,
} from './Constants';
import { useTranslation } from 'react-i18next';

const RelationshipReport = ({ report, onButtonClick, isPaid }) => {
  const { t } = useTranslation();
  const {
    ResultsText,
    HseStrengths,
    HseWeaknesses,
    SchoolStrengths,
    SchoolWeaknesses,
    SuggestedPriorityActions,
    Score,
  } = report;
  return (
    <WrapperStyle>
      <h1 className='report-title'>{isPaid ? 4 : 5}. Relationships</h1>
      <p id='relationshipTitle'>
        This includes promoting positive working to avoid conflict and dealing
        with unacceptable {t('behviour')}.
      </p>
      <button data-test-id='relationshipReport' onClick={onButtonClick}>
        {ViewIndividualAnswers}
      </button>
      <p id='demandsTitle' style={{ 'text-align': 'center' }}>
        Overall Scores and Deciles
      </p>
      <ReportScores
        Score={Score}
        id='relationshipScore'
        className='report-score relation-score'
        isPaid={isPaid}
      />
      {scoreNotAvailableValue(
        limitAverageValue(Score.SupportStaffClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(
        limitAverageValue(Score.SupportStaffNonClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(
        limitAverageValue(Score.TeachingStaffClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(
        limitAverageValue(Score.TeachingStaffNonClassCatAvg)
      ) === '-1.00' &&
      scoreNotAvailableValue(limitAverageValue(Score.MiddleLeaderCatAvg)) ===
        '-1.00' &&
      scoreNotAvailableValue(limitAverageValue(Score.SeniorLeaderCatAvg)) ===
        '-1.00' ? (
        ''
      ) : (
        <>
          <br />
          <p id='demandsTitle' style={{ 'text-align': 'center' }}>
            Scores by Job Role
          </p>
          <RoleWiseReportScores
            Score={Score}
            id='RoleWiserelationshipScore'
            className='report-score'
          />
        </>
      )}
      <DescriptionStyle id='relationshipDescription'>
        <h1>Description</h1>
        <Title className='description-title'>
          The Management Standard is that:
        </Title>
        <ul className='report-list-wrapper'>
          <li>
            Employees indicate that they are not subjected to unacceptable{' '}
            {t('behviours')}, e.g bullying at work.
          </li>
          <li>
            Systems are in place locally to respond to any individual concerns.
          </li>
        </ul>
        <Title className='description-title'>
          This means that where possible:
        </Title>
        <ul className='report-list-wrapper'>
          <li>
            The {t('organisation')} promotes positive {t('behviours')} at work
            to avoid conflict and ensure fairness.
          </li>
          <li>Staff share information relevant to their work.</li>
          <li>
            The school has agreed policies and procedures to prevent or resolve
            unacceptable {t('behviour')}.
          </li>
          <li>
            Systems are in place to enable and encourage {t('linemanagers')} to
            deal with unacceptable {t('behviour')}.
          </li>
          <li>
            Systems are in place to enable and encourage employees to report
            unacceptable {t('behviour')}.
          </li>
        </ul>
      </DescriptionStyle>

      {isPaid ? (
        <div>
          <SeparatorStyle />
          <ReportResultsSection
            id='relationshipResults'
            ResultsText={ResultsText}
            SchoolStrengths={SchoolStrengths}
            SchoolWeaknesses={SchoolWeaknesses}
            HseStrengths={HseStrengths}
            HseWeaknesses={HseWeaknesses}
            AreasForReviewAndPossibleFocus={AreasForReviewAndPossibleFocus}
          />
        </div>
      ) : null}

      {isPaid ? (
        <div>
          <SeparatorStyle />
          <SuggestedPriorityActionsView
            id='relationshipSuggestions'
            heading='Suggested Priority Actions for Relationships'
            SuggestedPriorityActions={SuggestedPriorityActions}
          />
        </div>
      ) : null}
      <div id='role' />
    </WrapperStyle>
  );
};

export default RelationshipReport;
