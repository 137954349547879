import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import { PageWrapper } from '../../resources/styling/appStyle';
import { welbeeLoginLogo } from '../../resources/images/index';
import { MainWrapper } from './loginStyle';

class LoginWrapper extends Component {
  render() {
    return (
      <MainWrapper>
        <PageWrapper>
          <div className='mobile-warning-wrapper'>
            <span>&#9888;</span>
            Please use desktop to view Welbee
          </div>
          <Grid container className='login-main-warpper'>
            <Grid item
              lg={6}
              sm={12}
              md={6}
              mdx={12}
              xs={12}
              className='login-mobile-wrapper login-left-panel'
            >
              <img src={welbeeLoginLogo} alt='' />
            </Grid>
            <Grid item
              lg={6}
              sm={12}
              xs={12}
              md={6}
              mdx={12}
              className='logo-container welcome-right-panel registeration-right-panel'
            >
              {this.props.children}
            </Grid>
          </Grid>
        </PageWrapper>
      </MainWrapper>
    );
  }
}

export default LoginWrapper;
