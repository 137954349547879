import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { colors } from '../../resources/theme';

const {
  mineShaft,
  pictonBlue,
  whiteColor,
  teal,
  yellowColor,
  blizzardBlue,
  darkBlue
} = colors;

export const WrapperStyle = styled.div`
  .skill-heading {
    padding: 0 110px;
    font-weight: 900;
    ${media.xs`
      padding: 0 30px;
    `} 
  }
  .report-score {
    box-sizing: border-box;
    li {
      padding-left: 0;
      text-indent: 0;
    }
    border-top: none;
    border-bottom: none;
  }
  li {
    padding-left: 20px;
    text-indent: -1.2em;
  }
  .secondaryList {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      li {
        margin-top: 15px !important;
        /* text-indent: 0; */
      }
    }
  }
  background: ${whiteColor};
  color: ${mineShaft};
  margin: 60px 0;
  box-shadow: 3px 0px 9px -5px ${darkBlue};
  padding-bottom: 50px;

  .support-peer {
    display: inline-block;
    font-size: 18px;    
  }

  .sub-heading-message {
    font-weight: 400;
  }

  .sub-heading-message-support {
    font-weight: 400;
  }

  .comp-strength .sub-heading,
  .sub-heading {
    font-weight: 900;
  }
  .undeline-title {
    text-decoration: underline;
    font-weight: 900;
    font-size: 20px;
  }
  .top-margin{
    margin-top:50px;
  }
  h3 {
    padding: 0 110px;
    font-weight: 900;
    font-size: 16px;
    ${media.xs`
      padding: 0 30px;
    `}
  }

  .report-title {
    color: ${mineShaft};
    font-size: 36px;
    padding: 60px 60px 27px 60px;
    text-align: left;
    margin: 0;
    width: 100%;
    font-weight: 900;
    box-sizing: border-box;
  }
  p {
    color: ${mineShaft};
    font-size: 20px;
    padding: 0 110px;
    text-align: left;
    margin: 0;
    width: 100%;
    font-weight: 700;
    line-height: 26px;
    box-sizing: border-box;
    ${media.xs`
      padding: 0 30px;
    `}
  }
  button {
    color: ${pictonBlue};
    font: 900 16px Avenir;
    word-spacing: 2px;
    margin: 18px 0 49px 110px !important;
    text-align: left;
    margin: 0;
    line-height: 34px;
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
  }
`;

export const CalculationsStyle = styled.div`
  color: ${teal};
  border-top: 2px solid ${teal};
  border-bottom: 2px solid ${teal};
  padding: 24px 60px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;

  ul {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
    justify-content: center;
    display: flex;
    li {
      color: ${teal};
      font-size: 25px;
      width: 20%;
      font-weight: 900;
      float: left;
      text-align: center;
      margin: 0 5px;
      span {
        display: block;
        font-size: 12px;
        font-weight: 900;
        margin: auto;
        line-height: 19px;
        margin-top: 10px;
      }
    }
    li::before {
      content: '' !important;
      color: ${yellowColor};
      font-size: 15px;
      margin-right: 10px;
    }
  }
  ${media.xs`
    padding: 24px 0px;
  `}
`;

export const DescriptionStyle = styled.div`
  .next-steps-bottomwrapper {
    p {
      margin-bottom: 10px;
    }
  }
  .control-text {
    font-weight: 400;
    font-size: 16px;
  }
  width: 100%;
  h1 {
    color: ${mineShaft};
    text-align: center;
    font-size: 30px;
    margin: 52px 0 40px 0;
  }
  ul {
    padding: 0 110px;
    margin: 10px 0 10px 0;
    list-style: none;
    line-height: 1.6;
    word-wrap: break-word;
    li {
      color: ${mineShaft};
      font-size: 16px;
      padding: 0;
      text-align: left;
      margin: 0;
      width: 100%;
      font-weight: 400;
      line-height: 26px;
      margin-top: 11px;
      padding-left: 20px;
      text-indent: -1.2em;
    }
    li::before {
      content: '•';
      color: ${yellowColor};
      font-size: 15px;
      margin-right: 10px;
    }
    ${media.xs`
      padding: 0 30px;
    `}
  }

  ol {
    padding: 0 130px;
    margin: 0 0 30px 0;
    /* list-style: none; */
    line-height: 1.6;
    li {
      color: ${mineShaft};
      font-size: 16px;
      padding: 0;
      text-align: left;
      margin: 0;
      width: 100%;
      font-weight: 400;
      line-height: 26px;
      margin-top: 11px;
      text-indent: 0;
      .secondaryList {
        li {
          text-indent: -0.2em !important;
        }
      }
      p {
        padding: 0;
        text-align: left;
      }
    }

    li::before {
      content: '' !important;
      margin-right: 0 !important;
    }
    ${media.xs`
      padding: 0 30px;
    `}
  }
`;

export const SeparatorStyle = styled.div`
  width: 90%;
  margin: 53px auto 60px auto;
  padding: 5px 0;
  border-top: 2px solid ${blizzardBlue};
`;

export const ResultsStyle = styled(DescriptionStyle)`
  h1 {
    padding-top: 0;
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
  }
  span {
    a {
      color: ${pictonBlue};
      font-weight: 400;
      text-decoration: none;
      margin: 0 3px;
    }
  }
`;

export const SuggestionStyle = styled(DescriptionStyle)`
  h1 {
    padding-top: 0;
  }
  .guide-lines {
    padding: 0 30px;
  }

  li::before {
    content: '•';
  }
  ol {
    li {
      p {
        font-weight: 400;
        font-size: 16px;
      }
      ul {
        li::before {
          content: '•' !important;
          margin-right: 10px !important;
        }
      }
      .secondaryList li {
        text-indent: -1.2em !important;
        margin-left: 20px;
      }
    }
  }
  a {
    color: ${pictonBlue};
    font: 400 16px Avenir;
    word-spacing: 2px;
    margin: 0 3px 0 3px !important;
    text-align: left;
    margin: 0;
    /* line-height: 34px; */
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    text-decoration: none;
  }
  .further-suggestion {
    margin: 18px 0 49px 110px !important;
    font: 900 16px Avenir;
  }
`;

export const SupportScore = styled.div`
  margin: 49px 0 52px 0;
  ul {
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
    li {
      color: ${teal};
      font-size: 25px;
      width: 20%;
      font-weight: 900;
      float: left;
      text-align: center;
      text-indent: inherit;
    }
  }
`;

export const Title = styled.div`
  color: ${mineShaft};
  font-size: 16px;
  padding: 0 110px;
  text-align: left;
  margin: 0;
  width: 100%;
  font-weight: 900;
  line-height: 34px;
  ${media.xs`
      padding: 0 30px;
    `}
`;
