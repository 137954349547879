import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { LeftMenu, SurveyDropDown, MainHeader } from '../../components';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { navigationActions } from '../../store/headerStore';
import { surveyCommentsService } from '../../services';
import { Sender } from '../../utils/Constants';
import { storageService, surveyService } from '../../services';
import { WebStorageNames, Roles, QueryStringTokens } from '../../utils';
import { routesObj } from '../../routes';
import { AssistanceWrapperHeading } from '../../resources/styling/appStyle';
import CommentsReplyCommon from './CommentsReplyCommon';
import { getTransformedSurveyRecordList, getQueryStringVal } from '../../utils/CommonFunctions';
import { commentsActions } from '../../store/commentsStore';
import Grid from '@mui/material/Grid';
import { ExtendedSubContainer, CommentsMainWrapper, ExtendedAssistanceWrapper, ExtendedSearchFilterWrapper } from './SurveyCommentStyle';

class HeadTeacherCommentsReply extends Component {
  constructor(props) {
    super(props);

    this.state = {
      latestSurveyOption: { value: '', label: 'Latest Survey' },
      latestSurveyOptions: [{ value: '', label: 'Survey list' }],
      comment: [],
      accessLevel: 0,
    };
  }

  componentDidMount() {
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    this.setState({ accessLevel: accessLevel });
    if (this.props.selectedComment === null) {
      let token = storageService.getItem(WebStorageNames.SurveyToken);
      this.props.history.push(
        routesObj.SurveyComments.path + '?token=' + token
      );
      return;
    }

    if (!isEmpty(this.props.selectedComment)) {
      this.setSurveyDropDownValue();
      surveyCommentsService
        .getCommentWithReplies(this.props.selectedComment.Id)
        .then((response) => {
          if (response.success) {
            this.setState({ comment: response.data });
          }
        })
        .catch((error) => console.log(error));
    }
  }

  setSurveyDropDownValue() {
    let token = storageService.getItem(WebStorageNames.SurveyToken);
    surveyService
      .getSurveysNameAndToken(token)
      .then((response) => {
        const surveyRecordList = getTransformedSurveyRecordList(response.data);
        this.setState({ latestSurveyOptions: surveyRecordList });
      })
      .catch((error) => console.log(error));
  }

  handleSurveyValueChange = (latestSurveyOption) => {
    console.log(latestSurveyOption);
    this.setState({ latestSurveyOption });
    this.props.history.push(
      routesObj.SurveyComments.path + '?token=' + latestSurveyOption.value
    );
  };

  render() {
    const {
      latestSurveyOptions,
      latestSurveyOption,
      isRole,
      comment,
    } = this.state;
    const { selectedComment, selectedQuestion } = this.props;
    return (
      <CommentsMainWrapper>
        <div className='containerClass'>          
          <div className='left-container'>
              <LeftMenu />
          </div>

            <div className='right-container'>
              <MainHeader />
              <ExtendedAssistanceWrapper>
                
                <AssistanceWrapperHeading>
                  <h2 style={{margin: '0px'}}>
                    {selectedQuestion ? 'Q' + selectedQuestion.Order : comment.CommentQuestionType === 1 ? 'Q39' : 'Q40'} comment
                  </h2>
                  <span> comment replies</span>
                </AssistanceWrapperHeading>

                {/* <div class='btn-request-assistance'>
                <RequestAssistanceButton />
                </div> */}
                <div class='dd-survey'>
                <SurveyDropDown
                  isRole={isRole}
                  latestSurveyOptions={latestSurveyOptions}
                  latestSurveyOption={latestSurveyOption}
                  handleSurveyValueChange={this.handleSurveyValueChange}
                />
                </div>
              </ExtendedAssistanceWrapper>
              <ExtendedSubContainer>
                <ExtendedSearchFilterWrapper>
                  <div className='subcontainer-inner'>
                    <div
                      className='comments-back-button'
                      onClick={() => {
                        let surveyToken = storageService.getItem(
                          WebStorageNames.SurveyToken
                        );
                        const role = storageService.getItem(
                          WebStorageNames.Role
                        );
                        let schoolToken = getQueryStringVal(
                          QueryStringTokens.schoolToken
                        );
                        if (
                          role === Roles.GroupDirector ||
                          schoolToken !== null
                        ) {
                          this.props.history.push(
                            routesObj.SurveyComments.path +
                              '?token=' +
                              surveyToken +
                              '&schoolToken=' +
                              schoolToken
                          );
                        } 
                        else
                          this.props.history.push(
                            routesObj.SurveyComments.path +
                              '?token=' +
                              surveyToken
                          );
                      }}
                    >
                      Back to comments
                    </div>
                  </div>
                </ExtendedSearchFilterWrapper>
                <CommentsReplyCommon
                  comment={comment}
                  selectedComment={selectedComment}
                  sender={Sender.You}
                  selectedQuestion={selectedQuestion}
                />
              </ExtendedSubContainer>
            </div>
          
        </div>
      </CommentsMainWrapper>
    );
  }
}

const mapStateToProps = ({ surveyComments: { selectedComment, selectedQuestion } }) => ({
  selectedComment,
  selectedQuestion
});

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  commentActions: bindActionCreators(commentsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeadTeacherCommentsReply));
