import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { AnalyticsChartWrapper, AnalyticsChartPopupWrapper, ChartWrapperError } from '../../resources/styling/appStyle';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { ShowChartType } from '../../utils/Constants';
import GraphPopup from '../dashboard/GraphPopup';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MATAnalyticsLineChart from '../../components/Charts/MATAnalyticsLineChart';
import MATAnlyticeBarChart from '../../components/Charts/MATAnlyticeBarChart';
export default function MATCharateristicsTypeAnalytics(props) {
    console.log('label',props.mainLabel);
    const [isPopupShowing, setisPopupShowing] = useState(false);
    const [characteristicsTypeValue, setcharacteristicsTypeValue] = useState(0);
    const showGraphPopup = (id) => {
        setcharacteristicsTypeValue(id);
        setisPopupShowing(true);
    };
    const closePopup = () => {
        setcharacteristicsTypeValue(0);
        setisPopupShowing(false);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <div className='mb-2'>
                <h5 className='d-inline-block'>{props.characteristicsTypeLabel}: {props.categoryLabel}</h5>
            </div>
            {props.matCharacteristicsTypeValues.length <= 0 ? (
                <ChartWrapperError>
                    <span className='nodata'>There were insufficient filtered responses to display this information.</span>
                </ChartWrapperError>
            ) : (
                <Grid container columnSpacing={{ xs: 2, md: 2 }} rowSpacing={2} >
                    {(props.SearchTypeValue === 0 || props.isSchoolAddFilters === true) ? (
                        props.matCharacteristicsTypeValues.filter(x => x.value !== 0).map((item) =>
                            props.datasets.filter(x => x.characteristicsType === item.value).length !== 0 && (
                                <> <Grid item md={props.isSchoolAddFilters? 6 : props.pageColumnSize} xs={12} key={item.value}>
                                    <AnalyticsChartWrapper>
                                        <div className='large-btn'>
                                            <Tooltip title="Full Screen View" arrow>
                                                <IconButton
                                                    key={item.value} onClick={() => showGraphPopup(item.value)}
                                                > <FullscreenIcon class="large-graph-btn d-inline-block" fontSize='inherit' /></IconButton>
                                            </Tooltip>
                                        </div>
                                        {props.chartType === ShowChartType.LineChart &&
                                            <MATAnalyticsLineChart datasets={props.datasets.filter(x => x.characteristicsType === item.value).map(data => (data))} datalables={props.datalables} Chartlabel={item.label}
                                                colorValue={props.colorsValue}
                                                height={'200'}
                                                id={item.value} />
                                        }
                                        {props.chartType === ShowChartType.BarChart &&                                     
                                            <MATAnlyticeBarChart datasets={props.datasets.filter(x => x.characteristicsType === item.value).map(data => (data))} datalables={props.datalables} Chartlabel={props.datasets.filter(x => x.characteristicsType === item.value).map(data => (data.title))}
                                                colorValue={props.colorsValue}
                                                height={'200'}
                                                categoryPercentage={props.categoryPercentage}
                                                barPercentage={props.barPercentage}
                                                isSchoolAddFilters={props.isSchoolAddFilters}
                                                isSchoolTitle={true}
                                                id={item.value}
                                                mainLabel={props.mainLabel}
                                            />
                                        }
                                    </AnalyticsChartWrapper>
                                </Grid>
                                </>
                            )

                        )
                    ) : (       
                                      
                        <Grid item md={props.pageColumnSize} xs={12}>
                            <AnalyticsChartWrapper>
                                <div className='large-btn'>
                                    <Tooltip title="Full Screen View" arrow>
                                        <IconButton
                                            onClick={() => showGraphPopup(props.SearchTypeValue)}
                                        > <FullscreenIcon class="large-graph-btn d-inline-block" fontSize='inherit' /></IconButton>
                                    </Tooltip>
                                </div>
                                {props.chartType === ShowChartType.LineChart &&
                                    <MATAnalyticsLineChart datasets={props.datasets.filter(x => x.characteristicsType === props.SearchTypeValue).map(data => (data))}
                                        Chartlabel={props.matCharacteristicsTypeValues.filter(x => x.value === props.SearchTypeValue).map((x => x.label))}
                                        colorValue={props.colorsValue}
                                        datalables={props.datalables}
                                        height={'110'} 
                                        id={props.SearchTypeValue} />
                                        
                                }
                                {props.chartType === ShowChartType.BarChart &&
                                    <MATAnlyticeBarChart datasets={props.datasets.filter(x => x.characteristicsType === props.SearchTypeValue).map(data => (data))}
                                        Chartlabel= {`${props.Chartlabel +' - '+props.matCharacteristicsTypeValues.filter(x => x.value === props.SearchTypeValue).map((x => x.label))}`}
                                        colorValue={props.colorsValue}
                                        datalables={props.datalables}
                                        height={'110'}
                                        categoryPercentage={props.categoryPercentage}
                                        barPercentage={props.barPercentage} 
                                        isSchoolTitle={false}
                                        id={props.SearchTypeValue}
                                        mainLabel={props.mainLabel}
                                    />
                                }
                            </AnalyticsChartWrapper>
                        </Grid>)}
                    {props.children}
                </Grid>
            )}
            {isPopupShowing ? (
                <GraphPopup>
                    <div className='graph-container' id='large-graph'>
                        <div className='redCrossButtom'>
                            <CloseIcon className='closeIcon' onClick={closePopup} />
                        </div>
                        <Grid item md={12} xs={12}>
                            <AnalyticsChartPopupWrapper>
                                {props.chartType === ShowChartType.LineChart &&
                                    <MATAnalyticsLineChart datasets={props.datasets.filter(x => x.characteristicsType === characteristicsTypeValue).map(data => (data))}
                                        Chartlabel={props.matCharacteristicsTypeValues.filter(x => x.value === characteristicsTypeValue).map((x => x.label))}
                                        colorValue={props.colorsValue}
                                        datalables={props.datalables}
                                        height={'110'}
                                        id={characteristicsTypeValue} />
                                }
                                {props.chartType === ShowChartType.BarChart &&
                                    <MATAnlyticeBarChart datasets={props.datasets.filter(x => x.characteristicsType === characteristicsTypeValue).map(data => (data))}
                                        Chartlabel=  {`${props.isFitlerbySchool===true?
                                            (props.datasets.filter(x => x.characteristicsType === characteristicsTypeValue).map(data => (data.title))) :
                                            (props.Chartlabel +' - '+props.matCharacteristicsTypeValues.filter(x => x.value === props.SearchTypeValue).map((x => x.label)))}`}
                                        colorValue={props.colorsValue}
                                        datalables={props.datalables}
                                        height={'110'}
                                        categoryPercentage={props.categoryPercentage}
                                        barPercentage={props.barPercentage}
                                        isSchoolTitle={false}
                                        id={characteristicsTypeValue}
                                        mainLabel={props.mainLabel}
                                    />
                                }
                            </AnalyticsChartPopupWrapper>
                        </Grid>
                    </div>
                </GraphPopup>
            ) : (
                ''
            )}
        </Box>
    );
}