import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { amplitudeLog } from '../../utils/CommonFunctions';
import { accountService, storageService } from '../../services';
import { routesObj } from '../../routes';
import { loaderAction } from '../../store/loaderStore';
import { firstLoginAction } from '../../store/userStore';
import { WebStorageNames, Roles, toggleOption, HeadTeacherAccess, WelbeeTypes} from '../../utils/Constants';
import { parseJwt } from '../../utils/CommonFunctions';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import { usePermission } from 'react-permission-role';
import { signinRedirect } from '../../services/api/userService';
import { useSelector } from 'react-redux';
import { welbeeLogo } from '../../resources/images';
import { Loader } from '../../components/common/loginStyle';
import { CircularProgress } from '@mui/material';
import { userService } from '../../services';

function Login(props) {
  const { setUser } = usePermission();

  const user = useSelector(state => state.auth.user);

  const getRegions = () => {
    accountService
      .checkCurrentRegion()
      .then((response) => {
        storageService.setItem(WebStorageNames.UsRegion, response.data);
        i18next.changeLanguage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!user) {
      signinRedirect();
    }

    if (user) {
      loadHomePage(user);
    }

    getRegions();
    amplitudeLog('PageView', 'Login', '');
  }, []);

  const loadHomePage = (user) => {
    accountService.login(user).then((response) => {
      const { success, message } = response;
      props.loaderActions.dataLoading(false);
      if (success) {
        storageService.setToken({token: response.data.access_token});
        storageService.setItem( WebStorageNames.IsDisplayMatHeatMap, response.data.IsDisplayMatHeatMap );
        storageService.setItem( WebStorageNames.SubscriptionId, response.data.SubscriptionId );

        const userInfo = parseJwt(response.data.access_token);
        
        storageService.setItem( WebStorageNames.UserInfo, response.data.userInfo );
        storageService.setItem( WebStorageNames.SelectedTab, response.data.SubscriptionId === WelbeeTypes.WelbeeVoiceFree 
          || response.data.SubscriptionId === WelbeeTypes.WelbeeVoicePro ? toggleOption.WelbeeVoice : toggleOption.WelbeeSurvey);

        if (userInfo.role === Roles.GroupDirector) {
          storageService.setItem( WebStorageNames.IsMat, true );
          storageService.setItem( WebStorageNames.MatGroupId, response.data.MatGroupId );
          storageService.setItem ( WebStorageNames.Role, Roles.GroupDirector );
          props.history.push(routesObj.GroupDashboard.path);
        }

        storageService.setItem( WebStorageNames.SchoolId, response.data.schoolId );
        storageService.setItem( WebStorageNames.SchoolName, response.data.schoolName );
        const token = storageService.getItem(WebStorageNames.Token);
        
        if (!token) {
          return <Redirect to={routesObj.Login.path} />;
        }

        // Settings permissions
        setUser({
          id: userInfo?.id,
          roles: [userInfo?.role],
          permissions: response?.data?.Permissions
        });

        if (userInfo.role === Roles.SuperAdmin) {
          storageService.setItem( WebStorageNames.Role, Roles.SuperAdmin );
          props.history.push(storageService.getItem(WebStorageNames.SuperadminRedirectUrl));
        }

        storageService.setItem( WebStorageNames.AccessLevel, response.data.AccessLevel );
        storageService.setItem( WebStorageNames.IsStaff, response.data.IsStaff );
        storageService.setItem( WebStorageNames.WelbeeVoiceAccountType, response.data.WelbeeVoiceAccountType );
        storageService.setItem( WebStorageNames.IsFirstLogin, response.data.IsFirstLogin );
        storageService.setItem( WebStorageNames.IsVoiceFirstLogin, response.data.IsVoiceFirstLogin );
        storageService.setItem( WebStorageNames.FreeTrialEndDate, response.data.FreeTrialEndDate );
        storageService.setItem( WebStorageNames.SelectedTab, response.data.SubscriptionId === WelbeeTypes.WelbeeVoiceFree || 
          response.data.SubscriptionId === WelbeeTypes.WelbeeVoicePro ? toggleOption.WelbeeVoice : toggleOption.WelbeeSurvey);
        storageService.setItem( WebStorageNames.IsUpgradeDisplay, response.data.IsUpgradeDisplay );
        storageService.setItem( WebStorageNames.IsUpgradBannderDisplayed, false );
        
        if (userInfo.role === Roles.HeadTeacher) {
          storageService.setItem( WebStorageNames.WelbeeType, response.data.WelbeeType );
          storageService.setItem( WebStorageNames.IsMat, response.data.MatGroupId !== null );
          storageService.setItem( WebStorageNames.MatGroupId, response.data.MatGroupId );
          storageService.setItem( WebStorageNames.Role, Roles.HeadTeacher );
          if (response.data.AccessLevel === HeadTeacherAccess.Admin) {
            if ( response.data.SubscriptionId === WelbeeTypes.WelbeeVoiceFree || response.data.SubscriptionId === WelbeeTypes.WelbeeVoicePro ) {
              props.history.push(routesObj.Account.path);
            } else {
              props.history.push(routesObj.AdminDashboard.path);
            }
          } else {
            if ( response.data.SubscriptionId === WelbeeTypes.WelbeeVoiceFree || response.data.SubscriptionId === WelbeeTypes.WelbeeVoicePro ) {
              props.history.push(routesObj.WelbeeVoice.path);
            } else { 
              props.history.push(routesObj.Dashboard.path + '?schoolToken=' + response.data.SchoolToken);
            }
          }
        }
      } else {
        //Show Error
      }
    })
    .catch((error) => console.log(error));
  }; 

  return (
    <Loader>
      <div className="loading">
        <div className="login-logo">
          <img src={welbeeLogo} alt="" />
        </div>
        <div>
          <svg width={0} height={0}>
            <defs>
              <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={50} />
        </div>        
      </div>
    </Loader>
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});
export default connect(null, mapDispatchToProps)(withRouter(Login));
