import { UserManager } from 'oidc-client';
import { authActions } from '../../actions';

const authApiUrl = 'https://api.identity.welbee.co.uk';

const config = {
  authority: authApiUrl,
  client_id: "WellbeingApp",
  redirect_uri: "https://wellbeing.welbee.co.uk/login-redirect",
  response_type: "code",
  scope: "openid profile email",
  post_logout_redirect_uri: "https://wellbeing.welbee.co.uk/login"
};

const userManager = new UserManager(config)

export async function loadUserFromStorage(store) {
  try {
    let user = await userManager.getUser();
    if (!user) { return store.dispatch(authActions.storeUserError()); }
    store.dispatch(authActions.storeUser(user));
  } catch (e) {
    console.error(`User not found: ${e}`);
    store.dispatch(authActions.storeUserError());
  }
}

export function signinRedirect() {
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function signoutRedirect() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}

const userService = {
  userManager,
  loadUserFromStorage,
  signinRedirect,
  signinRedirectCallback,
  signoutRedirect,
  signoutRedirectCallback
};

export default userService