import React from 'react';
import ComparativeStrength from '../../components/common/ComparativeStrength';
import ComparativeWeakness from '../../components/common/ComparativeWeakness';
import { ResultsStyle } from '../../resources/styling/surveyReportStyle';

const ReportResultsSection = ({
  id,
  ResultsText,
  SchoolStrengths,
  SchoolWeaknesses,
  HseStrengths,
  HseWeaknesses,
  AreasForReviewAndPossibleFocus
}) => {
    return (
    <ResultsStyle id={id}>
      <h1 className="report-list-wrapper">Results</h1>
      <ul
        className="report-list-wrapper"
        dangerouslySetInnerHTML={{ __html: ResultsText }}
      />
      <ComparativeStrength
        SchoolStrengths={SchoolStrengths}
        HseStrengths={HseStrengths}
      />
      <h3 className="undeline-title top-margin report-list-wrapper">
        {AreasForReviewAndPossibleFocus}
      </h3>
      <ComparativeWeakness
        SchoolWeaknesses={SchoolWeaknesses}
        HseWeaknesses={HseWeaknesses}
      />
    </ResultsStyle>
  );
};

export default ReportResultsSection;
