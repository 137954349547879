import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Header } from '../../components';
import {
  MobileLogoWrapper,  
  MainFooter,
  PageWrapper,
  ThankYouMainWrapper,
  ThankYouWrapper,
  ThankYouInnerWrapper
} from '../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import { footerLogo } from '../../resources/images';
import { surveyService } from '../../services';
import { surveyAction } from '../../store/surveyStore';
import { amplitudeLog } from '../../utils/CommonFunctions';
import { ExtendedDesktopWraper, WelcomeHeaderWrapper } from './SurveyStyle';

class SurveyComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsSurveyClosed: false,
      IsDataLoaded: false
    };
  }

  componentDidMount() {
    var surveyToken = window.location.search;
    surveyService.isSurveyClosed(surveyToken).then(response => {
      const { data } = response;
      this.props.action.setSurveyRecord(data.SchoolName);
      this.setState({
        IsSurveyClosed: data.IsSurveyClosed,
        IsDataLoaded: true
      });
    });
    amplitudeLog('PageView', 'SurveyComplete', '');
  }

  render() {
    const { IsSurveyClosed, IsDataLoaded } = this.state;

    return (
      <PageWrapper>
        <WelcomeHeaderWrapper>
          <Header />
        </WelcomeHeaderWrapper>
        {IsDataLoaded ? (
          <ThankYouMainWrapper>
            <Grid container spacing={2}>
              
                <Grid item
                  lg={9}
                  sm={12}
                  md={12}
                  xs={12}                  
                  className="mobile-wrapper"
                >
                  <ThankYouWrapper>
                    <ThankYouInnerWrapper>
                      <MobileLogoWrapper>
                        <img src={this.props.SchoolLogo} alt="School-logo"/>
                      </MobileLogoWrapper>
                     
                      {IsSurveyClosed ? (     
                        <p>
                          <h1 data-test-id="surveyCompleted">
                          This survey is now closed.                        
                          </h1> 
                          <h1 data-test-id="surveyCompleted">
                          Thank you.                        
                          </h1> 
                        </p>                                                               
                      ) : (                        
                        <p>
                          <h1 data-test-id="surveyCompleted">
                            Thank you!                        
                          </h1>
                          Your answers will help to make {this.props.SchoolName}{' '}
                          a better place to work.
                        </p>
                      )}
                    </ThankYouInnerWrapper>
                  </ThankYouWrapper>
                </Grid>

                <Grid item
                  lg={3}
                  sm={12}
                  xs={12}
                  md={12}
                  className="logo-container  welcome-right-panel"
                >
                  <ExtendedDesktopWraper>
                    <img src={this.props.SchoolLogo} alt="School-logo"/>
                  </ExtendedDesktopWraper>
                </Grid>
              
            </Grid>
            <MainFooter>
              <span>
                <img src={footerLogo} alt="logo"/>
              </span>
              <span> Survey provided by</span>
            </MainFooter>
          </ThankYouMainWrapper>
        ) : (
          ''
        )}
      </PageWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { surveyRecord } = state.survey;
  return surveyRecord;
};
const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(surveyAction, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SurveyComplete));
