import React from 'react';

const LearnObtaining = () => {
  return (
    <div>
      <p>
        Obtaining a high response rate is helpful in ensuring your results
        correctly reflect the situation across your school. As a crude rule of
        thumb, for a survey of this type, a response rate of over 50% could be
        considered adequate; over 60% desirable; over 70% good; and more than
        80% very good. With a response rate of less than 50%, the data should be
        considered as indicative only, and treated with caution.
      </p>
      <p>
        There are several things you can do to encourage a high response rate
        before you start your survey. Not least is that staff should feel it is
        important, that their views matter and that they will be acted on.
      </p>

      <p className='custom-bullet'>
        Publicise the survey within the school; include supportive comments from
        senior management and staff/union representatives, where appropriate,
        encouraging people to take part, before it is distributed.
      </p>
      <p className='custom-bullet'> Explain to all staff:</p>

      <p className='costom-sub-bullet'>The purpose of the survey.</p>
      <p className='costom-sub-bullet'>
        Why they should take part – what’s in it for them and that they should
        not rely on others as personal wellbeing is subjective.
      </p>
      <p className='costom-sub-bullet'>
        How and when they will be receiving a link to the survey.
      </p>
      <p className='costom-sub-bullet'>
        How and when they will get feedback on results.
      </p>
      <p className='costom-sub-bullet'>
        How actions will be taken to address findings.
      </p>

      <p className='custom-bullet'>
        Ensure anonymity of responses. This will encourage staff to give frank
        and honest responses. A formal statement of anonymity at the beginning
        of the survey (which we include) will help with this. You should also
        reinforce this in school. It is also why using an external provider
        helps.
      </p>
      <p className='custom-bullet'>
        Give people enough time to complete the survey. It should take no more
        than 10 minutes.
      </p>
      <p className='custom-bullet'>
        Ensure managers understand that the process is important and that they
        encourage their staff to take time to complete the survey.
      </p>
      <p className='custom-bullet'>
        Ideally, allow staff to complete the survey in work time, rather than
        expecting them to complete it in their own time. We know that this
        provides much better response rates. We have found that doing this at
        the start of a meeting, for example, and giving them time to go away and
        complete and then come back works better than finishing earlier and
        giving time then (they sometimes do other things instead).
      </p>
      <p className='custom-bullet'>
        Continue to publicise the survey throughout the process.
      </p>
      <p className='custom-bullet'>
        Provide appropriate instructions and a contact for questions or support.
      </p>

      <p>An example message you might use and adapt is included below.</p>
      <span className='colleague-message'>
        <p>Dear [Colleague],</p>
        <p>
          [School Name] is committed to protecting the health, safety and
          welfare of its staff and we acknowledge the importance of wellbeing
          and tackling the causes of stress in our workplace.
        </p>
        <p>
          We would like your support in undertaking a survey, which is designed
          to find out how you feel about various aspects of working conditions
          here. We are asking you and your colleagues to do this to be sure that
          we are doing all we can to make this a good place to work. The survey
          is anonymous and the questions asked do not allow for any individual
          to be identified.
        </p>
        <p>
          You will receive a link to allow you to take the survey on-line and
          please complete it as soon as you can (or enter details of when you
          are making time for them to take it in work) and before (enter date).
        </p>
        <p>
          When the survey responses have been collated, summary results will be
          shared with you, and we will reach agreement on appropriate action.
        </p>
        <p>
          Please take the time to complete the survey. It will provide an
          indication of how well we are performing and where any problem areas
          might be. Don’t rely on others to raise issues, as wellbeing and
          stress is subjective and can affect people in different ways.
        </p>
        <p>
          The survey should take no more than 10 minutes to complete. For each
          question click on the answer that most accurately reflects how you
          feel. There is also the opportunity for you to share comments about
          what you think is going well and how we might improve.
        </p>
        <p>
          If you have any questions, please contact [enter contact name/phone
          number]. Thank you for participating.
        </p>
      </span>
      <p className='copy-link'> You can copy and paste the above message.</p>
    </div>
  );
};

export default LearnObtaining;
