import React from 'react';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../MatStyle';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { ChartWrapperError } from '../../../resources/styling/appStyle';
import PresentationChart from '../../../components/Charts/PresentationChart';

const GraphScoresbyJobRolePpt = (props) => {
  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section'>
          <span className='app-logo'>
            <img src={props.welbeeslideslogo} alt='' />
          </span>
          <h1 className='matpres-title'>
            {props?.surveyCount > 1 ? props.title : 'Scores by Job Role'}
          </h1>
          <div>
            {props.JoRoleGraph === null ? (
              <ChartWrapperError>
                <span className='nodata'>{`There were insufficient filtered responses to display this information.`}</span>
              </ChartWrapperError>
            ) : (
              <div className='graph-wrapper'>
                <Scrollbars className='scrollbar-view'>
                  <PresentationChart
                    data={props?.JoRoleGraph}
                    surveyCount={props?.surveyCount}
                    name={props?.name}
                  />
                </Scrollbars>
              </div>
            )}
          </div>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default GraphScoresbyJobRolePpt;
