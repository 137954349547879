import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames, MenuItems } from '../../utils';
import { HeadTeacherAccess } from '../../utils/Constants';
import { navigationActions } from '../../store/headerStore';
import MatAdditionalQuestionForm from './MatAdditionalQuestionForm';
import MatAdditionalQuestionLikertForm from './MatAdditionalQuestionLikertForm';
import AddMatQuestion from './AddMatQuestion';
import { storageService, matAdditionalQuestionService } from '../../services';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import { toast } from 'react-toastify';
import MatAdditionalQuestionDropdown from './MatAdditionalQuestionDropdown';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import {
  ExtendedSubContainer,
  ExtendedModalInnerWrapper,
  customStyles,
} from './MatAdditionalQuestionStyle';
import { Alert } from '@mui/material';
import { WrapperMain } from '../../resources/styling/formStyle';
import { Formik } from 'formik';
import { styled } from '@mui/material/styles';
import { AppModel } from '../../components';
import MatAddLikertForm from './MatAddLikertForm';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';

/*** Table Style ***/
const purple = {
  50: '#45338C',
  100: '#45338C',
};

const Root = styled('div')(
  ({ theme }) => `
	table {	  
	  font-size: 14px !important;
	  border-collapse: collapse;
	  width: 100%;
	  font-weight: 500 !important;
	  margin-top: 15px;
	  margin-bottom: 0 !important;
	}
  
	td,
	th {	  	 
	  padding: 8px !important; 
	  font-size: 14px !important; 
    text-align: left;
	}

	tr:nth-of-type(odd){
		background-color: ${theme.palette.action.hover};
	}
  
	th {
	  background-color: ${theme.palette.mode === 'dark' ? purple[50] : purple[100]};
	  color: ${theme.palette.common.white};
	}
	`
);

class MatAdditionalQuestionBank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: [],
      message: 'Loading...',
      sortingByTypeOption: { value: false, label: 'Open actions' },
      suggestedActions: { value: '', label: 'Choose from the Question Bank' },
      questionBankOptions: [],
      createDialogOpen: false,
      matQuestionsData: [],
      editRecord: null,
      isEdit: false,
      isLikertEdit: false,
      accessLevel: 0,
      matCycleId: null,
      matGroupId: 0,
      isDeleteQuestion: false,
      questionToDelete: {},
    };
  }

  componentDidMount() {
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    this.props.navigationActions.activeItemAction({
      item: MenuItems.QuestionBank,
      showReportSubMenu: false,
      disableReportMenu: false,
    });
    this.setState({ accessLevel: accessLevel });
    const matGroupId = window.location.search.split('?matGroupId=')[1];
    this.setState({ matGroupId: parseInt(matGroupId) });
    this.getQuestionsByMatGroupId(matGroupId);
    this.getQuestionBankByMatGroupId(matGroupId);
  }

  getQuestionsByMatGroupId(matGroupId) {
    const { loaderActions } = this.props;
    loaderActions.dataLoading(true);
    matAdditionalQuestionService
      .getQuestionsByMatGroupId(matGroupId)
      .then((response) => {
        if (response.success) {
          this.setState({ matQuestionsData: response.data });
        }
        loaderActions.dataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        loaderActions.dataLoading(false);
      });
  }

  getQuestionBankByMatGroupId(matGroupId) {
    matAdditionalQuestionService
      .getQuestionBankByMatGroupId(matGroupId)
      .then((response) => {
        if (response.success) {
          this.setState({ questionBankOptions: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setMatQuestions(question, questions) {
    const msg = question.Likert?.Id > 0 ? 'Updated' : 'Added';
    matAdditionalQuestionService
      .setMatQuestions(question)
      .then((response) => {
        toast.success(`Additional Question ${msg} successfully.`);
        if (response.success && response.total <= 20) {
          if (questions !== null) {
            this.setState({ questionBankOptions: questions });
          }
          this.setState({ matQuestionsData: response.data });
        } else {
          this.setState({ questionBankOptions: response.data });
          this.setState({ isAlertPopupVisible: true });
        }
      })
      .catch((error) => {
        toast.warning('Something went wrong. Please try again!');
        console.log(error);
      });
  }

  deleteMatQuestion(question) {
    matAdditionalQuestionService
      .deleteMatQuestions(question)
      .then((response) => {
        if (response.success) {
          toast.success('Additional Question Deleted Successfully.');
          this.setState({
            matQuestionsData: response.data.matQuestions,
            questionBankOptions: response.data.questionBankOption,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  downloadHseQuestions() {
    matAdditionalQuestionService
      .downloadHseQuestions()
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'HSE Indicator Tool and Welbee Questions.pdf'
        );
        document.body.appendChild(link);
        link.click();
        this.setState({ isOpen: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleCreateQuestion = () => {
    const token = getQueryStringVal('token');
    token === 'null'
      ? toast.warning('Need to create Mat first.')
      : this.setState({
          createAddQuestionFormOpen: true,
          editRecord: null,
          isEdit: false,
        });
  };

  handleCreateLikert = () => {
    const token = getQueryStringVal('token');
    token === 'null'
      ? toast.warning('Need to create survey first.')
      : this.setState({
          createAddLikertFormOpen: true,
          editRecord: null,
          isEdit: false,
        });
  };

  handleAddQuestionSubmit = (question) => {
    this.setMatQuestions(question, null);
    this.setState({
      createAddQuestionFormOpen: false,
      editLikertQuestionFormOpen: false,
    });
  };

  handleAddRemoveQuestion = (question, questions) => {
    if (question.IsIncluded) {
      this.setMatQuestions(question, questions);
    } else {
      this.deleteMatQuestion(question);
    }
  };

  addQuestionsFormCancel = (event) => {
    event.preventDefault();
    this.setState({ createAddQuestionFormOpen: false });
  };

  addLikertFormCancel = (event) => {
    event.preventDefault();
    this.setState({ createAddLikertFormOpen: false });
  };

  addLikertQuestionsFormCancel = (event) => {
    event.preventDefault();
    this.setState({ editLikertQuestionFormOpen: false });
  };

  handleQuestionEdit = (question) => {
    this.setState({
      editRecord: question,
      createAddQuestionFormOpen: true,
      isEdit: true,
    });
  };

  handleLikertQuestionEdit = (question) => {
    this.setState({
      editRecord: question,
      editLikertQuestionFormOpen: true,
      isLikertEdit: true,
    });
  };

  handleQuestionDelete = (question) => {
    this.deleteMatQuestion(question);
    this.setState({ isDeleteQuestion: false });
  };

  setIsAlertPopupClose = (event) => {
    this.setState({ isAlertPopupVisible: false });
  };

  render() {
    const {
      createAddQuestionFormOpen,
      createAddLikertFormOpen,
      editLikertQuestionFormOpen,
      isAlertPopupVisible,
      suggestedActions,
      questionBankOptions,
      matQuestionsData,
      editRecord,
      isEdit,
      isLikertEdit,
      accessLevel,
      existingQuestions,
      matCycleId,
      matGroupId,
      isDeleteQuestion,
    } = this.state;

    return (
      <div>
        <Alert severity='info'>
          We recommend adding no more than 5-10 additional questions to the 35
          core Health & Safety Executive (HSE) survey questions. This will
          provide a better experience for your staff and allow a greater focus
          on feedback and post-survey actions. Click here to download a copy of
          the 35{' '}
          <a href='#' onClick={this.downloadHseQuestions}>
            <b>HSE Survey Questions</b>
          </a>
          .
        </Alert>
        <br />
        <ExtendedSubContainer>
          <div className='search-data-container'>
            <div className='search-inner-container'>
              {matQuestionsData.length > 0 ? (
                <div className='search-inner-sub-container'>
                  <h2>
                    Additional Survey Questions
                    {accessLevel !== HeadTeacherAccess.View && (
                      <>
                        <Tooltip title='Add your own question' aria-label='add'>
                          <button
                            onClick={this.handleCreateQuestion}
                            className='add-btn'
                          >
                            +
                          </button>
                        </Tooltip>
                        {/* <Tooltip title='Add your own Likert' aria-label='add'>
                          <button
                            onClick={this.handleCreateLikert}
                            className='add-likert-btn'
                          >
                            Custom Likert
                          </button>
                        </Tooltip> */}
                      </>
                    )}
                  </h2>
                  <Root>
                    <table>
                      <thead>
                        <tr>
                          <th>Question</th>
                          <th>Question Type</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {matQuestionsData.map((question, key) => (
                          <tr key={key}>
                            <td>{question.QuestionText}</td>
                            <td>{question.TypeDescription}</td>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-evenly',
                                }}
                              >
                                <Tooltip
                                  title='Delete question'
                                  aria-label='add'
                                >
                                  <DeleteIcon
                                    style={{ color: '#E84F6B' }}
                                    onClick={() => {
                                      if (
                                        accessLevel === HeadTeacherAccess.Full
                                      ) {
                                        //this.handleQuestionDelete(question);
                                        this.setState({
                                          questionToDelete: question,
                                          isDeleteQuestion: true,
                                        });
                                      }
                                    }}
                                    sx={{ fontSize: '20px' }}
                                  />
                                </Tooltip>

                                {accessLevel === HeadTeacherAccess.Full ? (
                                  question.Category !== 11 ? (
                                    <Tooltip
                                      title='Edit question'
                                      aria-label='add'
                                    >
                                      <EditIcon
                                        color='primary'
                                        onClick={() => {
                                          if (question.Category === 10) {
                                            this.handleQuestionEdit(question);
                                          } else {
                                            this.handleLikertQuestionEdit(
                                              question
                                            );
                                          }
                                        }}
                                        sx={{ fontSize: '20px' }}
                                      />
                                    </Tooltip>
                                  ) : null
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Root>

                  {isDeleteQuestion ? (
                    <ConfirmPopupForm
                      onClose={() => this.setState({ isDeleteQuestion: false })}
                      onOk={() =>
                        this.handleQuestionDelete(this.state.questionToDelete)
                      }
                      isOpen={this.state.isDeleteQuestion}
                      successPopupMessage={
                        <Alert
                          style={{ backgroundColor: 'rgb(255, 245, 204)' }}
                          severity='warning'
                        >
                          Are you sure you want to delete this Additional
                          Question?
                        </Alert>
                      }
                    />
                  ) : (
                    <div />
                  )}
                </div>
              ) : (
                <AddMatQuestion
                  isOpen={this.handleCreateQuestion}
                  accessLevel={accessLevel}
                />
              )}

              <div className='suggested-actions-wrapper'>
                <MatAdditionalQuestionDropdown
                  name='SuggestedActions'
                  options={questionBankOptions}
                  selectedOption={suggestedActions}
                  existingQuestions={existingQuestions}
                  handleAddRemoveQuestion={this.handleAddRemoveQuestion}
                  value={suggestedActions}
                  withButton={true}
                />
              </div>
            </div>
          </div>
        </ExtendedSubContainer>

        {createAddQuestionFormOpen ? (
          <MatAdditionalQuestionForm
            isOpen={createAddQuestionFormOpen}
            onClose={this.addQuestionsFormCancel}
            handleSubmit={this.handleAddQuestionSubmit}
            editRecord={editRecord}
            isEdit={isEdit}
            className={'xyz'}
            matGroupId={matGroupId}
            matCycleId={matCycleId}
          />
        ) : null}

        {createAddLikertFormOpen ? (
          <MatAddLikertForm
            isOpen={createAddLikertFormOpen}
            onClose={this.addLikertFormCancel}
            editRecord={editRecord}
            isEdit={isEdit}
            className={'xyz'}
            matGroupId={matGroupId}
            matCycleId={matCycleId}
          />
        ) : null}

        {editLikertQuestionFormOpen ? (
          <MatAdditionalQuestionLikertForm
            isOpen={editLikertQuestionFormOpen}
            onClose={this.addLikertQuestionsFormCancel}
            handleSubmit={this.handleAddQuestionSubmit}
            editRecord={editRecord}
            isEdit={isLikertEdit}
            className={'xyz'}
            matGroupId={matGroupId}
            matCycleId={matCycleId}
          />
        ) : null}

        {isAlertPopupVisible ? (
          <AppModel
            className='survey-popup'
            open={isAlertPopupVisible}
            onClose={() => this.setIsAlertPopupClose()}
            bodyText={
              <Formik
                enableReinitialize={true}
                onSubmit={() => {}}
                render={({}) => (
                  <WrapperMain>
                    <ExtendedModalInnerWrapper>
                      <Alert severity='warning'>
                        You have already added the maximum number of additional
                        questions for your survey. If you want to add further
                        questions, then please delete one or more of the
                        questions you have already added.
                      </Alert>
                    </ExtendedModalInnerWrapper>
                  </WrapperMain>
                )}
              ></Formik>
            }
            styles={customStyles}
            footer={<div />}
          />
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(MatAdditionalQuestionBank));
