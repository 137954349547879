import React from 'react';
import { AppButton } from '../resources/styling';
import { storageService } from '../services';
import { WebStorageNames } from '../utils';
import { colors } from '../resources/theme/colors';
import { config } from '../utils/config';

const { mandy } = colors;

const InsightsButton = ({ id, name, email, ismat,firstName,lastName }) => {
  // TODO: pulse url should be taken from config file once it is configured in trnsformtion file
  return (
    <AppButton
      color={mandy}
      onClick={() => {
        window.open(
          `${config.INSIGHT_URL}?name=${btoa(name)}&email=${btoa(
            email
          )}&id=${id}&ismat=${ismat}&access_token=${storageService.getItem(
            WebStorageNames.Token
          )}&firstName=${firstName} &lastName=${lastName}`,
          '_blank'
        );
      }}
    >
      Insights
    </AppButton>
  );
};

export default InsightsButton;
