import styled from 'styled-components';
import { colors } from '../../../resources/theme/colors';
import { MediaQuery } from '../../../resources/styling/appStyle';

const {
  pictonBlue, whiteColor, purple, mandy, teal, barleyWhite
} = colors;

/*** Add Cycle ***/
export const DropDownWrapper = styled.div `
    #multiselectContainerReact {
      > div {
        min-height: 50px;
        border: 2px solid ${pictonBlue};
      }
      span {
        float: none !important;
        margin-top: 6px;
      }
      li:first-child {
        margin-bottom: 0;
      }
      .searchBox {
        margin-top: 11px;
        width: 105px;
      }
      .highlight {
        position: static;
        height: '112px';
        width: 100%;
        top: 0;
        left: 0;
        pointer-events: all;
        opacity: 1;
        color: ${pictonBlue};
        background: transparent;
      }
      .option {
        color: ${pictonBlue};
        font-size: 13px;
        font-weight: 400;
        justify-content: end;
        display: flex;
      }
      li:hover {
        color: ${whiteColor};
        background: ${teal};
      }
    }
    .multi-select-wrapper {
      width: 100%;
      float: left;
    }
  
    width: 100%;
    float: right;
    span:first-child {
      line-height: normal !important;
      width: auto !important;
    }
    /* display: flex;
    align-items: flex-start; */
    button {
      padding: 15px 21px;
      margin: 0 0 0 15px;
    }
    .category-inner-wrapper {
      margin: 0;
      border: 2px solid;
      padding: 10px;
      width: 100%;
      position: relative;
      overflow: initial;
    }
    label {
      font-size: 16px;
    }
    .options {
      background: ${whiteColor};
      border: 2px solid;
      position: absolute;
      max-height: initial;
      width: 101.5%;
      left: -2px;
      z-index: 10;
      padding: 0px;
      margin-top: 9px;
  
      span {
        padding: 7px 17px 7px 0;
      }
    }
`;
export const InputWrapper = styled.form `    
    .mat-options {
      border: 2px solid ${pictonBlue};
      border-radius: 6px;
      float: left;      
      width: 100%;
      padding: 0;
      list-style: none;
      max-height: 150px;
      overflow-y: auto;
  
      li {
        padding: 10px;
        color: ${pictonBlue};
        cursor: pointer;
  
        &:hover {
          background: ${teal};
          color: ${whiteColor};
        }
      }
    }
  
    .date-field {
      width: 100% !important;
      margin-top: 0px !important;
    }
  
    .date-field .MuiInputBase-root {
      width: 100% !important;
      background-color: transparent;
      border-radius: 6px;
      padding: 17px 0px 13px 20px;
      border: 1px solid #45338c;
      color: #363636;
    }
  
    .date-field input {
      background-color: transparent;
      border: none !important;
      color: #363636;
    }
  
    .date-field button {
      margin: 0 !important;
    }
    .date-field .MuiInput-underline:after,
    .date-field .MuiInput-underline:before {
      border-bottom: none !important;
    }
    .school-field .search-wrapper {
      border-radius: 6px !important;
      border: #45338c 1px solid !important;
    }
  
    .school-field .search-wrapper input {
      border: none !important;
      padding: 0px 0px 0px 20px;
    }
  
    .school-field #multiselectContainerReact .option {
      justify-content: start !important;
    }
`;

/*** Detail Cycle ***/
export const customStyles = {
    rows: {
        style: {
            minHeight: '60px', // override the row height
        },
    },
    headCells: {
        style: {
            backgroundColor: '#45338C',
            padding: '10px 20px',
            color: '#ffffff',
            borderRight: '1px solid ${lightBlue}',
            fontSize: '16px',      
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            fontWeight: '500',
        },
    },
};
export const SchoolSurveyInner = styled.div `
    display: block;
    margin-top: 30px;
    .back-btn {
      background: ${mandy};
      height: 60px;
      width: 80px;
      text-align: center;
      padding: 20px;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
    }
    .heading {
      margin-left: 80px;
    }
    button{
      margin-bottom: 0;
      padding: 9px 18px;
    }
`;
export const SchoolSurveyInnerMain = styled.div `
    width: 100%;
    min-height: calc(100vh - 216px);
`;
export const SchoolSurveyWrapper = styled.div `
    float: left;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    ${MediaQuery}
  
    .email-link {
      color: ${pictonBlue};
      text-decoration: underline;
      cursor: pointer;
    }
    .table {
        font-size: 14px;
        td,
        th {
          vertical-align: middle;
          padding: 15px;
        }
        tr > th {
          border-bottom: none !important;
        }
        button {
          margin-bottom: 0px;
          padding: 9px 18px;
          margin-right: 5px;
        }
      }
`;

/*** Mat Cycles ***/
export const PaginationWrapper = styled.div`
  width: 75%;
  margin: auto;
  text-align: center;
`;

const MatCycleStyle = {    
    DropDownWrapper,
    InputWrapper,
    customStyles,
    SchoolSurveyInner,
    SchoolSurveyInnerMain,
    SchoolSurveyWrapper,
    PaginationWrapper
}
export default MatCycleStyle;