import React from 'react';
import { AppButton, FooterButtonsWrapper } from '../../resources/styling';

import { colors } from '../../resources/theme/colors';
const { outrageousOrange } = colors;

const FooterButtons = ({
  submitButtonText,
  handleSubmit,
  onClose,
  showFooterButton = true,
  closeButtonText = 'Cancel',
  isDisabled = false,
}) => {
  return (
    <FooterButtonsWrapper>
      {showFooterButton && (
        <>
          <AppButton
            color={isDisabled ? '' : outrageousOrange}
            type='submit'
            onClick={isDisabled ? {} : handleSubmit}
          >
            {submitButtonText}
          </AppButton>

          <AppButton color={outrageousOrange} type='button' onClick={onClose}>
            {closeButtonText}
          </AppButton>
        </>
      )}
    </FooterButtonsWrapper>
  );
};

export default FooterButtons;
