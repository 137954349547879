import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';

const {
    pictonBlue, mineShaft, hummingBird, outrageousOrange, blackColor    
} = colors;

/*** Survey Welcome ***/
export const WelcomeMainWrapper = styled.div`
  float: left;
  width: 100%;

  .welcome-right-panel {
    border-right: 2px solid ${hummingBird};
    border-left: 2px solid ${hummingBird};
    ${media.md`
      border: 0;
    `}
    ${media.lg`
    border-right: 2px solid ${hummingBird};
    border-left: 2px solid ${hummingBird};
    `}
    ${media.xl`
    border-right: 2px solid ${hummingBird};
    border-left: 2px solid ${hummingBird};
    `}
  }
  .school-name {
  font-weight: 600;
  color: ${pictonBlue} !important;
   margin: 0 6px;
  }

  margin-top: 63px !important;
  ${media.xs`
  position: relative;
    min-height: calc(94vh - 7px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
  ${media.sm`
  position: relative;
    min-height: calc(94vh - 7px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
  ${media.md`
  position: relative;
    min-height: calc(94vh - 7px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}

  ${media.lg`
  min-height: calc(100vh - 68px);
  `}
`;
export const customCSS = `

.main-Outer-Wrapper {
  float: left;
  width: 100%;

  margin-top: 63px !important;
  ${media.xs`
  position: relative;
    min-height: calc(100vh - 72px);
  `}
  ${media.sm`
  position: relative;
    min-height: calc(100vh - 72px);
  `}
  ${media.md`
  position: relative;
    min-height: calc(100vh - 72px)
  `}
  ${media.lg`
  position: relative;
  min-height: calc(100vh - 68px);
  `}
  ${media.xl`
  position: relative;
  min-height: calc(100vh - 68px);
  `}
`;
export const WelcomeWrapper = styled.div`
  display: table;
  height: 100%;
  padding-left: 30px;

  h1 {
    color: ${mineShaft};
    font-size: 48px;
    margin-top: 30px; 
    ${media.xs`
       font-size: 36px;
       font-weight: 900;
     `}
  }
  h2 {
    color: ${mineShaft};
    font-size: 36px;
    font-weight: 400;
    width: 55%;
    margin-top: 0;
    ${media.xs`
       width: 100%;
       font-size: 24px;
       line-height: 36px;
     `}
    ${media.sm`
       width: 90%;
       font-size: 24px;
     `}
     ${media.md`
       width: 70%;
       font-size: 24px;
     `}
     ${media.lg`
       width: 55%;
       font-size: 36px;
       line-height: 48px;
     `}
  }
  p {
    color: ${mineShaft};
    width: 55%;
    font-weight: 400;
    font-size: 20px;
    margin: 31px 0 55px 0;
    ${media.xs`
       width: 90%;
       line-height: 31px;
       font-size:18px;
     `}
    ${media.sm`
       width: 90%;
       line-height: 31px;
       font-size:18px;
     `}
     ${media.md`
       width: 70%;
       line-height: 31px;
       font-size:18px;
     `}
  }
  span {
    color: ${outrageousOrange};
    i{
      color: ${blackColor};
      font-weight: 400;
    }
  }

`;
export const WelcomeHeaderWrapper = styled.div`
  > div {
    display: block;
  }
`;
export const WelcomeInnerWrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const SurveyWelcomeStyle = {
    WelcomeMainWrapper,
    customCSS,
    WelcomeWrapper,
    WelcomeHeaderWrapper,
    WelcomeInnerWrapper
}
export default SurveyWelcomeStyle;