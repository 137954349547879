import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Routes } from './routes';
import { store } from './store';
import ReactGA from 'react-ga';
import amplitude from 'amplitude-js';
import { config } from './utils/config';
import { hotjar } from 'react-hotjar';
import AppLoader from './components/loader/AppLoader';
import LoggerService from './services/api/LoggerService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { PermissionProvider } from "react-permission-role";
import userManager, { loadUserFromStorage } from './services/api/userService';
import AuthProvider from './utils/authProvider'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Avenir LT Pro',
    ].join(','),
  }
});

ReactGA.initialize(config.GOOGLE_ANALYTICS_KEY, {
  gaOptions: { allowLinker: true },
});

ReactGA.pageview(window.location.pathname + window.location.search);
hotjar.initialize(config.HOTJAR.hjid, config.HOTJAR.hjsv);
amplitude.init(config.AMPLITUDE_KEY);

function App() {

  useEffect(() => {
    ReactGA.set('user_id', '5');
    window.onerror = (message, file, line, column, errorObject) => {
      LoggerService.log(message, file, line, column, errorObject);
    };
    // fetch current user from cookies
    loadUserFromStorage(store);
  }, [])

  return (
    <PermissionProvider>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <AuthProvider userManager={userManager} store={store}>
              <AppLoader />
              <Routes />
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </AuthProvider>
          </Provider>
        </ThemeProvider>
      </PermissionProvider>
  );
}

export default App;
