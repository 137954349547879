import request from '../request';
import { wondeAPI } from './urls';

const getWondeSchoolRequestStatus = (wondeSchoolUrn) => {
  return request({
    url: wondeAPI.getWondeSchoolRequestStatus(wondeSchoolUrn),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const updateWondeRequestStatus = (SchoolUrnNo) => {
  return request({
    url: wondeAPI.updateWondeRequestStatus(SchoolUrnNo),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((error) => error);
};

const updateWondeSyncedStatus = (SchoolUrnNo) => {
  return request({
    url: wondeAPI.updateWondeSyncedStatus(SchoolUrnNo),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((error) => error);
};

const wondeService = {
  getWondeSchoolRequestStatus,
  updateWondeRequestStatus,
  updateWondeSyncedStatus,
};

export default wondeService;
