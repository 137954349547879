import request from '../request';
import { staffGroupAPI } from './urls';

const getAllGroupsBySchoolId = (schoolId) => {
  return request({
    url: staffGroupAPI.GetAllGroupsBySchoolId(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const addStaffGroup = (model) => {
  return request({
    url: staffGroupAPI.AddStaffGroup,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const deleteStaffGroup = (model) => {
  return request({
    url: staffGroupAPI.DeleteStaffGroup(model),
    method: 'DELETE',
  })
    .then((response) => response)
    .catch((error) => error);
};

const updateStaffGroup = (model) => {
  return request({
    url: staffGroupAPI.UpdateStaffGroup,
    method: 'PUT',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const getById = (id) => {
  return request({
    url: staffGroupAPI.getById(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const archive = (id) => {
  return request({
    url: staffGroupAPI.archive(id),
    method: 'DELETE',
  })
    .then((response) => response)
    .catch((error) => error);
};

const staffGroupService = {
  getAllGroupsBySchoolId,
  addStaffGroup,
  deleteStaffGroup,
  updateStaffGroup,
  getById,
  archive
};

export default staffGroupService;
