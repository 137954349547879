import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { colors } from '../../resources/theme';
import { MediaQuery } from '../../resources/styling/appStyle';
const { mineShaft, pictonBlue, whiteColor, lightGray, teal, mandy, blackColor } = colors;

export const AssistanceWrapper = styled.div`
  width: 100%;  
  position: relative;  
  z-index: 20;
  button {
    border: 0;
    background: ${teal};
    color: ${whiteColor};    
    border-radius: 8px;    
    float: right;
    cursor: pointer;
    width: 45px;
    height: 45px;
    line-height: 49px;
    img{
      height: 14px;
    }
  }

  .action-heading-wrapper {
    font-size: 20px;
    font-weight: 900;
    float: left;
    .action-heading {
      display: block;
      font-weight: 900;
      font-size: 24px;
      color: ${blackColor};
    }
    span {
      color: ${lightGray};
      font-size: 14px;
      font-weight: 400;
    }    
  }

  @media only screen and (max-width: 600px){
      .action-heading-wrapper{
        width: 100%;
        .action-heading {          
          font-size: 20px;
          margin-bottom: 10px;          
        }
      }
    } 
    
    @media only screen and (max-width: 700px){    
      .btn-request-assistance button{
        width: 100%;
        display: block;
        margin-bottom: 10px; 
        padding: 0;     
      }
      .dd-survey .latest-survey{
        width: 100%;
        margin-right: 0;
      }
    }
  
`;

export const AdditionalQuestionsMainWrapper = styled.div`

  .mobile-wrapper {
    padding-bottom: 0;
  }

  .latest-survey {
    .options {
      max-height: inherit;
    }
    .selected-option {
      border: 0;
      font-weight: 500;
    }
    .active-option {
      background: #45338C;
      color: ${whiteColor};
    }

    float: right;
    margin-right: 8px;
    > div {
      margin: 0;
      border-radius: 8px;
      min-height: 45px;
      min-width: 123px;
      background: #45338C;
      color: ${whiteColor} !important;
      .selected-option {
        min-height: 45px;
        padding: 13px 25px;  
        label {
          color: ${whiteColor} !important;
        }
      }
    }

    .options{
      border-radius: 5px;
      background: #45338C;
      color: ${whiteColor};
      padding: 10px 0;
      margin: -10px 0 0 0;
      //box-shadow: 3px 3px 10px rgba(0,122,122,0.12);
      z-index: 9999;
      max-height: inherit;
      position: absolute;
      width: 100%;
      span{
        color: ${whiteColor};
        font-size: 12px;	
        padding: 7px 11px 7px 26px;
      }
    }
    .selected-option {
      padding: 13px 25px;
      font-size: 14px;
      img {
        margin-left: 17px;
        width: 12px;
        margin-top: 6px;
      }
    }
  }
  mark {
    background: transparent !important;
    color: ${mandy} !important;
    font-weight: 900;
  }
  .selected-option {
    cursor: pointer;
  }
  .suggested-actions-wrapper {
    margin: 20px 0 15px 0;
    float: left;
    width: 100%;
    .action-plan-options {
      .action-plan-detail {
        float: left;
        width: 100%;
        position: relative;
        padding-right: 133px;
        box-sizing: border-box;
        z-index: 1;
        cursor: auto;
      }
      .add-action-plan {
        padding-left: 0;
        padding-right: 0;
        font-weight: 900;
        top: 15%;
        position: absolute;
        right: 20px;
        font-style: normal;
        z-index: 2;
      }
      .action-category-heading {
        font-size: 14px;
        font-weight: bold;
        //padding: 30px 39px 15px 39px;
        cursor: auto;
      }
    }
    .action-plan-options span.action-plan-detail {
      font-size: 15px;
      line-height: 24px;
      padding-left: 39px;
      cursor: pointer;      
    }
  }
  .searched-data-wrapper {
    display: none;
  } 
  .containerClass {
    padding-top: 0;
    width: 100%;
    max-width: 100%;
  }


  .material-ui-switch {
    span{
      padding: 0;  
    }
    .MuiSwitch-root {
      padding: 12px;
      
      .MuiButtonBase-root {
        padding: 9px;       
      }
    }
  }
`;
