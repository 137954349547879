export const colors = {
  pictonBlue: '#53B5E0',
  blackColor: '#000000',
  outrageousOrange: '#FF5939',
  yellowColor: '#FFCF05',
  teal: '#007A7A',
  lightTeal: '#159292',
  illusion: '#F5ABCC',
  mineShaft: '#363636',
  lightGray: '#7B7B7B',
  whiteColor: '#FFFFFF',
  alto: '#DADADA',
  ginFizz: '#FFF9DE',
  gorse: '#FFE64C',
  hummingBird: '#D6EEF9',
  lightBlue: '#7CC7E8',
  lighterGrey: '#F3EEEE',
  never: '#FF5939',
  seldom: '#53B5E0',
  sometimes: '#FFCF05',
  often: '#007A7A',
  always: '#F5ABCC',
  mandy: '#E84F6B',
  twilightBlue: '#F8FDFF',
  twilightBlueLight: '#F0FBFF',
  keppel: '#2FAFAF',
  blizzardBlue: '#A9DAF0',
  blizzBlue: '#BAE1F3',
  lightGreen: '#C5EAEA',
  orient: '#93BCCC',
  purple: '#45338C',
  redColor: '#EB5B41',
  grayColor: '#007b7b',
  grainsboro: '#DCDCDC',
  minsk: '#45338C',
  easternBlue: '#188B98',
  darkBlue: '#00597A',
  viola: '#C27BA0',
  solidRed: '#FF0000',
  blazzingBlue: '#A3D7EE',
  cornflowerBlue: '#0FC4C4',
  lightPink: '#FD92C2',
  parrotGreen: '#4DC4A4',
  darkYellow: '#FFB205',
  barleyWhite: '#FFF5CC',
  darkPurple: '#47318D',
  disabled: '#f9b2bf',
  viking: '#64dac1',
  bittersweet: '#FF7A6A',
  minttulipLight: '#C0F1E3',
  yourpinkLight: '#FFC8C0',
  midSoftGrey: '#D6D6D6',
  softGrey: '#f1f1f1',
  minttulip: '#4DC4A4',
  yourpink: '#FF5939',
  lightminttulip: '#84C1C3',
  lightyourpink: '#F7B2A5',
  darkgrey: '#3C3C3B',
  bordergrey: '#e2e2e2',
};
