import { map } from 'lodash';
import request from '../request';
import { surveyAPI, surveyResultAPI, surveyClosedAPI } from './urls';
import { colors } from '../../resources/theme';
import { storageService } from '../../services';
import {
  WebStorageNames,
  QueryStringTokens,
  WelbeeTypes,
} from '../../utils/Constants';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import schoolService from '../../services/api/schoolService';
import { NoEncryption } from '@mui/icons-material';
const { never, seldom, sometimes, often, always } = colors;

const experienceTimeObj = {
  LessThan1Year: '<1yr',
  OneTo3: '1-3 years',
  FourTo5: '4-5 years',
  SixTo10: '6-10 years',
  ElevenTo15: '11-15 years',
  SixteenToTwenty: '16-20 years',
  GreaterThan20: '>20 years',
};

const roleSlide = (
  schoolName,
  IsJobRoleAvailable,
  IsEmploymentTypeAvailable,
  IsEmploymentStartDateAvailable
) => (
  {
    type: 'empty',
    question: {
      Text: '',
      answer: {
        type: 'empty',
        options: [],
      },
    },
  },
  {
    type: 'question',
    question: {
      isAnswered: false,
      Text: `Thanks for answering the survey questions. To complete the survey and improve reporting we require some information about you. These answers will remain anonymous.`,
      answer: {
        type: 'dropdown',
        options: {
          jobRoleOptions: {
            isAnswered: false,
            options: [
              {
                value: 0,
                label: 'Select the job role that best describes what you do...',
                Selected: true,
              },
              {
                value: 4,
                label: 'Teaching staff classroom',
                Selected: false,
              },
              {
                value: 8,
                label: 'Teaching staff non-classroom',
                Selected: false,
              },
              {
                value: 16,
                label: 'Support staff classroom',
                Selected: false,
              },
              {
                value: 32,
                label: 'Support staff non-classroom',
                Selected: false,
              },
              {
                value: 64,
                label: 'Middle Leader',
                Selected: false,
              },
              {
                value: 128,
                label: 'Senior Leader',
                Selected: false,
              },
            ],
          },
          ageOptions: {
            isAnswered: false,
            options: [
              { value: 0, label: 'Select age...', Selected: true },
              { value: 1, label: '<30', Selected: false },
              { value: 2, label: '30 - 39', Selected: false },
              { value: 3, label: '40 - 49', Selected: false },
              { value: 4, label: '50 - 59', Selected: false },
              { value: 5, label: '>59', Selected: false },
            ],
          },
          employmentStatusOptions: {
            isAnswered: false,
            options: [
              {
                value: 0,
                label: 'Select employment status...',
                Selected: true,
              },
              { value: 1, label: 'Full Time', Selected: false },
              { value: 2, label: 'Part Time', Selected: false },
            ],
          },
          timeWorkingInEducationOptions: {
            isAnswered: false,
            options: [
              {
                value: 0,
                label: 'Select time working in education...',
                Selected: true,
              },
              {
                value: 1,
                label: experienceTimeObj.LessThan1Year,
                Selected: false,
              },
              { value: 2, label: experienceTimeObj.OneTo3, Selected: false },
              { value: 3, label: experienceTimeObj.FourTo7, Selected: false },
              { value: 4, label: experienceTimeObj.EightTo15, Selected: false },
              {
                value: 5,
                label: experienceTimeObj.GreaterThan15,
                Selected: false,
              },
            ],
          },
          timeWorkingInSchoolOptions: {
            isAnswered: false,
            options: [
              {
                value: 0,
                label: `Select how long you have worked at ${schoolName}...`,
                Selected: true,
              },
              {
                value: 1,
                label: experienceTimeObj.LessThan1Year,
                Selected: false,
              },
              { value: 2, label: experienceTimeObj.OneTo3, Selected: false },
              { value: 3, label: experienceTimeObj.FourTo5, Selected: false },
              { value: 4, label: experienceTimeObj.SixTo10, Selected: false },
              {
                value: 5,
                label: experienceTimeObj.ElevenTo15,
                Selected: false,
              },
              {
                value: 6,
                label: experienceTimeObj.SixteenToTwenty,
                Selected: false,
              },
              {
                value: 7,
                label: experienceTimeObj.GreaterThan20,
                Selected: false,
              },
            ],
          },
        },
      },
    },
  }
);

const openQuestionSlides = (totalQuestions) => {
  return [
    {
      type: 'question',
      question: {
        Id: totalQuestions + 1,
        isAnswered: false,
        Text: 'What is your opinion of staff wellbeing? What is going well and what could be improved?',
        NoteText: `Your comments are provided to the school anonymously and it is not possible to identify you, unless you identify yourself. Do not use names or comments that are likely to do this.`,
        answer: {
          type: 'comment',
          name: 'staffOpinion',
          comment: '',
        },
      },
    },
    {
      type: 'question',
      question: {
        Id: totalQuestions + 2,
        isAnswered: false,
        Text: 'What one thing would have the biggest impact on improving your wellbeing and that of other staff?',
        NoteText: `Your comments are provided to the school anonymously and it is not possible to identify you, unless you identify yourself. Do not use names or comments that are likely to do this.`,
        answer: {
          type: 'comment',
          name: 'biggestImpact',
          comment: '',
        },
      },
    },
  ];
};

export const getQuestions = () => {
  var queryString = window.location.search;
  var surveyToken = getQueryStringVal(QueryStringTokens.token);
  const survey = storageService.getItem(WebStorageNames.SurveyInProgress);
  const oldSurveyToken = storageService.getItem(WebStorageNames.SurveyToken);
  const isSurveyEditable = storageService.getItem(
    WebStorageNames.IsSurveyEditable
  );

  if (survey && surveyToken === oldSurveyToken && !isSurveyEditable) {
    return new Promise((resolve) => {
      resolve(survey);
    });
  }

  // remove old in progress survey if any
  storageService.removeItem(WebStorageNames.SurveyInProgress);
  storageService.setItem(WebStorageNames.SurveyToken, surveyToken);

  return request({
    url: surveyAPI.loadSurvey(queryString),
    method: 'GET',
  })
    .then((response) => {
      const { data } = response;

      if (data.IsSurveyClosed) {
        return new Promise((resolve) => {
          resolve(data);
        });
      }

      const isSurveyEditable = storageService.getItem(
        WebStorageNames.IsSurveyEditable
      );

      let questions = data.questions;

      if (isSurveyEditable) {
        questions = map(questions, (question) => {
          question.answer = getEditAnswer({
            type: 'radio',
            questionId: question.Id,
            answerId:
              question.SurveyAnswer !== null
                ? question.SurveyAnswer.AnswerType
                : null,
          });
          question.isAnswered = question.SurveyAnswer !== null ? true : false;
          return question;
        });
      } else {
        questions = map(questions, (question) => {
          question.answer = getAnswer({
            type: 'radio',
            questionId: question.Id,
          });
          question.isAnswered = false;
          return question;
        });
      }

      const slides = map(questions, (question) => {
        return {
          type: 'question',
          question,
        };
      });

      if (
        storageService.getItem(WebStorageNames.SubscriptionId) ===
        WelbeeTypes.WelbeeSurveyPremium
      ) {
        let additionalQuestions = storageService.getItem(
          WebStorageNames.AdditionalQuestions
        );
        if (isSurveyEditable) {
          additionalQuestions = map(additionalQuestions, (question) => {
            console.log('additionalQuestion', question);
            const { Likert } = question.question;
            let options = null;
            if (Likert) {
              const {
                LikertOption1,
                LikertOption2,
                LikertOption3,
                LikertOption4,
                LikertOption5,
              } = question.question.Likert;
              options = [
                {
                  Id: 1,
                  Answer: LikertOption1,
                  Color: never,
                  Selected: false,
                },
                {
                  Id: 2,
                  Answer: LikertOption2,
                  Color: seldom,
                  Selected: false,
                },
                {
                  Id: 3,
                  Answer: LikertOption3,
                  Color: sometimes,
                  Selected: false,
                },
                { Id: 4, Answer: LikertOption4, Color: often, Selected: false },
                {
                  Id: 5,
                  Answer: LikertOption5,
                  Color: always,
                  Selected: false,
                },
              ];
            }
            //console.log('options', options);

            if (question.question.Type === 0) {
              question.question.answer = getEditAnswer({
                type: 'radio',
                questionId: '' + question.question.Id + '',
                answerId:
                  question.question.SurveyAnswer !== null
                    ? question.question.SurveyAnswer.AnswerType
                    : null,
                options: question.question.answer ? question.question.answer.options : null
              });
              question.question.isAnswered =
                question.question.SurveyAnswer !== null ? true : false;
              return question;
            } else {
              question.question.answer = getEditComment({
                type: 'comment',
                name: question.question.Id,
                comment:
                  question.question.SurveyComment !== null
                    ? question.question.SurveyComment.CommentText
                    : '',
                options,
              });
              question.question.isAnswered =
                question.question.SurveyComment !== null ? true : false;
              return question;
            }
          });
        } else {
          //Tanveer
          additionalQuestions = map(additionalQuestions, (question) => {
            console.log('myQuestion', question);
            const { Likert } = question.question;
            let options = null;
            if (Likert) {
              const {
                LikertOption1,
                LikertOption2,
                LikertOption3,
                LikertOption4,
                LikertOption5,
              } = question.question.Likert;
              options = [
                {
                  Id: 1,
                  Answer: LikertOption1,
                  Color: never,
                  Selected: false,
                },
                {
                  Id: 2,
                  Answer: LikertOption2,
                  Color: seldom,
                  Selected: false,
                },
                {
                  Id: 3,
                  Answer: LikertOption3,
                  Color: sometimes,
                  Selected: false,
                },
                { Id: 4, Answer: LikertOption4, Color: often, Selected: false },
                {
                  Id: 5,
                  Answer: LikertOption5,
                  Color: always,
                  Selected: false,
                },
              ];
            }
            //console.log('question', question);
            if (question.question.Type === 0) {
              //DISCUSS WITH ALI THE BELOW COMMENTED BECAUSE WE ARE NOT USING question.answer, we use question.question.answer
              // question.answer = getAnswer({
              //   type: 'radio',
              //   questionId: question.question.Id,
              //   options,
              // });
              question.question.answer = getAnswer({
                type: 'radio',
                questionId: question.question.Id,
                options,
              });
              question.question.isAnswered = false;
              console.log('answer', question.question.answer);
              return question;
            } else {
              return question;
            }
          });
        }

        slides.push(...additionalQuestions);
      }

      if (
        storageService.getItem(WebStorageNames.SubscriptionId) ===
          WelbeeTypes.WelbeeSurveyPro ||
        storageService.getItem(WebStorageNames.SubscriptionId) ===
          WelbeeTypes.WelbeeSurveyFree
      )
        slides.push(...openQuestionSlides(slides.length));

      let IsJobRoleAvailable = data.IsJobRoleAvailable;
      let IsEmploymentTypeAvailable = data.IsEmploymentTypeAvailable;
      let IsEmploymentStartDateAvailable = data.IsEmploymentStartDateAvailable;
      let isAdditionalStaffInfo = data.IsAdditionalStaffInfo;
      if (isAdditionalStaffInfo === false)
        slides.push(
          roleSlide(
            data.schoolInfo.SchoolName,
            IsJobRoleAvailable,
            IsEmploymentTypeAvailable,
            IsEmploymentStartDateAvailable
          )
        );

      slides.push({
        type: 'slide',
        question: {
          isAnswered: false,
          Title: 'Are you ready to submit your answers?',
          Description:
            'When you are ready, click the Submit button below. Otherwise you can go back and change your answers.',
          answer: {
            type: 'none',
            options: [],
          },
        },
      });

      return slides;
    })
    .catch((response) => response);
};

const setSubscription = (schoolId) => {
  schoolService.getSchoolSubscriptions(schoolId).then((response) => {
    if (response.success) {
      storageService.setItem(
        WebStorageNames.SubscriptionId,
        response.data.SubscriptionId
      );
    }
  });
};

const getEditComment = ({ type, name, comment }) => {
  const answer = {
    type: type,
    name: name,
    comment: comment,
  };
  return answer;
};

const getEditAnswer = ({ type = 'radio', questionId, answerId, options }) => {
  const answer = {
    type,
    questionId,
    options: options
      ? options
      : [
          {
            Id: 1,
            Answer: 'Never',
            Color: never,
            Selected: answerId === 1 ? true : false,
          },
          {
            Id: 2,
            Answer: 'Seldom',
            Color: seldom,
            Selected: answerId === 2 ? true : false,
          },
          {
            Id: 3,
            Answer: 'Sometimes',
            Color: sometimes,
            Selected: answerId === 3 ? true : false,
          },
          {
            Id: 4,
            Answer: 'Often',
            Color: often,
            Selected: answerId === 4 ? true : false,
          },
          {
            Id: 5,
            Answer: 'Always',
            Color: always,
            Selected: answerId === 5 ? true : false,
          },
        ],
  };
  return answer;
};

const getAnswer = ({ type = 'radio', questionId, options }) => {
  //console.log(options, 'options inside getAnswer');
  const answer = {
    type,
    questionId,
    options: options
      ? options
      : [
          { Id: 1, Answer: 'Never', Color: never, Selected: false },
          { Id: 2, Answer: 'Seldom', Color: seldom, Selected: false },
          { Id: 3, Answer: 'Sometimes', Color: sometimes, Selected: false },
          { Id: 4, Answer: 'Often', Color: often, Selected: false },
          { Id: 5, Answer: 'Always', Color: always, Selected: false },
        ],
  };
  return answer;
};

const postSurvey = ({ survey }) => {
  return request({
    url: surveyAPI.submitSurvey,
    method: 'POST',
    data: survey,
  }).then((response) => response);
};

const getIndividualQuestionResults = (queryString) => {
  return request({
    url: surveyAPI.getIndividualQuestionResults(queryString),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getSchoolSurveys = (schoolId) => {
  return request({
    url: surveyAPI.getSchoolSurveys(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getSurveysNameAndToken = (token) => {
  return request({
    url: surveyAPI.getSchoolSurveysNameAndToken(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getSurvey = () => {
  return request({
    url: surveyAPI.api,
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const addSurvey = (data) => {
  return request({
    url: surveyAPI.api,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getNewSurveyName = (schoolId) => {
  return request({
    url: surveyAPI.getSurveyName(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const isSurveyClosed = (surveyToken) => {
  return request({
    url: surveyClosedAPI.isSurveyClosed(surveyToken),
    method: 'Get',
  })
    .then((response) => response)
    .catch((error) => error);
};

const updateSurvey = (data) => {
  return request({
    url: surveyAPI.api,
    method: 'PUT',
    data: data,
  }).then((response) => response);
};

const closeSurvey = (surveyId) => {
  return request({
    url: surveyResultAPI.closeSurvey(surveyId),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((error) => error);
};

const closeSurveyWithToken = (surveyToken, schoolToken) => {
  schoolToken = schoolToken === null ? '' : schoolToken;
  return request({
    url: surveyResultAPI.closeSurveyWithToken(surveyToken, schoolToken),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((error) => error);
};

const reOpenSurvey = (surveyId) => {
  return request({
    url: surveyResultAPI.reOpenSurvey(surveyId),
    method: 'PUT',
  });
};

const addSurveyCustomComments = (data) => {
  return request({
    url: surveyAPI.addSurveyComments(),
    method: 'PUT',
    data,
  });
};

export const getLatestSurvey = (schoolId) => {
  return request({
    url: surveyAPI.getLatestSurvey(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const getNewMatSurveyName = (MatGroupId) => {
  return request({
    url: surveyAPI.getNewMatSurveyName(MatGroupId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const archiveSurvey = (surveyId) => {
  return request({
    url: surveyAPI.archiveSurvey(surveyId),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const checkAddtionalStaffInfo = (token) => {
  return request({
    url: surveyAPI.checkAddtionalStaffInfo(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getSurveyByToken = (token) => {
  return request({
    url: surveyAPI.getSurveyByToken(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const hasAddtionalQuestionFiltersIncluded = (schoolId) => {
  return request({
    url: surveyAPI.hasAddtionalQuestionFiltersIncluded(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getSurveyNameAndTokenBySchool = (surveyId) => {
  return request({
    url: surveyAPI.getSurveyNameAndTokenBySchool(surveyId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const hasSurveyStarted = (surveyToken) => {
  return request({
    url: surveyAPI.hasSurveyStarted(surveyToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const IsSchoolSurveyLive = (schoolId) => {
  return request({
    url: surveyAPI.IsSchoolSurveyLive(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const surveyService = {
  IsSchoolSurveyLive,
  getSurvey,
  getSchoolSurveys,
  addSurvey,
  updateSurvey,
  closeSurvey,
  closeSurveyWithToken,
  getQuestions,
  postSurvey,
  getIndividualQuestionResults,
  getSurveysNameAndToken,
  isSurveyClosed,
  addSurveyCustomComments,
  reOpenSurvey,
  getNewSurveyName,
  getLatestSurvey,
  getSurveyByToken,
  getNewMatSurveyName,
  archiveSurvey,
  checkAddtionalStaffInfo,
  getSurveyNameAndTokenBySchool,
  hasAddtionalQuestionFiltersIncluded,
  hasSurveyStarted,
};

export default surveyService;
