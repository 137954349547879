import React, { useEffect, useState } from 'react';
import UpgradeAccountButton from '../../components/common/UpgradeAccountButton';
import AddVoiceDashboardButton from '../../views/welbeeVoice/AddVoiceDashboardButton';
import { welbeeVoiceService, storageService } from '../../services';
import { WebStorageNames, WelbeeType, HeadTeacherAccess } from '../../utils';
import SurveyVoiceDropDown from './SurveyVoiceDropDown';

const VoiceOptions = ({
  addVoiceButtonClick,
  showConversationDropDown,
  onChange,
  latestSurveyOption,
  isFirstConverstaion,
}) => {
  let [conversationsList, setConversationsList] = useState([]);
  let [welbeeType] = useState(
    storageService.getItem(WebStorageNames.WelbeeType)
  );
  welbeeType = 1;
  const [accessLevel] = useState(
    storageService.getItem(WebStorageNames.AccessLevel)
  );
  useEffect(() => {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    welbeeVoiceService
      .getSurveyVoiceNameAndToken(schoolId)
      .then((response) => {
        if (response.success) {
          setConversationsList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {welbeeType === WelbeeType.WelbeeVoice && <UpgradeAccountButton />}
      {showConversationDropDown && (
        <SurveyVoiceDropDown
          conversationsList={conversationsList}
          onChange={onChange}
          latestSurveyOption={latestSurveyOption}
        />
      )}
      {accessLevel === HeadTeacherAccess.Full && (
        <AddVoiceDashboardButton
          onClick={addVoiceButtonClick}
          isFirstConverstaion={isFirstConverstaion}
        />
      )}
    </div>
  );
};

export default VoiceOptions;
