import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AppButton } from '../../resources/styling';
import { WebStorageNames } from '../../utils/Constants';
import { isEmpty } from 'lodash';
import { loaderAction } from '../../store/loaderStore';
import { amplitudeLog } from '../../utils/CommonFunctions';
import {
  Header,
  FloatingInputLabel,
  FloatinLabelDropDown,
  AnalyticsDropdown,
} from '../../components';
import {
  ErrorWrapper,
  FieldWrapper,
  WelbeeTextField,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { accountService, schoolService, storageService } from '../../services';
import { PhaseOfSchool, SectorItems } from '../../utils';
import { ExtendedWrapperMain } from '../../resources/styling/formStyle';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import { RegisterInner } from './AccountStyle';
import { withTranslation } from 'react-i18next';

const { mandy } = colors;

class AccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SchoolRecord: null,
      phaseOfSchool: PhaseOfSchool,
      sector: SectorItems,
      selectedPhaseOfSchool: { value: '', label: 'Phase of school' },
      selectedSector: { value: '', label: 'Sector' },
      emailErrorMessage: '',
      duplicateSchoolErrorMessage: '',
      passwordValidation: '',
      IsUpdateEnabled: false,
      schoolLogoUrl: '',
    };
    this.splitName = this.splitName.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  splitName = (fileName) => {
    var splitFileName = fileName.split('.');
    var imageName = splitFileName[0];
    var trancateString =
      imageName.length > 23
        ? imageName.substr(imageName, 23, 0) + '...'
        : fileName;
    return trancateString;
  };
  onChangeHandler = (event) => {
    console.log(event.target.files[0]);
  };
  handleDropDownChange = (option) => {
    this.setState({ selectedPhaseOfSchool: option });
  };

  duplicationErrorMeassageHandle = (message) => {
    if (message === 'Email is already in use, please choose another email.') {
      this.setState({
        emailErrorMessage: message,
        duplicateSchoolErrorMessage: '',
      });
    } else if (message === 'School name already exist.') {
      this.setState({
        duplicateSchoolErrorMessage: message,
        emailErrorMessage: '',
      });
    }
  };

  componentWillReceiveProps(props) {
    if (props.SchoolRecord) {
      let getPhaseofSchool = PhaseOfSchool.find(
        (o) => o.value === props.SchoolRecord.school.PhaseOfSchool
      );
      let getSector = SectorItems.find(
        (o) => o.value === props.SchoolRecord.school.Sector
      );
      if (isEmpty(getPhaseofSchool)) getPhaseofSchool = PhaseOfSchool[0];
      if (isEmpty(getSector)) getSector = SectorItems[0];

      this.setState({
        SchoolRecord: props.SchoolRecord,
        selectedPhaseOfSchool: getPhaseofSchool,
        selectedSector: getSector,
        schoolLogoUrl:
          props.SchoolRecord && props.SchoolRecord.school.Logo
            ? props.SchoolRecord.school.Logo.FileUrl
            : '',
        isLogoUpdated: false,
      });
    }
  }

  render() {
    const { t } = this.props;
    const {
      SchoolRecord,
      selectedPhaseOfSchool,
      IsUpdateEnabled,
      schoolLogoUrl,
      isLogoUpdated,
    } = this.state;
    // let schoolLogoUrl =
    //   SchoolRecord && SchoolRecord.school.Logo
    //     ? SchoolRecord.school.Logo.FileUrl
    //     : '';
    return (
      <Formik
        enableReinitialize
        initialValues={{
          Id: SchoolRecord ? SchoolRecord.school.Id : 0,
          Name: SchoolRecord
            ? SchoolRecord.school.Name != null
              ? SchoolRecord.school.Name
              : ''
            : '',
          Address: SchoolRecord
            ? SchoolRecord.school.Address != null
              ? SchoolRecord.school.Address
              : ''
            : '',
          PhaseOfSchool: this.state.selectedPhaseOfSchool.value,
          Sector: this.state.selectedSector.value,
          File: '',
          NumberOfPupil: SchoolRecord
            ? SchoolRecord.school.NumberOfPupil != null
              ? SchoolRecord.school.NumberOfPupil
              : ''
            : '',
          FileName: SchoolRecord
            ? SchoolRecord.school.Logo != null
              ? SchoolRecord.school.Logo.FileName
              : ''
            : '',
          TrancateFileName: SchoolRecord
            ? SchoolRecord.school.Logo != null
              ? this.splitName(SchoolRecord.school.Logo.FileName)
              : ''
            : '',
          IsLogoUpdate: true,
        }}
        onSubmit={(values, actions) => {
          console.log(values);

          const { loaderActions } = this.props;
          schoolService
            .updateSchoolDetailOnly(values)
            .then((response) => {
              amplitudeLog('Account', 'SchoolInfoUpdated', {
                SchoolName: this.props.SchoolRecord.Name,
              });
              if (response.success) {
                toast.success(
                  'School information has been updated successfully.'
                );
                if (values.File) {
                  const logo = `${
                    SchoolRecord.school.Logo.FileUrl.split('?')[0]
                  }?fileName=${response.data}`;

                  this.setState({
                    schoolLogoUrl: logo,
                    isLogoUpdated: true,
                  });

                  storageService.setItem(WebStorageNames.schoolLogoUrl, logo);
                  this.props.updateData(logo);
                  //window.location.reload();
                } else {
                  loaderActions.dataLoading(false);
                }
              } else {
                loaderActions.dataLoading(false);
                this.duplicationErrorMeassageHandle(response.message);
              }
            })
            .catch((error) => {
              loaderActions.dataLoading(false);
            });
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={Yup.object().shape({
          Name: Yup.string()
            .trim()
            //.max(50, 'School name must be at most 50 characters.')
            .required('School name is required.'),
          Address: Yup.string()
            .trim()
            .required(t('zip_code') + ' is required'),
          PhaseOfSchool: Yup.string()
            .trim()
            .min(1, 'Phase of school is required.')
            .required('Phase of school is required.'),
          Sector: Yup.string()
            .trim()
            .min(1, 'Sector is required.')
            .required('Sector is required.'),
          NumberOfPupil: Yup.number()
            .max(10000, 'Max number is 10000')
            .min(1, 'Enter number greater than 0')
            .integer()
            .required('Number of pupils is required.'),
        })}
        render={({
          values,
          handleChange,
          touched,
          errors,
          handleSubmit,
          setFieldValue,
        }) => (
          <div>
            <Header />

            <h2> School information</h2>
            <div>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label={t('SchoolName')}
                      name='School/Trust name'
                      value={values.Name}
                      onChange={(e) => {
                        setFieldValue('Name', e.target.value);
                        this.setState({ IsUpdateEnabled: true });
                      }}
                    />
                    <ErrorWrapper>
                      {(touched.Name && errors.Name) ||
                        this.state.duplicateSchoolErrorMessage}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={6} xs={12}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label='Number of pupils'
                      name='Number of pupils'
                      value={values.NumberOfPupil}
                      onChange={(e) => {
                        setFieldValue('NumberOfPupil', e.target.value);
                        this.setState({ IsUpdateEnabled: true });
                      }}
                      type='number'
                    />
                    <ErrorWrapper>
                      {touched.NumberOfPupil && errors.NumberOfPupil}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={6} xs={12} style={{ paddingTop: '0' }}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label={t('zip_code')}
                      name='Postcode'
                      value={values.Address}
                      onChange={(e) => {
                        setFieldValue('Address', e.target.value);
                        this.setState({ IsUpdateEnabled: true });
                      }}
                    />
                    <ErrorWrapper>
                      {touched.Address && errors.Address}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={6} xs={12} style={{ paddingTop: '0' }}>
                  <ExtendedWrapperMain>
                    <div className='secondary-style'>
                      <AnalyticsDropdown
                        name='PhaseOfSchool'
                        label='Phase'
                        placeholder='Phase'
                        options={PhaseOfSchool}
                        //selectedOption={selectedPhaseOfSchool}
                        value={selectedPhaseOfSchool.value}
                        onChange={(option) => {
                          this.setState({
                            selectedPhaseOfSchool: option.target,
                          });
                          setFieldValue('PhaseOfSchool', option.target);
                          this.setState({ IsUpdateEnabled: true });
                        }}
                      />
                    </div>

                    <ErrorWrapper>
                      {touched.PhaseOfSchool && errors.PhaseOfSchool}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={6} xs={12} style={{ paddingTop: '0' }}>
                  <ExtendedWrapperMain>
                    <div className='secondary-style'>
                      <AnalyticsDropdown
                        name='Sector'
                        label='Sector'
                        options={SectorItems}
                        //selectedOption={this.state.selectedSector}
                        value={values.Sector}
                        onChange={(option) => {
                          this.setState({ selectedSector: option.target });
                          setFieldValue('Sector', option.target);
                          this.setState({ IsUpdateEnabled: true });
                        }}
                      />
                    </div>
                    <ErrorWrapper>
                      {touched.Sector && errors.Sector}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={6} xs={12} style={{ paddingTop: '0' }}>
                  <ExtendedWrapperMain>
                    <FieldWrapper className=''>
                      <div className='box'>
                        <input
                          type='file'
                          name='file-7[]'
                          id='file-7'
                          accept='image/x-png,image/jpeg'
                          className='inputfile inputfile-6'
                          data-multiple-caption='{count} files selected'
                          onChange={(event) => {
                            var files = event.target.files;
                            var file = files[0];
                            var trancateString = this.splitName(file.name);
                            if (files && file) {
                              var reader = new FileReader();
                              reader.onload = function (readerEvt) {
                                var binaryString = readerEvt.target.result;
                                setFieldValue('File', btoa(binaryString));
                                setFieldValue('FileName', file.name);
                                setFieldValue(
                                  'TrancateFileName',
                                  trancateString
                                );
                              };
                              this.setState({
                                IsUpdateEnabled: true,
                              });
                              reader.readAsBinaryString(file);
                            }
                          }}
                        />
                        <label>
                          <span className='no-file-upload '>
                            {values.TrancateFileName
                              ? values.TrancateFileName
                              : 'Upload a logo'}
                          </span>
                          <strong>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='20'
                              height='17'
                              viewBox='0 0 20 17'
                            >
                              <path d='M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z' />
                            </svg>{' '}
                          </strong>
                        </label>
                      </div>
                    </FieldWrapper>
                    {schoolLogoUrl &&
                    (isEmpty(values.File) || isLogoUpdated) ? (
                      <div className='accountImage'>
                        <img src={schoolLogoUrl} alt='school logo' />
                      </div>
                    ) : (
                      <Fragment />
                    )}
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={6} xs={12} style={{ paddingTop: '0' }}>
                  <ExtendedWrapperMain>
                    <FieldWrapper>
                      <AppButton
                        color={IsUpdateEnabled ? mandy : ''}
                        type='submit'
                        onClick={IsUpdateEnabled ? handleSubmit : {}}
                        style={{ marginTop: '0' }}
                      >
                        Update
                      </AppButton>
                    </FieldWrapper>
                  </ExtendedWrapperMain>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withTranslation()(AccountForm)));
