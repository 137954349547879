import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { LeftMenu, MultiSelectDropDown, MainHeader } from '../../components';
import { colors } from '../../resources/theme/colors';
import { toast } from 'react-toastify';
import { routesObj } from '../../routes';
import { loaderAction } from '../../store/loaderStore';
import { getQueryStringVal, isVoicePaid } from '../../utils/CommonFunctions';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import {
  AssistanceWrapperHeading,
  TableStyle,
} from '../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import {
  welbeeVoiceService,
  storageService,
  schoolService,
  schoolStaffService,
  staffGroupService,
} from '../../services';
import { WebStorageNames, QueryStringTokens, WelbeeType } from '../../utils';
import { WrapperMain } from '../../resources/styling/formStyle';
import { CheckBox, AppButton } from '../../resources/styling/appStyle';
import { filterIcon, nameSwap } from '../../resources/images/index';
import { WelbeeTypes } from '../../utils/Constants';
import {
  ExtendedAssistanceWrapper,
  ExtendedVoiceNotificationWrapper,
  ConversationBody,
  EmailListBody,
  DropDownWrapper,
} from './WelbeeVoiceStyle';
const { mandy, teal, whiteColor } = colors;

function WelbeeVoiceNotification(props) {
  const [staffList, setStaffList] = useState([]);
  const [filteredStaffList, setFilteredStaffList] = useState([]);
  const [converstion, setConversation] = useState(null);
  const [schoolId] = useState(storageService.getItem(WebStorageNames.SchoolId));
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const [isSendLinkEnable, setSendLinkEnable] = useState(false);
  const [isConfirmInvitation, setIsConfirmInvitation] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [orderInAsc, setOrderInAsc] = useState(true);
  const [isFeePaid, setFeePaid] = useState(false);
  const [swapName, toggleName] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(
    storageService.getItem(WebStorageNames.SubscriptionId)
  );

  useEffect(() => {
    loadSchoolStaffList();
    loadStaffGroupList();
    const voiceId = parseInt(getQueryStringVal(QueryStringTokens.voiceId));
    if (!isNaN(voiceId) && voiceId > 0) {
      loadWelbeeVoice(voiceId);
    } else if (voiceId === 0) {
      const tempVoiceRecord = storageService.getItem(WebStorageNames.TempVoice);
      setConversation(tempVoiceRecord);
    }
  }, []);

  function loadSchoolStaffList() {
    props.loaderActions.dataLoading(true);

    const schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    schoolService
      .getSchoolDetail(schoolId, schoolToken)
      .then((response) => {
        if (response.data.school !== null) {
          const schoolRecord = response.data.school;
          if (
            subscriptionId === WelbeeTypes.WelbeeSurveyPremium ||
            subscriptionId === WelbeeTypes.WelbeeSurveyPro ||
            subscriptionId === WelbeeTypes.WelbeeVoicePro
          )
            setFeePaid(true);
          else setFeePaid(false);
        }
      })
      .catch((error) => console.log(error));

    schoolService
      .getSchoolStaff(schoolId)
      .then((response) => {
        props.loaderActions.dataLoading(false);
        const { data, success } = response;
        const transformedStaffList = [];
        if (success) {
          data.map((y) => {
            const item = y;
            item.IsChecked = false;
            transformedStaffList.push(item);
          });
          setStaffList(transformedStaffList);
          sortStaffList(false, transformedStaffList);

          console.log('transformedStaffList', transformedStaffList);
          setFilteredStaffList(transformedStaffList);
        }
      })
      .catch((error) => {
        setStaffList([]);
        props.loaderActions.dataLoading(false);
      });
  }

  function loadStaffGroupList() {
    staffGroupService
      .getAllGroupsBySchoolId(schoolId)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          data.sort((a, b) => a.JobTitle.localeCompare(b.JobTitle));
          var multiDD_list = data.map((value, i) => ({
            key: value.JobTitle,
            Id: value.Id,
            cat: i,
          }));

          const ddList = multiDD_list.reduce(function (acc, cur) {
            const existingItem = acc.find((item) => cur.key === item.key);
            if (existingItem) {
              existingItem.count++;
            } else {
              acc.push({ ...cur, count: 1 });
            }
            return acc;
          }, []);
          console.log('multiDD_list', ddList);
          setGroupList(ddList);
        }
      })
      .catch((error) => {
        setGroupList([]);
      });
  }

  function onSelect(selectedList, selectedItem) {
    const updatedList = [];
    let _break = false;
    staffList.map((x) => {
      _break = false;
      x.forEach((groups) => {
        if (_break || groups.SchoolStaffs.CoreFilters == null) return true;
        else
          selectedList.map((y) => {
            if (
              groups.SchoolStaffs.CoreFilters.some((x) => x.JobTitle === y.key)
            ) {
              x[0].SchoolStaffs.IsChecked = true;
              updatedList.push(x);
              _break = true;
            }
          });
      });
    });
    console.log('updatedList', updatedList);
    const unique = [
      ...new Map(updatedList.map((item) => [item[0], item])).values(),
    ];

    if (unique.length > 0) {
      let updatedSelectedList = [];
      updatedSelectedList = unique.map((y) => {
        return y[0].SchoolStaffs.Id;
      });
      console.log('updatedSelectedList', updatedSelectedList);
      setSelectedStaffIds(updatedSelectedList);
      converstion.Id > 0
        ? setSendLinkEnable(updatedSelectedList.length > 0)
        : setSendLinkEnable(updatedSelectedList.length >= 8);
    }
    if (unique.length > 0) {
      setFilteredStaffList(unique);
    } else {
      const tempList = [];
      staffList.map((y) => {
        y[0].SchoolStaffs.IsChecked = false;
        tempList.push(y);
      });
      setSelectedStaffIds([]);
      setSendLinkEnable(false);
      setFilteredStaffList(tempList);
    }
  }

  function loadWelbeeVoice(id) {
    welbeeVoiceService
      .getSurveyVoice(id)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          setConversation(data);
        }
      })
      .catch((error) => {
        setConversation(null);
      });
  }

  function staffCheckboxChange(staffId, isChecked) {
    const staffIdsList = selectedStaffIds;
    const updatedStaffList = [];
    if (isChecked) {
      staffIdsList.push(staffId);
      setSelectedStaffIds(staffIdsList);
      converstion.Id > 0
        ? setSendLinkEnable(staffIdsList.length >= 1)
        : setSendLinkEnable(staffIdsList.length >= 8);
      filteredStaffList.map((y) => {
        if (y[0].SchoolStaffs.Id === staffId)
          y[0].SchoolStaffs.IsChecked = true;
        updatedStaffList.push(y);
      });
      setFilteredStaffList(updatedStaffList);
    } else {
      // remove from the list
      const filteredStaffIdsList = staffIdsList.filter((x) => x !== staffId);
      setSelectedStaffIds(filteredStaffIdsList);
      converstion.Id > 0
        ? setSendLinkEnable(filteredStaffIdsList.length >= 1)
        : setSendLinkEnable(filteredStaffIdsList.length >= 8);
      filteredStaffList.map((y) => {
        if (y[0].SchoolStaffs.Id === staffId)
          y[0].SchoolStaffs.IsChecked = false;
        updatedStaffList.push(y);
      });
      setFilteredStaffList(updatedStaffList);
    }
  }

  function onSendManualLinkClick(conversationId) {
    const { loaderActions } = props;
    if (selectedStaffIds.length > 0) {
      setIsConfirmInvitation(false);
      loaderActions.dataLoading(true);
      schoolStaffService
        .ManuallySendLink({
          SchoolStaffIds: selectedStaffIds,
          WelbeeType: 1,
          SchoolId: schoolId,
          SelectedConversation: conversationId,
          SelectedSurvey: null,
        })
        .then((response) => {
          loaderActions.dataLoading(false);
          if (converstion.Id === 0) {
            storageService.removeItem(WebStorageNames.TempVoice);
            props.history.push(routesObj.WelbeeVoice.path);
          }
          if (response.success) {
            toast.success('Invitations sent successfully.');

            props.history.push(routesObj.WelbeeVoice.path);
          }
        })
        .catch((error) => {
          loaderActions.dataLoading(false);
        });
    } else {
      toast.error('No staff selected.');
    }
  }

  function createConverstion() {
    const { loaderActions } = props;
    converstion.SchoolId = schoolId;
    loaderActions.dataLoading(true);
    welbeeVoiceService
      .createConverstion(converstion)
      .then((response) => {
        if (response.success) {
          onSendManualLinkClick(response.data.Id);
        }
      })
      .catch((error) => {
        console.log(error);
        loaderActions.dataLoading(false);
      });
  }

  function selectAllStaff(isChecked) {
    const updatedStaffList = [];
    if (isChecked) {
      setSelectedStaffIds(filteredStaffList.map((a) => a[0].SchoolStaffs.Id));
      setSendLinkEnable(filteredStaffList.length >= 8);
    } else {
      setSelectedStaffIds([]);
      setSendLinkEnable(false);
    }
    filteredStaffList.map((y) => {
      y[0].SchoolStaffs.IsChecked = isChecked;
      updatedStaffList.push(y);
    });
    setFilteredStaffList(updatedStaffList);
  }

  function sortStaffList(prepForNext = false, data = false) {
    let sortedStaffList = data.sort((first, second) =>
      first[0].SchoolStaffs.LastName != null &&
      second[0].SchoolStaffs.LastName != null
        ? first[0].SchoolStaffs.LastName.toLowerCase() >
          second[0].SchoolStaffs.LastName.toLowerCase()
          ? 1
          : -1
        : first[0].SchoolStaffs.FirstName.toLowerCase() >
          second[0].SchoolStaffs.FirstName.toLowerCase()
        ? 1
        : -1
    );
    prepForNext
      ? !orderInAsc
        ? setFilteredStaffList(sortedStaffList)
        : setFilteredStaffList(sortedStaffList.reverse())
      : orderInAsc
      ? setFilteredStaffList(sortedStaffList)
      : setFilteredStaffList(sortedStaffList.reverse());

    if (prepForNext) setOrderInAsc(!orderInAsc);
  }

  function sortStaffListByFirstName(prepForNext = false, data = false) {
    let sortedStaffList = data.sort((first, second) =>
      first[0].SchoolStaffs.FirstName.toLowerCase() >
      second[0].SchoolStaffs.FirstName.toLowerCase()
        ? 1
        : -1
    );
    prepForNext
      ? !orderInAsc
        ? setStaffList(sortedStaffList)
        : setStaffList(sortedStaffList.reverse())
      : orderInAsc
      ? setStaffList(sortedStaffList)
      : setStaffList(sortedStaffList.reverse());

    if (prepForNext) setOrderInAsc(!orderInAsc);
  }

  function onConfirmButtonPress(converstion) {
    if (converstion.Id === 0) {
      // Need to add conversation first.
      createConverstion();
    } else {
      onSendManualLinkClick(converstion.Id);
    }
  }
  return (
    <ExtendedVoiceNotificationWrapper>
      <div className='left-container'>
        <LeftMenu />
      </div>

      <ConfirmPopupForm
        isOpen={isConfirmInvitation}
        onClose={() => setIsConfirmInvitation(false)}
        onOk={() => onConfirmButtonPress(converstion)}
        successPopupMessage={
          'You are about to send your invitation to (' +
          selectedStaffIds.length +
          ') staff members.'
        }
        okButtonText='Confirm'
      />

      <div className='right-container'>
        <MainHeader />
        <ExtendedAssistanceWrapper>
          <Grid container>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <AssistanceWrapperHeading>
                <span className='comment-heading'>
                  Welbee voice notification
                </span>
              </AssistanceWrapperHeading>

              <div className='voice-direction-text'>
                <p>
                  Select which staff to invite below. Choose a group by clicking
                  in the ‘Select Group box’ and choose from the options shown.
                  You can select one or more groups. For all new conversations,
                  there must be a total of at least 8 staff members across the
                  groups you select to maintain anonymity and staff confidence.
                  You can add individuals or smaller groups to an existing
                  conversation.
                </p>
                <p>
                  Alternatively, you can select individual staff using the check
                  boxes.
                </p>
              </div>
            </Grid>

            <Grid item lg={3} md={3} sm={12} xs={12}>
              <Link to='/welbee-voice' className='back-btn'>
                Go back to conversations
              </Link>
            </Grid>
          </Grid>
        </ExtendedAssistanceWrapper>

        <ConversationBody>
          <div className='voice-body-inner-wrapper upper-list-wrapper'>
            {converstion !== null && (
              <ul key={converstion.Id} className='voice-list'>
                <li>
                  <span style={{ color: mandy, fontWeight: 'bold' }}>
                    {converstion.Title}
                  </span>
                  <DropDownWrapper>
                    {
                      //isFeePaid && ()
                      <MultiSelectDropDown
                        data={groupList}
                        onSelect={onSelect}
                        onRemove={onSelect}
                      />
                    }
                    <AppButton
                      className={
                        isSendLinkEnable ? 'normal-btn' : 'disable-btn'
                      }
                      onClick={
                        isSendLinkEnable
                          ? () => setIsConfirmInvitation(true)
                          : {}
                      }
                      style={{ marginTop: 0, marginBottom: 0, float: 'right' }}
                    >
                      Send invitation
                    </AppButton>
                  </DropDownWrapper>
                </li>
              </ul>
            )}
            {staffList.length === 0 ? (
              <p className='staff-member-link'>
                <Link to='/staff-management'>
                  Please add your staff members details
                </Link>
              </p>
            ) : converstion === null ? (
              <p> No welbee voice conversation selected</p>
            ) : null}
          </div>

          <EmailListBody className='content-body-inner-wrapper voice-body-inner-wrapper list-wrapper'>
            <div className='uploadDirections'>
              You must select a minimum of 8 staff members to be able to send an
              invitation for a{' '}
              <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                new
              </span>{' '}
              conversation.
            </div>
            <TableStyle style={{ marginTop: '30px' }}>
              <table>
                <thead>
                  <tr>
                    <td>
                      <span className='list-checkbox'>
                        <WrapperMain style={{ marginBottom: '0px' }}>
                          <CheckBox className='list-chkbox-inner'>
                            <input
                              class='styled-checkbox'
                              type='checkbox'
                              onChange={(e) => {
                                selectAllStaff(e.target.checked);
                              }}
                            />
                            <label for='styled-checkbox-1'></label>
                          </CheckBox>
                        </WrapperMain>
                      </span>
                    </td>
                    <td className='list-heading'>
                      Name
                      <AppButton
                        type='submit'
                        onClick={() => {
                          !swapName
                            ? sortStaffList(true, staffList)
                            : sortStaffListByFirstName(true, staffList);
                        }}
                        className='sorting-name'
                      >
                        <img src={filterIcon} alt='sorting icon' />
                        <span className='tooltiptext'>
                          Sort Names A-Z or Z-A
                        </span>
                      </AppButton>
                      <AppButton
                        style={{ marginLeft: '23px' }}
                        type='submit'
                        onClick={() => toggleName(!swapName)}
                        className='sorting-name'
                      >
                        <img src={nameSwap} alt='Swap icon' />
                        <span className='tooltiptext'>
                          Show by First Name / Last Name
                        </span>
                      </AppButton>{' '}
                    </td>

                    <td className='list-heading'>Email</td>
                    <td className='list-heading'>{true ? 'Group' : ''}</td>
                    <td className='list-heading list-action'></td>
                  </tr>
                </thead>
                {filteredStaffList.length > 0 &&
                  filteredStaffList.map((item) => (
                    <tr>
                      <td>
                        <span className='list-checkbox'>
                          <WrapperMain style={{ marginBottom: '0px' }}>
                            <CheckBox className='list-chkbox-inner'>
                              <input
                                class='styled-checkbox'
                                type='checkbox'
                                checked={item[0].SchoolStaffs.IsChecked}
                                onChange={(e) => {
                                  staffCheckboxChange(
                                    item[0].SchoolStaffs.Id,
                                    e.target.checked
                                  );
                                }}
                              />
                              <label for='styled-checkbox-1'></label>
                            </CheckBox>
                          </WrapperMain>
                        </span>
                      </td>
                      <td>
                        <td className='list-heading'>
                          {!swapName
                            ? (item[0].SchoolStaffs.LastName
                                ? item[0].SchoolStaffs.LastName + ', '
                                : '') + item[0].SchoolStaffs.FirstName
                            : item[0].SchoolStaffs.FirstName +
                              (item[0].SchoolStaffs.LastName
                                ? ' ' + item[0].SchoolStaffs.LastName
                                : '')}
                        </td>
                      </td>
                      <td>{item[0].SchoolStaffs.Email}</td>
                      {true ? (
                        <td>
                          {item[0].SchoolStaffs.CoreFilters.map(
                            (innerItem) => innerItem.JobTitle
                          ).join(', ')}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td></td>
                    </tr>
                  ))}
              </table>
            </TableStyle>
          </EmailListBody>
        </ConversationBody>
      </div>
    </ExtendedVoiceNotificationWrapper>
  );
}

const mapStateToProps = ({ user: { isBellNotification } }) => {
  return {
    isBellNotification,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WelbeeVoiceNotification));
