import { matStaffApi } from './urls';
import request from '../request';



export const getStaff = (model) => {
  return request({
    url: matStaffApi.getStaff,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const UploadMatExcel = (model) => {
  return request({
    url: matStaffApi.UploadMatExcel,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((response) => response);
};

const exportMatExcelTemplate = (matId) => {
  return request({
    url: matStaffApi.exportMatExcelTemplate(matId),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};

const exportMatExcelStaff = (matId) => {
  return request({
    url: matStaffApi.exportMatExcelStaff(matId),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};

const ValidateMatExcel = (model) => {
  return request({
    url: matStaffApi.ValidateMatExcel,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const matSchools = (matId) => {
  return request({
    url: matStaffApi.matSchools(matId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const grantAccessMATLevel = (model) => {
  console.log('service ok')
  return request({
    url: matStaffApi.grantAccessMATLevel,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const matStaffService = {
  UploadMatExcel,
  getStaff,
  exportMatExcelTemplate,
  exportMatExcelStaff,
  ValidateMatExcel,
  matSchools,
  grantAccessMATLevel
};



export default matStaffService;
