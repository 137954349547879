import React from 'react';
import { WelbeeTextArea } from '../../resources/styling/appStyle';

const FloatingLabelTextArea = ({
  name,
  maxLength,
  onBlur,
  value,
  onChange,
  onFocus,
  label,
  textAreaLabel
}) => (
    <WelbeeTextArea   
        fullWidth     
        name={name}
        onBlur={onBlur}
        value={value}
        label={label}
        placeholder={name}
        maxLength={maxLength}
        onChange={onChange}
        multiline
        rows={4}
        onFocus={onFocus}
      />
);

export default FloatingLabelTextArea;
