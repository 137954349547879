import React from 'react';
import { AppModel } from '../../components';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import {
  ModalInnerWrapper,
  NewSurveyInnerMain,
  NewSurveyInner,
  NewSurveyWrapper,
  AssitanceForm,
} from '../../resources/styling';
import Grid from '@mui/material/Grid';
import FooterButtons from '../../components/common/FooterButtons';
import { withRouter } from 'react-router-dom';
import { LoaderOverlay } from '../../resources/styling/appStyle';
import { ExtendedNewSurveyInputWrapper } from './MatStyle';

const CloseSurveyForm = (props) => {
  const {
    onSubmit,
    title,
    submitButtonText,
    isOpen,
    onClose,
    isLoading,
    selectAllSchool,
    matSchoolsGroup,
    schoolCheckboxChange,
    allSelected,
  } = props;

  return (
    <Formik
      initialValues={{
        chkschool: '',
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        onSubmit(values);
      }}
      render={({ handleSubmit }) => {
        return (
          <AppModel
            open={isOpen}
            onClose={onClose}
            bodyText={
              <ModalInnerWrapper>
                <NewSurveyWrapper>
                  <Grid container className='form-inner-wrapper'>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      md={12}
                      xs={12}
                      className='form-column-wrapper'
                    >
                      <NewSurveyInnerMain>
                        <NewSurveyInner>
                          <AssitanceForm>
                            <h2>{title}</h2>
                            {isLoading && (
                              <LoaderOverlay>
                                <div className='comment-loader'>
                                  <div id='loading' />
                                </div>
                              </LoaderOverlay>
                            )}
                            <ExtendedNewSurveyInputWrapper>
                              <p class='info-text'>
                                The following schools all have active surveys.
                                Select which ones you would like to close
                                immediately:
                              </p>
                              <div class='school-list-wrapper'>
                                <div className='school-list-heading'>
                                  <span>
                                    <input
                                      checked={allSelected}
                                      name='chkschool'
                                      type='checkbox'
                                      onChange={(e) => {
                                        selectAllSchool(
                                          e.target.checked,
                                          false
                                        );
                                      }}
                                    />
                                  </span>
                                  <span class='school-title'>Schools</span>
                                </div>
                                <div className='school-list-body-outer'>
                                  {matSchoolsGroup &&
                                    matSchoolsGroup.map((item) =>
                                      item.hasActiveSurvey ? (
                                        <div className='school-list-body'>
                                          <span>
                                            <input
                                              type='checkbox'
                                              checked={item.IsChecked}
                                              onChange={(e) => {
                                                schoolCheckboxChange(
                                                  item,
                                                  !item.IsChecked,
                                                  false
                                                );
                                              }}
                                            />
                                          </span>
                                          <span className='school-title'>
                                            {item.Name}
                                          </span>
                                        </div>
                                      ) : (
                                        ''
                                      )
                                    )}
                                </div>
                              </div>
                            </ExtendedNewSurveyInputWrapper>
                          </AssitanceForm>
                        </NewSurveyInner>
                      </NewSurveyInnerMain>
                    </Grid>
                  </Grid>
                </NewSurveyWrapper>
              </ModalInnerWrapper>
            }
            footer={
              <FooterButtons
                submitButtonText={submitButtonText}
                handleSubmit={() => {
                  handleSubmit();
                }}
                onClose={onClose}
              />
            }
          />
        );
      }}
    />
  );
};

export default withRouter(CloseSurveyForm);
