import React from 'react';

const LearnComments = () => {
  return (
    <div className='learnmore-positioning'>
      <p>
        You are provided with the anonymous comments of staff to two open
        questions asked. These are questions that have generated the best
        responses after testing. When clicking on ‘Comments’ in the menu you are
        taken to the responses to the first question and use the arrow by the
        question title to move to the second question.
      </p>
      <p>
        You can filter the comments by job role using the drop down menu across
        the top and also search them for any keywords you think may be important
        in your school, for example workload. Comments will provide you with
        further insight into why staff have scored as they have within the
        different Management Standards.
      </p>
      <p>
        Review the comments as a whole and look for trends and try not to react
        emotionally or take any as personal criticism, which isn’t always easy.
        It is just feedback and you may or may not want to take action. Whether
        true or not they are the perceptions of staff and it may also be the
        only way some of them will bring matters to your attention.
      </p>
      <p>
        Depending on when your survey was completed you may have the option to
        highlight comments using a flag, so you can quickly see any that you
        want to prioritise, and also be able to respond to them.
      </p>
      {/*<p>You can see an example of comments below.</p>
      <span className='report-pictures'>
        <img className='screenshots' src={commentimg} alt='' />
      </span>
      */}
      <p className='sub-heading'>Responding to staff comments</p>
      <p className='italic-text'>
        This will only be available for surveys run after 1st March 2020 and
        where staff invitations were sent through Welbee. If this is not
        available there will not be a reply arrow after each comment.
      </p>
      <p>
        You can respond to individual staff comments and address their concerns,
        provide specific feedback, ask for more information, and/or engage with
        them as needed. These responses and ongoing conversations will remain
        anonymous unless the staff member decides to reveal their identity.
      </p>
      <p>
        To respond to staff comments click on the reply arrow to the right of
        any comment where you would like to reply. A box opens and you simply
        type in your message and send. This will automatically email this to the
        person making the comment (you will not know who they are). They will
        have the opportunity to read and respond to you and if or when they do,
        this will update the comment.
      </p>
      <p>
        You will see a new message that has been received in your menu to the
        right of the word <i>Comment</i>. Then within ‘comments’, the message
        will be highlighted. You will need to click the message and this will
        open and you can choose to respond again if you wish.
      </p>
      <p>
        When receiving a message from you, your staff will be clearly told that
        this is anonymous and they have not been identified to provide them with
        the confidence needed should they want to provide further information
        without revealing their identity. We have found in some cases they will
        come forward and talk to you, though it is important this remains their
        choice.
      </p>
      <p>
        You can click on any comment at any time to open up and review any
        conversations between you and your staff members.
      </p>
      <p>
        The opportunity to read comments will be available to all those staff
        you have given full and viewing user rights.{' '}
      </p>
      <p>
        Those with full user rights will also be able to respond to staff
        comments. This means you can share the workload as a senior team or
        delegate this to specific team members.
      </p>
      <p>
        When a staff member reads a response they will know who has sent it –
        the name of the logged-in user will show above the response. However,
        the identity of the staff member will never be revealed unless they
        choose to do so.
      </p>
    </div>
  );
};

export default LearnComments;
