import React from 'react';
import { WelbeeTextField } from '../../resources/styling/appStyle';


const FloatingLabelInputField = ({
  id,
  name,
  value,
  type,
  onChange,
  children,
  onFocus,
  onClick,
  autoComplete,
  label,
  placeholder,
  onBlur,
}) => (
  <WelbeeTextField
      id={id}
      fullWidth      
      type={type}
      name={name ? name : null}
      label={label}
      placeholder={placeholder}
      value={value}
      autoComplete={autoComplete}
      onChange={onChange}
      children={children}
      onFocus={onFocus ? onFocus : null}
      onClick={onClick ? onClick : null}
      onBlur={onBlur}
    />  
);

export default FloatingLabelInputField;
