import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { amplitudeLog } from '../../utils/CommonFunctions';
import {
  AppButton,
  LoginFormWrapper,
  ErrorWrapper,
} from '../../resources/styling/appStyle';
import { loginNormalEye, loginShowEye } from '../../resources/images/index';
import { colors } from '../../resources/theme/colors';
import Grid from '@mui/material/Grid';
import { AppInputField, FloatingInputLabel } from '../../components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { accountService, storageService } from '../../services';
import { routesObj } from '../../routes';
import { loaderAction } from '../../store/loaderStore';
import { firstLoginAction } from '../../store/userStore';
import {
  WebStorageNames,
  Roles,
  PasswordReg,
  WelbeeTypes,
  toggleOption,
} from '../../utils/Constants';
import { parseJwt, getQueryStringVal } from '../../utils/CommonFunctions';
import { Redirect } from 'react-router-dom';
import {
  LoginFormInner,
  InputWrapper,
  FieldWrapper,
  PasswordViewIcon,
} from '../../components/common/loginStyle';
import { WrapperMain } from '../../resources/styling/formStyle';
import { usePermission } from 'react-permission-role';


const { mandy } = colors;

function RegisterAdminPassword(props) {
  let [isPasswordShow, setIsPasswordShow] = useState(true);
  let [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  let [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { setUser } = usePermission();

  useEffect(() => {
    amplitudeLog('PageView', 'Register_AdminPassword', '');
    storageService.clear();
  }, {});
  return (
    <Formik
      initialValues={{
        Email: props.Email,
        Password: '',
      }}
      onSubmit={(values, actions) => {
        setPasswordErrorMessage('');
        setIsButtonDisabled(true);
        const token = getQueryStringVal('token');
        const userId = getQueryStringVal('userid');
        props.loaderActions.dataLoading(true);
        setTimeout(() => {
          actions.setSubmitting(false);
          accountService
            .setPasswordForRegisteredSchool(values, token, userId)
            .then((response) => {
              const { success, message } = response;
              props.loaderActions.dataLoading(false);
              if (success) {
                storageService.setToken({
                  token: response.data.access_token,
                });
                storageService.setItem(
                  WebStorageNames.UserInfo,
                  response.data.userInfo
                );
                storageService.setItem(
                  WebStorageNames.SchoolId,
                  response.data.schoolId
                );
                storageService.setItem(
                  WebStorageNames.SchoolName,
                  response.data.schoolName
                );
                storageService.setItem(
                  WebStorageNames.AccessLevel,
                  response.data.AccessLevel
                );
                storageService.setItem(
                  WebStorageNames.IsStaff,
                  response.data.IsStaff
                );
                storageService.setItem(
                  WebStorageNames.IsFirstLogin,
                  response.data.IsFirstLogin
                );
                storageService.setItem(
                  WebStorageNames.FreeTrialEndDate,
                  response.data.FreeTrialEndDate
                );
                storageService.setItem(
                  WebStorageNames.IsVoiceFirstLogin,
                  response.data.IsVoiceFirstLogin
                );
                storageService.setItem(
                  WebStorageNames.IsUpgradeDisplay,
                  response.data.IsUpgradeDisplay
                );
                storageService.setItem(
                  WebStorageNames.WelbeeVoiceAccountType,
                  response.data.WelbeeVoiceAccountType
                );

                const token = storageService.getItem(WebStorageNames.Token);
                if (!token) {
                  return <Redirect to={routesObj.Login.path} />;
                }
                const userInfo = parseJwt(token);

                if (userInfo.role === Roles.SuperAdmin) {
                  props.history.push(routesObj.SuperAdminDashboard.path);
                }
                if (userInfo.role === Roles.HeadTeacher) {
                  amplitudeLog(
                    'HeadTeacherLogin',
                    response.data.schoolName + ' HeadTeacherLogin',
                    {
                      UserName: values.Email,
                      SchoolName: response.data.schoolName,
                    }
                  );
                  storageService.setItem(
                    WebStorageNames.SubscriptionId,
                    response.data.SubscriptionId
                  );
                  storageService.setItem(WebStorageNames.IsMat, false);
                  if (
                    response.data.SubscriptionId ===
                    WelbeeTypes.WelbeeVoicePro ||
                    response.data.SubscriptionId === WelbeeTypes.WelbeeVoiceFree
                  ) {
                    storageService.setItem(
                      WebStorageNames.SelectedTab,
                      toggleOption.WelbeeVoice
                    );
                    props.history.push(routesObj.WelbeeVoice.path);
                  } else {
                    props.history.push(
                      routesObj.Dashboard.path +
                      '?schoolToken=' +
                      response.data.SchoolToken
                    );
                  }
                }
                // Settings permissions
                setUser({
                  id: userInfo?.id,
                  roles: [userInfo?.role],
                  permissions: response?.data?.Permissions
                })
                amplitudeLog('Login', 'LoginSuccessful', {
                  UserName: values.Email,
                });
              } else {
                setPasswordErrorMessage(message);
                setIsButtonDisabled(false);

                amplitudeLog('Login', 'LoginFailed', {
                  UserName: values.Email,
                });
              }
            })
            .catch((error) => console.log(error));
        }, 1000);
      }}
      validateOnBlur={true}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        Password: Yup.string()
          .trim()
          .min(12, 'Password must be at least 12 characters.')
          .matches(
            PasswordReg,
            'Your password must contain at least 1 uppercase, lowercase, number and special character from @£!#$%^&+="\',-.'
          )
          .required('Password is required.'),
        ConfirmPassword: Yup.string()
          .trim()
          .oneOf([Yup.ref('Password'), null], 'Passwords must match.')
          .required('Confirm password is required.'),
      })}
      render={({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
      }) => (
        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.keyCode === 13) handleSubmit();
          }}
        >
          <LoginFormWrapper>
            {props.isEmailVerified ? (
              <LoginFormInner>
                <h2>Email verified</h2>
                <p>
                  Your Email is already verified. Please{' '}
                  <a
                    href
                    style={{
                      color: '#53B5E0',
                      cursor: 'pointer',
                    }}
                    onClick={() => props.history.push(routesObj.Login.path)}
                  >
                    login
                  </a>{' '}
                  to get access to your dashboard.
                </p>
              </LoginFormInner>
            ) : (
              <LoginFormInner>
                <h2>One last thing</h2>
                <p>
                  Please create your password to complete your registration and
                  go straight to your online dashboard.
                </p>

                <InputWrapper>
                  <WrapperMain>
                    <FloatingInputLabel
                      label='Password'
                      autoComplete='new-password'
                      type={isPasswordShow ? 'password' : 'text'}
                      name='Password'
                      value={values.Password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                      <img
                        src={isPasswordShow ? loginNormalEye : loginShowEye}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsPasswordShow(!isPasswordShow);
                        }}
                        alt=''
                      />
                    </PasswordViewIcon>
                    <ErrorWrapper>
                      {(touched.Password &&
                        errors.Password &&
                        errors.Password) ||
                        passwordErrorMessage}
                    </ErrorWrapper>
                  </WrapperMain>
                  <WrapperMain>
                    <FloatingInputLabel
                      label='Confirm Password'
                      autoComplete='new-password'
                      type={isPasswordShow ? 'password' : 'text'}
                      name='ConfirmPassword'
                      value={values.ConfirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                      <img
                        src={isPasswordShow ? loginNormalEye : loginShowEye}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsPasswordShow(!isPasswordShow);
                        }}
                        alt=''
                      />
                    </PasswordViewIcon>
                    <ErrorWrapper>
                      {touched.ConfirmPassword &&
                        errors.ConfirmPassword &&
                        errors.ConfirmPassword}
                    </ErrorWrapper>
                  </WrapperMain>

                  <FieldWrapper>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        lg={3}
                        sm={12}
                        xs={12}
                        md={12}
                        className='forget-password'
                      ></Grid>
                      <Grid item lg={9} sm={12} xs={12} md={12}>
                        <AppButton
                          color={mandy}
                          type='submit'
                          onClick={handleSubmit}
                          disabled={isButtonDisabled}
                          isDisable={isButtonDisabled}
                        >
                          Go to welbee
                        </AppButton>
                      </Grid>
                    </Grid>
                  </FieldWrapper>
                </InputWrapper>
              </LoginFormInner>
            )}
          </LoginFormWrapper>
        </form>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});
export default connect(
  null,
  mapDispatchToProps
)(withRouter(RegisterAdminPassword));
