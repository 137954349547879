import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import SchoolRegisterWrapper from '../../components/common/SchoolRegisterWrapper';
import RegisterDirectorPassword from './RegisterDirectorPassword';
import { loaderAction } from '../../store/loaderStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function RegisterDirector(props) {
  const [isBackButtonEnable, setIsBackButtonEnable] = useState(false);

  const onBackButtonPress = () => {
    setIsBackButtonEnable(false);
  };
  return (
    <SchoolRegisterWrapper
      onBack={isBackButtonEnable ? onBackButtonPress : null}
    >
      <RegisterDirectorPassword />
    </SchoolRegisterWrapper>
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(RegisterDirector));
