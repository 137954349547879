import React from 'react';

const LearnRunning = () => {
  return (
    <div>
      <p>
      There are a number of steps you can take to make sure your survey is set up effectively 
      and achieves a high participation rate.
      </p>
      <p>
      Before issuing the survey, it is important to plan when and how you will ask staff to take part, when and how findings will be fed 
      back to them and how you will engage them in agreeing actions moving forward. This will help to ensure tackling staff 
      wellbeing is part of a coherent plan and one that becomes just part of what you do, rather than a series of one-off 
      activities or a reaction to specific events that address symptoms rather than causes.
      </p>
      <p>
      You can run your survey directly from your dashboard by issuing invitations to each staff member. To do this will require you to upload brief details, including e-mail addresses. 
      Instructions on how to do this are included in ‘Issuing Survey Invitations’ below.
      </p>
      <p>
      You can also choose to provide the survey link directly to staff through your own e-mail or approach. 
      This means you don’t need to upload staff information, though it will mean you cannot respond to staff comments 
      and won’t benefit from automatic reminders to staff who have not yet completed the survey.
      </p>
    </div>
  );
};

export default LearnRunning;
