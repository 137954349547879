import React, { Component } from 'react';
import { LeftMenu } from '../../components';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { AssistanceWrapper } from '../../resources/styling/appStyle';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import { ExtendedVoiceDashboardWrapper, AdminDashboardBody } from './SchoolSubscriptionsStyle';

export default class SubscriptionNotAvailable extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <ExtendedVoiceDashboardWrapper>
                <LeftMenu />
                <div className='content-wrapepr'>
                    <AssistanceWrapper>
                            <RequestAssistanceButton />
                    </AssistanceWrapper>
                    <AdminDashboardBody>
                        <div className='content-body-inner-wrapper'> 
                            <h3> <RemoveCircleTwoToneIcon /> Automatic subscription upgrade is temporarily unavailable. Please contact support@welbee.co.uk.</h3>
                        </div>
                    </AdminDashboardBody>
                </div>
            </ExtendedVoiceDashboardWrapper>
        );
    }
}
