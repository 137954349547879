import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import {
  unfillarrowprev,
  wellbeing,
  tick1,
  tick2,
  tick3,
  tick4,
  tick5,
  tick6,
  tick7,
  tick8,
  tick9,
  tick10,
  upgradeconfety,
} from '../../resources/images/index';
import {
  AssistanceWrapper,
  SearchFilterWrapper,
  VoiceDashboardWrapper,
  ConversationBody as VoiceConversationBody,
  FooterButtonsWrapper,
} from '../../resources/styling/appStyle';
import { WrapperMain } from '../../resources/styling/formStyle';

const {
  pictonBlue,
  whiteColor,
  barleyWhite,
  purple,
  mandy,
  teal,
  lighterGrey,
  lightGray,
  twilightBlueLight,
  twilightBlue,
  blazzingBlue,
  mineShaft,
  blackColor,
  softGrey,
} = colors;

/*** Head Teacher Voice Conversation ***/
export const ExtendedSearchFilterWrapper = styled(SearchFilterWrapper)`
  .back-button {
    background: url(${unfillarrowprev}) no-repeat left center;
    float: left;
    border-left: 1px solid rgba(83, 181, 224, 0.5);
    border-right: 1px solid rgba(83, 181, 224, 0.5);
    padding: 20px 40px;
    background-position-x: 9px;
    cursor: pointer;
    text-decoration: none;
    margin-left: 13px;
    color: ${pictonBlue};
  }
  .subcontainer-inner {
    width: 80%;
    margin: auto;
  }
  margin: 20px 0;
`;
export const ExtendedAssistanceWrapper = styled(AssistanceWrapper)`
  width: 100%;
  float: left;
  position: initial;
  ${media.lg`
  width: 100%;
  `}
  .latest-survey {
    .category-inner-wrapper {
      position: relative;
      overflow: initial;
    }
    .selected-option {
      border: 0;
      font-size: 14px;
      font-weight: 500;
      img {
        margin-left: 17px;
        width: 12px;
        margin-top: 6px;
      }
    }
    .options {
      border-radius: 5px;
      background: #45338c;
      color: ${whiteColor};
      padding: 10px 0;
      margin: -10px 0 0 0;
      //box-shadow: 3px 3px 10px rgba(0,122,122,0.12);
      z-index: 9999;
      max-height: inherit;
      position: absolute;
      width: 100%;
      span {
        color: ${whiteColor};
        font-size: 12px;
        padding: 7px 11px 7px 26px;
      }
    }
    .active-option {
      background: #45338c;
      color: ${whiteColor};
    }
    float: right;
    margin-right: 8px;
    > div {
      margin: 0;
      border-radius: 8px;
      min-height: 45px;
      min-width: 123px;
      background: #45338c;
      color: ${whiteColor} !important;
      .selected-option {
        min-height: 45px;
        padding: 13px 25px;
        label {
          color: ${whiteColor} !important;
        }
      }
    }
  }

  .back-btn {
    float: right;
    background: #45338c;
    color: ${whiteColor};
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 10px 28px;
    text-decoration: none;
    text-transform: capitalize;
  }

  .voice-direction-text {
    width: 100%;
    float: left;
  }
`;

/*** New Welbee Voice Form ***/
export const ExtendedFieldWrapper = styled(WrapperMain)`
  float: left;
  width: 100%;
  position: relative;
  textarea {
    margin-bottom: 0;
  }
  textarea,
  input {
    color: #363636 !important;
  }

  span {
    color: ${blackColor};
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
  }
  .floating-label {
    margin-bottom: 11px;
  }
  .floating-textarea {
    height: 128px;
  }
`;

/*** Voice Instructions ***/
export const VideoWrapper = styled.div`
  width: 960px;
  .video-react-big-play-button {
    left: 50% !important;
    top: 50% !important;
    margin-left: -84px;
    margin-top: -22px;
    height: 2.5em;
    line-height: 2.5em;
    width: 5em;
    background-color: ${mandy} !important;
    &:before {
      color: ${whiteColor};
    }
  }
`;
export const ExtendedVoiceDashboardWrapper = styled(VoiceDashboardWrapper)`
  .content-body-inner-wrapper {
    .voice-list {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
      li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        :nth-child(1) {
          margin-bottom: 0;
        }
        span:first-child {
          float: left;
          width: 61%;
          line-height: 24px;
          color: ${mineShaft};
          font-weight: 400;
        }
        span {
          float: right;
          cursor: pointer;
          position: relative;
          padding-right: 0;
          button {
            margin: 0;
            padding: 7px 10px;
            font-size: 12px;
            :nth-child(1) {
              margin-right: 8px;
              /* margin-right: 41px; */
            }
            :nth-child(2) {
              margin-right: 8px;
            }
            :nth-child(3) {
              margin-right: 8px;
            }
          }
        }
      }
      .conversation-list-dropdown {
        cursor: default;
        .latest-survey {
          float: none;
          position: relative;
          .category-inner-wrapper {
            /* width: auto; */
            position: relative;
            overflow: visible;
          }
          .action-plan-options {
            position: absolute;
            width: 100%;
          }
          .selected-option:hover {
            background: transparent;
            color: ${whiteColor};
          }
          .selected-option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:hover {
              label {
                color: ${pictonBlue} !important;
              }
            }
          }
          .selected-option img {
            margin-left: 17px;
            width: 12px;
            margin-top: 0;
          }
          .options {
            background: ${whiteColor};
            box-shadow: none;
            border-right: 1px solid ${pictonBlue};
            border-radius: 0;
            margin-top: -17px;
            border-left: 1px solid ${pictonBlue};
            border-bottom: 1px solid ${pictonBlue};
            /* border-bottom-left-radius: 24px;
              border-bottom-right-radius: 24px; */
            margin-left: -1px;
            width: 100.3%;
            min-width: 185px;
            max-height: 195px;
            overflow-y: auto;
            span {
              color: ${pictonBlue};
              width: 100%;
              &:hover {
                background: ${pictonBlue};
                color: ${whiteColor};
              }
            }
          }
          .active-option {
            background: transparent;
            label {
              color: ${pictonBlue} !important;
            }
          }
        }
      }
    }
    .staff-member-link {
      a {
        color: ${pictonBlue};
      }
    }
  }
  .back-button {
    background: url(${unfillarrowprev}) no-repeat left center;
    float: left;
    border-left: 1px solid rgba(83, 181, 224, 0.5);
    border-right: 1px solid rgba(83, 181, 224, 0.5);
    padding: 10px 40px;
    background-position-x: 9px;
    cursor: pointer;
    color: ${pictonBlue};
  }
  .voice-instructions-inner {
    display: block;
    padding: 20px 40px;

    .video-button {
      button {
        cursor: pointer;
        background: ${mandy};
        border-radius: 30px;
        color: ${whiteColor};
        border: 0;
        font-size: 14px;
        font-weight: 900;
        padding: 14px 20px;
        box-shadow: none;
        margin-bottom: 13px;
        margin-top: 25px;
      }
    }

    .bold-text {
      font-weight: 700;
      font-style: italic;
    }

    .bold-text-subheadings {
      font-weight: 700;
    }

    .email-non-link {
      color: ${pictonBlue};
    }

    ol.how-works-number {
      font-size: 16px;
      line-height: 24px;
      margin: 0px;
      padding: 0px 20px;
      li {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 9px;
      }
    }

    .message-box {
      width: 78%;
      display: block;
      background: #f7f7f7;
      /* background: url(${wellbeing}) no-repeat left center; */
      padding: 10px 40px;
      /* height: 926px; */
      /* background-size: 100%; */
    }
    span {
      img {
        border-radius: 10px;
      }
    }
    a {
      color: ${pictonBlue};
      text-decoration: underline;
      font-weight: 600;
    }
    h1:nth-child(1) {
      font-size: 32px;
    }
    h2:nth-child(3) {
      border: 0;
      margin: 0;
    }
    h2:nth-child(2) {
      border: 0;
      padding: 0;
    }
    h1,
    h2 {
      font-size: 22px;
      font-weight: 700;
      color: ${teal};
    }
    h2 {
      font-size: 20px;
      margin-top: 40px;
      border-top: 1px solid ${lighterGrey};
      padding-top: 35px;
    }
    .pink-btn {
      border-radius: 30px;
      background: ${mandy};
      font-size: 14px;
      font-weight: 400;
      padding: 6px 10px;
      color: ${whiteColor};
      box-shadow: none;
      margin: 0 5px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }

    ul {
      margin: 0;
      padding: 0 20px;
      list-style: disc;
    }
    ul li {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 9px;
    }
  }
`;

/*** Voice Upgrade ***/
export const VoiceUpgradeDashboardWrapper = styled.div`
  .content-wrapepr {
    display: flex;
    margin-left: 189px;
    padding: 0;

    .upgrade-body-content {
      border-right: 1px solid ${blazzingBlue};
      width: 66%;
      min-height: 100vh;
      background: ${twilightBlueLight};
      padding: 45px 32px;
      h2 {
        font-size: 30px;
        color: ${teal};
        line-height: 41px;
        margin-top: 0;
        padding-right: 269px;
      }
      p {
        font-size: 16px;
        margin-bottom: 42px;
        /* padding-right: 225px; */
      }
      .button-wrapper {
        text-align: center;
        float: right;
        margin-top: 0;
        button {
          font-size: 14px;
          padding: 15px 30px;
          margin: 0;
        }
      }

      .title {
        color: ${pictonBlue};
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        display: block;
        font-weight: 900;
      }

      .voice-pro {
        margin-bottom: 68px !important;
        ul {
          li:nth-child(1) {
            border-top: 2px solid #ff5939 !important;
          }
          li:nth-child(2) {
            border-bottom: 2px solid #f5abcc !important;
          }
        }
      }

      .survey-voice-box {
        width: 100%;
        float: left;
        margin: 0;
        background: ${whiteColor};
        border-radius: 12px;
        padding: 8px 0;
        box-shadow: 0 0 17px -8px ${lightGray};
        position: relative;
        margin-top: 20px;
        .heading {
          padding: 15px 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-size: 20px;
            font-weight: 900;
          }
        }
        .sub-heading {
          padding: 15px 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            margin-bottom: 0;
            margin-top: 0;
            width: 80%;
          }
          .amount-VAT {
            width: 22%;
            padding-left: 30px;
            box-sizing: border-box;
            text-align: center;
          }
        }

        ul {
          margin: 0;
          padding: 0;
          li i {
            font-style: normal;
            font-weight: 600;
          }
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 30px;
            font-size: 14px;
          }
          li:nth-child(1) {
            border-bottom: 2px solid #f5abcc;
            border-top: 2px solid #ff5939;
          }
          li:nth-child(2) {
            border-bottom: 2px solid #e3bda3;
          }
          li:nth-child(3) {
            border-bottom: 2px solid #53b5e0;
          }
          li:nth-child(4) {
            border-bottom: 2px solid #e84f6b;
          }
          li:nth-child(5) {
            border-bottom: 2px solid #45338c;
          }

          li span:nth-child(2) {
            font-weight: 700;
          }

          li:last-child {
            border-bottom: 0;
          }
        }
      }

      .screen-short {
        width: 48.7%;
        float: left;
        margin: 0;
        background: ${whiteColor};
        border-radius: 12px;
        padding: 8px;
        height: 205px;
        box-shadow: 0 0 17px -8px ${lightGray};
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }

        .image-enlarge {
          width: 100%;
          float: left;
          padding: 0;
          margin-top: 18px;
          margin-left: -6px;
          font-style: italic;
          span:nth-child(1) {
            font-size: 12px;
            float: left;
            color: ${lightGray};
          }
          span:nth-child(2) {
            font-size: 12px;
            color: ${mandy};
            font-weight: 700;
            float: right;
            cursor: pointer;
            color: ${lightGray};
          }
        }
      }
      .screen-short:last-child {
        margin-left: 15px;
      }
    }
    .screen-main-wrapper {
      float: left;
      margin-bottom: 30px;
    }
    .upgrade-suggested-pic {
      background: ${whiteColor};
      width: 34%;
      min-height: 100vh;
      padding: 45px 32px;
      background: ${twilightBlue};

      .upgrade-check-list {
        display: block;
        h2 {
          color: ${purple};
          font-size: 24px;
          line-height: 24px;
          padding-right: 0;
        }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            font-size: 16px;
            margin-bottom: 20px;
            padding-left: 38px;
            background-repeat: no-repeat !important;
            background-position: left top !important;
            background-position-y: 5px !important;
            :nth-child(1) {
              background: url(${tick1});
            }
            :nth-child(2) {
              background: url(${tick2});
            }
            :nth-child(3) {
              background: url(${tick3});
            }
            :nth-child(4) {
              background: url(${tick4});
            }
            :nth-child(5) {
              background: url(${tick5});
            }
            :nth-child(6) {
              background: url(${tick6});
            }
            :nth-child(7) {
              background: url(${tick7});
            }
            :nth-child(8) {
              background: url(${tick8});
            }
            :nth-child(9) {
              background: url(${tick9});
            }
            :nth-child(10) {
              background: url(${tick10});
            }
          }
        }
      }
    }
  }

  .upgrade-popup-inner {
    width: 75%;
    height: 80vh;
    overflow-y: auto;
    > div > div {
      overflow-x: hidden;
    }
  }
`;

/*** Welbee Voice ***/
export const ExtendedConversationBody = styled(VoiceConversationBody)`
  float: left;
  width: 100%;
  margin-top: 30px;
  .notification-btn {
    display: inline-block;
    position: relative;
    top: 7px;
    margin-right: 8px;
    margin-left: 8px;
    width: 30px;
    button {
      background: transparent;
      padding: 0 !important;
      margin-left: 0 !important;
      img {
        width: 27px;
      }
    }
    button:hover .notification-tooltip {
      display: block;
      position: absolute;
      background: ${mandy};
      z-index: 2;
      width: 322px;
      left: -225px;
      padding: 10px;
      text-align: left;
      font-weight: 400;
      border-radius: 6px;
      line-height: 18px;
      p {
        margin: 0;
        display: block;
      }
      .note {
        width: 100%;
        display: block;
        margin: 10px 0;
        font-weight: 900;
      }
    }
    .notification-tooltip {
      display: none;
    }
  }

  ul:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
`;
export const PaginationWrapper = styled.div`
  width: 75%;
  margin: auto;
  text-align: center;
  .pagination {
    padding: 10px 0 0 0;
    li {
      border-left: none !important;
    }
    li.active a {
      padding: 10px 15px;
    }
  }
`;

/*** Welbee Voice Notification ***/
export const ExtendedVoiceNotificationWrapper = styled(VoiceDashboardWrapper)`
  

  .content-body-inner-wrapper { 
    ul{
      padding: 10px 20px;
    }   
    .voice-list {
      .searchBox {
        width: 90px;
      }
      .action-plan-options {
        span {
          padding: 11px 11px;
           width: 100%;
          :hover {
          background: ${teal};
          color: ${whiteColor};
          }
         }
       }
       li {
         display: flex;
         align-items: center;
         justify-content: space-between;
       }
      }

      li {
        width: 100%;
        span:first-child {
          float: left;
          width: 30%;
          line-height: 24px;
        }
        span {
          float: right;
          cursor: pointer;

          button {
            margin: 0;
            padding: 7px 10px;
            font-size: 12px;
            :nth-child(1) {
              margin-right: 8px;
            }
            :nth-child(2) {
              margin-right: 8px;
            }
            :last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .staff-member-link {
      a {
        color: ${pictonBlue};
      }
    }
  }

  .upper-list-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }  

  .list-heading 
  {    
    padding-right: 0;
    font-weight: 900;    
    img{
      height: 20px;
    }
    button {
      position: absolute;
      padding: 0;
    }    
  }  
  .sorting-name {
    background: transparent;
    padding: 0 5px;
  
    position: relative;
    .tooltiptext 
    {
      display: none;
      width: 180px;
      background-color: ${pictonBlue};
      color: ${whiteColor};
      font-weight: 400;
      font-size: 13px;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 145%;
      left: -175%;
      opacity: 0;
      transition: opacity 0.7s;
      :after {
        content: '';
        position: absolute;
        top: 100%;
        left: 20%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${pictonBlue} transparent transparent transparent;
      }
    }
    :hover .tooltiptext {
      display: inline;
      opacity: 1;
    }
    .tooltiptext:hover {
      display: none;
    }
  }
}
`;
export const ConversationBody = styled.div`
  width: 100%;
  .normal-btn {
    background: ${mandy};
  }
  .disable-btn {
    background: ${lighterGrey};
    pointer-events: none;
  }
  .back-btn {
    position: absolute;
    top: 13px;
    left: 112px;
    text-decoration: none;
    color: ${mandy};
    font-size: 15px;
    font-weight: 900;
    z-index: 10;
    span {
      font-size: 20px;
    }
  }
  position: relative;
  .list-checkbox {
    margin: 0px;
    label {
      left: 0;
      top: 0;
    }
    .list-chkbox-inner {
      margin-bottom: 0;
    }
    .styled-checkbox + label:before {
      margin-right: 0;
    }
    .styled-checkbox {
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
  .upper-list-wrapper {
    ul {
      padding: 0;
      margin-bottom: 25px;
      list-style: none;
      li:nth-child(1) {
        display: block;
        color: ${mandy};
        font-weight: 700;
      }
      li:nth-child(2) {
        font-size: 15px;
      }
    }
    ul:last-child {
      border-bottom: 0;
      margin: 0;
    }
  }
`;
export const EmailListBody = styled(VoiceConversationBody)`
  ul {
    padding: 20px;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${lighterGrey};
    ul:nth-child(1) {
      padding-top: 0;
    }
    li {
      flex: 1;
      color: ${mineShaft};
      font-size: 13px;
      button {
        margin: 0 0 0 2px;
        padding: 0 10px;
        border-radius: 20px;
        box-shadow: none;
        font-size: 11px;
      }
    }

    li:last-child {
      text-align: left;
    }
  }
`;
export const DropDownWrapper = styled.div`
  #multiselectContainerReact {
    > div {
      min-height: 47px;
      border: 2px solid ${pictonBlue};
    }
    span {
      float: none !important;
      margin-top: 6px;
    }
    li:first-child {
      margin-bottom: 0;
    }
    .searchBox {
      margin-top: 9px;
    }
    .highlight {
      color: ${pictonBlue};
      background: transparent;
    }
    .option {
      color: ${pictonBlue};
      font-size: 13px;
      font-weight: 400;
      justify-content: flex-start;
      display: flex;
    }
    li:hover {
      color: ${whiteColor};
      background: ${teal};
    }
  }
  .multi-select-wrapper {
    width: 78%;
    float: left;
  }

  width: 60%;
  float: right;
  span:first-child {
    line-height: normal !important;
    width: auto !important;
  }
  /* display: flex;
  align-items: flex-start; */
  button {
    padding: 15px 21px;
    margin: 0 0 0 15px;
  }
  .category-inner-wrapper {
    margin: 0;
    border: 2px solid;
    padding: 10px;
    max-width: 74%;
    width: 59.5%;
    position: relative;
    overflow: initial;
  }
  label {
    font-size: 16px;
  }
  .options {
    background: ${whiteColor};
    border: 2px solid;
    position: absolute;
    max-height: initial;
    width: 101.5%;
    left: -2px;
    z-index: 10;
    padding: 0px;
    margin-top: 9px;

    span {
      padding: 7px 17px 7px 0;
    }
  }
`;

/*** Welbee Voice Upgrade Popup ***/

export const NewModalCustomStyles = {
  overlay: {
    background: 'rgba(255, 255,  255, 0.9',
  },
  modal: {
    background: `${barleyWhite}`,
    color: `${purple}`,
    borderRadius: '10px',
    backgroundImage: `(url(${upgradeconfety}))`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    boxShadow: '0 6px 15px 0 rgba(0, 0, 0, 0.1)',
    paddingTop: '90px',
  },
  closeIcon: {
    fill: `${purple}`,
    cursor: 'pointer',
  },
  closeIconSize: {
    width: 15,
    height: 15,
  },
};
export const ExtendedFooterButtonsWrapper = styled(FooterButtonsWrapper)`
  .confety {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    img {
      width: 100%;
    }
  }
  text-align: center;
  padding: 0 28px 20px;
  button {
    padding: 16px 40px;
    font-size: 15px;
  }
`;

const WelbeeVoiceStyle = {
  ExtendedSearchFilterWrapper,
  ExtendedAssistanceWrapper,
  ExtendedVoiceDashboardWrapper,
  ExtendedFieldWrapper,
  VideoWrapper,
  VoiceUpgradeDashboardWrapper,
  ExtendedConversationBody,
  PaginationWrapper,
  ExtendedVoiceNotificationWrapper,
  ConversationBody,
  EmailListBody,
  DropDownWrapper,
  NewModalCustomStyles,
  ExtendedFooterButtonsWrapper,
};
export default WelbeeVoiceStyle;
