import React from 'react';
import { Grid, Button } from '@mui/material';
import {
  InsightProduct,
  InsightProductDisabled,
} from '../../resources/styling/appStyle';
import { invert } from 'lodash';

export default function ProductCard({
  title,
  description,
  image,
  loadProduct,
  onRequest,
}) {
  const [showRequestButton, setShowRequestButton] = React.useState(false);
  return loadProduct ? (
    <Grid container onClick={loadProduct} style={{ cursor: 'pointer' }}>
      <Grid item xs={12} sm={12} md={12}>
        <InsightProduct>
          <div className='product-card'>
            <img src={image} alt={title} />
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </InsightProduct>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      // style={{ filter: invert(0.3), cursor: 'pointer' }}
      onMouseOver={(e) => setShowRequestButton(true)}
      onMouseLeave={(e) => setShowRequestButton(false)}
    >
      <Grid item xs={12} sm={12} md={12}>          
        
        <InsightProductDisabled>
          <div className='product-card-disable'>
            <div className='product-card'>
              <img
                src={image}
                alt={title}                
              />
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
            <div className="overlay">
              <div className="overlay-btn">
              {showRequestButton && (
                <Button className='request-btn' variant="outlined" onClick={() => onRequest(title)}>Click to request access</Button>
              )}
              </div>
            </div>
          </div>
        </InsightProductDisabled>
      </Grid>
    </Grid>
  );
}
