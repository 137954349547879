import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LeftMenu, MainHeader } from '../../components';
import { AssistanceWrapper } from '../../components/common/ActionPlanStyle';
import { RegisterWrapper } from '../../resources/styling/appStyle';
import {
  WebStorageNames,
  MenuItems,
  WelbeeType,
  HeadTeacherAccess,
  QueryStringTokens,
} from '../../utils';
import { schoolService, storageService } from '../../services';
import { amplitudeLog, getQueryStringVal } from '../../utils/CommonFunctions';
import AccountForm from './AccountForm';
import AdminInfoForm from './AdminInfoForm';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { loaderAction } from '../../store/loaderStore';
import { firstLoginAction } from '../../store/userStore';
import { bindActionCreators } from 'redux';
import { navigationActions } from '../../store/headerStore';
import AccountSubscription from '../account/AccountSubscription';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import {
  ExtendedActionPlanMainWrapper,
  ExtendedSubContainer,
} from './AccountStyle';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';
import { colors } from '../.././resources/theme/colors';
import { routesObj } from '../../routes';
import { WelbeeTypes } from '../../utils/Constants';

const { outrageousOrange } = colors;

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SchoolRecord: null,
      schoolId: null,
      //welbeeType: storageService.getItem(WebStorageNames.WelbeeType),
      welbeeType: storageService.getItem(WebStorageNames.SubscriptionId),
      accessLevel: 0,
      isStaff: false,
      welbeeAccountType: 0,
      logoValue: '',
    };
  }

  componentDidMount() {
    const { loaderActions, navigationActions } = this.props;
    loaderActions.dataLoading(true);
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    this.setState({
      accessLevel: storageService.getItem(WebStorageNames.AccessLevel),
      isStaff: storageService.getItem(WebStorageNames.IsStaff),
    });
    navigationActions.activeItemAction({
      item: MenuItems.Account,
      showReportSubMenu: false,
      disableReportMenu: false,
    });
    const schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    if (schoolId) {
      schoolService
        .getSchoolDetail(schoolId, schoolToken)
        .then((response) => {
          loaderActions.dataLoading(false);
          this.setState({
            SchoolRecord: response.data,
            welbeeAccountType: response.data.school.WelbeeVoiceAccountType,
            schoolId,
          });
          storageService.setItem(
            WebStorageNames.schoolLogoUrl,
            this.state.SchoolRecord.school.Logo.FileUrl
          );

          navigationActions.setSchoolLogoUrl(
            this.state.SchoolRecord.school.Logo.FileUrl
          );
        })
        .catch((error) => console.log(error));
    } else {
      amplitudeLog('PageView', 'Account', '');
    }
    this.props.userAction.doScroll(false);
  }

  getUpgradePlan() {
    return this.state.welbeeType === WelbeeTypes.WelbeeSurveyPremium ? null : (
      <div className='btn-upgrade'>
        <a
          href
          onClick={() => {
            this.props.history.push(routesObj.AccountSubscription.path);
          }}
        >
          Upgrade Subscription
        </a>
      </div>
    );
  }

  handleUpdateData = (logoValue) => {
    this.setState({ logoValue: logoValue });
  };

  render() {
    const {
      SchoolRecord,
      welbeeType,
      accessLevel,
      isStaff,
      welbeeAccountType,
    } = this.state;
    return (
      <ExtendedActionPlanMainWrapper>
        <RegisterWrapper>
          <div className='containerClass'>
            <div className='left-container'>
              <LeftMenu logo={this.state.logoValue} />
            </div>

            <div className='right-container'>
              <MainHeader />
              <Grid container>
                <Grid item md={12} xs={12}>
                  <AssistanceWrapper>
                    <div className='action-heading-wrapper'>
                      <span> {SchoolRecord ? SchoolRecord.Name : ''}</span>
                    </div>
                  </AssistanceWrapper>
                </Grid>
                <Grid item md={12} xs={12}>
                  <ExtendedSubContainer>
                    <div className='search-inner-sub-container'>
                      <h2>Licence Information</h2>
                      <Box className='license-information'>
                        <Grid item xs={12} lg={12} className='details'>
                          <CheckIcon
                            className='icon-check'
                            sx={{ color: green[500] }}
                          />
                          Your Account Renewal Date is{' '}
                          <span style={{ color: outrageousOrange }}>
                            {this.state.SchoolRecord !== null
                              ? new Date(
                                  this.state.SchoolRecord.school.SchoolSubscription.EndDate
                                ).toDateString()
                              : ''}
                          </span>
                        </Grid>
                        <Grid item xs={12} lg={12} className='details'>
                          <CheckIcon
                            className='icon-check'
                            sx={{ color: green[500] }}
                          />
                          Your Licence runs from{' '}
                          <span style={{ color: outrageousOrange }}>
                            {this.state.SchoolRecord !== null
                              ? new Date(
                                  this.state.SchoolRecord.school.SchoolSubscription.StartDate
                                ).toDateString() +
                                ' - ' +
                                new Date(
                                  this.state.SchoolRecord.school.SchoolSubscription.EndDate
                                ).toDateString()
                              : ''}
                          </span>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          {this.getUpgradePlan()}
                        </Grid>
                      </Box>
                    </div>
                  </ExtendedSubContainer>
                </Grid>

                <Grid item md={12} xs={12}>
                  <ExtendedSubContainer>
                    <div className='search-data-container'>
                      <div className='search-inner-container mx-auto'>
                        {accessLevel === HeadTeacherAccess.Full && (
                          <div className='search-inner-sub-container'>
                            <AccountForm
                              SchoolRecord={SchoolRecord}
                              updateData={this.handleUpdateData}
                            />
                          </div>
                        )}

                        <AdminInfoForm SchoolRecord={SchoolRecord} />
                      </div>
                    </div>
                  </ExtendedSubContainer>
                </Grid>
              </Grid>
            </div>
          </div>
        </RegisterWrapper>
      </ExtendedActionPlanMainWrapper>
    );
  }
}

const mapStateToProps = ({ user: { doScroll } }) => {
  return {
    doScroll,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Account));
