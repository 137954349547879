import { matCycleApi } from './urls';
import request from '../request';

export const saveCycle = (model) => {
  return request({
    url: matCycleApi.addMatCycle,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getByMatGroupId = (matId) => {
  return request({
    url: matCycleApi.getByMatId(matId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const removeCycle = (id) => {
  return request({
    url: matCycleApi.removeCycle(id),
    method: 'DELETE',
  })
    .then((response) => response)
    .catch((error) => error);
};

const removeCycleSchool = (id) => {
  return request({
    url: matCycleApi.removeCycleSchool(id),
    method: 'DELETE',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getById = (id) => {
  return request({
    url: matCycleApi.getById(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

export const saveCycleSchool = (model) => {
  return request({
    url: matCycleApi.saveCycleSchool,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const toggleSchool = (id) => {
  return request({
    url: matCycleApi.toggleSchool(id),
    method: 'PATCH',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const getMatSurveys = (model) => {
  return request({
    url: matCycleApi.getMatSurveysEndDates(),
    method: 'POST',
    data:model
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getCyclesNameAndToken = (cycle) => {
  return request({
    url: matCycleApi.getMatNameAndToken(cycle),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getMatCyclesByMatGroupId = (groupId) => {
  return request({
    url: matCycleApi.getMatCyclesByMatGroupId(groupId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const matCycleService = {
  saveCycle,
  getById,
  getByMatGroupId,
  removeCycle,
  removeCycleSchool,
  saveCycleSchool,
  toggleSchool,
  getMatSurveys,
  getCyclesNameAndToken,
  getMatCyclesByMatGroupId,
};
export default matCycleService;
