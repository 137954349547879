import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { colors } from '../../resources/theme';
import { AppButton } from '../../resources/styling';
import { WrapperMain } from '../../resources/styling/formStyle';
import { TextArea, FloatingTextareaLabel } from '../../components';
import { questions, Sender } from '../../utils/Constants';
import {
  DateFormatWithMonth,
  getJobRole,
  getQueryStringVal,
  parseJwt,
} from '../../utils/CommonFunctions';
import { surveyCommentsService, storageService } from '../../services';
import { ErrorWrapper } from '../../resources/styling/appStyle';
import { WebStorageNames, Roles, QueryStringTokens } from '../../utils';
import Grid from '@mui/material/Grid';
import { CommonRepplyWrapper } from './SurveyCommentStyle';
import { toast } from 'react-toastify';

const { mandy, lightGray } = colors;

const CommentsReplyCommon = ({
  comment,
  sender,
  selectedComment,
  selectedQuestion,
  isSurveyClosed = false,
}) => {
  let [commentWithReplies, setcommentWithReplies] = useState();
  let [enableBtn, setEnableBtn] = useState(false);
  const [accessLevel] = useState(
    storageService.getItem(WebStorageNames.AccessLevel)
  );
  useEffect(() => {
    setcommentWithReplies(comment);
  }, [comment]);
  return (
    <Formik
      initialValues={{
        Reply: '',
      }}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          setEnableBtn(false);
          if (sender === Sender.You) {
            let schoolIdToken = '';
            const role = storageService.getItem(WebStorageNames.Role);
            let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
            if (role === Roles.GroupDirector || schoolToken !== null) {
              schoolIdToken = schoolToken;
            }
            const model = {
              ReplyText: values.Reply,
              Sender: sender,
              CommentQuestionType: commentWithReplies.CommentQuestionType,
              SurveyCommentId: commentWithReplies.Id,
              SchoolStaffId: commentWithReplies.SchoolStaffId,
              SchoolToken: schoolIdToken,
            };
            surveyCommentsService
              .SubmitHeadTeacherCommentReply(model)
              .then((response) => {
                const { success, data } = response;
                if (success) {
                  actions.resetForm();
                  setcommentWithReplies(data);
                } else {
                  const { success, message } = response;
                  toast.warning(message);
                }
              })
              .catch((error) => {});
          }
          if (sender === Sender.Support) {
            const token = window.location.search.split('?id=')[1];
            const model = {
              ReplyText: values.Reply,
              Sender: sender,
              CommentQuestionType: commentWithReplies.CommentQuestionType,
              SurveyCommentId: commentWithReplies.Id,
              SurveyToken: token,
            };
            surveyCommentsService
              .SubmitStaffCommentReply(model)
              .then((response) => {
                const { success, data } = response;
                if (success) {
                  actions.resetForm();
                  setcommentWithReplies(data);
                }
              })
              .catch((error) => {});
          }
        }, 1000);
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        Reply: Yup.string().required('Reply message is required.'),
      })}
      render={({
        values,
        handleChange,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
      }) => {
        return commentWithReplies && commentWithReplies.CommentReplies ? (
          <CommonRepplyWrapper>
            <Grid container>
              <Grid item md={12} xs={12}>
                <div className='search-inner-container'>
                  <div className='search-inner-sub-container'>
                    <div className='reply-section support-reply'>
                      <h4>
                        {selectedQuestion
                          ? selectedQuestion.QuestionText
                          : commentWithReplies.CommentQuestionType === 1
                          ? questions.first
                          : questions.second}
                      </h4>
                      <h3>
                        {DateFormatWithMonth(commentWithReplies.AddedDate)}{' '}
                        {selectedComment
                          ? getJobRole(
                              selectedComment ? selectedComment.JobRole : 1
                            )
                          : 'You'}
                      </h3>
                      <div className='comments-body'>
                        {commentWithReplies.CommentText}
                      </div>
                    </div>
                    {commentWithReplies.CommentReplies !== null &&
                    commentWithReplies.CommentReplies !== undefined
                      ? commentWithReplies.CommentReplies.map((x) => {
                          return (
                            <>
                              <hr className='horizontal-ruler' />

                              <div
                                className={
                                  Sender.Support === x.Sender
                                    ? 'reply-section support-reply'
                                    : 'reply-section your-reply'
                                }
                              >
                                <h3>
                                  {DateFormatWithMonth(x.UpdatedDate)}
                                  {Sender.Support === x.Sender
                                    ? selectedComment
                                      ? getJobRole(
                                          selectedComment
                                            ? selectedComment.JobRole
                                            : 1
                                        )
                                      : 'You'
                                    : x.SenderId != null
                                    ? x.HeadTeacher.AdminName
                                    : 'School'}
                                </h3>
                                <div className='comments-body'>
                                  {x.ReplyText}
                                </div>
                              </div>
                            </>
                          );
                        })
                      : null}
                    <div className='reply-section'>
                      <WrapperMain>
                        <FloatingTextareaLabel
                          label='Reply'
                          name='Reply'
                          value={values.Reply}
                          onChange={(e) => {
                            setFieldValue('Reply', e.target.value);
                            setEnableBtn(
                              e.target.value.length === 0 ? false : true
                            );
                          }}
                        />
                        <ErrorWrapper>
                          {touched.Reply && errors.Reply ? errors.Reply : ''}
                        </ErrorWrapper>
                      </WrapperMain>
                      <WrapperMain className='send-button'>
                        {enableBtn ? (
                          <AppButton
                            color={mandy}
                            type='submit'
                            onClick={handleSubmit}
                          >
                            Send
                          </AppButton>
                        ) : (
                          <AppButton color={lightGray} type='button'>
                            Send
                          </AppButton>
                        )}
                      </WrapperMain>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </CommonRepplyWrapper>
        ) : (
          <></>
        );
      }}
    />
  );
};

export default CommentsReplyCommon;
