import {
  Button,
  IconButton,
  Stack,
  Container,
  Box,
  Menu,
  Tooltip,
  Avatar,
  MenuItem,
  Typography,
  AppBar,
  Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState, useEffect } from 'react';
import { WebStorageNames } from '../../utils';
import { storageService } from '../../services';
import { InsightProduct } from '../../resources/styling/appStyle';
import { Roles } from '../../utils';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import { QueryStringTokens } from '../../utils';
import { routesObj } from '../../routes';
import { withRouter } from 'react-router-dom';
import schoolService from '../../services/api/schoolService';

const settings = [
  //'Account',
  'Logout',
];

export default function Header(props) {
  const [name, setName] = useState('');

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: 'green',
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };

  const logout = () => {
    localStorage.removeItem('token');
    window.location = '/login';
  };

  useEffect(() => {
    let n = storageService.getToken(WebStorageNames.SchoolName);
    setName(n);
  }, []);

  const redirectToRoute = (routeName) => {
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    let surveyToken = storageService.getItem(WebStorageNames.SurveyToken);
    if (schoolToken) {
      schoolService.getSchool(schoolToken).then((response) => {
        const route = routeName.split('?')[0];
        const { data } = response;
        if (response.data.MatGroupId == null) {
          storageService.setItem(WebStorageNames.IsDisplayMatHeatMap, 'false');
        }
        if (data) {
          //var obj = storageService.getItem(`cache_${response.data.Name}`);
          //const { surveyToken } = obj;

          window.open(
            `${route}?token=${surveyToken}&schoolToken=${schoolToken}`
          );
        } else {
          window.open(`${route}?schoolToken=${schoolToken}`);
        }
      });
    } else {
      window.open(routeName);
    }
  };

  const redirectToRouteWithSchoolToken = (routeName) => {
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    var route = `${routeName}?schoolToken=${schoolToken}&IsMatSchoolFromHeatmap=false`;
    redirectToRoute(route);
  };

  return (
    <InsightProduct>
      <AppBar className='header'>
        <Container maxWidth='xxl'>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <div className='logo'>
                <span>{name}</span>
              </div>

              <div>
                {/* Responsive Menu */}
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size='large'
                    onClick={handleOpenNavMenu}
                    color='inherit'
                  >
                    <MenuIcon />
                  </IconButton>

                  <Menu
                    id='menu-appbar'
                    anchorEl={anchorElNav}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                  ></Menu>
                </Box>

                {/* Desktop Menu */}
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  <div className='header-middle'>
                    <Button onClick={(q) => {}} disableRipple>
                      <svg
                        width='17'
                        height='17'
                        viewBox='0 0 17 17'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.08333 17.25C1.57917 17.25 1.14742 17.0706 0.788083 16.7119C0.429361 16.3526 0.25 15.9208 0.25 15.4167V2.58333C0.25 2.07917 0.429361 1.64742 0.788083 1.28808C1.14742 0.929361 1.57917 0.75 2.08333 0.75H14.9167C15.4208 0.75 15.8526 0.929361 16.2119 1.28808C16.5706 1.64742 16.75 2.07917 16.75 2.58333V15.4167C16.75 15.9208 16.5706 16.3526 16.2119 16.7119C15.8526 17.0706 15.4208 17.25 14.9167 17.25H2.08333ZM2.08333 15.4167H7.58333V2.58333H2.08333V15.4167ZM9.41667 15.4167H14.9167V9H9.41667V15.4167ZM9.41667 7.16667H14.9167V2.58333H9.41667V7.16667Z'
                          fill='white'
                        />
                      </svg>
                      My Products
                    </Button>
                    <Button
                      disableRipple
                      onClick={(q) => {
                        let userInfo = storageService.getItem(
                          WebStorageNames.UserInfo
                        );
                        if (userInfo.role === Roles.GroupDirector) {
                          redirectToRouteWithSchoolToken(
                            routesObj.StaffManagement.path
                          );
                        } else redirectToRoute(routesObj.StaffManagement.path);
                      }}
                    >
                      <svg
                        width='20'
                        height='20'
                        viewBox='0 0 21 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14.3333 18.25V16.4167C14.3333 15.4442 13.947 14.5116 13.2594 13.8239C12.5718 13.1363 11.6391 12.75 10.6667 12.75H5.16667C4.19421 12.75 3.26158 13.1363 2.57394 13.8239C1.88631 14.5116 1.5 15.4442 1.5 16.4167V18.25'
                          stroke='white'
                          stroke-width='1.83333'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M7.91667 9.08333C9.94171 9.08333 11.5833 7.44171 11.5833 5.41667C11.5833 3.39162 9.94171 1.75 7.91667 1.75C5.89162 1.75 4.25 3.39162 4.25 5.41667C4.25 7.44171 5.89162 9.08333 7.91667 9.08333Z'
                          stroke='white'
                          stroke-width='1.83333'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M19.8335 18.25V16.4167C19.8329 15.6043 19.5625 14.815 19.0647 14.173C18.567 13.5309 17.8701 13.0723 17.0835 12.8692M14.3335 1.86917C15.1222 2.07111 15.8213 2.52981 16.3205 3.17295C16.8197 3.81609 17.0907 4.60709 17.0907 5.42125C17.0907 6.23541 16.8197 7.02641 16.3205 7.66955C15.8213 8.31269 15.1222 8.77139 14.3335 8.97333'
                          stroke='white'
                          stroke-width='1.83333'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                      Data Management
                    </Button>
                  </div>
                </Box>
              </div>

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title='Open settings' arrow>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt='' src='/broken-image.jpg' />
                  </IconButton>
                </Tooltip>

                <Menu
                  sx={{ mt: '45px' }}
                  id='menu-appbar'
                  anchorEl={anchorElUser}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={(e) => {
                        if (setting === 'Logout') {
                          logout();
                          return;
                        }
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography textAlign='center'>{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Stack>
          </Grid>
        </Container>
      </AppBar>
    </InsightProduct>
  );
}
