import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { colors } from '../../resources/theme';
import { AppButton } from '../../resources/styling';
import { WrapperMain } from '../../resources/styling/formStyle';
import { TextArea, FloatingTextareaLabel } from '../../components';
import { Sender, HeadTeacherAccess } from '../../utils/Constants';
import { DateFormatWithMonth } from '../../utils/CommonFunctions';
import { welbeeVoiceService, storageService } from '../../services';
import { WebStorageNames } from '../../utils';
import { loaderAction } from '../../store/loaderStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ErrorWrapper, CommonRepplyWrapper } from '../../resources/styling/appStyle';
const { mandy } = colors;

const ExtendedCommonRepplyWrapper = styled(CommonRepplyWrapper)`
  width: 100%;
  float: left;
`;

const WelbeeReply = ({
  converstionReplyList,
  sender,
  converstionId,
  converstionDetail,
  converstionDescription,
  loaderActions,
}) => {
  let [voiceComments, setvoiceComments] = useState();
  const [accessLevel] = useState(
    storageService.getItem(WebStorageNames.AccessLevel)
  );
  const [logedUserInfo] = useState(
    storageService.getItem(WebStorageNames.UserInfo)
  );
  useEffect(() => {
    setvoiceComments(converstionReplyList);
  }, [converstionReplyList]);
  return (
    <Formik
      initialValues={{
        Reply: '',
      }}
      onSubmit={(values, actions) => {
        loaderActions.dataLoading(true);
        setTimeout(() => {
          if (sender === Sender.You) {
            const schoolId = storageService.getItem(WebStorageNames.SchoolId);
            const model = {
              ReplyText: values.Reply,
              Sender: sender,
              VoiceConversationId: converstionId,
              SchoolStaffId: converstionDetail.SchoolStaffId,
              SurveyVoiceId: converstionDetail.SurveyVoiceId,
              SchoolID: schoolId,
            };
            welbeeVoiceService
              .submitHeadTeacherVoiceReply(model)
              .then((response) => {
                loaderActions.dataLoading(false);
                const { success, data } = response;
                if (success) {
                  actions.resetForm();
                  setvoiceComments(data);
                }
              })
              .catch((error) => {
                loaderActions.dataLoading(false);
              });
          }else{
            loaderActions.dataLoading(false);
          }
        }, 1000);
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        Reply: Yup.string().required('Reply message is required.'),
      })}
      render={({ values, handleChange, touched, errors, handleSubmit }) => {
        return (
          <ExtendedCommonRepplyWrapper>
            <div className='search-inner-container'>
              <div className='search-inner-sub-container'>
                <div className='reply-section'>
                  <h3>{converstionDescription}</h3>
                </div>
                {voiceComments !== null && voiceComments !== undefined
                  ? voiceComments.map((x) => (
                      <>
                        <hr className='horizontal-ruler' />
                        <div
                          className={
                            Sender.Support === x.Sender
                              ? 'reply-section support-reply'
                              : 'reply-section your-reply'
                          }
                        >
                          <h3>
                            {DateFormatWithMonth(x.UpdatedDate)}
                            {Sender.Support === x.Sender
                                ? 'Staff member'
                                : (x.HeadTeacher && x.HeadTeacher.UserId !== logedUserInfo.Id)
                                  ? x.HeadTeacher.AdminName : "You"}
                          </h3>
                          <div className='comments-body'>{x.ReplyText}</div>
                        </div>
                      </>
                    ))
                  : null}
                {accessLevel === HeadTeacherAccess.Full && <div className='reply-section'>
                  <WrapperMain>
                    <FloatingTextareaLabel
                      label='Reply'
                      name='Reply'
                      value={values.Reply}
                      onChange={handleChange}
                    />
                    <ErrorWrapper>
                      {touched.Reply && errors.Reply ? errors.Reply : ''}
                    </ErrorWrapper>
                  </WrapperMain>
                  <WrapperMain className='send-button'>
                    <AppButton
                      color={mandy}
                      type='submit'
                      onClick={handleSubmit}
                    >
                      Send
                    </AppButton>
                  </WrapperMain>
                </div>}
              </div>
            </div>
          </ExtendedCommonRepplyWrapper>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(WelbeeReply);
