import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AppButton } from '../../resources/styling';
import { isEmpty } from 'lodash';
import { loaderAction } from '../../store/loaderStore';
import { amplitudeLog } from '../../utils/CommonFunctions';
import {
  Header,
  FloatinLabelDropDown,
  FloatingInputLabel,
  FloatingTextareaLabel,
  AnalyticsDropdown,
} from '../../components';
import {
  MainFooter,
  PasswordViewIcon,
  ErrorWrapper,
  FieldWrapper,
  RegisterInnerMain,
  RegisterWrapper,
  CheckBox,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import {
  footerLogo,
  loginNormalEye,
  loginShowEye,
} from '../../resources/images';
import { accountService, schoolService } from '../../services';
import { routesObj } from '../../routes';
import {
  PasswordReg,
  PhaseOfSchool,
  SelectedPhaseOfSchool,
  SectorItems,
  SelectedSector,
} from '../../utils';
import { WrapperMain } from '../../resources/styling/formStyle';
import { toast } from 'react-toastify';
import { SignUpStatus } from '../../utils/CommonFunctions';
import {
  RegisterInner,
  ExtendedAssitanceForm,
  InputWrapper,
} from './SchoolRegisterStyle';

const { mandy } = colors;

class SchoolRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SchoolRecord: null,
      isEdit: false,
      schoolId: 0,
      phaseOfSchool: PhaseOfSchool,
      selectedPhaseOfSchool: SelectedPhaseOfSchool,
      sector: SectorItems,
      selectedSector: SelectedSector,
      emailErrorMessage: '',
      duplicateSchoolErrorMessage: '',
      passwordValidation: '',
      isPasswordShow: true,
      headTeachersInfo: null,
      IsTestAccount: false,
      signUpStatus: '',
    };
    this.splitName = this.splitName.bind(this);
  }
  componentWillMount() {
    const query = new URLSearchParams(this.props.location.search);
    const schoolId = query.get('schoolId');
    if (schoolId) {
      schoolService
        .getSchoolDetailBySuperAdmin(schoolId)
        .then((response) => {
          let getPhaseofSchool = this.state.phaseOfSchool.find(
            (o) => o.value === response.data.school.PhaseOfSchool
          );
          let getSector = this.state.sector.find(
            (o) => o.value === response.data.school.Sector
          );
          if (isEmpty(getPhaseofSchool))
            getPhaseofSchool = this.state.phaseOfSchool[0];
          if (isEmpty(getSector)) getSector = this.state.sector[0];
          this.setState({
            SchoolRecord: response.data.school,
            IsTestAccount: response.data.school.IsTestAccount,
            headTeachersInfo: response.data.headTeacher,
            isEdit: true,
            schoolId,
            selectedPhaseOfSchool: getPhaseofSchool,
            selectedSector: getSector,
            signUpStatus: SignUpStatus(response.data.school.SignUpStatus),
          });
        })
        .catch((error) => console.log(error));
    } else {
      amplitudeLog('PageView', 'School Register', '');
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  splitName = (fileName) => {
    var splitFileName = fileName.split('.');
    var imageName = splitFileName[0];
    var trancateString =
      imageName.length > 23
        ? imageName.substr(imageName, 23, 0) + '...'
        : fileName;
    return trancateString;
  };
  onChangeHandler = (event) => {
    console.log(event.target.files[0]);
  };
  handleDropDownChange = (option) => {
    this.setState({ selectedPhaseOfSchool: option });
  };

  passwordValidation = (password, actions) => {
    if (password !== '') {
      if (password.length < 12) {
        actions.setFieldError(
          'Password',
          'Password must be at least 12 characters.'
        );
        return false;
      }
      if (!PasswordReg.test(password)) {
        actions.setFieldError(
          'Password',
          'Your password must contain at least 1 uppercase, lowercase, number and special character from @£!#$%^&+="\',-.'
        );
        return false;
      }
    }
    return true;
  };

  duplicationErrorMeassageHandle = (message) => {
    if (message === 'Email is already in use, please choose another email.') {
      this.setState({
        emailErrorMessage: message,
        duplicateSchoolErrorMessage: '',
      });
    } else if (message === 'School name already exist.') {
      this.setState({
        duplicateSchoolErrorMessage: message,
        emailErrorMessage: '',
      });
    }
  };

  render() {
    const {
      SchoolRecord,
      isEdit,
      schoolId,
      isPasswordShow,
      headTeachersInfo,
      IsTestAccount,
    } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          Id: schoolId ? schoolId : 0,
          Name: SchoolRecord
            ? SchoolRecord.Name != null
              ? SchoolRecord.Name
              : ''
            : '',
          FirstName: headTeachersInfo
            ? headTeachersInfo.FirstName != null
              ? headTeachersInfo.FirstName
              : ''
            : '',
          LastName: headTeachersInfo
            ? headTeachersInfo.LastName != null
              ? headTeachersInfo.LastName
              : ''
            : '',
          ContactNumber: SchoolRecord
            ? SchoolRecord.ContactNumber != null
              ? SchoolRecord.ContactNumber
              : ''
            : '',
          Email: SchoolRecord
            ? SchoolRecord.Email != null
              ? SchoolRecord.Email
              : ''
            : '',
          Password: '',
          ConfirmPassword: '',
          Address: SchoolRecord
            ? SchoolRecord.Address != null
              ? SchoolRecord.Address
              : ''
            : '',
          PhaseOfSchool: isEdit ? this.state.selectedPhaseOfSchool.value : '',
          Sector: isEdit ? this.state.selectedSector.value : '',
          HearAboutUs: SchoolRecord
            ? SchoolRecord.HearAboutUs != null
              ? SchoolRecord.HearAboutUs
              : ''
            : '',
          File: '',
          NumberOfStaff: SchoolRecord
            ? SchoolRecord.NumberOfStaff != null
              ? SchoolRecord.NumberOfStaff
              : 0
            : 0,
          NumberOfPupil: SchoolRecord
            ? SchoolRecord.NumberOfPupil != null
              ? SchoolRecord.NumberOfPupil
              : 0
            : 0,
          FileName: SchoolRecord
            ? SchoolRecord.Logo != null
              ? SchoolRecord.Logo.FileName
              : ''
            : '',
          TrancateFileName: SchoolRecord
            ? SchoolRecord.Logo != null
              ? this.splitName(SchoolRecord.Logo.FileName)
              : ''
            : '',

          IsTestAccount: this.state.IsTestAccount,
        }}
        onSubmit={(values, actions) => {
          const { loaderActions } = this.props;
          loaderActions.dataLoading(true);
          if (isEdit) {
            if (!this.passwordValidation(values.Password, actions)) {
              loaderActions.dataLoading(false);
              return;
            }
            schoolService
              .updateSchoolDetail(values)
              .then((response) => {
                if (response.success) {
                  if (response.message)
                    toast.success(response.message, { autoClose: 10000 });
                  this.props.history.push(routesObj.Schools.path);
                  loaderActions.dataLoading(false);
                } else {
                  loaderActions.dataLoading(false);
                  this.duplicationErrorMeassageHandle(response.message);
                }
              })
              .catch((error) => {
                loaderActions.dataLoading(false);
              });
          } else {
            accountService
              .SchoolRegister(values)
              .then((response) => {
                if (response.success) {
                  this.props.history.push(routesObj.ThankYouMessage.path);
                  loaderActions.dataLoading(false);
                } else {
                  this.duplicationErrorMeassageHandle(response.message);
                  loaderActions.dataLoading(false);
                }
              })
              .catch((error) => loaderActions.dataLoading(false));
          }
        }}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          Name: Yup.string()
            .trim()
            .max(50, 'School name must be at most 50 characters.')
            .required('School name is required.'),
          ContactNumber: Yup.string()
            .trim()
            .max(50)
            .matches(/^[0-9]{1,50}$/, {
              message: 'Contact number can only contain numbers.',
              excludeEmptyString: false,
            })
            .required('Contact number is required.'),
          Email: Yup.string()
            .trim()
            .max(60)
            .email('Provide email address like example@g.com.')
            .required('Email is required.'),
          FirstName: Yup.string()
            .trim()
            .max(50, 'First name must be at most 50 characters.')
            .required('First name  is required.'),
          LastName: Yup.string()
            .trim()
            .max(50, 'Last name must be at most 50 characters.')
            .required('Last name  is required.'),
          Address: Yup.string().trim().required('Address is required.'),
          PhaseOfSchool: Yup.string()
            .trim()
            .min(1, 'Phase of school is required.')
            .required('Phase of school is required.'),
          Sector: Yup.string()
            .trim()
            .min(1, 'Sector is required.')
            .required('Sector is required.'),
          HearAboutUs: Yup.string()
            .trim()
            .max(450, 'Hear about us must be at most 500 characters.'),
          Password:
            isEdit && SchoolRecord
              ? Yup.string().trim()
              : Yup.string()
                  .trim()
                  .min(12, 'Password must be at least 12 characters.')
                  .matches(
                    PasswordReg,
                    'Your password must contain at least 1 uppercase, lowercase, number and special character from @£!#$%^&+="\',-.'
                  )
                  .required('Password is required.'),
          ConfirmPassword: isEdit
            ? Yup.string().trim()
            : Yup.string()
                .trim()
                .oneOf([Yup.ref('Password'), null], 'Passwords must match')
                .required('Confirm password is required.'),
          NumberOfStaff: Yup.number()
            .max(10000, 'Max number is 10000')
            .min(1, 'Enter number greater than 0')
            .integer()
            .required('Number of staff is required.'),
          NumberOfPupil: Yup.number()
            .max(10000, 'Max number is 10000.')
            .required('Number of pupils is required.')
            .integer(
              'Please enter a number greater than 0, no fractions allowed.'
            )
            .min(1, 'Please enter a number greater than 0'),
        })}
        render={({
          values,
          handleChange,
          touched,
          errors,
          handleSubmit,
          setFieldValue,
        }) => (
          <div>
            <Header />

            <RegisterWrapper>
              <Grid container>
                <Grid
                  item
                  lg={5}
                  sm={12}
                  md={7}
                  xs={12}
                  mx='auto'
                  className='mobile-wrapper'
                >
                  <RegisterInnerMain>
                    <RegisterInner>
                      <ExtendedAssitanceForm>
                        <h2>School Register</h2>
                        <InputWrapper>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='School Name'
                              placeholder='School name'
                              name='Name'
                              value={values.Name}
                              onChange={handleChange}
                            />
                            <ErrorWrapper>
                              {(touched.Name && errors.Name) ||
                                this.state.duplicateSchoolErrorMessage}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='First name'
                              placeholder='First name'
                              name='FirstName'
                              value={values.FirstName}
                              onChange={handleChange}
                            />
                            <ErrorWrapper>
                              {touched.FirstName &&
                                errors.FirstName &&
                                errors.FirstName}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Last Name'
                              placeholder='Last name'
                              name='LastName'
                              value={values.LastName}
                              onChange={handleChange}
                            />
                            <ErrorWrapper>
                              {touched.LastName &&
                                errors.LastName &&
                                errors.LastName}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Contact email'
                              placeholder='Contact email'
                              name='Email'
                              value={values.Email}
                              autoComplete='new-password'
                              onChange={handleChange}
                            />
                            <ErrorWrapper>
                              {(touched.Email && errors.Email) ||
                                this.state.emailErrorMessage}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Contact number'
                              placeholder='Contact number'
                              name='ContactNumber'
                              value={values.ContactNumber}
                              onChange={handleChange}
                            />
                            <ErrorWrapper>
                              {touched.ContactNumber &&
                                errors.ContactNumber &&
                                errors.ContactNumber}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Address'
                              placeholder='Address'
                              name='Address'
                              value={values.Address}
                              onChange={handleChange}
                            />
                            <ErrorWrapper>
                              {touched.Address &&
                                errors.Address &&
                                errors.Address}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Password'
                              placeholder='Password'
                              type={isPasswordShow ? 'password' : 'text'}
                              name='Password'
                              autoComplete='new-password'
                              value={values.Password}
                              onChange={handleChange}
                            />
                            <PasswordViewIcon
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                src={
                                  isPasswordShow ? loginNormalEye : loginShowEye
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    isPasswordShow: !isPasswordShow,
                                  });
                                }}
                                alt=''
                              />
                            </PasswordViewIcon>
                            <ErrorWrapper>
                              {touched.Password &&
                                errors.Password &&
                                errors.Password}
                            </ErrorWrapper>
                          </WrapperMain>
                          {!isEdit ? (
                            <WrapperMain>
                              <FloatingInputLabel
                                label='Confirm Password'
                                placeholder='Confirm Password'
                                type='password'
                                name='ConfirmPassword'
                                value={values.ConfirmPassword}
                                onChange={handleChange}
                              />
                              <ErrorWrapper>
                                {touched.ConfirmPassword &&
                                  errors.ConfirmPassword &&
                                  errors.ConfirmPassword}
                              </ErrorWrapper>
                            </WrapperMain>
                          ) : null}
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Number of staff'
                              placeholder='Number of staff'
                              name='NumberOfStaff'
                              value={values.NumberOfStaff}
                              onChange={handleChange}
                              type='number'
                            />
                            <ErrorWrapper>
                              {touched.NumberOfStaff &&
                                errors.NumberOfStaff &&
                                errors.NumberOfStaff}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <FloatingInputLabel
                              label='Number of pupils'
                              placeholder='Number of pupils '
                              name='NumberOfPupil'
                              value={values.NumberOfPupil}
                              onChange={handleChange}
                              type='number'
                            />
                            <ErrorWrapper>
                              {touched.NumberOfPupil &&
                                errors.NumberOfPupil &&
                                errors.NumberOfPupil}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <AnalyticsDropdown
                              label='Phase Of School'
                              name='PhaseOfSchool'
                              options={this.state.phaseOfSchool}
                              selectedOption={this.state.selectedPhaseOfSchool}
                              value={values.PhaseOfSchool}
                              onChange={(option) => {
                                this.setState({
                                  selectedPhaseOfSchool: option.target,
                                });
                                setFieldValue('PhaseOfSchool', option.value);
                              }}
                            />
                            <ErrorWrapper>
                              {touched.PhaseOfSchool &&
                                errors.PhaseOfSchool &&
                                errors.PhaseOfSchool}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <AnalyticsDropdown
                              label='Sector'
                              name='Sector'
                              options={this.state.sector}
                              selectedOption={this.state.selectedSector}
                              value={values.Sector}
                              onChange={(option) => {
                                this.setState({
                                  selectedSector: option.target,
                                });
                                setFieldValue('Sector', option.value);
                              }}
                            />
                            <ErrorWrapper>
                              {touched.Sector && errors.Sector && errors.Sector}
                            </ErrorWrapper>
                          </WrapperMain>
                          {isEdit ? (
                            <WrapperMain>
                              <FloatingInputLabel
                                label='Sign Up Status'
                                name='signUpStatus'
                                value={this.state.signUpStatus}
                                readOnly={true}
                              />
                            </WrapperMain>
                          ) : (
                            <></>
                          )}
                          <WrapperMain>
                            <FloatingTextareaLabel
                              label='Where did you hear about us'
                              placeholder='Where did you hear about us'
                              name='HearAboutUs'
                              value={values.HearAboutUs}
                              onChange={handleChange}
                            />
                            <ErrorWrapper>
                              {touched.HearAboutUs &&
                                errors.HearAboutUs &&
                                errors.HearAboutUs}
                            </ErrorWrapper>
                          </WrapperMain>
                          <WrapperMain>
                            <div className='box'>
                              <input
                                type='file'
                                name='file-7[]'
                                id='file-7'
                                accept='image/x-png,image/jpeg'
                                className='inputfile inputfile-6'
                                data-multiple-caption='{count} files selected'
                                onChange={(event) => {
                                  var files = event.target.files;
                                  var file = files[0];
                                  var trancateString = this.splitName(
                                    file.name
                                  );
                                  if (files && file) {
                                    var reader = new FileReader();
                                    reader.onload = function (readerEvt) {
                                      var binaryString =
                                        readerEvt.target.result;
                                      setFieldValue('File', btoa(binaryString));
                                      setFieldValue('FileName', file.name);
                                      setFieldValue(
                                        'TrancateFileName',
                                        trancateString
                                      );
                                    };
                                    reader.readAsBinaryString(file);
                                  }
                                }}
                              />
                              <label>
                                <span className='no-file-upload '>
                                  {values.TrancateFileName
                                    ? values.TrancateFileName
                                    : 'Upload a logo'}
                                </span>
                                <strong>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='17'
                                    viewBox='0 0 20 17'
                                  >
                                    <path d='M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z' />
                                  </svg>{' '}
                                  Choose a file&hellip;
                                </strong>
                              </label>
                            </div>
                            <ErrorWrapper>
                              {touched.File && errors.File && errors.File}
                            </ErrorWrapper>
                          </WrapperMain>
                          <FieldWrapper>
                            <CheckBox>
                              <input
                                class='styled-checkbox'
                                id='IsTestAccount'
                                type='checkbox'
                                value='value1'
                                checked={values.IsTestAccount}
                                onChange={(e) => {
                                  this.setState({
                                    IsTestAccount: e.target.checked,
                                  });
                                }}
                              />
                              <label for='IsTestAccount'>
                                <span className='checkbox-wrapper'>
                                  Is Test Account
                                </span>
                              </label>
                            </CheckBox>
                          </FieldWrapper>
                          <FieldWrapper>
                            <AppButton
                              color={mandy}
                              type='submit'
                              onClick={handleSubmit}
                            >
                              {isEdit ? 'Update' : 'Submit'}
                            </AppButton>
                          </FieldWrapper>
                        </InputWrapper>
                      </ExtendedAssitanceForm>
                    </RegisterInner>
                  </RegisterInnerMain>
                </Grid>
              </Grid>
              <MainFooter>
                <span>
                  <img src={footerLogo} alt='logo' />
                </span>
                <span> Survey provided by</span>
              </MainFooter>
            </RegisterWrapper>
          </div>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(SchoolRegister));
