import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LeftMenu, Paging, MainHeader, AppInputField, FloatingInputLabel } from '../../components';
import { AppButton } from '../../resources/styling';
import { CheckBox,TableStyle } from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { routesObj } from '../../routes';
import VoiceOptions from '../../components/common/VoiceOptions';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import WelbeeVoiceWelcomePopup from '../welbeeVoice/WelbeeVoiceWelcomePopup';
import { firstLoginAction } from '../../store/userStore';
import { loaderAction } from '../../store/loaderStore';
import { getVoiceDate, getQueryStringVal } from '../../utils/CommonFunctions';
import { WrapperMain } from '../../resources/styling/formStyle';
import { AssistanceWrapperHeading } from '../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import NewWelbeeVoiceForm from './NewWelbeeVoiceForm';
import { welbeeVoiceService, storageService, schoolService } from '../../services';
import { WebStorageNames, Roles, QueryStringTokens } from '../../utils';
import { StaffTypes, MenuItems, HeadTeacherAccess } from '../../utils/Constants';
import { navigationActions } from '../../store/headerStore';
import { voicenotifications } from '../../resources/images/index';
import { ExtendedAssistanceWrapper, ExtendedVoiceDashboardWrapper, ExtendedConversationBody, PaginationWrapper } from './WelbeeVoiceStyle';

const { mandy, teal } = colors;

const SortOrder = {
    Newest: 'Newest',
    Oldest: 'Oldest',
};

class WelbeeVoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            converstionList: [],
            openConverstionPopUp: false,
            isArchive: false,
            isResendInvite: false,
            archiveItem: {},
            selectedOption: { value: StaffTypes.All, label: 'All staff' },
            sortingSelectedOption: { value: SortOrder.Newest, label: 'Newest first' },
            noOfStaff: '',
            isVoiceFirstLogin: false,
            newConverstionVoicePopUpShow: storageService.getItem(
                WebStorageNames.NewConverstionVoicePopUpShow
            ),
            isConfirmInvitation: false,
            voiceRecord: null,
            isFirstConverstaion: false,
            selectedConversation: null,
            voiceSelectedOption: { value: '', label: 'Select Conversation' },
            transformedConversationList: [],
            accessLevel: 0,
            newConversationMsg: false,
            pagedItems: [],
            keywords: '',
            getAll: true,
        };
    }

    componentDidMount() {
        const { voiceSelectedOption } = this.state;
        this.props.userAction.isBellNotificationAction(false);
        const schoolId = storageService.getItem(WebStorageNames.SchoolId);
        const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
        const { loaderActions, navigationActions } = this.props;
        loaderActions.dataLoading(true);
        navigationActions.activeItemAction({
            item: MenuItems.Conversations,
        });
        this.setState({ accessLevel: accessLevel });

        welbeeVoiceService
            .getConverstion(schoolId)
            .then((response) => {
                if (response.success) {
                    const conversatonList = response.data.data;
                    const selectedConv =
                        conversatonList.length > 0
                            ? conversatonList[conversatonList.length - 1]
                            : null;
                    const transformedList = this.getConversationList(response.data.data);
                    const selectedOption =
                        transformedList.length > 0
                            ? transformedList[transformedList.length - 1]
                            : voiceSelectedOption;
                    this.setState({
                        converstionList: response.data.data,
                        isVoiceFirstLogin: response.data.isVoiceFirstLogin,
                        selectedConversation: selectedConv,
                        transformedConversationList: transformedList,
                        voiceSelectedOption: selectedOption,
                        newConversationMsg: true,
                    });
                    this.props.navigationActions.showNewConversationCount({
                        item: response.data.unreadVoiceCount,
                    });
                    this.props.navigationActions.setSchoolLogoUrl(response.data.logoUrl);

                    welbeeVoiceService
                        .schoolStaffExist(schoolId)
                        .then((response) => {
                            if (response.success) {
                                this.setState({ noOfStaff: response.total });
                                loaderActions.dataLoading(false);

                                const {
                                    converstionList,
                                    isVoiceFirstLogin,
                                    noOfStaff,
                                } = this.state;
                                if (
                                    converstionList.length === 0 &&
                                    !isVoiceFirstLogin &&
                                    noOfStaff > 0 &&
                                    this.state.newConverstionVoicePopUpShow === null
                                )
                                    this.setState({ isFirstConverstaion: true });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    loaderActions.dataLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    onAllStaffClick(values) {
        const { noOfStaff } = this.state;
        if (!values.IsFirstEmailSent) {
            if (noOfStaff >= 8)
                this.setState({ isConfirmInvitation: true, voiceRecord: values });
            else {
                this.setState({ voiceRecord: values });
                toast.error(
                    <div>
                        You must select a minimum of 8 staff members to be able to send an
            invitation for a{' '}
                        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                            new
            </span>{' '}
            conversation.
          </div>
                );
            }
        } else {
            values.Id = 0;
            storageService.setItem(WebStorageNames.TempVoice, values);
            this.props.history.push(
                routesObj.WelbeeVoiceNotification.path + '?voiceId=0'
            );
        }
    }

    onNewConversationClick = () => {
        const { loaderActions } = this.props;
        const schoolId = storageService.getItem(WebStorageNames.SchoolId);
        loaderActions.dataLoading(true);
        this.setState({ openConverstionPopUp: true });
        schoolService
            .getSchoolStaff(schoolId)
            .then((response) => {
                loaderActions.dataLoading(false);
                const { data, success } = response;
                if (success) {
                    this.setState({ noOfStaff: data.length });
                }
            })
            .catch((error) => {
                loaderActions.dataLoading(false);
            });
    };

    onCreateConverstionClick() {
        const { loaderActions } = this.props;
        const { voiceRecord, voiceSelectedOption } = this.state;
        this.setState({ isFirstConverstaion: false });
        const schoolId = storageService.getItem(WebStorageNames.SchoolId);
        voiceRecord.SchoolId = schoolId;
        voiceRecord.IsAllStaffInviteSurveyVoice = true;
        storageService.setItem(WebStorageNames.NewConverstionVoicePopUpShow, false);
        loaderActions.dataLoading(true);
        this.setState({ isConfirmInvitation: false });
        console.log("This is the voice object " + voiceRecord)
        welbeeVoiceService
            .createConverstion(voiceRecord)
            .then((response) => {
                if (response.success) {
                    if (voiceRecord.IsFirstEmailSent) {
                        this.props.history.push(
                            routesObj.WelbeeVoiceNotification.path +
                            '?voiceId=' +
                            response.data.Id
                        );
                        this.setState({
                            openConverstionPopUp: false,
                            converstionList: [],
                            voiceRecord: null,
                        });
                    } else {
                        toast.success('Invitations sent successfully.');
                        const transformedList = this.getConversationList(response.data);
                        const selectedOption =
                            transformedList.length > 0
                                ? transformedList[transformedList.length - 1]
                                : voiceSelectedOption;
                        this.setState({
                            openConverstionPopUp: false,
                            converstionList: response.data,
                            isConfirmInvitation: false,
                            voiceRecord: null,
                            transformedConversationList: transformedList,
                            voiceSelectedOption: selectedOption,
                        });
                    }
                }
                loaderActions.dataLoading(false);
            })
            .catch((error) => {
                console.log(error);
                loaderActions.dataLoading(false);
            });
    }

    onDeleteButtonClick = () => {
        const { archiveItem, converstionList } = this.state;
        const itemToDelete = converstionList.find((x) => x.Id === archiveItem.Id);
        welbeeVoiceService
            .setVoiceConversationArchive(itemToDelete)
            .then((response) => {
                if (response.success) {
                    const updatedList = converstionList.filter(
                        (con) => con.Id !== archiveItem.Id
                    );
                    this.setState({
                        converstionList: updatedList,
                        archiveItem: {},
                        isArchive: false,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    onManualSendInviteButtonClick = (voice) => {
        welbeeVoiceService
            .setManualInviteForSurveyVoice(voice)
            .then((response) => {
                if (response.success) {
                    this.setState({
                        isResendInvite: true,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    onConverstionItemChange = (item) => {
        const { converstionList } = this.state;
        const selectedConv = converstionList.find((x) => x.Id === item.value);
        if (selectedConv !== null)
            this.setState({
                selectedConversation: selectedConv,
                voiceSelectedOption: item,
            });
    };

    onDetailButtonClick = (item) => {
        const { converstionList } = this.state;
        const selectedConv = converstionList.find((x) => x.Id === item.Id);
        if (selectedConv !== null) {
            const role = storageService.getItem(WebStorageNames.Role);
            let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
            if (role === Roles.GroupDirector || schoolToken !== null) {
                this.props.history.push(
                    routesObj.VoiceDashboard.path +
                    '?token=' +
                    selectedConv.VoiceToken +
                    '&schoolToken=' +
                    schoolToken
                );
            } else
                this.props.history.push(
                    routesObj.VoiceDashboard.path + '?token=' + selectedConv.VoiceToken
                );
        }
    };

    onEmailNotificationClick(item) {
        this.props.userAction.isBellNotificationAction(true);
        this.props.history.push(
            routesObj.WelbeeVoiceNotification.path + '?voiceId=' + item.Id
        );
    }
    closePopUp() {
        this.setState({ openConverstionPopUp: false });
        storageService.setItem(WebStorageNames.NewConverstionVoicePopUpShow, false);
    }
    redirectToStaffManagment() {
        this.props.history.push(routesObj.StaffManagement.path);
    }
    onWelbeeVoiceOK = () => {
        this.setState({ openConverstionPopUp: true });
    };
    onCloseWelcomePopup = () => {
        const schoolId = storageService.getItem(WebStorageNames.SchoolId);
        welbeeVoiceService
            .setFirstTimeVoiceLoggedInSuccessful(schoolId)
            .then((response) => {
                if (response.success) {
                    this.setState({
                        isVoiceFirstLogin: false,
                    });
                    storageService.setItem(WebStorageNames.IsVoiceFirstLogin, false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    onGoToInstructionsClick = () => {
        storageService.setItem(WebStorageNames.IsVoiceFirstLogin, false);
        this.onCloseWelcomePopup();
        this.props.userAction.doScroll(true);
    };

    getConversationList = (data) => {
        const transformedList = [];
        data.map((item) => {
            let label =
                item.Title +
                ' ' +
                (item.UnReadCount > 0 ? '(' + item.UnReadCount + ')' : '');
            let nameNDate = this.getNameAndVoiceDate(item);
            transformedList.push({
                value: item.Id,
                label: (
                    <div>
                        {label} {nameNDate}
                    </div>
                ),
            });
            return item;
        });
        return transformedList;
    };

    getConversation = (item) => {
        let labelCount = (item.UnReadCount > 0 ? ' (' + item.UnReadCount + ') ' : '');
        let nameNDate = this.getNameAndVoiceDate(item);

        return <span className='conversation-list-dropdown'>{item.Title} <b style={{ color: '#00b8d9' }}>{labelCount}</b> {nameNDate}</span>;
    };

    getNameAndVoiceDate = (item) => {
        let nameAndDate = getVoiceDate(item.AddedDate);
        if (item.HeadTeacher !== null) {
            nameAndDate = item.HeadTeacher.AdminName + ' ' + nameAndDate;
        }
        nameAndDate = '( ' + nameAndDate + ' )';
        return <i style={{ fontWeight: 'bold', fontSize: 15 }}>{nameAndDate}</i>;
    };

    getTrimedTitleOfItemToDelete = (trimLength = 50, Threshold = 5) => {
        const { archiveItem, converstionList } = this.state;
        const itemToDelete = converstionList.find((x) => x.Id === archiveItem.Id);
        if (!itemToDelete) return '';

        let Title = itemToDelete.Title;
        if (Title.length <= trimLength + Threshold) return Title;

        let allWords = Title.split(' ');

        let firstPart = '';
        let secondPart = '';
        for (let i = 0; allWords.length > 1;) {
            if (i < allWords.length / 2) {
                if (firstPart.length + allWords[i].length < trimLength / 2) {
                    firstPart += ' ' + allWords[i];
                    i++;
                } else i = allWords.length - 1;
            } else if (secondPart.length + allWords[i].length < trimLength / 2) {
                secondPart = allWords[i] + ' ' + secondPart;
                i--;
            } else break;
        }
        firstPart = firstPart.trim();
        secondPart = secondPart.trim();

        trimLength = 35;
        if (firstPart.length === 0)
            firstPart = allWords[0].substr(0, trimLength / 2);
        if (secondPart.length === 0) {
            const last = allWords[allWords.length - 1];
            secondPart = last.substr(last.length - trimLength / 2);
        }

        return firstPart + '...' + secondPart;
    };

    onChangePage = (pagedData) => {
        this.setState({ pagedItems: pagedData });
    };

    searchKeywords = () => {
        const schoolId = storageService.getItem(WebStorageNames.SchoolId);
        welbeeVoiceService.searchVoiceConversation(schoolId, this.state.keywords, this.state.getAll).then(
            (response) => {
                if (response.success) {
                    this.setState({ converstionList: response.data, newConversationMsg: false });
                }
            }
        );
    }

    render() {
        const {
            openConverstionPopUp,
            converstionList,
            noOfStaff,
            isVoiceFirstLogin,
            isConfirmInvitation,
            isFirstConverstaion,
            pagedItems,
            accessLevel,
            newConversationMsg,
        } = this.state;
        return (
            <ExtendedVoiceDashboardWrapper>
                {isVoiceFirstLogin && (
                    <WelbeeVoiceWelcomePopup
                        isOpen={isVoiceFirstLogin}
                        onClose={() => {
                            this.onCloseWelcomePopup();
                        }}
                        onOk={
                            //noOfStaff > 0
                            //  ? this.onWelbeeVoiceOK
                            this.onGoToInstructionsClick
                        }
                        buttonText={
                            //noOfStaff > 0
                            //  ? 'Start your first conversation'
                            'Go to instructions'
                        }
                    />
                )}

                <div className='left-container'>
                    <LeftMenu />
                </div>

                <ConfirmPopupForm
                    isOpen={isConfirmInvitation}
                    onClose={() => this.setState({ isConfirmInvitation: false })}
                    onOk={() => this.onCreateConverstionClick()}
                    successPopupMessage={
                        'You are about to send your invitation to (' +
                        noOfStaff +
                        ') staff members.'
                    }
                    okButtonText='Confirm'
                />

                <div className='right-container'>
                    <MainHeader />
                    <ExtendedAssistanceWrapper>
                        <AssistanceWrapperHeading>
                            <h2 style={{margin: '0px'}}>Welbee Voice</h2>
                            <span> conversation list</span>
                        </AssistanceWrapperHeading>
                        {accessLevel !== HeadTeacherAccess.View && (
                            <VoiceOptions
                                showConversationDropDown={false}
                                addVoiceButtonClick={() => this.onNewConversationClick()}
                                isFirstConverstaion={isFirstConverstaion}
                            />
                        )}
                    </ExtendedAssistanceWrapper>
                    <ExtendedConversationBody>
                        <div className='content-body-inner-wrapper voice-body-inner-wrapper'>
                            <Grid container spacing={2}>
                                <Grid item
                                    lg={8}
                                    sm={12}
                                    md={8}
                                    xs={12}>
                                    <WrapperMain>
                                        <FloatingInputLabel
                                            label='Search keyword here'
                                            type='text'
                                            name='seachTxt'
                                            onChange={(e) => { this.setState({ keywords: e.target.value }, () => { this.searchKeywords(); }) }}
                                        />
                                    </WrapperMain>
                                </Grid>
                                <Grid item
                                    lg={4}
                                    sm={12}
                                    md={4}
                                    xs={12}>
                                    <WrapperMain>
                                        <CheckBox>
                                            <input style={{ left: "-70px" }}
                                                class='styled-checkbox'
                                                id='styled-checkbox-1'
                                                type='checkbox'
                                                checked={!this.state.getAll}
                                                onChange={(e) => { this.setState({ getAll: !e.target.checked }, () => { this.searchKeywords(); }) }}
                                            />
                                            <label for='styled-checkbox-1'>
                                                <span className='checkbox-wrapper'>
                                                    Created by me only
                                                </span>
                                            </label>
                                        </CheckBox>
                                    </WrapperMain>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item
                                    lg={12}
                                    sm={12}
                                    md={12}
                                    xs={12}>
                                    Subject: {openConverstionPopUp && (
                                        <NewWelbeeVoiceForm
                                            onSubmit={(value) => {
                                                this.onAllStaffClick(value);
                                            }}
                                            isLoading={false}
                                            submitButtonText={'Invite all staff'}
                                            closeButtonText={'Select staff to invite'}
                                            isOpen={this.state.openConverstionPopUp}
                                            onClose={() => {
                                                this.closePopUp();
                                            }}
                                            onSelectStaff={() => this.redirectToStaffManagment()}
                                            voiceRecord={this.state.voiceRecord}
                                        />
                                    )}
                                    {converstionList.length > 0 && (
                                        <TableStyle>
                                            <table className='voice-list'>
                                                {pagedItems.map((item) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {this.getConversation(item)}
                                                            </td>
                                                            <td>
                                                                <span>
                                                                    <AppButton
                                                                        color={teal}
                                                                        type='submit'
                                                                        onClick={() => this.onDetailButtonClick(item)}
                                                                    >
                                                                        Detail
                                                                    </AppButton>
                                                                    {accessLevel !== HeadTeacherAccess.View && (
                                                                        <div className='notification-btn'>
                                                                            <AppButton
                                                                                color=''
                                                                                type='submit'
                                                                                onClick={() => {
                                                                                    this.onEmailNotificationClick(item);
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={voicenotifications}
                                                                                    alt='Notification icon'
                                                                                />
                                                                                <span className='notification-tooltip'>
                                                                                    <p>
                                                                                        Click here to resend individual invitations
                                                                                        or to invite new or more staff to this
                                                                                        conversation. If you are inviting new staff
                                                                                        members you will need to first add them
                                                                                        using the Staff Management tab in your menu.
                                                                                    </p>
                                                                                </span>
                                                                            </AppButton>
                                                                        </div>
                                                                    )}
                                                                    {accessLevel !== HeadTeacherAccess.View && (
                                                                        <AppButton
                                                                            color={mandy}
                                                                            type='submit'
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    isArchive: true,
                                                                                    archiveItem: item,
                                                                                })
                                                                            }
                                                                        >
                                                                            Delete
                                                                        </AppButton>
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </TableStyle>
                                    )}
                                    {noOfStaff === 0 ? (
                                        <p className='staff-member-link'>
                                            <Link to='/staff-management'>
                                                Please add your staff members details
                                            </Link>
                                        </p>
                                    ) : converstionList.length === 0 ? (
                                        newConversationMsg ? (
                                            <p>
                                                Click the new conversation button to send your first staff
                                                invitation
                                            </p>
                                        ) : (
                                            <p>{''}</p>
                                        )
                                    ) : null}
                                    <PaginationWrapper>
                                        <Paging
                                            items={converstionList}
                                            onChangePage={(items) => this.onChangePage(items)}
                                        />
                                    </PaginationWrapper>
                                </Grid>
                            </Grid>
                        </div>
                    </ExtendedConversationBody>
                </div>

                <ConfirmPopupForm
                    isOpen={this.state.isArchive}
                    onClose={() => this.setState({ isArchive: false, archiveItem: {} })}
                    onOk={this.onDeleteButtonClick}
                    successPopupMessage={`Are you sure to archive this conversation "${this.getTrimedTitleOfItemToDelete()}"?`}
                />
                <ConfirmPopupForm
                    isOpen={this.state.isResendInvite}
                    onClose={() => this.setState({ isResendInvite: false })}
                    onOk={() => this.setState({ isResendInvite: false })}
                    successPopupMessage='Invites have been sent'
                />
            </ExtendedVoiceDashboardWrapper>
        );
    }
}
const mapStateToProps = ({ user: { isFirstLogin } }) => {
    return {
        isFirstLogin,
    };
};

const mapDispatchToProps = (dispatch) => ({
    userAction: bindActionCreators(firstLoginAction, dispatch),
    loaderActions: bindActionCreators(loaderAction, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WelbeeVoice));
