import React from 'react';
import { colors } from '../../resources/theme/colors';
import ChartPercentLegend from '../../components/common/ChartPercentLegend';
import { ChartBar, ExtendedChartWrapper } from './DashboardStyle';

const { never, seldom, sometimes, often, always } = colors;

const IndividualQuestionChart = ({ options, index, data }) => (
  <ExtendedChartWrapper>
    <div className='main-wrapper'>
      <span>
        <i>Results</i>
      </span>
      <div className='main'>
        {data.NeverPercentage > 0 ? (
          <ChartBar width={data.NeverPercentage} color={never}>
            <span>{data.Never}</span>
          </ChartBar>
        ) : null}

        {data.SeldomPercentage > 0 ? (
          <ChartBar width={data.SeldomPercentage} color={seldom}>
            <span>{data.Seldom}</span>
          </ChartBar>
        ) : null}

        {data.SometimesPercentage > 0 ? (
          <ChartBar width={data.SometimesPercentage} color={sometimes}>
            <span>{data.Sometimes}</span>
          </ChartBar>
        ) : null}

        {data.OftenPercentage > 0 ? (
          <ChartBar width={data.OftenPercentage} color={often}>
            <span>{data.Often}</span>
          </ChartBar>
        ) : null}

        {data.AlwaysPercentage > 0 ? (
          <ChartBar width={data.AlwaysPercentage} color={always}>
            <span>{data.Always}</span>
          </ChartBar>
        ) : null}
      </div>
      <ChartPercentLegend />
    </div>
  </ExtendedChartWrapper>
);

export default IndividualQuestionChart;
