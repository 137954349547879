import request from '../request';
import { schoolAPI } from './urls';

const getSchoolList = (schoolsWithLiveSurveys) => {
  return request({
    url: schoolAPI.getAllSchools(schoolsWithLiveSurveys),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const addSchool = (school) => {
  return request({
    url: schoolAPI.api,
    method: 'POST',
    data: school,
  })
    .then((response) => response)
    .catch((error) => error);
};

const getSchoolDetail = (id, schoolToken = null) => {
  schoolToken = schoolToken === null ? '' : schoolToken;
  return request({
    url: schoolAPI.getSchoolDetail(id, schoolToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getSchool = (schoolToken = null) => {
  schoolToken = schoolToken === null ? '' : schoolToken;
  return request({
    url: schoolAPI.getSchoolDetailByToken(schoolToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getInfo = (id) => {
  return request({
    url: schoolAPI.getInfo(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getSchoolDetailBySuperAdmin = (id) => {
  return request({
    url: schoolAPI.getSchoolDetailBySuperAdmin(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getSchoolDetailByRegistrationToken = (token) => {
  return request({
    url: schoolAPI.getSchoolDetailByRegistrationToken(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const updateSchoolDetail = (school) => {
  return request({
    url: schoolAPI.updateSchoolDetail,
    method: 'PUT',
    data: school,
  })
    .then((response) => response)
    .catch((error) => error);
};

const updateSchoolDetailOnly = (school) => {
  return request({
    url: schoolAPI.UpdateSchoolOnly,
    method: 'PUT',
    data: school,
  })
    .then((response) => response)
    .catch((error) => error);
};
const updateSchoolDetailByHeadTeacher = (school) => {
  return request({
    url: schoolAPI.updateSchoolDetailByHeadTeacher,
    method: 'PUT',
    data: school,
  })
    .then((response) => response)
    .catch((error) => error);
};

const updateSchoolAdminInfoByHeadTeacher = (school) => {
  return request({
    url: schoolAPI.updateSchoolAdminInfoByHeadTeacher,
    method: 'PUT',
    data: school,
  })
    .then((response) => response)
    .catch((error) => error);
};

const archiveSchool = (schoolId) => {
  return request({
    url: schoolAPI.archiveSchool(schoolId),
    method: 'DELETE',
  })
    .then((response) => response)
    .catch((error) => error);
};

const updateSchoolInvoiceInfo = (data) => {
  return request({
    url: schoolAPI.updateSchoolInvoiceInfo,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((error) => error);
};
const sendUpgradeInvoice = (data) => {
  return request({
    url: schoolAPI.SendUpgradeInvoice,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((error) => error);
};
const updateSchoolWelbeeVoice = (data) => {
  return request({
    url: schoolAPI.updateSchoolWelbeeVoice,
    method: 'PUT',
    data: data,
  })
    .then((response) => response)
    .catch((error) => error);
};

const getAddressByPostCode = (postCode) => {
  return fetch('http://api.postcodes.io/postcodes/' + postCode)
    .then((response) => response.json())
    .then((response) => response)
    .catch((error) => error);
};
const getAddressSuggestions = (keyword) => {
  return request({
    url: schoolAPI.getAddressSuggestions(keyword),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getSchoolStaff = (schoolId, schoolToken = null) => {
  schoolToken = schoolToken === null ? '' : schoolToken;
  return request({
    url: schoolAPI.getSchoolStaff(schoolId, schoolToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const deleteSchoolStaff = (id) => {
  return request({
    url: schoolAPI.deleteSchoolStaff(id),
    method: 'DELETE',
  })
    .then((response) => response)
    .catch((error) => error);
};

const updateSchoolStaff = (schoolStaff) => {
  return request({
    url: schoolAPI.updateSchoolStaff,
    method: 'PUT',
    data: schoolStaff,
  })
    .then((response) => response)
    .catch((error) => error);
};

const hideUpgradeBanner = (schoolId) => {
  return request({
    url: schoolAPI.hideUpgradeBanner(schoolId),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getNumberOfPupil = (schoolId, token) => {
  return request({
    url: schoolAPI.getNumberOfPupil(schoolId === null ? 0 : schoolId, token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const GetSchoolsForMat = () => {
  return request({
    url: schoolAPI.GetSchoolsForMat,
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const GetSchoolsForMatWithCloseSurvey = () => {
  return request({
    url: schoolAPI.GetSchoolsForMatWithCloseSurvey,
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getSchoolSubscriptions = (id) => {
  return request({
    url: schoolAPI.getSchoolSubscriptions(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};
const addSchoolSubscriptions = (schoolId, id) => {
  return request({
    url: schoolAPI.addSchoolSubscriptions(schoolId, id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};
const GetOutstandingInvoices = () => {
  return request({
    url: schoolAPI.GetOutstandingInvoices(),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};
const SendInvoices = (invoices) => {
  return request({
    url: schoolAPI.SendInvoices(),
    method: 'POST',
    data: invoices,
  })
    .then((response) => response)
    .catch((error) => error);
};
const hasAdditionalSurvey = (schoolId) => {
  return request({
    url: schoolAPI.hasAdditionalSurvey(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};
const generateInvoiceForAdditionalSurveyBuy = (schoolId) => {
  return request({
    url: schoolAPI.generateInvoiceForAdditionalSurveyBuy(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};
const GetAllSchoolsForSubscription = () => {
  return request({
    url: schoolAPI.GetAllSchoolsForSubscription(),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};
const UpdateSubscription = (data) => {
  return request({
    url: schoolAPI.UpdateSubscription(),
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((error) => error);
};
const downLoadSchoolSubscription = (
  createdOnSort,
  StartDateSort,
  endDateSort,
  name,
  archivedOnly,
  testAccountOnly
) => {
  return request({
    url: schoolAPI.downLoadSchoolSubscription(
      createdOnSort,
      StartDateSort,
      endDateSort,
      name,
      archivedOnly,
      testAccountOnly
    ),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};
const getSentEmails = () => {
  return request({
    url: schoolAPI.getSentEmails(),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};
const GetSentEmailsWithDateTime = (val) => {
  return request({
    url: schoolAPI.GetSentEmailsWithDateTime(val),
    method: 'GET',
  })
    .then((response) => response)
    .catch((error) => error);
};
const downloadSESEmails = (name, from) => {
  return request({
    url: schoolAPI.downloadSESEmails(name, from),
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => response)
    .catch((error) => error);
};
const schoolService = {
  downloadSESEmails,
  getSentEmails,
  downLoadSchoolSubscription,
  UpdateSubscription,
  getSchoolList,
  addSchool,
  getSchoolDetail,
  getSchool,
  updateSchoolDetail,
  updateSchoolDetailByHeadTeacher,
  updateSchoolAdminInfoByHeadTeacher,
  archiveSchool,
  updateSchoolInvoiceInfo,
  getSchoolDetailByRegistrationToken,
  getAddressByPostCode,
  updateSchoolWelbeeVoice,
  getAddressSuggestions,
  getSchoolStaff,
  deleteSchoolStaff,
  updateSchoolStaff,
  getSchoolDetailBySuperAdmin,
  getNumberOfPupil,
  sendUpgradeInvoice,
  hideUpgradeBanner,
  getInfo,
  GetSchoolsForMat,
  GetSchoolsForMatWithCloseSurvey,
  getSchoolSubscriptions,
  addSchoolSubscriptions,
  GetOutstandingInvoices,
  SendInvoices,
  hasAdditionalSurvey,
  generateInvoiceForAdditionalSurveyBuy,
  GetAllSchoolsForSubscription,
  GetSentEmailsWithDateTime,
  updateSchoolDetailOnly,
};

export default schoolService;
