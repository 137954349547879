import React, { useState, useEffect } from 'react';
import { SurveyDropDown } from '..';
import { dashboardService, storageService } from '../../services';
import { SurveyTypes, QueryStringTokens } from '../../utils/Constants';
import {
  getTransformedSchoolRecordList,
  getQueryStringVal,
} from '../../utils/CommonFunctions';
import { WebStorageNames } from '../../utils/Constants';

function MatSchoolListDropDown(props) {
  const [latestSurveyOption] = useState({
    value: '',
    label: 'All schools',
  });
  const [latestSurveyOptions, setLatestSurveyOptions] = useState([
    { value: SurveyTypes.any, label: 'All schools' },
  ]);
  useEffect(() => {
    let token = getQueryStringVal(QueryStringTokens.token);
    if (token === null)
      token = storageService.getItem(WebStorageNames.MatUserId);
    dashboardService
      .getMatGroupSchoolName(token)
      .then((response) => {
        const surveyRecordList = getTransformedSchoolRecordList(response.data);
        setLatestSurveyOptions(surveyRecordList);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleSchoolValueChange = (selectedOption) => {
    props.onSchoolChange(selectedOption);
  };

  return (
    <SurveyDropDown
      //isRole={isRole}
      latestSurveyOptions={latestSurveyOptions}
      latestSurveyOption={latestSurveyOption}
      handleSurveyValueChange={handleSchoolValueChange}
    />
  );
}

export default MatSchoolListDropDown;
