import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  PageWrapper,
  AppButton,
  MainFooter,
  MobileLogoWrapper,
  DesktopLogoWrapper,
} from '../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import { BaseCSS } from 'styled-bootstrap-grid';
import Header from '../../components/Header';
import { colors } from '../../resources/theme/colors';
import { footerLogo } from '../../resources/images';
import { routesObj } from '../../routes';
import { surveyService } from '../../services';
import { surveyAction } from '../../store/surveyStore';
import { amplitudeLog } from '../../utils/CommonFunctions';
import { WelcomeMainWrapper, customCSS, WelcomeWrapper, WelcomeHeaderWrapper, WelcomeInnerWrapper} from './SurveyWelcomeStyle';

const {
  outrageousOrange
} = colors;

class SurveyWelcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyToken: '',
      schoolName: '',
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const surveyToken = window.location.search;

    surveyService.isSurveyClosed(surveyToken).then((response) => {
      const { data } = response;
      if(data){
        this.props.action.setSurveyRecord(data.SchoolName);
        if (data.IsSurveyClosed) {
          history.push(routesObj.SurveyComplete.path + surveyToken);
        }
      }
      
    });
  }

  loadSurvey() {
    const surveyToken = window.location.search;
    this.props.history.push(routesObj.Survey.path + surveyToken);
    amplitudeLog('Survey', 'StartSurvey', '');
  }

  render() {
    return (
      <PageWrapper>
        <BaseCSS css={customCSS} />
        <WelcomeHeaderWrapper>
          <Header style={{ border: '1px solid red' }} />
        </WelcomeHeaderWrapper>
        <WelcomeMainWrapper>
          <Grid container spacing={2}>
            
              <Grid item
                lg={9}
                sm={12}
                md={9}
                xs={12}                
                className='login-mobile-wrapper'
              >
                <WelcomeWrapper>
                  <WelcomeInnerWrapper>
                    <MobileLogoWrapper>
                      <img src={this.props.SchoolLogo} alt='' />
                    </MobileLogoWrapper>
                    {this.props.SchoolName === "Queen's College, London" ? (
                      <div>
                        <h1>Hello,</h1>
                        <h2>
                          Please tell us what you think about working at
                          <span className='school-name'>
                            {this.props.SchoolName ? this.props.SchoolName : ''}
                            <i>.</i>
                          </span>
                        </h2>
                      </div>
                    ) : (
                      <div>
                        <h1>Hello,</h1>
                        <h2>
                          Please help make
                          <span className='school-name'>
                            {this.props.SchoolName ? this.props.SchoolName : ''}
                          </span>
                          an even better place to work!
                        </h2>
                      </div>
                    )}
                    <p>                   
                      We are committed to supporting all staff with their 
                      wellbeing and this survey will help us to do that. The 
                      survey should take around ten minutes to complete and when 
                      answering each question please think about your work over 
                      the last six months. All the answers you give will be 
                      anonymous. Thank you for participating.
                    </p>
                    <AppButton
                      color={outrageousOrange}
                      data-test-id='welcomeButton'
                      id='btn-startsurvey'
                      onClick={() => this.loadSurvey()}
                    >
                      Start Survey
                    </AppButton>
                  </WelcomeInnerWrapper>
                </WelcomeWrapper>
              </Grid>

              <Grid item
                lg={3}
                sm={12}
                xs={12}
                md={3}
                className='logo-container welcome-right-panel'
              >
                <DesktopLogoWrapper>
                  <img src={this.props.SchoolLogo} alt='' />
                </DesktopLogoWrapper>
              </Grid>
            
          </Grid>
          <MainFooter>
            <span>
              <img src={footerLogo} alt='logo' />
            </span>
            <span> Survey provided by</span>
          </MainFooter>
        </WelcomeMainWrapper>
      </PageWrapper>
    );
  }
}
const mapStateToProps = (state) => {
  const { surveyRecord } = state.survey;
  return surveyRecord;
};
const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators(surveyAction, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SurveyWelcome));
