import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import {
  AppButton,
  customStyles,
  ErrorWrapper,
  StaffWrapper,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme';
import { filterLibraryService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { StaffJobRoles } from '../../utils/Constants';

const { purple } = colors;

const AddFilter = (props) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [department, setDepartment] = useState('');
  const [phaseYear, setPhaseYear] = useState('');
  const [isApproved] = useState(true);

  const handleJobRoleChange = (e, v) => {
    setJobRole(v);
  };
  const jobRoleSuggestions =
    StaffJobRoles !== null
      ? StaffJobRoles.sort((a, b) => (a.title > b.title ? 1 : -1)).map(
          (item, i) => {
            return {
              title: item.title === null ? 'None' : item.title,
              key: item.id,
            };
          }
        )
      : null;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        jobTitle: jobTitle !== null ? jobTitle : '',
        jobRole: jobRole !== null ? jobRole : '',
        department: department !== null ? department : '',
        phaseYear: phaseYear !== null ? phaseYear : '',
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        jobTitle: Yup.string().trim().required('Job Title is required.'),
        jobRole: Yup.string().trim().required('Job Role is required.'),
      })}
      onSubmit={(values, formProps) => {
        setBtnDisabled(true);

        let model = {
          KeyWord: values.jobTitle,
          JobRole: values.jobRole,
          Department: values.department,
          PhaseYear: values.phaseYear,
          IsApproved: isApproved,
        };
        filterLibraryService
          .saveFilter(model)
          .then((response) => {
            setBtnDisabled(false);
            if (response.success) {
              toast.success('Record added successfully.');
              props.handleClose();
              props.getFilterLibrary();
            } else if (
              response.errors.KeyWord[0] === 'job title already exist'
            ) {
              formProps.setErrors({ jobTitle: 'job title already exist' });
            } else {
              toast.error('Record not added. Please try again later.');
            }
          })
          .catch((error) => {
            setBtnDisabled(false);
          });
      }}
      render={({ values, touched, errors, handleSubmit, setFieldValue }) => {
        const { handleClose } = props;
        return (
          <Grid item xs={12}>
            <AppModel
              onClose={handleClose}
              styles={customStyles}
              open={true}
              titleText={'Add Filter'}
              bodyText={
                <div>
                  <p style={{ marginTop: '0px', marginBottom: '25px' }}>
                    Add your new filter into library.
                  </p>
                  <Box
                    component='form'
                    sx={{
                      '& .MuiTextField-root': { width: '100%' },
                    }}
                    autoComplete='off'
                  >
                    <StaffWrapper>
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='jobTitle'
                            id='job-title'
                            label='Job Title'
                            onChange={(e) => {
                              setFieldValue('jobTitle', e.target.value);
                              setJobTitle(e.target.value);
                            }}
                          />
                          <ErrorWrapper>
                            {touched.jobTitle && errors.jobTitle}
                          </ErrorWrapper>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <FormControl fullWidth>
                            <Autocomplete
                              name='jobRole'
                              id='core-jobRole'
                              options={[
                                ...new Set(
                                  jobRoleSuggestions.map((opt) => opt.title)
                                ),
                              ]}
                              onChange={(e, v) => {
                                setFieldValue('jobRole', v);
                                handleJobRoleChange(e, v);
                              }}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField {...params} label={'Job Role'} />
                              )}
                              renderOption={(props, option, { inputValue }) => {
                                const matches = match(option, inputValue);
                                const parts = parse(option, matches);

                                return (
                                  <li {...props}>
                                    <div>
                                      {parts.map((part, index) => (
                                        <span
                                          key={index}
                                          style={{
                                            fontWeight: part.highlight
                                              ? 700
                                              : 400,
                                          }}
                                        >
                                          {part.text}
                                        </span>
                                      ))}
                                    </div>
                                  </li>
                                );
                              }}
                            />
                            <ErrorWrapper>
                              {touched.jobRole && errors.jobRole}
                            </ErrorWrapper>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='department'
                            id='department'
                            label='Department'
                            onChange={(e) => {
                              setFieldValue('department', e.target.value);
                              setDepartment(e.target.value);
                            }}
                          />
                          <ErrorWrapper>
                            {touched.department && errors.department}
                          </ErrorWrapper>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='phaseYear'
                            id='phaseYear'
                            label='PhaseYear'
                            onChange={(e) => {
                              setFieldValue('phaseYear', e.target.value);
                              setPhaseYear(e.target.value);
                            }}
                          />
                          <ErrorWrapper>
                            {touched.phaseYear && errors.phaseYear}
                          </ErrorWrapper>
                        </Grid>
                      </Grid>

                      <AppButton
                        color={purple}
                        type='button'
                        onClick={handleSubmit}
                        style={{ marginTop: '20px' }}
                      >
                        {btnDisabled ? 'Saving...' : 'Save'}
                      </AppButton>
                    </StaffWrapper>
                  </Box>
                </div>
              }
            />
          </Grid>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(AddFilter);
