import React from 'react';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../../mat/MatStyle';

const getHeatmapCellMd = (level, value, isDecile) => {
  return (
    <div
      className={
        level <= 3 ? 'width-20-green' : level >= 7 ? 'width-20-red' : 'width-10'
      }
    >
      {value >= 1 ? (
        <>
          {' '}
          {value.toFixed(2)}
          <span className='score-superscript'>
            ({level}
            <sup>
              {level == 1 ? 'st' : level == 2 ? 'nd' : level == 3 ? 'rd' : 'th'}
            </sup>
            )
          </span>
        </>
      ) : (
        '-'
      )}
    </div>
  );
};
const ScoreByJobRoleHeatmap = (props) => {
  var groupType = 'MAT';
  if (props.region == 'us') {
    groupType = 'Districe';
  }
  console.log(props.JobRoleHeatmap);
  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section' id='summery-score'>
          {/* {props.SchoolName} {'MAT Presentation'}
                    <AppLogo type="overview" color={colors.lightPink} width="100" /> 
                      */}
          <span className='app-logo'>
            <img src={props.welbeeslideslogo} alt='' />
          </span>
          <h1 className='pres-title'>{props.title}</h1>

          <div id='score-job'>
            <p className='pres-text'>
              The average scores for participants in each of the core job role
              filters are shown in heatmap 2 below. Again comparatively high
              scores are shaded green and comparatively lower ones red. Those
              without shading are close to average. Using heatmaps in your{' '}
              {groupType} Dashboard you can apply further filters to identify
              where scores may be higher and lower.
            </p>
            <p className='table-title'>Heatmap 2: Scores by Job Role</p>
            <div className='section'>
              <div className='job-role-container'>
                <div style={{ width: '25%', float: 'left' }}>&nbsp;</div>
                <div className='stats-role-cell'>Demands</div>
                <div className='stats-role-cell'>Control</div>
                <div className='stats-role-cell'>Manager Support</div>
                <div className='stats-role-cell'>Peer Support</div>
                <div className='stats-role-cell'>Relationships</div>
                <div className='stats-role-cell'>Role</div>
                <div className='stats-role-cell'>Change</div>
              </div>
              <div style={{ width: '100%' }}>
                {props.JobRoleHeatmap.map((row) => {
                  return (
                    <div className='job-role-container'>
                      <div
                        style={{ width: '25%', marginLeft: '0px' }}
                        className='job-role-title-cell'
                      >
                        {row.Name}
                      </div>
                      {getHeatmapCellMd(row.DemandSDecile, row.Demand, false)}
                      {getHeatmapCellMd(row.ControlSDecile, row.Control, false)}
                      {getHeatmapCellMd(
                        row.ManagerSupportSDecile,
                        row.ManagerSupport,
                        false
                      )}
                      {getHeatmapCellMd(
                        row.PeerSupportSDecile,
                        row.PeerSupport,
                        false
                      )}
                      {getHeatmapCellMd(
                        row.RelationshipsSDecile,
                        row.Relationships,
                        false
                      )}
                      {getHeatmapCellMd(row.RoleSDecile, row.Role, false)}
                      {getHeatmapCellMd(row.ChangeSDecile, row.Change, false)}
                    </div>
                  );
                })}
              </div>
            </div>
            <p className='section-bottom'>
              * Heatmaps of all filtered scores can be viewed and downloaded
              from your dashboard.
            </p>
          </div>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default ScoreByJobRoleHeatmap;
