import React, { Component } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { AppButton } from '../../resources/styling';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { FloatingInputLabel, FloatingTextareaLabel } from '../../components';
import { colors } from '../../resources/theme/colors';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { AppModel } from '../../components';
import { loaderAction } from '../../store/loaderStore';
import { actionPlanService, storageService } from '../../services';
import {
  ActionPlanCategoryList,
  QueryStringTokens,
  WebStorageNames,
} from '../../utils';
import { actionPlanActions } from '../../store/actionPlanStore';
import { DateFormat, getQueryStringVal } from '../../utils/CommonFunctions';
import { DatePickerWrapper } from '../../resources/styling/formStyle';
import { ExtendedWrapperMain } from '../../resources/styling/formStyle';
import {
  NewSurveyWrapper,
  NewSurveyInnerMain,
  Autocompletemui,
  WelbeeTextField,
} from '../../resources/styling/appStyle';
import {
  ActionPlanFormWrapper,
  InputWrapper,
  ExtendedFieldWrapper,
  NewSurveyInner,
  ModalInnerWrapper,
  FooterButtonsWrapper,
  ErrorWrapper,
} from './ActionPlanStyle';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';

const { mandy, lighterGrey } = colors;

class ActionsPlanForm extends Component {
  constructor(props) {
    super(props);
    
    let categoryValue = '';
    if (props.editActionRecord) {
      let oldCategories = ActionPlanCategoryList.filter(
        (item) => item.value === props.editActionRecord.Category
      );

      if (oldCategories.length > 0) categoryValue = oldCategories[0].label;
    }
    this.state = {
      formTitle: props.isEdit ? 'Update Action' : 'Create Action',
      isListOpen: false,
      isCalenderOpen: false,
      CategorySelected: categoryValue,
      selectedDate: props.editActionRecord
        ? props.editActionRecord.DueDate
        : '',
      formattedDueDate: props.editActionRecord
        ? props.editActionRecord.DueDate
          ? DateFormat(props.editActionRecord.DueDate)
          : ''
        : '',
      isEdit: props.isEdit,
      selectedDay: undefined,
      isEmpty: true,
      isDisabled: false,
      isEmailList: false,
      isAssigneeList: false,
      disabledBtn: false,
    };
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleListChange = this.handleListChange.bind(this);
    this.handleAssigneeListChange = this.handleAssigneeListChange.bind(this);
    this.handleEmailListChange = this.handleEmailListChange.bind(this);
    this.handleCalender = this.handleCalender.bind(this);
  }

  handleDayChange(selectedDay, modifiers, dayPickerInput) {
    const input = dayPickerInput.getInput();
    this.setState({
      selectedDay,
      isEmpty: !input.value.trim(),
      isDisabled: modifiers.disabled === true,
    });
  }

  handleCalender() {
    this.setState({
      isCalenderOpen: !this.state.isCalenderOpen,
      isListOpen: false,
      isEmailList: false,
      isAssigneeList: false,
    });
  }

  handleListChange() {
    this.setState({ isListOpen: !this.state.isListOpen });
  }

  handleAssigneeListChange() {
    this.setState({ isAssigneeList: !this.state.isAssigneeList });
  }

  handleEmailListChange() {
    this.setState({ isEmailList: !this.state.isEmailList });
  }

  handleDayClick = (dateStr, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    this.setState({
      selectedDate: dateStr,
      formattedDueDate: DateFormat(dateStr),
      isCalenderOpen: false,
    });
  };

  closeAllFieldsPopups = () => {
    this.setState({
      isListOpen: false,
      isCalenderOpen: false,
      isAssigneeList: false,
      isEmailList: false,
    });
  };

  render() {
    const {
      isOpen,
      onClose,
      handleSubmit,
      editActionRecord,
      emailRecord,
      assigneeRecord,
      actionPlanData,
    } = this.props;
    const { formTitle, isEdit, selectedDay } = this.state;
    const { mandy, teal } = colors;
    const catOpts = [
      { id: 1, name: 'Demands' },
      { id: 2, name: 'Control' },
      { id: 3, name: 'Manager Support' },
      { id: 4, name: 'Relationships' },
      { id: 5, name: 'Role' },
      { id: 6, name: 'Peer Support' },
      { id: 7, name: 'Change' },
      { id: 8, name: 'Pre Survey' },
      { id: 9, name: 'Running the Survey' },
      { id: 10, name: 'Post Survey' },
      { id: 11, name: 'Other' },
    ];

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          Title: editActionRecord ? editActionRecord.Title : '',
          Assignee: editActionRecord ? editActionRecord.Assignee : '',
          Email: editActionRecord ? editActionRecord.Email : '',
          DueDate: editActionRecord ? editActionRecord.DueDate : '',
          Category: editActionRecord ? editActionRecord.Category : '',
          Notes: editActionRecord ? editActionRecord.Notes : '',
          Id: editActionRecord ? editActionRecord.Id : 0,
          token: '',
          SchoolId: storageService.getItem(WebStorageNames.SchoolId),
        }}
        onSubmit={(values, actions) => {
          const { selectedDate, isEdit } = this.state;

          if (!isEdit) values.Id = 0;
          const tokenURl = window.location.search;
          let token = getQueryStringVal(QueryStringTokens.token);
          values.token = token;
          values.DueDate = selectedDate !== '' ? selectedDate : '';
          actions.setSubmitting(true);
          handleSubmit(values);
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={Yup.object().shape({
          Title: Yup.string().trim().required('Title is required.'),
          Assignee: Yup.string().trim().nullable(true),
          Email: Yup.string()
            .trim()
            .email('Please enter a valid email')
            .nullable(true),
          Notes: Yup.string().trim(),
        })}
        render={({
          values,
          handleChange,
          touched,
          errors,
          handleSubmit,
          setFieldValue,
        }) => (
          <div className='styles_modal__gNwvD'>
            <AppModel
              open={isOpen}
              onClose={onClose}
              bodyText={
                <ModalInnerWrapper>
                  <NewSurveyWrapper>
                    <Grid container>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <NewSurveyInnerMain>
                          <NewSurveyInner>
                            <ActionPlanFormWrapper>
                              <h2>{formTitle}</h2>
                              <div className='action-plan-instructions'>
                                Complete the details of your task below,
                                including the title, who it is assigned to and
                                in the notes you can set out the task to be
                                completed.
                              </div>

                              <InputWrapper>
                                <ExtendedWrapperMain>
                                  <WelbeeTextField
                                    fullWidth
                                    label='Action'
                                    name='Title'
                                    value={values.Title}
                                    onChange={(e) =>
                                      setFieldValue('Title', e.target.value)
                                    }
                                    onFocus={this.closeAllFieldsPopups}
                                  />
                                  <ErrorWrapper>
                                    {touched.Title &&
                                      errors.Title &&
                                      errors.Title}
                                  </ErrorWrapper>
                                </ExtendedWrapperMain>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    lg={6}
                                    sm={12}
                                    md={6}
                                    xs={12}
                                    style={{ paddingTop: '0px' }}
                                  >
                                    <ExtendedFieldWrapper
                                      onFocus={this.handleAssigneeListChange}
                                    >
                                      <WelbeeTextField
                                        fullWidth
                                        label='Assignee'
                                        name='Assignee'
                                        value={values.Assignee}
                                        onChange={(e) =>
                                          setFieldValue(
                                            'Assignee',
                                            e.target.value
                                          )
                                        }
                                        onFocus={this.closeAllFieldsPopups}
                                      />

                                      <ErrorWrapper>
                                        {touched.Assignee &&
                                          errors.Assignee &&
                                          errors.Assignee}
                                      </ErrorWrapper>
                                    </ExtendedFieldWrapper>
                                  </Grid>

                                  <Grid
                                    item
                                    lg={6}
                                    sm={12}
                                    md={6}
                                    xs={12}
                                    style={{ paddingTop: '0px' }}
                                  >
                                    <ExtendedFieldWrapper
                                      className='category-wrapper'
                                      onFocus={this.handleEmailListChange}
                                    >
                                      <WelbeeTextField
                                        id='ddl-email'
                                        label='Their email'
                                        name='Email'
                                        sx={{ width: '100%' }}
                                        value={values.Email}
                                        onChange={(e) =>
                                          setFieldValue('Email', e.target.value)
                                        }
                                        onFocus={this.closeAllFieldsPopups}
                                      />

                                      {/* <Autocompletemui
                                        id='ddl-email'
                                        getOptionLabel={(option) => option.name}
                                        sx={{ width: '100%' }}
                                        options={emailRecord}
                                        inputValue={values.Email}
                                        onInputChange={(
                                          event,
                                          newInputValue
                                        ) => {
                                          setFieldValue('Email', newInputValue);
                                        }}
                                        freeSolo
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label='Their email'
                                          />
                                        )}
                                      /> */}
                                      <ErrorWrapper>
                                        {touched.Email &&
                                          errors.Email &&
                                          errors.Email}
                                      </ErrorWrapper>
                                    </ExtendedFieldWrapper>
                                  </Grid>

                                  <Grid
                                    item
                                    lg={6}
                                    sm={12}
                                    md={6}
                                    xs={12}
                                    style={{ paddingTop: '0px' }}
                                  >
                                    <ExtendedFieldWrapper className='new-survey-placeholder'>
                                      <FloatingInputLabel
                                        label='Date'
                                        name='Due date'
                                        value={this.state.formattedDueDate}
                                        onChange={this.handleCalender}
                                        onFocus={this.handleCalender}
                                      />
                                      <DatePickerWrapper>
                                        {this.state.isCalenderOpen && (
                                          <DayPicker
                                            fromMonth={new Date()} // will not allow to select previose month
                                            disabledDays={[
                                              {
                                                before: new Date(), // will give only view as disable for past date
                                              },
                                            ]}
                                            showOutsideDays
                                            onDayClick={this.handleDayClick}
                                            dayPickerProps={{
                                              selectedDays: selectedDay,
                                              disabledDays: {
                                                daysOfWeek: [0, 6],
                                              },
                                            }}
                                          />
                                        )}
                                      </DatePickerWrapper>
                                      <ErrorWrapper>
                                        {touched.DueDate &&
                                          errors.DueDate &&
                                          errors.DueDate}
                                      </ErrorWrapper>
                                    </ExtendedFieldWrapper>
                                  </Grid>

                                  <Grid
                                    item
                                    lg={6}
                                    sm={12}
                                    md={6}
                                    xs={12}
                                    style={{ paddingTop: '0px' }}
                                  >
                                    <ExtendedFieldWrapper
                                      className='category-wrapper'
                                      onFocus={this.handleListChange}
                                    >
                                      <Autocomplete
                                        disablePortal
                                        id='combo-box-demo'
                                        options={catOpts}
                                        //  value={values.Category}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, val) => {
                                          setFieldValue('Category', val.name);
                                        }}
                                        ListboxProps={{
                                          style: { maxHeight: '8rem' },
                                          position: 'bottom-start',
                                        }}
                                        sx={{
                                          '.MuiAutocomplete-option[data-focus="true"]':
                                            {
                                              background: 'blue',
                                            },
                                          '& .MuiOutlinedInput-root': {
                                            '&.hover': {
                                              backgroundColor: `${teal} !important`,
                                            },
                                            '& fieldset': {
                                              borderColor: '#53B5E0',
                                            },
                                            '&:hover fieldset': {
                                              borderColor: '#53B5E0',
                                            },
                                            '&.Mui-focused fieldset': {
                                              borderColor: '#53B5E0',
                                            },
                                          },
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label='Category'
                                            value={catOpts.filter(
                                              (x) => x.name === values.Category
                                            )}
                                            onFocus={() =>
                                              this.setState({
                                                isListOpen: true,
                                                isCalenderOpen: false,
                                                isEmailList: false,
                                                isAssigneeList: false,
                                              })
                                            }
                                          />
                                        )}
                                      />

                                      <ErrorWrapper>
                                        {touched.Category &&
                                          errors.Category &&
                                          errors.Category}
                                      </ErrorWrapper>
                                    </ExtendedFieldWrapper>
                                  </Grid>
                                </Grid>

                                <ExtendedFieldWrapper>
                                  <FloatingTextareaLabel
                                    label='Notes'
                                    placeholder='Notes'
                                    name='Notes'
                                    value={values.Notes}
                                    onChange={handleChange}
                                    type='number'
                                    onFocus={this.closeAllFieldsPopups}
                                  />
                                  <ErrorWrapper>
                                    {touched.Notes &&
                                      errors.Notes &&
                                      errors.Notes}
                                  </ErrorWrapper>
                                </ExtendedFieldWrapper>
                              </InputWrapper>
                            </ActionPlanFormWrapper>
                          </NewSurveyInner>
                        </NewSurveyInnerMain>
                      </Grid>
                    </Grid>
                  </NewSurveyWrapper>
                </ModalInnerWrapper>
              }
              footer={
                <FooterButtonsWrapper>
                  {!this.state.disabledBtn ? (
                    <AppButton
                      color={mandy}
                      type='button'
                      onClick={() => {
                        if (!this.state.disabledBtn) {
                          this.setState({ disabledBtn: true });
                          handleSubmit();
                        }
                      }}
                    >
                      {isEdit ? 'Update' : 'Create'}
                    </AppButton>
                  ) : (
                    <AppButton color={lighterGrey} type='button'>
                      {isEdit ? 'Update' : 'Create'}
                    </AppButton>
                  )}
                </FooterButtonsWrapper>
              }
            />
          </div>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionPlanService, dispatch),
  actionPlanActions: bindActionCreators(actionPlanActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(ActionsPlanForm);
