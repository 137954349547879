import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
} from 'recharts';
import { ScrollContainer } from 'react-nice-scroll';
import Legends from './Legends';
import html2canvas from 'html2canvas';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Tooltip1 from '@mui/material/Tooltip';

const GroupedBarChart = ({ data, surveyCount, category, title }) => {
  const [isLoader, setIsLoader] = React.useState(false);
  const survey1Colors = {
    Demands: '#45338c',
    Control: '#e84f6b',
    Manager_Support: '#007a7a',
    Peer_Support: '#ff5939',
    Relationships: '#53b5e0',
    Role: '#fd92c2',
    Change: '#ffb205',
  };
  const survey2Colors = {
    Demands: '#978DE2',
    Control: '#F27F95',
    Manager_Support: '#18B5B2',
    Peer_Support: '#FF8D79',
    Relationships: '#82D4F4',
    Role: '#FDB0D6',
    Change: '#FFCD60',
  };

  const allCategories = Object.keys(survey2Colors);

  const reformatData = (originalData, surveyCount) => {
    const reformattedData = {};
    // Iterate over the original data
    originalData.forEach((entry) => {
      const label = entry.label;
      const characteristicsType = entry.characteristicsType;
      // Skip entries with characteristicsType other than 1-7, as we want to focus on these categories
      if (
        characteristicsType < 1 ||
        characteristicsType > 7 ||
        label === null
      ) {
        return;
      }
      // Check if both value1 and value2 are null, and if surveyCount is 1, skip the entire entry
      if (surveyCount === 1 && entry.value1 === null && entry.value2 === null) {
        return;
      }
      // Create or update the entry in the reformatted data
      if (!reformattedData[label]) {
        reformattedData[label] = { label };
      }
      // Add the score for the current category for both value1 and value2
      if (surveyCount === 1) {
        if (
          entry.value1 !== null &&
          entry.value1 !== -1 &&
          entry.value1 !== 0
        ) {
          reformattedData[label][`${entry.title}_value1`] = entry.value1;
          reformattedData[label][
            `${entry.title}_decileValue1`
          ] = `(${entry.decileValue1})`;
        }
        if (
          entry.value2 !== null &&
          entry.value2 !== -1 &&
          entry.value1 !== 0
        ) {
          reformattedData[label][`${entry.title}_value2`] = entry.value2;
          reformattedData[label][
            `${entry.title}_decileValue2`
          ] = `(${entry.decileValue2})`;
        }
      } else {
        reformattedData[label][`${entry.title}_value1`] =
          entry.value1 === -1 || entry.value1 === 0 ? null : entry.value1;
        reformattedData[label][`${entry.title}_value2`] =
          entry.value2 === -1 || entry.value2 === 0 ? null : entry.value2;
        reformattedData[label][`${entry.title}_decileValue1`] = entry.value1 ===null ||entry.value1 === -1 || entry.value1 === 0 ? '' : `(${
           entry.decileValue1
        })`;
        reformattedData[label][`${entry.title}_decileValue2`] = entry.value2 === null || entry.value2 === -1 || entry.value2 === 0 ? '' : `(${ entry.decileValue2})`;
      }
    });

    const result = Object.values(reformattedData);

    return result;
  };

  const reformattedData = reformatData(data);
  console.log('dataT', reformattedData);
  console.log('allCategories', allCategories);
  var barHeight = 30;
  var barGap = surveyCount > 1 ? 3 : 1.8;
  const totalHeight = reformattedData?.length * barHeight * barGap;

  const downloadChart = () => {
    setIsLoader(true);
    return new Promise(async (resolve, reject) => {
      try {
        const chartId = 'ppt-chart-download';
        const devElement = document.getElementById(chartId);

        if (!devElement) {
          throw new Error(`Element with id '${chartId}' not found in the DOM.`);
        }

        const canvas = await html2canvas(devElement);

        if (!(canvas instanceof HTMLCanvasElement)) {
          throw new Error(`Element with id '${chartId}' is not a canvas.`);
        }

        const padding = 20;
        const newCanvas = document.createElement('canvas');
        const context = newCanvas.getContext('2d');
        newCanvas.width = canvas.width + 2 * padding;
        newCanvas.height = canvas.height + 2 * padding;
        context.fillStyle = '#ffffff';
        context.fillRect(0, 0, newCanvas.width, newCanvas.height);
        context.drawImage(canvas, padding, padding);

        const imageLink = document.createElement('a');
        imageLink.download = `${chartId}.png`;
        imageLink.href = newCanvas.toDataURL('image/png');
        imageLink.click();

        setIsLoader(false);
        resolve();
      } catch (error) {
        setIsLoader(false);
        console.error('Error occurred during chart download:', error);
        reject(error);
      }
    });
  };
  return (
    <div style={{ zIndex: 10 }}>
      <div className='large-download-btn'>
        <Tooltip1 title='Download Chart' arrow>
          {isLoader ? (
            <CircularProgress />
          ) : (
            <ArrowCircleDownIcon
              className='large-graph-btn'
              fontSize='inherit'
              onClick={downloadChart}
            />
          )}
        </Tooltip1>
      </div>
      <ScrollContainer alwaysShowTracks={true}>
        <div className='analytics-container responsive-analytics'>
          <div className='horizontal-analytics'>
            <div id='ppt-chart-download'>
              <div className='mb-3' style={{ textAlign: 'center' }}>
                <h5 className='d-inline-block'>{title}</h5>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Legends category={category} />
              </div>
              <ResponsiveContainer height={totalHeight}>
                <BarChart
                  height={totalHeight}
                  layout='vertical'
                  data={reformattedData}
                  barSize={30}
                  fontSize={13}
                  zIndex={9999}
                >
                  <CartesianGrid strokeDasharray='3 3' vertical={false} />
                  <XAxis type='number' hide={true} />
                  <YAxis
                    dataKey='label'
                    type='category'
                    width={110}
                    fontWeight='600'
                    tick={{ fill: '#000', fontSize: 12 }}
                    tickLine={{ stroke: 'none' }}
                  />

                  <Tooltip
                    hide={true}
                    contentStyle={{ zIndex: 9999 }}
                    position={{ y: allCategories?.length <= 2 ? -140 : '' }}
                  />

                  {allCategories.map((category, index) => (
                    <React.Fragment key={index}>
                      <Bar
                        key={index}
                        dataKey={`${category.replace(/_/g, ' ')}_value1`}
                        stackId='a'
                        background={{ fill: '#333333', fillOpacity: 0.01 }}
                        fill={survey1Colors[category]}
                        name={`${category.replace(/_/g, ' ')}`}
                      >
                        <LabelList
                          dataKey={`${category.replace(/_/g, ' ')}_value1`}
                          position='insideLeft'
                          fill='#fff'
                          fontSize={14}
                          className='value-label'
                          offset={20}
                          formatter={(value) => value?.toFixed(2)}
                        />
                        <LabelList
                          dataKey={`${category.replace(
                            /_/g,
                            ' '
                          )}_decileValue1`}
                          position='insideLeft'
                          fill='#fff'
                          fontSize={12}
                          className='decile-label'
                          offset={50}
                        />
                      </Bar>
                      {surveyCount > 1 && (
                        <Bar
                          key={`${index}_value2`}
                          dataKey={`${category.replace(/_/g, ' ')}_value2`}
                          stackId='b'
                          background={{ fill: '#333333', fillOpacity: 0.01 }}
                          fill={survey2Colors[category]}
                          name={`${category.replace(/_/g, ' ')}`}
                        >
                          <LabelList
                            dataKey={`${category.replace(/_/g, ' ')}_value2`}
                            position='insideLeft'
                            fill='#fff'
                            fontSize={14}
                            className='value-label'
                            offset={20}
                            formatter={(value) => value?.toFixed(2)}
                          />
                          <LabelList
                            dataKey={`${category.replace(
                              /_/g,
                              ' '
                            )}_decileValue2`}
                            position='insideLeft'
                            fill='#fff'
                            fontSize={12}
                            className='decile-label'
                            offset={50}
                          />
                        </Bar>
                      )}
                    </React.Fragment>
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </ScrollContainer>
    </div>
  );
};

export default GroupedBarChart;
