import React from 'react';
import { getJobRole } from '../../utils/CommonFunctions';

const Comments = ({ comments, noCommentsMessage }) => {
  return comments.length > 0 ? (
    comments.map(function (item, index) {
      return (
        <div className='tiles-box' key={index}>
          {comments.some((comment) => comment.IsRead === false) ? (
            <div className='day'>
              {item.submissionFormatedDate},{' '}
              <span>{getJobRole(item.jobRole)}</span>
            </div>
          ) : (
            <></>
          )}
          {/* {!item.IsRead ? (<div className='comment-description'>{item.CommentText}</div>):('')} */}
          <div className='comment-description'>{item.CommentText}</div>
        </div>
      );
    })) : (
      <div>{noCommentsMessage}</div>
    );
};

export default Comments;
