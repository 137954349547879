import React, { useState, useEffect } from 'react';
import { Dropdown } from '../';
import { LatestSurveyDropdown as CycleDropDown } from '../../resources/styling/appStyle';
import { storageService, matCycleService } from '../../services';
import { WebStorageNames } from '../../utils/Constants';

const MatCycleDropDown = (props) => {
  const [matCycles, setMatCycles] = useState([]);
  const [currentCycle, setCurrentCycle] = useState({});
  const options = [];
  const { handleOnChange, isGroupSurveyCreated } = props;

  useEffect(() => {
    getMatCycles();
  }, [isGroupSurveyCreated]);

  const getMatCycles = () => {
    let matId = storageService.getItem(WebStorageNames.MatGroupId);
    matId = matId === null || matId === undefined ? 0 : matId;
    matCycleService.getByMatGroupId(matId).then((response) => {
      if (response.success) {
        if (response.data) {
          if (response.data.length === 0) {
            setCurrentCycle({
              label: 'Create First Survey',
              value: 0,
            });
          }
          response.data.map((item, i) => {
            if (i === 0){
              setCurrentCycle({
                label: item.Name,
                value: item.Id,
              });
              storageService.setItem(WebStorageNames.MatCycleId, item.Id);
            }

            options.push({
              label: item.Name,
              value: item.Id,
            });
          });
          setMatCycles(options);
        }
      }
    });
  };

  return (
    <div>
      <CycleDropDown>
        <div className='latest-survey'>
          <Dropdown
            name='mat-cycles'
            options={matCycles}
            selectedOption={currentCycle}
            onChange={props.handleOnChange}
            onChange={(value) => {
              handleOnChange(value);
              setCurrentCycle(value);
            }}
            value={currentCycle}
          />
        </div>
      </CycleDropDown>
    </div>
  );
};
export default MatCycleDropDown;
