import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import { HeaderWrapper, ReportNavigation } from '../resources/styling/appStyle';
import { routesObj } from '../routes';
import { navigationActions } from '../store/headerStore';
import { storageService } from '../services';
import { welbeeLogo } from '../resources/images';
import { getQueryStringVal } from '../utils/CommonFunctions';
import { QueryStringTokens } from '../utils';
import { UserGuideSurveyLink } from '../utils/Constants';

class Header extends Component {
  componentWillMount() {
    if (!isEmpty(window.location.search)) {
      let surveyToken = getQueryStringVal(QueryStringTokens.token);
      storageService.setItem('surveyToken', surveyToken);
    }
  }

  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }

  redirectToRouteWithToken(routeName) {
    let surveyToken = storageService.getItem('surveyToken');
    this.redirectToRoute(routeName + '?token=' + surveyToken);
  }

  render() {
    const { activeItem } = this.props;

    return (
      <HeaderWrapper data-test-id="appHeaderMenus">
        <img src={welbeeLogo} alt="Welbee Logo" style={{ cursor: 'default' }} />
        <ReportNavigation>
          <li
            className={activeItem === 'report' ? 'active' : ''}
            onClick={() => {
              this.props.actions.activeItemAction({ item: 'report' });
              this.redirectToRouteWithToken(routesObj.SurveyReport.path);
            }}
          >
            Report
          </li>
          <li
            className={activeItem === 'userGuide' ? 'active' : ''}
            onClick={() => {
              this.props.actions.activeItemAction({ item: 'userGuide' });
              var win = window.open(UserGuideSurveyLink, '_blank');
              win.focus();
            }}
          >
            User Guide
          </li>
          <li
            className={activeItem === 'staffPresentation' ? 'active' : ''}
            onClick={() => {
              this.props.actions.activeItemAction({
                item: 'staffPresentation'
              });
              this.redirectToRouteWithToken(routesObj.StaffPresentation.path);
            }}
          >
            Staff Presentation
          </li>
          <li
            className={activeItem === 'comments' ? 'active' : ''}
            onClick={() => {
              this.props.actions.activeItemAction({ item: 'comments' });
              this.redirectToRouteWithToken(routesObj.SurveyComments.path);
            }}
          >
            Comments
          </li>
          <li
            className={activeItem === 'account' ? 'active' : ''}
            onClick={() => {
              this.props.actions.activeItemAction({ item: 'account' });
            }}
          >
            Account
          </li>
          <li
            onClick={() =>
              this.redirectToRoute(routesObj.RequestAssistance.path)
            }
          >
            Request Assistance
          </li>
        </ReportNavigation>
      </HeaderWrapper>
    );
  }
}

const mapStateToProps = ({ header: { activeItem } }) => ({
  activeItem
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(navigationActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
