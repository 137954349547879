import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderAction } from '../../store/loaderStore';
import { navigationActions } from '../../store/headerStore';
import { DirectorLeftMenu, MainMATHeader } from '../../components';
import MatSchoolListDropDown from '../../components/common/MatSchoolListDropDown';
import { NewGroupSurveyForm, CloseGroupSurveysForm } from '../';
import { isEmpty } from 'lodash';
import { colors } from '../../resources/theme/colors';
import { routesObj } from '../../routes';
import { AppButton } from '../../resources/styling';
import { toast } from 'react-toastify';
import { AssistanceWrapper } from '../../resources/styling/appStyle';
import { ScoreTiles } from './index';
import {
  storageService,
  matGroupService,
  dashboardService,
  surveyService,
  schoolSubscriptionService,
  matCycleService,
} from '../../services';
import {
  WebStorageNames,
  DateFormats,
  MenuItems,
  QueryStringTokens,
} from '../../utils/Constants';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import {
  setTextLengthLimit,
  DateFormat,
  getQueryStringVal,
  DateFormatLocalTime,
} from '../../utils/CommonFunctions';
import { WelbeeType } from '../../utils';
import FooterButtons from '../../components/common/FooterButtons';
import { AppModel } from '../../components';
import MatCycleDropDown from '../../components/common/MatCycleDropDown';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { GroupDashboardMainWrapper } from './DashboardStyle';

/*** Table Style ***/
const purple = {
  50: '#45338C',
  100: '#45338C',
};

const Root = styled('div')(
  ({ theme }) => `

	overflow-x: auto;
	table {	  
	  font-size: 14px !important;
	  border-collapse: collapse;
	  width: 100%;
	  font-weight: 600 !important;
	  margin-top: 10px;
	  margin-bottom: 0 !important;	  
	}
  
	td,
	th {	  	 
	  padding: 8px !important; 
	  font-size: 14px !important; 
	  text-align: left;
	}

	tr:nth-of-type(odd){
		background-color: ${theme.palette.action.hover};
	}
  
	th {
	  background-color: ${theme.palette.mode === 'dark' ? purple[50] : purple[100]};
	  color: ${theme.palette.common.white};
	}
	`
);

const { teal } = colors;

function GroupDashboard(props) {
  const [strongestScore, setStrongestScore] = useState([{}]);
  const [weakestScore, setWeakestScore] = useState([{}]);
  const [schoolName, setSchoolName] = useState('');
  const [schoolSurvey, setSchoolSurvey] = useState([]);
  const [matSchoolsGroup, setMatSchoolsGroup] = useState([]);
  const [isLoading] = useState(false);
  const [matSurveyName, setMatSurveyName] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const [openSurveysClosePopup, setOpenSurveysClosePopup] = useState(false);
  const [selectedSchoolIds, setSelectedSchoolIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [canCreateSurvey, setCanCreateSurvey] = useState(false);
  const [canHidePreviousCycle, setCanHidePreviousCycle] = useState(false);
  const [canCloseSurvey, setCanCloseSurvey] = useState(false);
  const [message, setMessage] = useState('');
  const [matGroupName, setMatGroupName] = useState('');
  const [isCreateSurveyConfirmation, setisCreateSurveyConfirmation] =
    useState(false);
  const [StartDate, setStartDate] = useState('');
  const { barleyWhite, purple } = colors;
  const [matGroupId, setMatGroupId] = useState('');
  const [matCycleId, setMatCycleId] = useState('');
  const [isActionAllowed, setIsActionAllowed] = useState(false);
  const { loaderActions, navigationActions } = props;
  const [isShowResults, setIsShowResults] = useState(true);
  const [isQuestionBankAvailable, setisQuestionBankAvailable] = useState(true);
  const [isGroupSurveyCreated, setIsGroupSurveyCreated] = useState(false);
  const [taggedStartHourPopUp, setTaggedStartHourPopUp] = useState('');
  const [taggedStartMinutePopUp, setTaggedStartMinutePopUp] = useState('');

  useEffect(() => {
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    let token = getQueryStringVal(QueryStringTokens.token);
    if (token !== null)
      storageService.setItem(WebStorageNames.MatUserId, token);
    if (token === null)
      token = storageService.getItem(WebStorageNames.MatUserId);
    storageService.setItem(WebStorageNames.MenuType, 'Group');
    storageService.setItem(WebStorageNames.WelbeeType, WelbeeType.Group);
    storageService.setItem(WebStorageNames.MatCycleId, matCycleId);
    const MatGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    if (MatGroupId) setMatGroupId(MatGroupId);
    else getMatDetailByUserId();
    getMatSchools();
    navigationActions.activeItemAction({
      item: MenuItems.GroupDashboard,
    });
    GetMatSchoolsSubscriptions(token);
    loaderActions.dataLoading(true);
    getDashboardSurveysSummary();
    LoadMatDashboardData(token, MatGroupId);
  }, [matCycleId]);

  function LoadMatDashboardData(token, MatGroupId) {
    dashboardService
      .getMatDashoboardItem(token, MatGroupId, matCycleId)
      .then((response) => {
        if (response.success) {
          loaderActions.dataLoading(true);
          setSchoolName('');
          setWeakestScore(response.data.weakestScore);
          setStrongestScore(response.data.strongestScore);
          setIsShowResults(true);
          loaderActions.dataLoading(false);
          setMessage('');
        } else {
          setMessage(response.message);
          loaderActions.dataLoading(false);
          setIsShowResults(false);
          return;
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
        toast.error(error.toString());
        setIsShowResults(false);
      });
  }

  function GetMatSchoolsSubscriptions(token) {
    schoolSubscriptionService
      .getMatSchoolSubscriptionsByToken(token)
      .then((response) => {
        let value = response.data;
        setIsActionAllowed(value);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GetMatSurveyName(date) {
    let startdate = ' (' + DateFormat(date) + ')';
    surveyService
      .getNewMatSurveyName(matGroupId)
      .then((response) => {
        setMatSurveyName(response.data + startdate);
        setStartDate(date);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getDashboardSurveysSummary() {
    let token = getQueryStringVal(QueryStringTokens.token);
    let matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    let matCycleId = storageService.getItem(WebStorageNames.MatCycleId);
    dashboardService
      .getMatSurveySchoolDashoboard(token, matGroupId, matCycleId)
      .then((response) => {
        if (response.success) {
          setSchoolSurvey(response.data);
        }
        loaderActions.dataLoading(false);
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  }

  function getMatSchools() {
    let matToken = getQueryStringVal(QueryStringTokens.token);
    let matCycleId = storageService.getItem(WebStorageNames.MatCycleId);
    if (matToken === null)
      matToken = storageService.getItem(WebStorageNames.MatUserId);
    matGroupService.getMatGroup(matToken, matCycleId).then((response) => {
      if (response.success && response.data) {
        storageService.setItem(
          WebStorageNames.IsDisplayMatHeatMap,
          response.data.isDisplayMatHeatMap
        );
        setMatSchoolsGroup(response.data.schoolsWithSurveyInfo);
        setCanCreateSurvey(response.data.canCreateSurvey);
        setCanCloseSurvey(response.data.canCloseSchoolSurvey);
        setCanHidePreviousCycle(response.data.hidePreviousCycle);
        setMatGroupName(response.data.Name);
      }
    });
  }

  function getMatDetailByUserId() {
    matGroupService.getMatDetailByUserContext().then((response) => {
      if (response.success && response.data) {
        setMatGroupId(response.data.Id);
        storageService.setItem(WebStorageNames.MatGroupId, response.data.Id);
      }
    });
  }

  const handleTaggedTimeToPopUp = (v, h) => {
    if (h === 'Hour') {
      setTaggedStartHourPopUp(v);
    } else {
      setTaggedStartMinutePopUp(v);
    }
  };
  const handleCancelCreateSurveyConfirm = () => {
    setisCreateSurveyConfirmation(false);
  };

  const handleCloseCreateSurveyConfirm = () => {
    //setIsCreateSurveyDialog(false);
    setisCreateSurveyConfirmation(false);
  };

  const handleGroupSurveyCancel = (event) => {
    event && event.preventDefault();
    setSelectedSchoolIds([]);
    setMatSchoolsGroup(
      matSchoolsGroup.map((item) => {
        item.IsChecked = false;
        return item;
      })
    );
    setOpenPopup(false);
    setOpenSurveysClosePopup(false);
    setAllSelected(false);
  };

  const handleAddSurveySubmit = (values) => {
    values.StartDate = DateFormatLocalTime(values.StartDate);
    if (selectedSchoolIds.length == 0) {
      toast.error('Please select atleast one school before submition');
      return;
    }
    setisCreateSurveyConfirmation({
      ...isCreateSurveyConfirmation,
      isOpen: false,
    });
    var token = getQueryStringVal(QueryStringTokens.token);
    var data = {
      Survey: values,
      selectedSchools: selectedSchoolIds,
      Token: token,
      MatGroupId: matGroupId,
    };

    loaderActions.dataLoading(true);
    matGroupService
      .createMatSurvey(data)
      .then((response) => {
        if (response.success) {
          toast.success('Successfully created group survey.');
          getMatSchools();
          setIsGroupSurveyCreated(true);
          getDashboardSurveysSummary();
          LoadMatDashboardData(token, matGroupId);
        } else toast.error('Failed to create group survey.');

        loaderActions.dataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        loaderActions.dataLoading(false);
      });
    setOpenPopup(false);
    setisCreateSurveyConfirmation(false);
    handleGroupSurveyCancel();
  };

  const handleCloseSurveysSubmit = (values) => {
    if (selectedSchoolIds.length === 0) {
      toast.error('Please select atleast one school before submition');
      return;
    }

    var data = selectedSchoolIds;

    loaderActions.dataLoading(true);
    matGroupService
      .closeMatSurvey(data)
      .then((response) => {
        if (response.success) {
          toast.success('Successfully closed group survey.');
          getMatSchools();
          getDashboardSurveysSummary();
        } else toast.error('Failed to close group survey.');

        loaderActions.dataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        loaderActions.dataLoading(false);
      });
    setOpenSurveysClosePopup(false);
    handleGroupSurveyCancel();
  };

  function selectAllSchool(isChecked, forAddForm = true) {
    setMatSchoolsGroup(
      matSchoolsGroup.map((a) => {
        a.IsChecked =
          ((forAddForm && !a.hasActiveSurvey && !a.wasInPreviouseGroupSurvey) ||
            (!forAddForm && a.hasActiveSurvey)) &&
          isChecked;
        return a;
      })
    );
    setSelectedSchoolIds(
      isChecked
        ? matSchoolsGroup
            .filter(
              (item) =>
                (!item.hasActiveSurvey &&
                  !item.wasInPreviouseGroupSurvey &&
                  forAddForm) ||
                (!forAddForm && item.hasActiveSurvey)
            )
            .map((item) => item.Id)
        : []
    );
    setAllSelected(isChecked);
  }

  function schoolCheckboxChange(
    schoolItem,
    isChecked,
    hasActiveSurveyChecks = true
  ) {
    if (schoolItem.hasActiveSurvey && hasActiveSurveyChecks) {
      toast.warning(
        `This school currently has an active survey running.
		  The school results will be included in your group dashboard
		  once the survey has been completed or closed.`
      );
      return;
    }
    if (schoolItem.wasInPreviouseGroupSurvey && hasActiveSurveyChecks) {
      toast.warning(
        `This school was previously added in group survey, you can't add it again.`
      );
      return;
    }
    const SchoolId = schoolItem.Id;
    const selectedList = selectedSchoolIds;
    setAllSelected(false);
    const updatedList = [];
    if (
      isChecked &&
      selectedList.findIndex((item) => item === SchoolId) === -1
    ) {
      // add to list
      selectedList.push(SchoolId);
      setSelectedSchoolIds(selectedList);
      matSchoolsGroup.map((y) => {
        if (y.Id === SchoolId) y.IsChecked = true;
        updatedList.push(y);
      });
      setMatSchoolsGroup(updatedList);
      if (matSchoolsGroup.length === selectedList.length) setAllSelected(true);
    } else {
      // remove from the list
      const filteredSchoolList = selectedList.filter((x) => x !== SchoolId);
      setSelectedSchoolIds(filteredSchoolList);

      matSchoolsGroup.map((y) => {
        if (y.Id === SchoolId) y.IsChecked = false;
        updatedList.push(y);
      });
      setMatSchoolsGroup(updatedList);
    }
  }

  const onSchoolValueChange = (selectedSchool) => {
    storageService.setItem(WebStorageNames.SchoolId, selectedSchool.value);
    window.open(
      routesObj.Dashboard.path + '?schoolToken=' + selectedSchool.schoolToken
    );
  };

  const onMatCycleChange = (selectedCycle) => {
    setMatCycleId(selectedCycle.value);
    let token = getQueryStringVal(QueryStringTokens.token);
  };

  return (
    <GroupDashboardMainWrapper className='containerClass'>
      <div className='left-container'>
        <DirectorLeftMenu />
      </div>

      <div className='right-container'>
        <Grid container>
          <Grid item md={12} xs={12}>
            <MainMATHeader />

            <AssistanceWrapper>
              <div className='main-app-header-group'>
                <div className='main-app'>
                  <div className='dd-survey mat-header-bottom'>
                    <MatSchoolListDropDown
                      onSchoolChange={onSchoolValueChange}
                      style={{ marginRight: '0px' }}
                    />
                    <MatCycleDropDown
                      handleOnChange={onMatCycleChange}
                      isGroupSurveyCreated={isGroupSurveyCreated}
                    />
                  </div>
                </div>
              </div>
            </AssistanceWrapper>
          </Grid>

          {!isEmpty(message) ? (
            <Grid item md={12} xs={12}>
              <div className='error-message'>{message}</div>
              <div style={{ display: 'none' }}>
                <ScoreTiles
                  strongestScore={strongestScore}
                  weakestScore={weakestScore}
                  IsDirector={true}
                  IsHeatMap={true}
                  isGroupDashboard={true}
                  isActionAllowed={isActionAllowed}
                  isShowResults={isShowResults}
                  hasAdditionalQuestions={true}
                  isQuestionBankAvailable={true}
                />
              </div>
            </Grid>
          ) : (
            <Grid item md={12} xs={12} pt='30px'>
              <div className='inner-left-container'>
                <ScoreTiles
                  strongestScore={strongestScore}
                  weakestScore={weakestScore}
                  IsDirector={true}
                  IsHeatMap={true}
                  isGroupDashboard={true}
                  isActionAllowed={isActionAllowed}
                  isShowResults={isShowResults}
                  hasAdditionalQuestions={true}
                  isQuestionBankAvailable={true}
                />
              </div>
            </Grid>
          )}

          <Grid item md={12} xs={12}>
            <div className='body-right-inner'>
              <div className='group-dashboard-listing'>
                <div className='survey-heading'>
                  <div>School Survey</div>

                  <div className='open-survey-btn'>
                    {!canHidePreviousCycle && (
                      <>
                        <AppButton
                          type='button'
                          className={
                            !canCloseSurvey ? 'disabled tooltip' : 'tooltip'
                          }
                          onClick={() =>
                            canCloseSurvey && setOpenSurveysClosePopup(true)
                          }
                        >
                          Close surveys
                          <span className='tooltiptext'>
                            This process can take up to 3 minutes - please wait
                            whilst your survey is closed
                          </span>
                        </AppButton>

                        <AppButton
                          color={teal}
                          type='button'
                          className={!canCreateSurvey ? 'disabled tooltip' : ''}
                          onClick={() => canCreateSurvey && setOpenPopup(true)}
                        >
                          Create New survey
                          {!canCreateSurvey && (
                            <span className='tooltiptext'>
                              You have already created your Welbee survey. If
                              you would like to create another survey please
                              contact support@welbee.co.uk
                            </span>
                          )}
                        </AppButton>
                      </>
                    )}
                  </div>
                </div>
                <div className='body-listing'>
                  <Root>
                    <table>
                      <thead>
                        <tr>
                          <th>School name</th>
                          <th>Participation</th>
                          <th>Open date</th>
                          <th>Close date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!schoolSurvey.length && (
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                textAlign: 'center',
                              }}
                            ></td>
                          </tr>
                        )}
                        {schoolSurvey.map((x) => {
                          return (
                            <tr>
                              <td>{setTextLengthLimit(x.schoolName)}</td>
                              <td>
                                {x.activeSurveyDetails
                                  ? x.activeSurveyDetails
                                      .calculateSubmissionPercentage + '%'
                                  : ''}{' '}
                                {' ('}
                                {x.activeSurveyDetails
                                  ? x.activeSurveyDetails.SurveySubmission
                                  : ''}
                                {'/'}
                                {x.activeSurveyDetails
                                  ? x.activeSurveyDetails.EmployeesCount + ')'
                                  : ''}
                              </td>
                              <td>
                                {x.activeSurveyDetails
                                  ? DateFormat(
                                      x.activeSurveyDetails.StartDate,
                                      DateFormats.format_31_12_19
                                    )
                                  : ''}
                              </td>
                              <td>
                                {x.status === 'Closed'
                                  ? x.activeSurveyDetails
                                    ? DateFormat(
                                        x.activeSurveyDetails.EndDate,
                                        DateFormats.format_31_12_19
                                      )
                                    : ''
                                  : ''}
                              </td>
                              <td>{x.status}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Root>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {openPopup && (
        <NewGroupSurveyForm
          title={'Create Survey for your schools'}
          submitButtonText={'Create Survey'}
          isOpen={openPopup}
          onClose={handleGroupSurveyCancel}
          onSubmit={(values) => {
            setStartDate(DateFormat(values.StartDate));
            setisCreateSurveyConfirmation({
              isOpen: true,
              onConfirm: () => {
                handleAddSurveySubmit(values);
              },
            });
          }}
          // onSubmit={handleAddSurveySubmit}
          surveyName={matSurveyName}
          isLoading={isLoading}
          selectAllSchool={selectAllSchool}
          matSchoolsGroup={matSchoolsGroup}
          schoolCheckboxChange={schoolCheckboxChange}
          allSelected={allSelected}
          GetMatSurveyName={GetMatSurveyName}
          StartDate={StartDate}
          TaggedTimeToPopUp={handleTaggedTimeToPopUp}
        />
      )}

      {openSurveysClosePopup && (
        <CloseGroupSurveysForm
          title={'Close surveys for your schools'}
          submitButtonText={'Close Survey'}
          isOpen={openSurveysClosePopup}
          onClose={handleGroupSurveyCancel}
          onSubmit={handleCloseSurveysSubmit}
          surveyName={matSurveyName}
          isLoading={isLoading}
          selectAllSchool={selectAllSchool}
          matSchoolsGroup={matSchoolsGroup}
          schoolCheckboxChange={schoolCheckboxChange}
          allSelected={allSelected}
        />
      )}
      {isCreateSurveyConfirmation ? (
        <AppModel
          onClose={handleCloseCreateSurveyConfirm}
          open={true}
          titleText={'Confirmation'}
          bodyText={
            <>
              You are creating your survey {<b>{matSurveyName}</b>} with a
              launch date of {<b>{StartDate}</b>}. Invitations to staff members
              will be sent at{' '}
              <b>
                {' '}
                {taggedStartHourPopUp}:{taggedStartMinutePopUp}
              </b>
              .
            </>
          }
          footer={
            <FooterButtons
              submitButtonText={'Confirm'}
              handleSubmit={isCreateSurveyConfirmation.onConfirm}
              onClose={handleCancelCreateSurveyConfirm}
            />
          }
        />
      ) : (
        <div />
      )}
    </GroupDashboardMainWrapper>
  );
}
const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(GroupDashboard);
