import UploadIcon from '@mui/icons-material/Upload';
import { InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { AppModel } from '../../../components';
import { colors } from '../../../resources/theme';
import { matStaffService, storageService } from '../../../services';
import { navigationActions } from '../../../store/headerStore';
import { loaderAction } from '../../../store/loaderStore';
import { WebStorageNames } from '../../../utils';

const { barleyWhite, purple } = colors;
const customStyles = {
  overlay: {
    background: 'rgba(255, 255,  255, 0.8)',
    //width: '100%'
  },
  modal: {
    background: `${barleyWhite}`,
    color: `${purple}`,
    //width: '100%'
  },
  closeIcon: {
    fill: `${purple}`,
  },
  closeIconSize: {
    width: 15,
    height: 15,
  },
};

const MatStaffExcel = (props) => {
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [matGroupId, setMatGroupId] = useState(null);
  const { loaderActions } = props;
  useEffect(() => {
    const matId = storageService.getItem(WebStorageNames.MatGroupId);
    setMatGroupId(matId);
  }, []);

  const checkValidation = (model) => {
    matStaffService
      .ValidateMatExcel(model)
      .then((response) => {
        loaderActions.dataLoading(false);
        if (response) {
          if (response.success) {
            setMessage(
              'Please confirm you want to update your staff records with those in the Excel file. New staff records will be added and existing staff records will be updated with name changes and new groups.'
            );
            setIsShowConfirm(true);
            setErrorMessage(null);
          } else {
            setMessage(
              <div>
                <p>
                  The following staff entries are invalid. If you would like to
                  add the valid entries, please click to upload below, or
                  cancel.
                  <br /> Once you have corrected the errors you can upload these
                  using the same spreadsheet. Thank you.
                </p>
                <ul>
                  {response.data.map((item, i) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            );
            setIsShowConfirm(true);
            setErrorMessage(null);
          }
        } else {
          setIsShowConfirm(false);
          setErrorMessage('Excel could not be uploaded.');
          setMessage(null);
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
        setErrorMessage('Excel could not be uploaded.');
        setMessage(null);
        setIsShowConfirm(false);
      });
  };

  const handleChange = (event, values) => {
    let f = event !== null ? event.target.files[0] : null;
    let fName = f !== null ? f.name : null;
    let fExtension = fName !== null ? fName.split('.').pop() : null;

    if (fExtension !== null && fExtension === 'xlsx') {
      setFile(f);
      setFileName(fName);
      const model = new FormData();
      model.append('FormFile', f);
      model.append('Filename', fName);
      checkValidation(model);
    } else {
      setErrorMessage('Invalid File');
      setMessage(null);
      setIsShowConfirm(false);
    }
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        excelFileUpload: null,
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, formProps) => {
        const model = new FormData();
        model.append('FormFile', file);
        model.append('Filename', fileName);
        model.append('MatGroupId', matGroupId);

        loaderActions.dataLoading(true);
        matStaffService
          .UploadMatExcel(model)
          .then((response) => {
            loaderActions.dataLoading(false);
            const { handleClose, getMatStaff, handleExcelUpload } = props;

            if (response.success) {
              getMatStaff();
              handleClose();
              handleExcelUpload();
              toast.success(
                'Your staff records have been successfully uploaded.'
              );
            } else {
              setErrorMessage(
                'An error occurred while processing your request, please try again.'
              );
              setIsShowConfirm(false);
            }
          })
          .catch((error) => {
            loaderActions.dataLoading(false);
          });
      }}
      render={({ values, touched, errors, handleSubmit, setFieldValue }) => {
        return (
          <Box className='right-container' spacing={6} sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
              <AppModel
                onClose={props.handleClose}
                styles={customStyles}
                open={true}
                titleText={'Upload Staff List'}
                bodyText={
                  <div>
                    Please{' '}
                    <a
                      href='javascript:void(0)'
                      onClick={props.exportMatExcelTemplate}
                    >
                      download this template
                    </a>{' '}
                    and upload it below.
                    {/* <br /> You can also{' '}
                    <a
                      href='javascript:void(0)'
                      onClick={props.exportMatExcelStaff}
                    >
                      {' '}
                      download your current data
                    </a>{' '} */}
                    <br />
                    <FormControl
                      sx={{ m: 1, width: '100%' }}
                      variant='outlined'
                    >
                      <InputLabel htmlFor='excel-upload-staff'>
                        {/* Upload Excel */}
                      </InputLabel>
                      <OutlinedInput
                        inputProps={{ accept: '.xlsx' }}
                        id='excel-upload-staff'
                        name='excelFileUpload'
                        type='file'
                        endAdornment={
                          <InputAdornment position='end'>
                            <UploadIcon
                              style={{
                                backgroundColor: '#007A7A',
                                color: 'white',
                              }}
                              edge='end'
                            ></UploadIcon>
                          </InputAdornment>
                        }
                        onChange={(e) => {
                          setFieldValue('excelFileUpload', e.target.files[0]);
                          handleChange(e);
                          setFieldValue('excelFileUpload', e.target.files[0]);
                          setErrorMessage('');
                          setMessage('');
                          handleChange(e);
                          setIsShowConfirm(true);
                        }}
                        label='Upload Excel'
                      />
                      {isShowConfirm ? (
                        <Box
                          className='right-container'
                          spacing={6}
                          sx={{ flexGrow: 1 }}
                        >
                          <Grid item xs={12}>
                            {message}
                          </Grid>
                          <Grid item xs={12}>
                            <Stack
                              direction='row'
                              justifyContent={'flex-end'}
                              spacing={1}
                            >
                              <Button
                                style={{
                                  backgroundColor: '#45338C',
                                }}
                                onClick={handleSubmit}
                                variant='contained'
                              >
                                Upload
                              </Button>
                            </Stack>
                          </Grid>
                        </Box>
                      ) : (
                        <div></div>
                      )}

                      {errorMessage ? (
                        <div className='fail-message'>
                          {' '}
                          <br />
                          {errorMessage}{' '}
                        </div>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </div>
                }
              />
            </Grid>
          </Box>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(MatStaffExcel);
