import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { AdminHeader, LeftMenu } from '../../components';
import { loaderAction } from '../../store/loaderStore';
import { navigationActions } from '../../store/headerStore';
import { questionCategoryType, QueryStringTokens } from '../../utils';
import {
  DemandSuggestion,
  ControlSuggestion,
  RelationshipSuggestion,
  RoleSuggestion,
  ChangeSuggestion,
  SupportSuggestion,
} from './';
import { getQueryStringVal } from '../../utils/CommonFunctions';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import {
  AssistanceWrapper,
  OtherSuggestionMainWrapper,
} from './OtherSuggestionStyle';

class OtherSuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Category: 0,
    };
  }

  componentDidMount() {
    let categoryId = getQueryStringVal(QueryStringTokens.categoryId);
    const id = parseInt(categoryId, 10);
    this.setState({ Category: id });
    window.scrollTo(0, 0);
    this.props.navigationActions.activeItemAction({
      showReportSubMenu: false,
    });
  }
  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }
  renderSuggestion = (Category) => {
    switch (Category) {
      case questionCategoryType.Demands.Id:
        return <DemandSuggestion />;
      case questionCategoryType.Control.Id:
        return <ControlSuggestion />;
      case questionCategoryType.Support.Id:
        return <SupportSuggestion />;
      case questionCategoryType.Relationships.Id:
        return <RelationshipSuggestion />;
      case questionCategoryType.Role.Id:
        return <RoleSuggestion />;
      case questionCategoryType.Change.Id:
        return <ChangeSuggestion />;
      default:
        return;
    }
  };

  render() {
    const { Category } = this.state;
    return (
      <div>
        <AdminHeader />
        <OtherSuggestionMainWrapper>
          <div className='containerClass'>
            <div className='left-container'>
              <LeftMenu />
            </div>
            <div className='right-container'>
              <AssistanceWrapper>
                <RequestAssistanceButton />
              </AssistanceWrapper>
              {this.renderSuggestion(Category)}
            </div>
          </div>
        </OtherSuggestionMainWrapper>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});
export default connect(null, mapDispatchToProps)(withRouter(OtherSuggestion));
