import React, { useEffect } from 'react';
import { HeatmapCell } from '../../../components';
import { loaderAction } from '../../../store/loaderStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { navigationActions } from '../../../store/headerStore';
import { ExtendedSubContainer } from '../../../resources/styling/appStyle';
import { Scrollbars } from 'react-custom-scrollbars-2';

function MatCustomHeatmap(props) {
  useEffect(() => {
    const { loaderAction } = props;
    loaderAction.dataLoading(props.isLoading);
  }, [props.isLoading]);

  return props?.customHeatmapError !== '' ? (
    <ExtendedSubContainer>
      <div className='search-data-container'>
        <div className='staff-result-innercontainer'>
          <div className='search-inner-sub-container'>
            <div className='staff-type-grid'>
              <div className='heatmap-error-msg'>
                {props?.customHeatmapError}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ExtendedSubContainer>
  ) : (
    <div className='search-inner-sub-container'>    
      <Scrollbars style={{ height: 400, width: '100%' }}>
        <div className='staff-type-grid-data staff-type-grid'>
          <span className='grid-role-heading'>
            <ul>
              {props?.staffHeatmap?.map((item) => {
                return (
                  <>
                    {' '}
                    <li>{item.StaffType} {item.SubType} {item.StaffCount}</li>
                  </>
                );
              })}
            </ul>
          </span>
          <span className='grid-data'>
            {props?.staffHeatmap?.map((items, index) => {
              return (
                <>
                  <ul>
                    <HeatmapCell
                      Decile={items.DemandColor}
                      Value={items.Demand}
                      Allow={true}
                      AllStaff={false}
                    />
                    <HeatmapCell
                      Decile={items.ControlColor}
                      Value={items.Control}
                      Allow={true}
                      AllStaff={false}
                    />
                    <HeatmapCell
                      Decile={items.ManagerSupportColor}
                      Value={items.ManagerSupport}
                      Allow={true}
                      AllStaff={false}
                    />
                    <HeatmapCell
                      Decile={items.PeerSupportColor}
                      Value={items.PeerSupport}
                      Allow={true}
                      AllStaff={false}
                    />
                    <HeatmapCell
                      Decile={items.RelationshipsColor}
                      Value={items.Relationships}
                      Allow={true}
                      AllStaff={false}
                    />
                    <HeatmapCell
                      Decile={items.RoleColor}
                      Value={items.Role}
                      Allow={true}
                    />
                    <HeatmapCell
                      Decile={items.ChangeColor}
                      Value={items.Change}
                      Allow={true}
                    />
                  </ul>
                </>
              );
            })}
          </span>
        </div>
      </Scrollbars>     
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  loaderAction: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(MatCustomHeatmap));
