import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 * useIdle is a custom created hook that is consumed to check Idle
 */
export default function useIdle({ onIdle, idleTime = 5 }) {
  const [isIdle, setIsIdle] = useState();

  const handleOnIdle = (event) => {
    setIsIdle(true);
    console.log('user is idle', event);
    console.log('Last Active', getLastActiveTime());
    onIdle();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return {
    getRemainingTime,
    getLastActiveTime,
    isIdle,
  };
}
