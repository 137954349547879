import React from 'react';

const LearnActions = () => {
  return (
    <div>
      <p>
        Once you have reviewed your results you will want to move to action. A
        planning tool is provided to support you in planning and assigning
        action in the areas where you want to focus your energy and effort.
      </p>
      <p>
        Clicking on the Action menu will allow you to create, assign and keep a
        record of actions you want to take. Simply click on 'task' and give each
        action a name and then add the information requested.
      </p>
      <p>
        This includes notes on what you will do, who it is assigned to and when
        it should be completed. You can add your own category, as well as use
        the drop down items. An e-mail will automatically be sent to those
        assigned a task.
      </p>
      <p>
        You are provided with actions for before the survey, as well as for
        after, and you can use this tool to allocate and share responsibilities
        as needed.
      </p>
    </div>
  );
};

export default LearnActions;
