import styled from 'styled-components';
import { colors } from '../theme/colors';

const {
  mineShaft,
  twilightBlue,
  pictonBlue,
  blizzBlue,
  purple,
  whiteColor,
  lighterGrey,
  teal,
  mandy,
} = colors;

export const Form = styled.div`
  width: 100%;
  float: left;

  input[type='text'] {
  }

  button {
  }
`;

export const ControlForm = styled.div`
  width: 100%;
  float: left;
  margin-bottom: 20px;
`;

export const InnerWrapper = styled.div`
  width: 85%;
  margin: auto;
  padding: 50px 20px;
  display: grid;
`;

export const FormWrapper = styled.div`
  width: 56%;
  float: left;
`;

export const ButtonWrapper = styled.div`
  width: 56%;
  float: left;
`;

export const ButtonWrapperInner = styled.div`
  padding: 25px 0;
  float: left;
  width: 100%;
`;

export const SubmitButton = styled.button`
  float: right;
  border: 0;
  background: #03e8d3;
  padding: 11px 23px;
  color: #3a3636;
  font-size: 18px;
  cursor: pointer;
  border-radius: 20px;
`;

export const CancelButton = styled.button`
  float: left;
  border: 0;
  padding: 11px 0 11px 0;
  background: transparent;
  text-decoration: underline;
  font-size: 18px;
  cursor: pointer;
  color: #797676;
`;

export const InputFormField = styled.input`
  padding: 22px 20px 20px 20px;
  width: 93.5%;
  float: left;
  border: 0;
  background: #f1f1f1;
  border-bottom: 2px solid #f1f1f1;

  transition: all 0.5s ease-in-out;
  font-size: 18px;
`;

export const DatePickerWrapper = styled.div`
  position: absolute;
  top: 12px;
  z-index: 1;
  width: 203px;
  right: 15px;
  margin-bottom: 20px;
  float: left;
  > div {
    /* border: 1px solid green; */
    width: 100%;
    padding: 10px 0 0 0 !important;
    background: ${twilightBlue};
    border: 0;
    border-radius: 5px;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    background-color: transparent;
  }
  .DayPicker-NavButton--prev {
    background-image: none;
    width: 0px;
    height: 0px;
    left: 1.2em;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid ${teal};
  }
  .DayPicker-NavButton--next {
    background-image: none;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid ${teal};
    right: 1.2em;
  }
  .DayPicker-Weekday abbr[title] {
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 900;
  }

  .DayPicker-Day {
    font-size: 8px;
    font-weight: 400;
    padding: 3px 0;
    cursor: pointer !important;
    width: 18px;
    height: 18px;
  }
  .DayPicker-Caption {
    text-align: center;
    color: ${mineShaft};
    > div {
      font-size: 12px;
      font-weight: 900;
    }
  }

  .DayPicker-Day--today {
    border-radius: 50%;
    border: 1px solid;
    display: inline-block;
    font-weight: 900;
    color: ${teal};
    margin-top: -5px;
  }
`;
//#endregion

export const WrapperMain = styled.div`
  margin-bottom: 20px;
  p {
    font-size: 14px;
    width: 100%;
    margin: 10px 0px 10px 0px;
  }
  input {
    ::-webkit-input-placeholder {
      color: transparent;
      font-size: 12px;
    }
    ::-moz-placeholder {
      color: transparent;
      font-size: 12px;
    }
    :-ms-input-placeholder {
      color: transparent;
      font-size: 12px;
    }
    :-moz-placeholder {
      color: transparent;
      font-size: 12px;
    }
  }
  .Label {
    z-index: 100;
    width: 100%;
    padding: 9px 0;
    background: #ffffff;
    top: 2px !important;
  }
  .Label1 {
    z-index: 100;
    width: 90%;
    padding: 3px 0;
    background: #f0fbff !important;
    top: 2px !important;
    height: 18px;
  }
  .address-suggested-list {
    position: absolute;
    background: ${whiteColor};
    z-index: 1;
    width: 100%;
    margin-top: -14px;
    border: 2px solid ${pictonBlue};
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0 li {
        padding: 14px 20px;
        color: purple;
        cursor: pointer;
        &:hover {
          background: ${teal};
          color: ${whiteColor};
        }
      }
    }
  }

  width: 100%;
  float: left;
  .accountImage {
    width: 13%;
    float: right;
    min-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      border: 1px solid ${lighterGrey};
      padding: 2px;
    }
  }
  .accoutImageUpload {
    width: 84%;

    label {
      top: 0;
    }
  }

  .change-button {
    font-family: 'Avenir' !important;
    font-weight: 900;
    position: absolute;
    top: 0;
    right: 0;
    background: ${pictonBlue};
    border: 0;
    color: ${whiteColor};
    padding: 16px 20px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
  }
  position: relative;
  .box {
    margin-bottom: 0;
    height: 63px;
  }
  .inputfile-6 + label strong {
    padding-top: 13px;
    background: ${pictonBlue};
  }
  .inputfile-6 + label {
    padding: 5px 0;
    background: transparent;
    border: 1px solid ${pictonBlue};
    font-size: 18px;
    top: 1px;
  }
  .secondary-style {
    .category-inner-wrapper {
      border: 1px solid ${pictonBlue};
      .selected-option {
        background: transparent;
        padding: 13px 20px 13px 20px;
        font-size: 18px;
      }
    }
  }

  textarea::-webkit-input-placeholder {
    opacity: 0;
  }

  textarea:-moz-placeholder {
    opacity: 0;
  }

  textarea::-moz-placeholder {
    opacity: 0;
  }

  textarea:-ms-input-placeholder {
    color: ${pictonBlue};
  }

  .floating-label {
    position: relative;
    margin-bottom: 11px;
  }

  .floating-label [type='number'] {
    padding: 17px 10px 13px 20px;
  }
  input::-webkit-input-placeholder {
    opacity: 0 !important;
    transition: inherit;
    font-family: 'Avenir' !important;
    font-size: 16px;
  }
  .floating-input,
  .floating-select {
    font-size: 14px;
    display: block;
    width: 100%;
    background-color: ${whiteColor};
    border-radius: 4px;
    padding: 17px 34px 17px 20px;
    border: 1px solid ${pictonBlue};
    color: ${mineShaft};
  }

  .floating-input:focus,
  .floating-select:focus {
    outline: none;
  }

  label {
    font-size: 14px;
  }

  .floating-input:focus ~ label,
  .floating-input:not(:placeholder-shown) ~ label {
    top: 8px;
    font-size: 10px;
    color: ${purple};
    font-weight: 400;
  }

  .floating-select:focus ~ label,
  .floating-select:not([value='']):valid ~ label {
    top: 11px;
    font-size: 7px;
    color: ${purple};
    font-weight: 400;
  }

  /* active state */
  .floating-input:focus ~ .bar:before,
  .floating-input:focus ~ .bar:after,
  .floating-select:focus ~ .bar:before,
  .floating-select:focus ~ .bar:after {
    width: 50%;
  }

  .floating-textarea {
    min-height: 30px;
    max-height: 260px;
    overflow-x: hidden;
  }

  /* highlighter */
  .highlight {
    position: absolute;
    height: 50%;
    width: 100%;
    top: 15%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  /* active state */
  .floating-input:focus ~ .highlight,
  .floating-select:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }
`;

export const ExtendedWrapperMain = styled(WrapperMain)`
  input::-webkit-input-placeholder {
    opacity: 1 !important;
    transition: inherit;
  }
  .success-message,
  .fail-message {
    color: ${teal};
    font-weight: 500;
  }
  .fail-message {
    color: ${mandy};
  }
  .floating-input:focus ~ label,
  .floating-input:not(:placeholder-shown) ~ label {
    top: 5px;
  }

  .floating-select:focus ~ label,
  .floating-select:not([value='']):valid ~ label {
    top: 5px;
  }

  .mat-analytics-dropdown,
  .mat-multi-select {
    width: 400px !important;
  }

  .analytics-dropdown {
    width: 250px;
    float: left;
    margin-right: 10px;
    position: relative;
    label {
      font-size: 14px;
      position: absolute;
    }
    .options {
      position: absolute;
      width: 250px;
      z-index: 999;
    }
    .floating-label {
      position: static !important;
    }
    .floating-input {
      position: relative !important;
    }
  }
  .analytics-Buttons {
    width: 140px;
    float: left;
    margin-right: 10px;
    position: relative;
  }
  @media only screen and (max-width: 760px) {
    .analytics-dropdown {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .analytics-dropdown {
      margin-bottom: 20px;
    }
  }
`;
