import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import { AppButton } from '../../resources/styling';
import { WrapperMain } from '../../resources/styling/formStyle';
import { loaderAction } from '../../store/loaderStore';
import { WelbeeType } from '../../utils';
import { RegisterInnerMain, WelbeeOption } from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { accountService, schoolService } from '../../services';
import { ExtendedRegisterWrapper, RegisterInner } from './WelbeeOptionStyle';

const { mandy } = colors;

class OptionsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      welbeeType: WelbeeType.WelbeeSurveyWithVoice,
    };
  }
  handleSubmit = () => {
    const { loaderActions } = this.props;
    loaderActions.dataLoading(true);
    const schoolinfo = this.props.SchoolInfo;
    schoolinfo.WelbeeType = this.state.welbeeType;
    schoolService
      .updateSchoolWelbeeVoice(schoolinfo)
      .then((response) => {
        if (response.success) {
          this.props.onCompletion(this.state.welbeeType);
        }
        loaderActions.dataLoading(false);
      })
      .catch((error) => loaderActions.dataLoading(false));
  };

  selectWelbeeSurveyWithVoice = () => {
    this.setState({
      welbeeType: WelbeeType.WelbeeSurveyWithVoice,
    });
  };

  selectWelbeeVoice = () => {
    this.setState({
      welbeeType: WelbeeType.WelbeeVoice,
    });
  };

  render() {
    const { welbeeType } = this.state;
    return (
      <div>
        <ExtendedRegisterWrapper>
          <Grid container>
            
              <Grid item
                lg={12}
                sm={12}
                md={12}
                xs={12}
                className='mobile-wrapper'
              >
                <RegisterInnerMain className='options-inner-wrapper'>
                  <RegisterInner>
                    <WelbeeOption>
                      <h2>Please select your preferred account type</h2>

                      <div className='welbee-Options-Inner'>
                        <div
                          className={`voice-box ${
                            welbeeType === WelbeeType.WelbeeSurveyWithVoice
                              ? 'voice-box-selected'
                              : ''
                          } `}
                          onClick={this.selectWelbeeSurveyWithVoice}
                        >
                          <WrapperMain
                            onClick={this.selectWelbeeSurveyWithVoice}
                          >
                            <input
                              type='radio'
                              id='test1'
                              name='WelbeeType'
                              checked={
                                welbeeType === WelbeeType.WelbeeSurveyWithVoice
                              }
                              onChange={this.selectWelbeeSurveyWithVoice}
                            />
                            <label for='test1'>
                              <span className='checkbox-wrapper'>
                                Welbee Survey &amp; Voice
                              </span>
                            </label>
                          </WrapperMain>
                          <span className='box-detail'>
                            By clicking this option you will be able to see your
                            invoice total and use Welbee Voice for free.
                          </span>
                        </div>

                        <div
                          className={`voice-box ${
                            welbeeType === WelbeeType.WelbeeVoice
                              ? 'voice-box-selected'
                              : ''
                          } `}
                          onClick={this.selectWelbeeVoice}
                        >
                          <WrapperMain onClick={this.selectWelbeeVoice}>
                            <input
                              type='radio'
                              id='test2'
                              checked={welbeeType === WelbeeType.WelbeeVoice}
                              name='WelbeeType'
                              onChange={this.selectWelbeeVoice}
                            />
                            <label for='test2'>
                              <span className='checkbox-wrapper'>
                                Welbee Voice <i>(free during lockdown)</i>
                              </span>
                            </label>
                          </WrapperMain>
                          <span className='box-detail'>
                            By clicking this option you will gain access to
                            Welbee Voice.
                          </span>
                        </div>

                        <WrapperMain>
                          <AppButton
                            color={mandy}
                            type='submit'
                            onClick={() => {
                              this.handleSubmit();
                            }}
                          >
                            Continue
                          </AppButton>
                        </WrapperMain>
                      </div>
                    </WelbeeOption>
                  </RegisterInner>
                </RegisterInnerMain>
              </Grid>
            
          </Grid>
        </ExtendedRegisterWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(OptionsDetail));
