import React from 'react';
import { DashboardWelcomePopupWrapper, AppButton } from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { AppModel } from '../../components';
import { upgradeconfety } from '../../resources/images/index';
import { WelbeeTypes } from '../../utils';
import { ExtendedFooterButtonsWrapper } from './WelbeeVoiceStyle';

const { purple } = colors;

const WelbeeVoiceUpgradePopup = ({ isOpen, onClose, onOk, children, welbeeType }) => {
  return (
    <DashboardWelcomePopupWrapper className='model-main-wrapper'>
      <AppModel
        open={isOpen}
        onClose={onClose}
        titleText={
          <span
            className='voice-welcome'
            style={{
              display: 'block',
              textAlign: 'center',
              fontSize: '30px',
              letterSpacing: '1.5px',
            }}
          >
            Thank you
          </span>
        }
        bodyText={
          <div className='dashboard-welcome-wrapper'>
                {welbeeType === WelbeeTypes.WelbeeSurveyFree || welbeeType === WelbeeTypes.WelbeeSurveyPro || welbeeType === WelbeeTypes.WelbeeSurveyPremium ?
            <p>
              Thank you for upgrading to Welbee Survey. This includes full
              online analysis, reporting and recommended actions. You will also
              be able to continue to use Welbee Voice.
            </p> :
            <p>
              Thank you for upgrading to Welbee Voice Pro. You will be able to 
              continue your anonymous conversations and allow multi-user key 
              staff access and group allocation of staff.
            </p>}
            <p>
              If you have any questions or would like further information you
              can email <strong>support@welbee.co.uk</strong>
            </p>
          </div>
        }        
              footer={
        <>
          <ExtendedFooterButtonsWrapper>
            <div className='confety'>
              <img src={upgradeconfety} alt='' />
            </div>
            <AppButton color={purple} type='button' onClick={onOk} style={{float:'none'}}>
              Back to login
            </AppButton>
          </ExtendedFooterButtonsWrapper>
            </>
        }
      />

      {children}
    </DashboardWelcomePopupWrapper>
  );
};
export default WelbeeVoiceUpgradePopup;
