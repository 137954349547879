import React from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { PageWrapper } from '../../../resources/styling/appStyle';
import { welbeeLoginLogo } from '../../../resources/images/index';
import UpgradePriceForm from './UpgragePriceForm';
import { ExtendedMainWrapper } from './UpgradePriceStyle';

function UpgradeInvoiceLeftPanel(props) {
  return (
    <ExtendedMainWrapper>
      <PageWrapper>
        <div className='mobile-warning-wrapper'>
          <span>&#9888;</span>
          Please use desktop to view Welbee
        </div>
        <Grid container spacing={2} className='login-main-warpper'>
          <Grid item
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className='login-mobile-wrapper login-left-panel'
          >            
            <img src={welbeeLoginLogo} alt='' />
          </Grid>
          <Grid item
            lg={6}
            sm={12}
            xs={12}
            md={6}
            className='logo-container welcome-right-panel registeration-right-panel'
          >
            <UpgradePriceForm welbeeType={props.welbeeType} onCompletion={props.onCompletion} />
          </Grid>
        </Grid>
      </PageWrapper>
    </ExtendedMainWrapper>
  );
}

export default withRouter(UpgradeInvoiceLeftPanel);
