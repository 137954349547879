import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { VoiceDashboardWrapper } from '../../resources/styling/appStyle';
import { media } from 'styled-bootstrap-grid';
import { ActionPlanMainWrapper } from '../../components/common/ActionPlanStyle';
import { SubContainer } from '../../resources/styling/appStyle';
import { customcursor } from '../../resources/images/index';
import { LoginFormInner, MainWrapper } from '../../components/common/loginStyle';
import { RegisterBackground } from '../../resources/images/index';

const {
    pictonBlue, whiteColor, mineShaft, softGrey, lighterGrey, mandy, bordergrey
} = colors;

/*** Not Subscribed ***/
export const ExtendedVoiceDashboardWrapper = styled(VoiceDashboardWrapper)`
  .content-body-inner-wrapper {
    width: 100%;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    background: ${whiteColor};
    border: 1px solid ${bordergrey}    
  }
`;
export const AdminDashboardBody = styled.div`    
  float:left;
  width: 100%;
  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    font-size: 13px;
    border-bottom: 1px solid ${softGrey};
    padding-bottom: 15px;
    li:first-child {
      flex: 1;
    }
    li {
      a {
        color: ${pictonBlue};
        text-decoration: underline;
        margin-left: 10px;
        display: inline-block;
      }
    }
  }
`;

/*** School Subscription ***/
export const HeaderWrapper = styled.div`
  background: ${({ value }) => value};
  width: 100%;
  box-sizing: border-box;
  padding: 15px 8px;
  text-align: center;
  color: #fff;
  font-weight: 900;
  font-size: 12px;
  ${media.md`
padding: 10px 8px;
`}
  ${media.lg`
padding: 10px 8px;
`}
${media.xl`
padding: 15px 8px;
`}
`;
export const ExtendedActionPlanMainWrapper = styled(ActionPlanMainWrapper)`
  > div {
    margin-top: 0 !important;
  }
`;
export const ExtendedSubContainer = styled(SubContainer)`
  .floating-label [type=number]{
    padding: 1px 22px 13px 20px;
  }

.uploadDirections{
  margin-bottom: 15px;
    display: block;
  a {
    color: ${pictonBlue};
    text-decoration: underline;
    font-weight: 900;
  }
}
  .search-data-container {
    width: 100%;
    float: left;
    padding-top: 120px;
    padding-bottom: 29px;
  }
  .search-inner-container {
    width: 95%;
    margin: auto;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 14px;

    .suggested-actions-wrapper {
      .suggested-action-list-main {
        float: left;
        margin-bottom: 5px;
      }
      .options {
        max-height: inherit;
      }
      .category-inner-wrapper {
        .selected-option {
          font-size: 16px;
          padding-left: 39px;
        }
      }
    }
  }


.search-inner-sub-container{
   p.password-instructions{
      font-size: 13px;
      color: ${mineShaft};
      font-weight: 400;
   }
  .floating-label {
    input{
      border: 1px solid ${pictonBlue};
      color: ${pictonBlue};
    }
    label{
      color: ${pictonBlue};
    }
  }
  padding: 15px 0;
  float: left;
  width: 100%;
  border-radius: 6px;
  background: ${whiteColor};
  box-shadow: 3px 3px 24px rgba(0, 122, 122, 0.12);
  margin-bottom: 30px;
  h2{
    padding: 0 40px 12px 40px;
    margin: 14px 0 14px 0;
    font-size: 16px;
    font-weight: 900;
    border-bottom: 1px solid ${lighterGrey};
  }
  .add-btn {
    float: right;
    border-radius: 50%;
    border: 0;
    color: ${whiteColor};
    font-size: 25px;
    background: ${mandy};
    box-shadow: 2px 1px 7px rgba(0, 122, 122, 0.12);
    padding: 3px 10px;
    /* cursor: url(${customcursor}), auto; */
    cursor: pointer;
    margin: -7px -22px 0 0;
  }
  
  .form-body{
    padding: 2px 40px;
    box-sizing: border-box;
    width: 100%;
    float: left;
    margin: 0;
    .form-holder{
      width:"100%"; 
      display:"flex";
      .form-inner {
        margin-right: 12px;
      }
    }
  }
}
`;

/*** Upgrade to Free Popup ***/
export const ExtendedLoginFormInner = styled(LoginFormInner)`
  width: 71% !important;
`;
export const ExtendedMainWrapper = styled(MainWrapper)`
  .welcome-right-panel {
    background: ${whiteColor};
    margin-left: 49%;
  }
  .login-left-panel {
    background: url(${RegisterBackground});
    background-size: 100%;
    background-position: center top;
    align-items: center;
    width: 40%;
    position: fixed;
    height: 100%;
    img {
      /* margin-top: 50%; */
    }
    .login-button {
      position: absolute;
      left: 23px;
      top: 16px;
    }
      button {
        background: transparent;
        box-shadow: none;
        border: 1px solid ${whiteColor};
        padding: 11px 30px;
        font-weight: 400;
      }
    }
  }
`;

const SchoolSubscriptionsStyle = {
    ExtendedVoiceDashboardWrapper,
    AdminDashboardBody,
    HeaderWrapper,
    ExtendedActionPlanMainWrapper,
    ExtendedSubContainer,
    ExtendedLoginFormInner,
    ExtendedMainWrapper
}
export default SchoolSubscriptionsStyle;