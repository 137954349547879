import React from 'react';
import { AppLogo } from '../../../components';
import { colors } from '../../../resources/theme';
import { LeftSectionStyle } from '../../../resources/styling/appStyle';
import { SurveyGreetingPresentationStyle } from '../SurveyReportPresentationStyle';

const SurveyGreetingPresentationV2 = (props) => {
  return (
    <SurveyGreetingPresentationStyle className="survey-greeting" id={props.id}>
      <div className="left-section">
        <LeftSectionStyle className="survey-category-slide">
          <div>
            <div>
              <h1 className="big-title">{props.SchoolName}</h1>
              <p
                className="content"
                dangerouslySetInnerHTML={{ __html: props.Content }}
              />
            </div>
          </div>
          <span className='app-logo'><img src={props.welbeeslideslogo} alt="" /></span>
        </LeftSectionStyle>
      </div>
      <div className="right-section">
        <div>
          <img className="greeting-avatar" src={props.SchoolLogo} alt="" />
        </div>
      </div>
    </SurveyGreetingPresentationStyle>
  );
};

export default SurveyGreetingPresentationV2;
