import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import { ReportDropDown } from '../../resources/styling/appStyle';


const {
    mineShaft, pictonBlue, blackColor, teal,
    outrageousOrange, yellowColor, darkYellow, lightGray, whiteColor, midSoftGrey, bordergrey
} = colors;

/*** Survey Report ***/
export const SurveyReportWrapper = styled.div`
 /*.sc-htpNat {
  width: 230px !important;
}*/
  .support-individual-answer {
    margin-bottom: 0 !important;
  }
  .support-list-wrapper {
    margin-top: 29px !important;
  }
  .active {
    font-weight: 900;    
  }
  .mobile-wrapper {
    padding: 0;   
  }
  .containerClass {
    padding-top: 0;
    width: 100%;
    max-width: 100%;
  }

  @supports (-moz-appearance: none) {
    .FF-fix {
      flex: 0 0 66.6667%;
      max-width: 66.6667%;
      margin-left: 241px;
    }
  }

.tooltip{
            background-color: ${lightGray};
            color: ${midSoftGrey};
          .tooltiptext {
            display: none;
            width: 120px;
            background-color: ${pictonBlue};
            color: ${whiteColor};
            font-weight: 400;
            font-size: 13px;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            bottom: 120%;
            left: -20%;
            opacity: 0;
            transition: opacity 0.7s;
            :after {
              content: '';
              position: absolute;
              top: 100%;
              left: 20%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: ${pictonBlue} transparent transparent transparent;
            }
          }
          :hover .tooltiptext {
            display: inline-block;
            opacity: 1;
          }
        }
      }

`;
export const SurveyReportInnerWrapper = styled.div`
.btn-download{
  display: inline-block;
  float: right;
}
      width: 100%;
  .report-not-available {
    padding: 25px;
    margin-top: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${whiteColor};
    border: 1px solid ${bordergrey}; 
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -o-border-radius: 8px;
  }
  
  .area-celebration-heading {
    margin-top: 20px;
  }  
  .next-steps-wrapper {
    li:before {
      content: '•';
      color: ${yellowColor};
      font-size: 15px;
      margin-right: 10px;
    }
    ul {
      margin-bottom: 0;
      li {
        p {
          padding: 0;
          font-weight: 400;
          font-size: 16px;
          text-indent: 0;
          line-height: 26px;
          margin-top: 14px;
          display: contents;
        }
      }
    }
    ol {
      li {
        p {
          span {
            .secondaryList {
              li {
                padding-left: 20px;
                text-indent: -1.2em !important;
              }

              li:before {
                content: '•' !important;
                color: ${yellowColor} !important;
                font-size: 15px !important;
                margin-right: 10px !important;
              }
            }
          }
        }
      }
    }
  }
  .other-suggestions {
    font-size:16;
    font-weight:400;
    padding: 0 110px 30px 110px !important;
    ol {
      padding: 0 110px !important;
    }
    ul {
      li {
        margin-top: 25px;
      }
    }
  }

  #demandsSuggestions,
  #controlSuggestions,
  #supportSuggestions,
  #roleSuggestions,
  #relationshipSuggestions,
  #changeSuggestions {
    ul {
      li {
        margin-top: 25px;
      }
    }
  }
  #demandsSuggestions,
  #controlSuggestions,
  #supportSuggestions,
  #roleSuggestions,
  #relationshipSuggestions,
  #changeSuggestions {
    ul {
      li {
        p {
          padding: 0;
          font-weight: 400;
          font-size: 16px;
          text-indent: 0;
          line-height: 26px;
          margin-top: 14px;
        }
      }
    }
  }
  .survey-report-title {
    font-size: 30px;
    width: 100%;    
    font-weight: 900;
    color: ${blackColor};
  }

  .report-score {
    ul {
      li::before {
        content: none;
        margin: 0;
      }
    }
    border-top: none;
    border-bottom: none;
  }
  .report-links{
    // position: fixed;
    // right: 15px;
    // margin-top: 30px; 
    // top: 50%;   
    // transform: translateY(-50%);
	  position: relative;
    right: 0px;    
    top: 0;   
    transform: translateY(0);    
    margin: 30px 0 30px 30px;

    .report-arrow{
      position: absolute;
      left: -52px;
      top: 50%;
      transform: translateY(-50%);
      width: 42px;
      height: 42px;
      background-color: ${whiteColor} ;
      border-radius: 50%;
      text-align: center;
      line-height: 51px;
      //box-shadow: 0px 0px 8px rgba(0,0,0,0.1);
      border: 1px solid rgba(0, 0, 0, 0.12);
      cursor: pointer;
    }

    .btn-report{
      border-radius: 8px;
      width: 100%;
      padding: 10px 0;
      display: block;
      text-align: center;
      color: ${whiteColor};
      text-decoration: none;
      width: 175px;
      font-size: 15px;
      font-weight: 600;
    }
    .btn-demand{
      background:#45338c;
      border: 1px solid #45338c;       
    }
    .btn-control{
      background:#e84f6b;
      border: 1px solid #e84f6b;       
    }
    .btn-support{
      background:#007a7a;
      border: 1px solid #007a7a;      
    }
    .btn-manager{
      background:#007a7a; 
      border: 1px solid #007a7a;     
    }
    .btn-peer{
      background:#ff5939;
      border: 1px solid #ff5939;   
    }
    .btn-relationship{
      background:#53b5e0; 
      border: 1px solid #53b5e0;     
    }
    .btn-role{
      background:#fd92c2;
      border: 1px solid #fd92c2;      
    }
    .btn-change{
      background:#ffb205; 
      border: 1px solid #ffb205;   
    }
    .btn-steps{
      background:#E3BDA3;
      border: 1px solid #E3BDA3;      
    }
  }
  .report-links ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    li{
      display: block;
      margin-bottom: 5px;
    }
  }
  
  .report-toggle-menu{
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: ${teal};
    color: ${whiteColor};  
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    padding: 10px 15px;
	  span{
		  font-size: 16px;
		  margin-right: 8px;
	  }    
  }

  .report-filters-drawer > div{
    height: auto;
    top: 25%;    
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    overflow-y: visible !important;
  }
`;

export const SubHeading = styled.div`
  width: 100%;    
  color: ${blackColor};  
  font-weight: 600;  
  font-size: 16px;
  margin-top: 10px;
`;
export const SurveyDate = styled.div`
  width: 100%;
  span {
    color: ${outrageousOrange};
  }
`;
export const SurveyCompleted = styled.div`
  display: block;
  margin-bottom: 20px;
  margin-top: 5px;
  span {
    color: ${pictonBlue};
  }
`;
export const SurveyReportIntro = styled.div`
  font-size: 15px;
  line-height: 28px;
  color: ${blackColor};
  font-weight: 500;
  width: 100%;
  margin: auto;
  a {
    color: ${pictonBlue};
    text-align: left;
    margin: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    -webkit-text-decoration: none;
    text-decoration: none;
  }
  
`;
export const ExtendedReportDropDown = styled(ReportDropDown)`
  .report-scrore ul li::before {
    content: inherit !important;
  }
`;

/*** Survey Suggestion Report ***/
export const OtherSuggestionWrapper = styled.div`
  display: block;
  width: 60%;
  margin: 100px auto;

  ${media.xl`
  width: 53%;
`}
  h2 {
    color: ${mineShaft};
    font-size: 48px;
    font-weight: 900;
    width: 72%;
    margin-top: 0;
    ${media.xs`
        width: 90%;
        font-size: 24px;
      `}
  }
  p {
    color: ${mineShaft};
    width: 70%;
    font-weight: 500;
    font-size: 16px;
    line-height: 33px;
    margin: 23px 0 11px 0;
    ${media.xs`
       width: 90%;
       line-height: 31px;
       font-size:18px;
     `}
    span {
      font-weight: 600;
      color: ${mineShaft};
    }
  }
`;

export const SummaryReportWrapper = styled.div`
  width: 100%;
  padding: 60px;
  font-size:16px;
  font-weight: 400;
  margin-top:30px;
  height: 100%;

  h3, h2{
    width: 100%;
    float:left;    
  }

  .center-text {
    text-align:center;
    font-size:20px;
    font-weight:700;
    padding-bottom:10px;
  }

  .table-of-content{
    width:100%;
    ol > li{
      margin-bottom: 10px;
      font-weight: 500;      
    } 
    a{
      color: #000000;
      text-decoration: none;      
      :hover{
        text-decoration: underline;
      }
    }   
  } 
  .report-section{    
    width:100%;   
    float: left;   
  background: #FFFFFF;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 20px 60px 60px 60px;
  font-size:16px;
  font-weight: 400;
  margin-top:30px;  
  }
  .report-heading{
    font-size: 32px;
    padding: 40px 0 10px 0px;
    text-align: left;    
    width: 100%;
    font-weight: 900;
    float:left;
    margin: 0;    
  }

  .stats-table{  
    width: 100%;
    float: left;    
    font-size: 13px;    
    margin-bottom:20px;     
  }

  .stats-title-cell{
    background: rgba(83, 181, 224, 0.25);
    border: 1px solid rgba(83, 181, 224, 0.32);
    width:30%;
    float:left;    
    height: 50px;
    line-height: 50px;
    padding-left: 12px;
    margin: 0 0 4px 4px;
    font-weight: 900;    
    font-size: 13px;
  }
  .stats-role-cell{    
    background: rgba(83, 181, 224, 0.25);
    border: 1px solid rgba(83, 181, 224, 0.32);
    height: 50px; 
    font-size: 13px;       
    margin: 0 0 4px 4px;
    font-weight: 900;    
    flex-grow: 1;
    flex-basis: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: initial;
  }

  .job-role-container{
    width:100%;
    float:left;  
    display: flex;
    flex-direction: row;  
  }
  
  .job-role-title-cell{
    width:30%;
    float:left;
    border: 1px solid #e2e2e2;
    height: 50px;    
    padding-left: 12px;
    margin: 0 0 4px 4px;
    font-weight: 900;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
  }
  
  .job-role-cell{    
    border: 1px solid #e2e2e2;
    height: 50px;
    line-height: 50px;    
    margin: 0 0 4px 4px;
    font-weight: 900;
    flex-grow: 1;
    flex-basis: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .table-title{
    text-align:center;
    font-weight: 700;
    width: 100%;
    float: left;
    margin-top: 30px;
  } 
  
  .section{  
    width:100%;
    float:left;
    line-height: 26px;
    margin-bottom: 0;
    overflow-x: auto;
    ul > li{
      margin-bottom: 10px;
    }
  }

  .center-text{
    text-align:center;
  }

 
.heatmap-title{
  border: 1px solid rgba(83, 181, 224, 0.32);
  padding-left: 12px;
  width: 100%;
  float: left;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 900;
  background-color: rgba(83,181,224,0.25);        
  height: 50px;
  line-height: 50px;
  margin: 0 0 4px 4px;  
}

.section{    
    width:100%;
    float:left;    
}
.width-10{    
    border:1px solid #e2e2e2;
    text-align:center;
    font-size: 13px;
    font-weight: 600;
    margin: 0px 0px 4px 4px;
    height: 50px;    
    flex-grow: 1;
    flex-basis: 0;
    justify-content: center;
    align-items: center;  
    display: inline-flex;
}
.width-20-red{    
    border:1px solid #e2e2e2;
    text-align:center;
    font-size: 13px;
    font-weight: bold;
    margin: 0px 0px 4px 4px;    
    background-color: rgb(247, 178, 165);
    height: 50px;
    line-height:50px;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: center;
    align-items: center;
}
.width-20-green{    
    border:1px solid #e2e2e2;
    text-align:center;
    font-size: 13px;
    font-weight: bold;
    margin: 0px 0px 4px 4px;    
    background-color: #84C1C3;
    height: 50px;
    line-height:50px;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: center;
    align-items: center;
}
.width-20-grey{
  border: 1px solid #e2e2e2;
  font-size: 13px;
  font-weight: 900;
  background-color: #f2f2f2;
  margin:0 0 4px 4px;
  height: 45px;
  line-height: 45px;
  padding-left: 12px;
  flex-grow: 1;
  flex-basis: 0;
}
.width-60-grey{
    border: 1px solid #e2e2e2;    
    font-size: 13px;
    font-weight: 500;
    margin: 0 0 4px 4px;
    height: 45px;
    line-height: 45px;
    padding-left: 12px;
    flex-grow: 5;
    flex-basis: 0;
}
.width-18-grey{
    border: 1px solid #e2e2e2;        
    font-size: 13px;
    font-weight: 500;
    margin: 0 0 4px 4px;
    height: 45px;
    line-height: 45px;
    padding-left: 12px;
    flex-grow: 1;
    flex-basis: 0;
}
`;
const SurveyReportStyle = {
    SurveyReportWrapper,
    SurveyReportInnerWrapper,
    SubHeading,
    SurveyDate,
    SurveyCompleted,
    SurveyReportIntro,
    ExtendedReportDropDown,
    OtherSuggestionWrapper,
    SummaryReportWrapper
}
export default SurveyReportStyle;