import React, { useEffect, useState } from 'react';
import { AppModel } from '../../components';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import {
  ModalInnerWrapper,
  NewSurveyInnerMain,
  NewSurveyInner,
  NewSurveyWrapper,
  AssitanceForm,
} from '../../resources/styling';
import Grid from '@mui/material/Grid';
import FooterButtons from '../../components/common/FooterButtons';
import { LoaderOverlay } from '../../resources/styling/appStyle';
import { ExtendedNewSurveyInputWrapper } from './StaffRoleHeatmapStyle';

const IndividualHeatmapSelectionForm = (props) => {
  const initialValues = {
    firstname: '',
    lastname: '',
  };

  const {
    onSubmit,
    title,
    submitButtonText,
    isOpen,
    onClose,
    isLoading,
    allSelected,
    downloadList,
    onSelectionChange,
    onSelectionAll,
  } = props;
  
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        heatmaps: '',
      }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        onSubmit(values);
      }}
      render={({ handleSubmit }) => {
        return (
          <AppModel
            open={isOpen}
            onClose={onClose}
            bodyText={
              <ModalInnerWrapper>
                <NewSurveyWrapper>
                  <Grid container className='form-inner-wrapper'>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      md={12}
                      xs={12}
                      className='form-column-wrapper'
                    >
                      <NewSurveyInnerMain>
                        <NewSurveyInner>
                          <AssitanceForm>
                            <h2>{title}</h2>
                            {isLoading && (
                              <LoaderOverlay>
                                <div className='comment-loader'>
                                  <div id='loading' />
                                </div>
                              </LoaderOverlay>
                            )}
                            <ExtendedNewSurveyInputWrapper>
                              <p class='info-text'>
                                Select one or more heatmaps to include in the
                                downloaded PDF.
                              </p>
                              <div class='school-list-wrapper'>
                                <div className='school-list-heading'>
                                  <span>
                                    <input
                                      name='heatmaps'
                                      checked={allSelected}
                                      type='checkbox'
                                      onChange={(e) => {
                                        onSelectionAll(e.target.checked);
                                      }}
                                    />
                                  </span>
                                  <span class='school-title'>Select All</span>
                                </div>
                                <div className='school-list-body-outer'>
                                  {downloadList?.map((item) => {
                                    return (
                                      <div className='school-list-body'>
                                        <span>
                                          <input
                                            type='checkbox'
                                            checked={item.value}
                                            onChange={(e) => {
                                              onSelectionChange(
                                                item,
                                                !item.value
                                              );
                                            }}
                                          />
                                        </span>
                                        <span className='school-title'>
                                          {item.label}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </ExtendedNewSurveyInputWrapper>
                          </AssitanceForm>
                        </NewSurveyInner>
                      </NewSurveyInnerMain>
                    </Grid>
                  </Grid>
                </NewSurveyWrapper>
              </ModalInnerWrapper>
            }
            footer={
              <FooterButtons
                submitButtonText={submitButtonText}
                handleSubmit={() => {
                  handleSubmit();
                }}
                onClose={onClose}
              />
            }
          />
        );
      }}
    />
  );
};

export default IndividualHeatmapSelectionForm;
