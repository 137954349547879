import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { colors } from '../../resources/theme';

const { mineShaft, pictonBlue, teal, minsk } = colors;

export const WrapperStyle = styled.div`
  display: block;
  width: 95%;
  margin: 100px auto;

  ${media.xl`
width: 70%;
`}
  h2 {
    color: ${minsk} !important;
    font-size: 48px;
    font-weight: 900;
    width: 72%;
    margin-top: 0;
    ${media.xs`
       width: 90%;
       font-size: 24px;
     `}
  }
  p {
    color: ${mineShaft};
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin: 23px 0 11px 0;
    ${media.xs`
       width: 90%;
       line-height: 31px;
       font-size:18px;
     `}
    span {
      font-weight: 600;
      color: ${mineShaft};
    }
  }
`;

export const InnerWrapperStyle = styled.div`
  display: block;
  ul {
    padding-left: 0;
    p {
      margin-top: 10px;
    }
  }

  h2 {
    width: 80%;
    color: ${minsk};
  }
  h3 {
    color: ${teal};
    font-size: 26px;
  }
`;

export const ManagementStandardStyle = styled.ul`
  .sub-heading {
    font-weight: bold;
  }
  padding: 0;
  width: 100%;
  margin: 0;
  p {
    width: 100%;
    margin: 0;
    a {
      color: ${pictonBlue};
      text-decoration: none;
      font-weight: 400;
    }
  }
  list-style: none;
  color: ${teal};
  ul {
    padding: 0;
    margin: 0;
  }

  li::before {
    content: '•';
    color: ${pictonBlue};
    display: inline-block;
    width: 8px;
    font-size: 20px;

    /* margin-left: -1em; */
  }
  li {
    color: ${mineShaft};
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    text-indent: -0.5em;
    padding-left: 21px;
    p {
      text-indent: 0em;
      margin-top: 10px;
    }
    span {
      font-weight: 600;
      color: ${mineShaft};
    }
  }
`;

export const RiskFactorsStyle = styled.ul`
  padding: 0;
  width: 100%;
  margin: 0 0 0 19px;
  list-style: none;
  color: ${teal};
  li::before {
    color: ${pictonBlue};
    content: '•';
    display: inline-block;
    width: 8px;
    font-size: 20px;
    margin-right: 0;
  }
  li {
    color: ${mineShaft};
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-indent: -0.5em;
    padding-left: 21px;
    span {
      font-weight: 600;
      color: ${mineShaft};
    }
  }
`;
