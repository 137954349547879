import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import { AssitanceForm } from '../../resources/styling/appStyle';
import { bullet2 } from '../../resources/images/index';

const {
    pictonBlue, whiteColor, teal, mineShaft
} = colors;

/*** Autocomplete ***/
export const AutoCompleteWrapper = styled.div`
  .search {
    /* width: 30rem;
    margin: 10rem auto 2rem auto; */
    text-align: right;
    position: relative;
  }
  .search-box {
    border: 4px solid transparent;
    border-radius: 2px;
    font-size: 2rem;
    width: 100%;
    padding: 1rem;
    transition: width 0.3s;
  }
  .search-box:focus {
    width: 100%;
    outline: none;
    border: 4px solid #08a1b6;
  }

  .search-btn:hover {
    outline: none;
    opacity: 0.4;
    cursor: pointer;
  }
  .search-btn:focus {
    outline: none;
    opacity: 0.6;
  }

  ul.options {
    display: block;
    list-style: none;
    width: 100%;
    transition: width 0.3s;
    margin: auto;
    position: absolute;
    z-index: 2;
    left: 0;
    padding-left: 0;
    margin-top: -10px;
    background: ${whiteColor};
    border: 2px solid ${pictonBlue};
    border-radius: 6px;
    overflow: hidden;
  }

  ul.options li {
    display: block;
    background: white;
    /* margin: 10px auto; */
    padding: 15px 10px;
    font-size: 13px;
    color: ${pictonBlue};
    width: 100%;
    border-radius: 2px;
    border-radius: 0;
  }
  ul.options li:hover {
    font-weight: 400;
    color: ${whiteColor};
    cursor: pointer;
    transition: 0.3s all;
    background: ${teal};
  }

  ul.options li.option-active {
    background: ${teal};
    font-size: 13px;
    color: ${whiteColor};
  }
  .no-options {
    color: white;
  }
`;

/*** Register Confirm Email Sent ***/
export const RegisterInner = styled.div`
  display: table-cell;
  vertical-align: middle;
`;
export const ExtendedAssitanceForm = styled(AssitanceForm)`
  .optional-label {
    font-size: 13px;
    float: left;
    position: absolute;
    bottom: -7px;
    left: 10px;
    color: ${pictonBlue};
  }
  width: 65%;
  ${media.lg`
  width: 55%;
  `}
  h2 {
    width: 100%;
    text-align: center;
    color: ${mineShaft} !important;
  }
  p {
    font-size: 18px;
    text-align: center;
    width: 91%;
    margin-bottom: 0;
    margin: auto;    
  }
  .form-heading {
    border-top: 1px solid ${pictonBlue};
    text-align: center;
    margin: 50px 0 40px 0;
    float: left;
    width: 100%;
    span {
      position: relative;
      background: ${whiteColor};
      margin: -10px 0 0 0;
      color: ${pictonBlue};
      font-size: 12px;
      font-weight: 900;
      padding: 0 12px;
      top: -12px;      
    }
  }

  ul {
    margin: 27px 0 0 0;
    padding: 0 0 0 28px;
    list-style: none;

    li {      
      margin-bottom: 22px;
      background: url(${bullet2});
      background-repeat: no-repeat;
      padding-left: 34px;
      background-position-y: 3px;
    }
  }
  .optional-label {
    left: 10px;
    color: ${pictonBlue};
  }
  width: 85%;
  ${media.lg`
  width: 85%;
  `}
`;

/*** School Detail Form ***/
export const InputWrapper = styled.form`
  padding: 10px;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const SchoolRegisterStyle = {
    AutoCompleteWrapper,
    RegisterInner,
    ExtendedAssitanceForm,
    InputWrapper
}
export default SchoolRegisterStyle;