import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { LeftMenu, SurveyDropDown, MainHeader } from '../../components';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames, MenuItems, QueryStringTokens } from '../../utils';
import Box from '@mui/material/Box';
import { navigationActions } from '../../store/headerStore';
import SurveyAdditionalQuestions from './SurveyAdditionalQuestions';
import SchoolAdditionalQuestions from './SchoolAdditionalQuestions';
import { schoolService, storageService, surveyService } from '../../services';
import {
  AssistanceWrapper,
  AdditionalQuestionsMainWrapper,
} from '../../components/common/SchoolQuestionStyle';
import {
  parseJwt,
  getTransformedSurveyRecordList,
  getQueryStringVal,
} from '../../utils/CommonFunctions';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';

class AdditionalQuestions extends Component {
  constructor(props) {
    super(props);
    const token = storageService.getItem(WebStorageNames.Token);
    const userInfo = parseJwt(token);

    this.state = {
      comments: [],
      message: 'Loading...',
      sortingByTypeOption: { value: false, label: 'Open actions' },
      latestSurveyOption: {
        value: 'schoolquestionbank',
        label: 'School Question Bank',
      },
      createDialogOpen: false,
      latestSurveyOptions: [
        { value: 'schoolquestionbank', label: 'School Question Bank' },
      ],
      isRole: userInfo.role,
      isSurveyProgressing: false,
      surveyExists: false,
    };
  }

  componentDidMount() {
    const schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    let isSurvey = getQueryStringVal(QueryStringTokens.IsSurvey);
    if (isSurvey == null) {
      isSurvey = false;
    } else {
      isSurvey = isSurvey === 'true' ? true : false;
    }
    this.props.navigationActions.activeItemAction({
      item: MenuItems.QuestionBank,
      showReportSubMenu: false,
      disableReportMenu: false,
    });
    if (schoolToken && schoolToken !== 'null') {
      if (schoolToken) {
        schoolService.getSchool(schoolToken).then((response) => {
          const { Id } = response.data;
          this.getSurveysNameAndTokenBySchool(Id, schoolToken, isSurvey);
          this.setState({ surveyExists: false });
          isSurvey
            ? this.setState({ surveyExists: true })
            : this.setState({ surveyExists: false });
        });
      } else {
        let schoolId = getQueryStringVal(QueryStringTokens.schoolId);
        this.getSurveysNameAndTokenBySchool(schoolId, schoolToken, isSurvey);
        isSurvey
          ? this.setState({ surveyExists: false })
          : this.setState({ surveyExists: true });
      }
    } else {
      const token = getQueryStringVal(QueryStringTokens.token);
      this.getSurveysNameAndToken(token);
      isSurvey
        ? this.setState({ surveyExists: false })
        : this.setState({ surveyExists: true });
    }
  }

  getSurveysNameAndToken(token) {
    surveyService
      .getSurveysNameAndToken(token)
      .then((response) => {
        const surveyRecordList = getTransformedSurveyRecordList(response.data);
        surveyRecordList.push({
          value: 'schoolquestionbank',
          label: 'School Question Bank',
        });
        this.setState({ latestSurveyOptions: surveyRecordList });
        const token = window.location.search;
        var selectedRecord = surveyRecordList.filter(
          (item) => '?token=' + item.value === token
        );

        if (selectedRecord.length > 0) {
          this.setState({ latestSurveyOption: selectedRecord[0] });
        }
      })
      .catch((error) => console.log(error));
  }

  getSurveysNameAndTokenBySchool(schoolId, schoolToken, isSurvey) {
    surveyService
      .getSurveyNameAndTokenBySchool(schoolId)
      .then((response) => {
        const surveyRecordList = getTransformedSurveyRecordList(
          response.data,
          schoolToken
        );
        surveyRecordList.push({
          value: 'schoolquestionbank',
          label: 'School Question Bank',
          schoolToken: schoolToken,
        });
        this.setState({ latestSurveyOptions: surveyRecordList });
        const token = isSurvey
          ? getQueryStringVal(QueryStringTokens.token)
          : '';
        var selectedRecord = surveyRecordList.filter(
          (item) => item.value === token
        );
        if (selectedRecord.length > 0) {
          this.setState({ latestSurveyOption: selectedRecord[0] });
        }
      })
      .catch((error) => console.log(error));
  }

  handleSurveyValueChange = (latestSurveyOption) => {
    this.setState({ latestSurveyOption });
    if (latestSurveyOption.value === 'schoolquestionbank') {
      window.location.search =
        '?token=' +
        latestSurveyOption.value +
        '&schoolToken=' +
        latestSurveyOption.schoolToken +
        '&isSurvey=' +
        false;
    } else {
      window.location.search =
        '?token=' +
        latestSurveyOption.value +
        '&schoolToken=' +
        latestSurveyOption.schoolToken +
        '&isSurvey=' +
        true;
    }
  };

  render() {
    const { latestSurveyOption, latestSurveyOptions, isRole, surveyExists } =
      this.state;

    return (
      <AdditionalQuestionsMainWrapper>
        <div className='containerClass'>
          <div className='left-container'>
            <LeftMenu />
          </div>

          <div className='right-container'>
            <MainHeader />
            <Box
              sx={{
                display: 'inline-block',
                marginBottom: '30px',
                width: '100%',
              }}
            >
              <AssistanceWrapper>
                <div className='action-heading-wrapper'>
                  <span className='action-heading'>
                    My Additional Questions
                  </span>
                </div>
              </AssistanceWrapper>

              <AssistanceWrapper>
                {/* <div className='btn-request-assistance'>
                    <RequestAssistanceButton />
                  </div> */}
                <div className='dd-survey'>
                  <SurveyDropDown
                    isRole={isRole}
                    latestSurveyOptions={latestSurveyOptions}
                    latestSurveyOption={latestSurveyOption}
                    handleSurveyValueChange={this.handleSurveyValueChange}
                  />
                </div>
              </AssistanceWrapper>
            </Box>

            {surveyExists ? (
              <SurveyAdditionalQuestions />
            ) : (
              <SchoolAdditionalQuestions />
            )}
          </div>
        </div>
      </AdditionalQuestionsMainWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(AdditionalQuestions));
