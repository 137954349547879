import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderAction } from '../../store/loaderStore';
import { firstLoginAction } from '../../store/userStore';
import { userService } from '../../services';
import { useHistory, withRouter } from 'react-router-dom';
import { routesObj } from '../../routes';
import { welbeeLogo } from '../../resources/images';
import { Loader } from '../../components/common/loginStyle';
import { CircularProgress } from '@mui/material';

function LoginRedirect(props) {

  const history = useHistory();
  
  useEffect(() => {
    async function signinAsync() {
      await userService.signinRedirectCallback();
      history.push(routesObj.Login.path);
    }
    signinAsync();
  }, [history]);

  return (
    <Loader>
      <div className="loading">
        <div className="login-logo">
          <img src={welbeeLogo} alt="" />
        </div>
        <div>
          <svg width={0} height={0}>
            <defs>
              <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={50} />
        </div>        
      </div>
    </Loader>
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});
export default connect(null, mapDispatchToProps)(withRouter(LoginRedirect));

