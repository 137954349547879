import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { routesObj } from '../../routes';
import { LeftMenu } from '../../components';
import { SurveyTypes, MenuItems, HeadTeacherAccess } from '../../utils/Constants';
import { welbeeVoiceService, storageService, schoolService } from '../../services';
import { WebStorageNames } from '../../utils';
import NewWelbeeVoiceForm from './NewWelbeeVoiceForm';
import VoiceOptions from '../../components/common/VoiceOptions';
import { Player } from 'video-react';
import ImagePopup from './ImagePopup';
import VideoButton from './VideoButton';
import { loaderAction } from '../../store/loaderStore';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import WelbeeVoiceWelcomePopup from '../welbeeVoice/WelbeeVoiceWelcomePopup';
import { AssistanceWrapperHeading } from '../../resources/styling/appStyle';
import {
  invitationBox,
  emailinvitation,  
  welbeeoption,
  removethin,
  staffIcons,
  addIcon,
  editIcon,
  deleteIcon,
} from '../../resources/images/index';
import voiceInstructionsVideo from '../../resources/video/Voice-Quick-Start-FN.mp4';
import { navigationActions } from '../../store/headerStore';
import { VideoWrapper, ExtendedVoiceDashboardWrapper, ExtendedAssistanceWrapper, ExtendedSearchFilterWrapper } from './WelbeeVoiceStyle';

class VoiceInstructions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: 'Loading...',
      conversationOptions: [{ value: SurveyTypes.any, label: '' }],
      converstion: [],
      openConverstionPopUp: false,
      openVideoPopup: false,
      isConfirmInvitation: false,
      numberOfStaff: 0,
      voiceRecord: null,
      accessLevel: 0,
      isVoiceFirstLogin: false,
    };
  }
  componentDidMount() {
    this.getSchoolConverstion();
    this.getDropDownValue();
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    const isVoiceFirstLogin = storageService.getItem(
      WebStorageNames.IsVoiceFirstLogin
    );
    this.props.navigationActions.activeItemAction({
      item: MenuItems.VoiceInstructions,
    });
    this.setState({
      accessLevel: accessLevel,
      isVoiceFirstLogin: isVoiceFirstLogin,
    });
  }

  onCloseWelcomePopup = () => {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    welbeeVoiceService
      .setFirstTimeVoiceLoggedInSuccessful(schoolId)
      .then((response) => {
        if (response.success) {
          this.setState({
            isVoiceFirstLogin: false,
          });
          storageService.setItem(WebStorageNames.IsVoiceFirstLogin, false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onGoToInstructionsClick = () => {
    storageService.setItem(WebStorageNames.IsVoiceFirstLogin, false);
    this.onCloseWelcomePopup();
    
  };

  getDropDownValue = (token = null) => {
    const tokenValue = window.location.search.split('token=')[1];
    welbeeVoiceService
      .getSurveyVoiceByToken(tokenValue)
      .then((response) => {
        if (response.success) {
          this.setState({ converstion: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleVoiceValueChange = (latestVoiceOption) => {
    this.setState({ latestVoiceOption });
    window.location.search = '?token=' + latestVoiceOption.value;
    this.getDropDownValue(latestVoiceOption.value);
  };

  getSchoolConverstion = () => {
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    welbeeVoiceService
      .getSurveyVoiceNameAndToken(schoolId)
      .then((response) => {
        if (response.success) {
          this.setState({ conversationOptions: response.data });
          const token = window.location.search;
          var selectedVoice = response.data.filter(
            (item) => '?token=' + item.value === token
          );
          if (selectedVoice.length > 0) {
            this.setState({ latestVoiceOption: selectedVoice[0] });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  closePopUp() {
    this.setState({ openConverstionPopUp: false });
  }

  redirectToStaffManagment() {
    this.props.history.push(routesObj.StaffManagement.path);
  }

  onAllStaffClick(values) {
    const { numberOfStaff } = this.state;
    if (!values.IsFirstEmailSent) {
      if (numberOfStaff >= 8)
        this.setState({ isConfirmInvitation: true, voiceRecord: values });
      else {
        this.setState({ voiceRecord: values });
        toast.error(
          <div>
            You must select a minimum of 8 staff members to be able to send an
            invitation for a{' '}
            <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
              new
            </span>{' '}
            conversation.
          </div>
        );
      }
    } else {
      values.Id = 0;
      storageService.setItem(WebStorageNames.TempVoice, values);
      this.props.history.push(
        routesObj.WelbeeVoiceNotification.path + '?voiceId=0'
      );
    }
  }

  onCreateConverstionClick() {
    const { loaderActions } = this.props;
    const { voiceRecord } = this.state;
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    voiceRecord.SchoolId = schoolId;
    loaderActions.dataLoading(true);
    this.setState({ isConfirmInvitation: false });
    welbeeVoiceService
      .createConverstion(voiceRecord)
      .then((response) => {
        loaderActions.dataLoading(false);
        if (response.success) {
          if (voiceRecord.IsFirstEmailSent) {
            this.props.history.push(
              routesObj.WelbeeVoiceNotification.path +
                '?voiceId=' +
                response.data.Id
            );
          } else {
            toast.success('Invitations sent successfully.');
            this.props.history.push(routesObj.WelbeeVoice.path);
          }
          this.setState({
            openConverstionPopUp: false,
            isConfirmInvitation: false,
            voiceRecord: null,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openConverstionPopUp: false,
          isConfirmInvitation: false,
          voiceRecord: null,
        });
        loaderActions.dataLoading(false);
      });
  }

  onNewConversationClick = () => {
    const { loaderActions } = this.props;
    const schoolId = storageService.getItem(WebStorageNames.SchoolId);
    loaderActions.dataLoading(true);
    this.setState({ openConverstionPopUp: true });
    schoolService
      .getSchoolStaff(schoolId)
      .then((response) => {
        loaderActions.dataLoading(false);
        const { data, success } = response;
        if (success) {
          this.setState({ numberOfStaff: data.length });
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  };

  redirectToStaffManagement() {
    this.props.history.push(routesObj.StaffManagement.path);
  }

  render() {
    const {
      openConverstionPopUp,
      openVideoPopup,
      numberOfStaff,
      isConfirmInvitation,
      accessLevel,
      isVoiceFirstLogin,
    } = this.state;
    return (
      <ExtendedVoiceDashboardWrapper>
        {isVoiceFirstLogin && (
          <WelbeeVoiceWelcomePopup
            isOpen={isVoiceFirstLogin}
            onClose={() => {
              this.onCloseWelcomePopup();
            }}
            onOk={
              //noOfStaff > 0
              //  ? this.onWelbeeVoiceOK
              this.onGoToInstructionsClick
            }
            buttonText={
              //noOfStaff > 0
              //  ? 'Start your first conversation'
              'Go to instructions'
            }
          />
        )}
        <LeftMenu />
        <ConfirmPopupForm
          isOpen={isConfirmInvitation}
          onClose={() => this.setState({ isConfirmInvitation: false })}
          onOk={() => this.onCreateConverstionClick()}
          successPopupMessage={
            'You are about to send your invitation to (' +
            numberOfStaff +
            ') staff members.'
          }
          okButtonText='Confirm'
        />
        <div className='content-wrapepr'>
          <ExtendedAssistanceWrapper>
            <AssistanceWrapperHeading>
              <span className='comment-heading'>Welbee voice instructions</span>
            </AssistanceWrapperHeading>
            {accessLevel !== HeadTeacherAccess.View && (
              <VoiceOptions
                showConversationDropDown={false}
                addVoiceButtonClick={() => this.onNewConversationClick()}
              />
            )}
          </ExtendedAssistanceWrapper>

          <ExtendedSearchFilterWrapper>
            <div className='subcontainer-inner'>
              <div
                className='back-button'
                onClick={() => {
                  // Clicking the menu item instead of navigation from props - because the menu button will be highlited.
                  // In other case, we have to highlite the menu button manually.
                  document.getElementById('conversation-menu').click();
                }}
              >
                Back to conversation
              </div>
            </div>
          </ExtendedSearchFilterWrapper>
          <>
            {openConverstionPopUp && (
              <NewWelbeeVoiceForm
                onSubmit={(value) => {
                  this.onAllStaffClick(value);
                }}
                isLoading={false}
                submitButtonText={'All staff invitation'}
                closeButtonText={'Select staff to invite'}
                isOpen={this.state.openConverstionPopUp}
                onClose={() => {
                  this.closePopUp();
                }}
                voiceRecord={this.state.voiceRecord}
                onSelectStaff={() => this.redirectToStaffManagment()}
              />
            )}
          </>
          {openVideoPopup ? (
            <ImagePopup>
              <button
                className='close'
                onClick={() => this.setState({ openVideoPopup: false })}
              >
                <img src={removethin} alt='remove button' />
              </button>

              <VideoWrapper>
                <Player autoPlay='true'>
                  <source src={voiceInstructionsVideo} />
                </Player>
              </VideoWrapper>
            </ImagePopup>
          ) : (
            ''
          )}

          <div className='content-body-inner-wrapper'>
            <div className='voice-instructions-inner'>
              <h1>Welcome to Welbee Voice</h1>

              <VideoButton
                onClick={() => this.setState({ openVideoPopup: true })}
              />

              <h2>Welbee Voice Instructions</h2>

              <p>
                We built Welbee Voice in response to the Covid-19 school
                closures and its impact on mental health and engagement due to
                the lockdown, isolation, and uncertainty. Staff value being
                heard and feeling they are able to contribute more and Voice
                will continue to support you for the long-term.
              </p>
              <p>
                Welbee Voice is a secure and anonymous way for all staff to
                provide feedback, share concerns, and put forward ideas and
                solutions to the school and / or Multi-Academy Trust senior
                leadership team.
              </p>
              <p>
                Importantly, it is also a way for you to respond to them, ask
                for more details, address concerns or seek ideas, while the
                identity of the staff member remains protected. It is up to them
                if and when they choose to break their anonymity in any
                conversation.
              </p>
              <h2>How does it work?</h2>
              <p>We have kept Welbee Voice very simple to access and use.</p>
              <p>
                These instructions cover the period when you are in your free
                trial and when you have upgraded to Voice (Pro) or access it as
                part of your Survey.
              </p>
              <p>
                If you have taken a free trial and choose not to upgrade then
                you can continue with a free account that has fewer features.
                Details of this are shown in the section called{' '}
                <i>Voice Forever Free below</i>.
              </p>
              <p>Using Welbee Voice follows a five-step process.</p>
              <ol className='how-works-number'>
                <li>
                  Add your staff members by downloading the CSV file template
                  from within your <i>Staff Management</i> tab in your
                  dashboard. Add all staff members’ first names, email addresses
                  and allocate them to groups and then upload them.
                  <p>
                    You create the groups to fit how you organise your school,
                    for example using departments, year groups, job roles, or
                    leadership groups. You can add multiple groups up to a
                    maximum of five by simply using the columns in the CSV
                    template file.
                  </p>
                </li>
                <li>
                  Create an invitation that will be sent to staff by e-mail to
                  allow them to participate and provide feedback through an
                  anonymous conversation. They do not need to remember login or
                  password details. You can choose to invite all staff or a
                  specific group or groups of staff (a minimum of 8 staff must
                  be invited to any conversation to protect their anonymity and
                  give them confidence that they can be honest and open).
                </li>
                <li>
                  Your staff members receive their invitations and can choose to
                  participate and share concerns, feedback or ideas.
                </li>
                <li>
                  You receive their feedback into your dashboard and can then
                  respond while continuing to maintain the anonymity of each
                  staff member.
                </li>
                <li>
                  You can continue open conversations as appropriate and issue
                  new invitations when needed.
                </li>
              </ol>
              <p>Full details of each of these steps are below.</p>

              <h2>Step 1</h2>
              <p>
                You will need to{' '}
                <a
                  href='javascript:void(0)'
                  onClick={() => {
                    if (accessLevel !== HeadTeacherAccess.View) {
                      this.redirectToStaffManagement();
                    }
                  }}
                >
                  add your staff members details
                </a>{' '}
                and you can do this within the{' '}
                <span className='bold-text'> Staff Management </span>
                tab on your menu on the left of your dashboard.
              </p>
              <p>
                Please note that if you have already uploaded staff records, for
                example using Survey, you will not have to do this again and can
                proceed to create your first invitation.
              </p>
              <p>
                First, add your staff members’ first names and email addresses.
                You should be able to copy and paste these from your staff
                database, for example, SIMs. Leave the title for each column in
                place.
              </p>
              <p>
                To create groups you just need to choose what will work for your
                school. We have deliberately built this flexibly to meet the
                needs of different schools. For example, you can group staff by
                their department name, year group, job role, leadership group,
                etc. Just use what works for you.
              </p>
              <p>
                You can add more than one group for each staff member to ensure
                you can include each of their peer, job, or other roles and up
                to a maximum of five. This means when you send out invitations
                to specific staff, you will capture the feedback you need. If
                they are in more than one group invited to any conversation they
                will only receive one invitation (rather than one for every
                group they belong to – we take care of that).
              </p>
              <p>
                Add a group name against each staff member in the columns
                provided (one per column), though you do not need to complete
                all columns and staff can be in as few or as many groups as you
                need (between 1 and 5).
              </p>
              <p>
                Once the CSV file is completed, click on the blue upload symbol
                on the right and select the file you have just created or you
                can drag your file across to the box. Your staff records will be
                added and now show as a list within <i>Staff management</i> and
                below where you added the CSV file.
              </p>
              <p>
                You can use this list to edit or delete existing records and to
                allocate user rights to other staff which will mean they have
                their own school account login.
              </p>
              <p className='bold-text-subheadings'>Allocating user rights</p>
              <p>
                You can assign full, viewing, or admin rights to individual
                staff members, so your senior team and other relevant people can
                access your dashboard.
              </p>
              <ul>
                <li>
                  Full rights mean the staff member can upload and edit staff,
                  create invitations, respond to conversations, and allocate
                  user rights. They will also be able to see all conversations
                  and respond to any staff comments, making sure that these are
                  not missed, even during absences.{' '}
                  <p>
                    Staff with full access rights can also grant access to other
                    staff members. The account originator will receive email
                    notification of who has granted access to a staff member and
                    what access level has been granted.
                  </p>
                </li>
                <li>
                  Viewing rights mean staff members will be able to view
                  conversations and comments only and not create invitations,
                  respond to comments, or add or amend staff records.
                </li>
                <li>
                  Admin rights mean staff members will only be able to add and
                  edit staff records, so you can delegate this to members of
                  your office team or any other relevant staff member.
                </li>
              </ul>
              <p>
                These rights can be revoked at any time by the main account
                holder (the person who registered for the account). You can
                change the main account holder by changing the name and email
                address in your <i>Account</i> tab in the menu.
              </p>
              <p>
                When user rights are allocated, these are automatic and the
                relevant staff member will receive an email giving them their
                login information.
              </p>
              <p className='bold-text-subheadings'>Adding new staff records</p>
              <p>
                You can add new staff or add records to existing staff when
                needed.{' '}
              </p>
              <p>
                To add new staff, go to <i>Staff management</i> and you can
                either add them manually by entering their details in the boxes
                shown, or use a new CSV file. Adding manually is ideal for one
                or a very small number of staff and if you are allocating them
                to existing group(s).
              </p>
              <p>
                If you need to create{' '}
                <img src={addIcon} alt='' style={{ width: '17px' }} /> , remove{' '}
                <img src={deleteIcon} alt='' style={{ width: '17px' }} />, or
                rename <img src={editIcon} alt='' style={{ width: '17px' }} /> a
                group you can do this using <img src={staffIcons} alt='' /> at
                the top of the staff list.
              </p>
              <p>
                If you want to add multiple staff members and create new groups
                then you should use a new CSV file. Follow the steps above for
                uploading your CSV and these new records will be created.{' '}
              </p>
              <p>
                If you are adding new information to an existing staff member
                then just add their email address in the CSV (the email address
                is used to match records), along with the new information.
              </p>
              <p>
                If their email address has changed you will need to amend this
                first using the edit button alongside their details.
              </p>
              <p>
                If you need any support with staff records, please email
                <a href='mailto:support@welbee.co.uk'>
                  {' '}
                  support@welbee.co.uk
                </a>{' '}
                or click the request assistance button using your Account tab in
                your menu.
              </p>
              <p className='bold-text-subheadings'>
                Adding staff to an existing conversation
              </p>

              <p>
                If you add new staff members (or replace incorrect details) you
                can add them to an existing conversation from within your{' '}
                <i>Conversations</i> dashboard. Click on the bell icon and then
                select the additional staff to invite by ticking the checkbox by
                the relevant staff member. Or you can invite new groups of
                staff.
              </p>
              <p>
                You can also use the bell icon to send an invitation again, for
                example, if a staff member says they haven’t received it.
              </p>
              <p className='bold-text-subheadings'>
                Before sending your first invitation
              </p>
              {/* <p>
                This is so invitations to participate can be sent to staff. You
                can see details of how we use your data and comply with the
                General Data Protection Regulations&nbsp;
                <a href='https://welbee.co.uk/GDPR/' target='_blank'>
                  here
                </a>
                .
              </p> */}

              <p>
                Ask your IT department or technician to ensure that e-mails
                received from{' '}
                <span className='email-non-link'>admin@welbee.co.uk </span> are
                included in your safe senders list. While invitations do come
                from this e-mail address they are sent through Amazon AWS and
                you should also ensure that e-mails from{' '}
                <span className='email-non-link'>
                  @eu-west-1.amazonses.com{' '}
                </span>{' '}
                are also allowed.
              </p>
              <p>
                Without this, it is likely that some staff members’ invitations
                will be delivered to their spam folder. Even after doing this,
                it is still possible an invitation could end up in a spam folder
                and if any staff member tells you they have not received their
                email, please ask them to check spam before re-issuing it.
              </p>

              {/* <p>
                Once you add the CSV file you will be immediately taken to your
                conversations and what to do next is in step 2 below.
              </p>
              <p>
                You can add any new staff members by uploading a new CSV file or
                individually using the add staff button in the Account
                Management tab.
              </p>
              <p>
                You can also replace any incorrectly spelt records, amend e-mail
                addresses or delete staff by using the edit and delete buttons
                at the end of each staff member’s record.
              </p>
              <p>
                If you add new staff members or replace incorrect addresses you
                can add them to an existing conversation from within your
                conversations dashboard. Click on the bell icon and then select
                the additional staff to invite by ticking the check box by the
                relevant staff member. Or you can add new groups of staff.
              </p>
              <p>
                You can also use the bell icon to send an invitation again, for
                example if a staff member says they haven’t received it.
              </p> */}
              {/* <p>
                You can add any new staff members or replace any incorrectly
                spelt addresses by uploading a new CSV file. If you add new
                staff members or replace incorrect addresses you can add them to
                an existing conversation using the <i>‘manually send invite’</i>{' '}
                button in your conversations tab. You only need to use it for
                this purpose as invitations will be sent automatically to those
                staff that have been uploaded previously.
              </p> */}

              <p>
                Before sending your first invitation you will want to let staff
                know about Voice and how to use it. To help there is a suggested
                message below you can adapt and send to them if needed.
              </p>

              <p>
                <span className='message-box'>
                  <p>Dear</p>
                  <p>
                    Your wellbeing is very important and current events have
                    obviously brought significant change. I know how much you
                    have had to do to adapt to our new ways of working.
                  </p>
                  <p>
                    We are making sure we keep in touch and have put in place
                    those things we think will help during this time and it will
                    be really helpful to get your feedback on what is happening
                    for you and what else we might do.
                  </p>
                  <p>
                    While I hope you will reach out if you do have concerns,
                    ideas or feedback, I also want to make sure you can do this
                    easily and, if needed, anonymously. To do this we will be
                    using an external service from Welbee.
                  </p>
                  <p>
                    You will receive an e-mail invitation to provide feedback on
                    (insert date). By clicking on the link you will be able to
                    send comments and these will be anonymous and your identity
                    will not be known.
                  </p>
                  <p>
                    I will be able to review your comments and feedback and also
                    respond directly to you, though will not know who I am
                    writing to. This will allow me and other senior leaders to
                    provide better support and guidance, address concerns and
                    listen to your ideas and solutions.
                  </p>
                  <p>
                    Unlike a survey you do not need to respond unless you have
                    something you want to say. You can use this to provide
                    feedback at any time that is right for you and the link will
                    remain open for you to use in the weeks ahead and over the
                    longer term.
                  </p>
                  <p>
                    I will also be able to send out further invitations to ask
                    for feedback on specific areas and I hope you will take the
                    opportunity to use your voice whenever it feels appropriate
                    to you.
                  </p>
                  <p>If you have any questions then please let me know.</p>
                  <p>
                    Thank you for your continuing support and work during what I
                    know are challenging times for all of us. Please look after
                    yourself and stay safe.
                  </p>
                </span>
              </p>
              <p>
                You can see details of how we use your data and comply with the
                General Data Protection Regulations&nbsp;
                <a href='https://welbee.co.uk/GDPR/' target='_blank' rel="noreferrer">
                  here
                </a>
              </p>
              <p>
                Once you add the CSV file you will be immediately taken to your
                conversations and see a confirmation message that your staff
                records have been added.{' '}
              </p>

              <p>Sending your first conversation is in step 2 below.</p>

              <p className='bold-text-subheadings'>
                Adding your school or MAT logo
              </p>

              <p>
                If you didn’t add your school logo when you first registered
                your account, you can do this at any time. Adding your log means
                it will show in your school dashboard and on staff invitations
                and responses sent out.
              </p>

              <p>
                To upload go to your <i>Account</i> tab in the menu and click on
                upload a logo at the bottom of the first section. Add the file
                or drag it across to the box and your logo will be added to your
                account.
              </p>

              <h2>Step 2</h2>

              <p>
                When you successfully upload your staff details you will
                automatically be taken to your conversation dashboard.
              </p>
              {/* <p>
                To issue invitations you go to the{' '}
                <span className='bold-text'>Conversations</span> tab in your
                menu on the left of your dashboard.
              </p> */}
              <p>
                To issue your first and subsequent invitations, click on the
                <span className='pink-btn'>New conversation</span>button and
                this opens up a box for you to create your staff invitation. An
                example is shown below.
              </p>
              <span>
                <img src={invitationBox} alt='invitation popup' />
              </span>

              <p>
                You can leave the subject title as it is and simply send with
                the existing message.
              </p>
              <p>
                Or you can add your own subject line and message to ask for
                specific feedback. For example, you can use this after
                implementing a change, to seek feedback on a specific area of
                school life or to ask staff how well they are managing at the
                current time. You can also use it for consultations when
                anonymity will help.
              </p>
              {/* <p>
                You can also add your name to the end of your message so the
                staff member knows who is responding to them.
              </p> */}
              <p>
                Once you are happy with the subject line and message you can
                send the invitation. Choose to invite all staff or select which
                staff you want to invite. If you invite all staff then there is
                nothing further to do and the invitations will be sent.
              </p>

              <p>
                If you click to select staff you will be taken to a page where
                you can choose who you want to invite. You do this by either
                selecting one or more groups from the Select Group drop down box
                or by ticking individual staff members. Once more than 8 staff
                are selected, or groups that have a total of 8 or more staff
                combined, the Send Invitation button will be highlighted.
              </p>
              <p>
                Click to send your invitation and you will be asked to confirm.
              </p>

              <p>
                Staff will receive an invitation to participate directly to
                their e-mail address and you can see details of the content and
                how this is presented in the invitation example shown below.
              </p>

              <p>
                This shows the general message that pre-populates all
                invitations and if you add your own details this will appear
                where you can see the subject and message.
              </p>

              <span>
                <img src={emailinvitation} alt='email linvitation' />
              </span>

              <p>
                One reminder invitation will be sent to staff after 7 days to
                make sure they do not miss an opportunity to provide feedback.
              </p>

              <h2>Step 3</h2>
              <p>
                The staff member receives their invitation by email. If they
                choose to participate and share feedback then they simply click
                the button in their email and are taken to their own
                conversation page.
              </p>
              <p>
                On this conversation page, they will be able to see the name of
                the person who started the conversation and who responds to any
                comments they make. The name displayed will be that of the
                logged-in user when the response is sent. We want to ensure that
                staff are confident in sending further open replies.
              </p>

              <p>
                To share feedback, ideas, comments, or questions they simply
                write this in the space provided and click the submit button.
              </p>

              <p>
                While the staff member will know who is responding, users will
                never know who they are communicating with unless the staff
                member reveals their identity.
              </p>

              {/* <span style={{ marginTop: '30px', display: 'block' }}>
                <img
                  src={messagebox}
                  alt='invitation popup'
                  style={{ width: '85%' }}
                />
              </span> */}

              <h2>Step 4</h2>
              <p>
                Staff comments arrive in your{' '}
                <span className='bold-text'> Conversations </span> dashboard and
                you will receive an e-mail alert to notify you whenever a new
                comment is received.
              </p>
              <p>
                This will be sent to the email that the person sending the
                invitation uses to login to their dashboard.
              </p>
              <p>
                If you are the person who registered the account then you remain
                the main account holder, until such time as you amend the name
                and address in your <i>account</i> tab within your menu. Note
                this will also change your account login email address.
              </p>
              <p>
                When a new staff comment is received you will also be notified
                of this in the conversations dashboard. A number will appear on
                your menu and against each conversation to show you how many new
                replies have been received and so you can quickly see where to
                find the comments that staff has made. These numbers will remain
                there until you click to open individual responses (like opening
                an email rather than just viewing it). This is to make sure you
                do not miss any responses from staff.
              </p>
              <p>
                You can flag and filter conversations so you can see those you
                identified as being most important first. You can also search
                using keywords to identify trends or find specific staff
                responses.
              </p>

              <h2>Step 5</h2>

              <p>
                From the <span className='bold-text'> Conversations </span> menu
                you can continue to respond and interact with any staff comments
                as long as needed. This allows you to create ongoing and open
                conversations, address concerns, seek ideas and solutions or use
                it in the way that best provides ongoing support to staff, you
                and the school.
              </p>
              {/* <p>
                You can also add your name to the end of any responses you make
                to staff comments so the staff member knows who is responding to
                them.
              </p> */}
              <p>
                When you respond to a staff comment they will receive an email
                alerting them to your reply, with a link for them to view it.
                They can also make further comments and continue the
                conversation.
              </p>
              <p>
                Staff can continue to use the same link to provide ongoing
                feedback for a specific conversation, if they wish, and you can
                also issue new invitations. There is no limit to the number of
                times you can do this and to different staff groups.
              </p>
              <p>
                This means you can focus directly on groups that are relevant to
                what you want to know, action you are taking or are maybe
                showing vulnerability. For example, if you have implemented a
                new assessment or other practice you can talk only to those that
                are affected.
              </p>
              <p>
                Creating new conversations will also be useful if you run a
                staff wellbeing survey to follow up and ask about the
                effectiveness of actions being taken or in any consultation
                where an element of anonymity will be helpful.
              </p>

              <p>
                To issue a new invitation return to step 2. You only need to add
                new or additional staff members as invitations will
                automatically be sent to those staff already in your account.
                Otherwise you can go straight ahead and create your next
                invitation. Just go to your conversations tab and click the New
                Conversations button.
              </p>
              <p>
                If any staff member does identify themselves during any
                conversation, invitations to new conversations will restore
                their anonymity and mean you are likely to receive more ongoing,
                open and honest feedback.
              </p>
              <p>
                You can delete any conversation from your conversation tab. Just
                use the red ‘Delete’ button. This will remove it from your
                dashboard. However, by keeping a record of conversations you can
                continue to review previous feedback you have received over
                time.
              </p>

              <h2>Survey</h2>
              <p>
                If you also ordered a staff wellbeing survey when you registered
                for Welbee Voice or are an existing customer, you can access
                your survey dashboard by clicking on the Survey button at the
                top of your left hand menu. This is to the left of Voice and you
                can toggle between the two.
              </p>
              <span>
                <img src={welbeeoption} alt='email linvitation' />
              </span>
              <p>
                Full instructions are available in your survey dashboard on
                creating, running and taking action following your survey, These
                are in the ‘User Guide’ tab in the menu on the left.
              </p>
              <p>
                Staff records are used across Survey and Voice and if you have
                added them in one of them, they will be available to use in the
                other application.
              </p>
              <p>
                If you did not order a survey then the survey button will not be
                highlighted or work for you. If you are interested in running a
                survey you can find out more by clicking the upgrade account
                button. This will show you full details of what is included and
                the benefits available. If interested you can add this to your
                account.
              </p>

              <h2>Voice Forever Free</h2>
              <p>
                We hope that you will want to use Welbee Voice (Pro) with all
                features, allowing you to have multiple users and assign staff
                to multiple groups.
              </p>
              <p>
                This means it will become part of the way the school operates
                and help make staff wellbeing and feedback simply part of what
                happens every day.
              </p>
              <p>
                We have priced Welbee Voice (Pro) at a level of investment we
                think makes it very easy for a school of any size to use it and
                it can be used at no additional cost by all our survey
                customers.
              </p>
              <p>
                However, we recognise that some schools may wish to move to our
                Forever Free plan.
              </p>
              <p>
                The instructions for operation are the same as above with two
                distinctions.
              </p>
              <ul>
                <li>
                  Only one user will have access to the account. This will be
                  the login shown in the <i>Account</i> tab in your menu. You
                  can change the name and email address for this user. This will
                  be the only person that can send, receive, and view
                  invitations and add or amend staff records.
                </li>
                <li>
                  You will not be able to use staff groups. Your existing staff
                  records will continue to show groups and you can continue to
                  add or modify these. However, when you issue staff invitations
                  you will only have the option to issue these to{' '}
                  <u>all staff</u>.
                </li>
              </ul>
              <p>
                This means you can continue to seek feedback and hold two-way
                anonymous conversations, which remains a very powerful tool in
                consultations, identifying and addressing concerns, seeking
                ideas, and creating an open and honest culture.
              </p>
              <p>
                You can do this for free. If you subsequently choose to upgrade
                you will be able to immediately seek more specific feedback from
                groups of staff, using the records already in your account and
                adding to them.
              </p>
              <p>
                If you have any questions on the difference between Welbee Voice
                (Free) and Welbee Voice (Pro) or would like to discuss the
                benefits of upgrading to Survey then please contact
                <a href='mailto:support@welbee.co.uk'>
                  {' '}
                  support@welbee.co.uk
                </a>{' '}
                or click the request assistance button from within your{' '}
                <i>Account</i> tab.
              </p>

              <h2>Support</h2>
              <p>
                We have designed Welbee Voice to be simple and flexible to use.
                Like anything new there may be things you want help with and if
                you do have any questions or need support then please first
                e-mail
                <a href='mailto:support@welbee.co.uk'> support@welbee.co.uk</a>.
              </p>
              <p>Thank you for choosing to use Welbee Voice.</p>
            </div>
          </div>
        </div>
      </ExtendedVoiceDashboardWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  welbeeService: bindActionCreators(welbeeVoiceService, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(withRouter(VoiceInstructions));
