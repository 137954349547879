import request from '../request';
import { requestAssistanceAPI } from './urls';

export const requestAssistance = data => {
  return request({
    url: requestAssistanceAPI.api,
    method: 'POST',
    data: data
  })
    .then(response => response)
    .catch(response => response);
};

const requestAssistanceService = {
  requestAssistance
};

export default requestAssistanceService;
