import React, { Component } from 'react';
import { Header } from '..';
import {
  MainFooter,
  PageWrapper,
  ThankYouMainWrapper,
  ThankYouWrapper,
  ThankYouInnerWrapper
} from '../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import { footerLogo } from '../../resources/images';

class ThankYouMessage extends Component {
  render() {
    return (
      <PageWrapper>
        <Header />
        <ThankYouMainWrapper>
          <Grid container>           
              <Grid item
                lg={9}
                sm={12}
                md={12}
                xs={12}                
                className="mobile-wrapper">
                <ThankYouWrapper>
                  <ThankYouInnerWrapper>
                    <h1> Thank you! </h1>
                    <p>
                      Your information has been submitted and we will be in
                      contact with details of your school wellbeing survey.
                    </p>
                  </ThankYouInnerWrapper>
                </ThankYouWrapper>
              </Grid>           
          </Grid>
          <MainFooter>
            <span>
              <img src={footerLogo} alt="logo" />
            </span>
            <span> Survey provided by</span>
          </MainFooter>
        </ThankYouMainWrapper>
      </PageWrapper>
    );
  }
}

export default ThankYouMessage;
