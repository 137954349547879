import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { NewSurveyInputWrapper } from '../../resources/styling';
import {
  MediaQuery,
  AssitanceForm,
  AssistanceWrapperHeading,
  AssistanceWrapper,
  ExtendedSubContainer,
} from '../../resources/styling/appStyle';
import { media } from 'styled-bootstrap-grid';
import { WrapperMain } from '../../resources/styling/formStyle';

const {
  pictonBlue,
  whiteColor,
  blackColor,
  mineShaft,
  ginFizz,
  keppel,
  gorse,
  hummingBird,
  lightGray,
  teal,
  orient,
  mandy,
  easternBlue,
  illusion,
  lighterGrey,
  twilightBlue,
  outrageousOrange,
  cornflowerBlue,
  parrotGreen,
  darkYellow,
  blizzBlue,
  alto,
  purple,
  barleyWhite,
  lightBlue,
  lightGreen,
  lightTeal,
  softGrey,
  midSoftGrey,
  viking,
  bittersweet,
  minttulip,
  yourpink,
  grainsboro,
  bordergrey,
} = colors;

/*** Close Group Survey Form ***/
export const ExtendedNewSurveyInputWrapper = styled(NewSurveyInputWrapper)`
  //padding-top: 30px;
  .info-text {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 10px;
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
  }
  .school-list-wrapper {
    border: 1px solid ${pictonBlue};
    border-radius: 8px;
    margin-bottom: 20px;
    .school-title,
    .disabled {
      color: ${blackColor};
      display: inline-block;
      margin-left: 15px;
    }

    .school-list-body-outer {
      display: block;
      max-height: 200px;
      overflow-y: auto;
      margin-bottom: 3px;
    }
    .school-list-heading,
    .school-list-body {
      border-bottom: 1px solid ${pictonBlue};
      padding: 8px 15px;
      font-size: 14px;
      font-weight: 900;
    }

    .school-list-body {
      border: 0;
      font-weight: 400;
    }
    .staff-members-title,
    .staff-members-count {
      float: right;
      margin-top: 3px;
      color: ${blackColor};
    }
    .staff-members-count {
      margin-right: 50px;
    }
    .disabled {
      color: ${lightGray};
    }
  }
`;

/*** Create Group Survey ***/
export const SchoolSurveyWrapper = styled.div`
  float: left;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  ${MediaQuery}

  .email-link {
    color: ${pictonBlue};
    text-decoration: underline;
    cursor: pointer;
  }
  .list-action {
    text-align: left !important;
  }

  .table {
    font-size: 14px;
    td,
    th {
      vertical-align: middle;
      padding: 15px;
    }
    tr > th {
      border-bottom: none !important;
    }
    button {
      margin-bottom: 0px;
      padding: 9px 18px;
      margin-right: 5px;
    }
  }
`;
export const SchoolSurveyInnerMain = styled.div`
  width: 100%;
  min-height: calc(100vh - 216px);
`;
export const SchoolSurveyInner = styled.div`
  display: block;
  margin-top: 30px;
  .back-btn {
    background: ${mandy};
    height: 60px;
    width: 80px;
    text-align: center;
    padding: 20px;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
  }
  .heading {
    margin-left: 80px;
  }
`;

/*** Edit Mat ***/
export const RegisterInner = styled.div`
  display: table-cell;
  vertical-align: middle;
`;
export const ExtendedAssitanceForm = styled(AssitanceForm)`
  width: 45%;
  ${media.lg`
  width: 45%;

  `}
`;
export const InputWrapper = styled.form`
  padding: 10px;
  .mat-options {
    border: 2px solid ${pictonBlue};
    border-radius: 6px;
    float: left;
    width: 100%;
    padding: 0;
    list-style: none;
    max-height: 200px;
    overflow-y: auto;

    li {
      padding: 10px;
      color: ${pictonBlue};

      &:hover {
        background: ${teal};
        color: ${whiteColor};
      }
    }
  }

  .styled-checkbox:checked + label:after {
    top: 12px !important;
  }
  .MuiFilledInput-root {
    border-radius: 6px !important;
  }
  .MuiFilledInput-underline:before {
    border-bottom: none !important;
  }
  .styled-checkbox + label:before {
    position: relative;
    top: 0px;
  }
`;

/*** Mat Heatmap ***/
export const AssistanceWrapperHeadingExt = styled(AssistanceWrapperHeading)`
  /*width: 40%;*/
  @media only screen and (min-width: 640px) and (max-width: 1024px) {
    width: 92.7%;
  }
`;
export const SuperExtendExtendedSubContainer = styled(ExtendedSubContainer)`
  .job-role-heading {
    background-image: none !important;
  }

  button {
    padding-right: 15px;
    background: transparent;
    border: 1px solid #45b1e8;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    height: 50px;
    text-align: left;
    font-weight: 500;
    width: 20%;
    span {
      right: 7px;
      position: absolute;
      top: 54%;
      transform: translateY(-50%);
    }
  }

  .staff-result-innercontainer {
    display: block;
  }
  .mat-heatmap-filters {
    border: 1px solid ${bordergrey};
    width: 100%;
    margin-right: 10px;
    background: ${whiteColor};
    height: 350px;
    overflow-y: auto;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        border-bottom: 1px solid ${bordergrey};
        color: ${blackColor};
        text-decoration: none;
        font-size: 12px;
        font-weight: 500;
        display: block;
        text-transform: capitalize;
        padding: 7px 10px;
        cursor: pointer;
        :hover {
          color: ${whiteColor};
          background: ${purple};
        }
        :last-child {
          border-bottom: 0px solid ${bordergrey};
        }
      }
      li.active {
        color: ${whiteColor};
        background: ${purple};
      }
    }
  }
`;
export const ExtendedAssistanceWrapper = styled(AssistanceWrapper)`
  position: relative;
`;
export const CommentsMainWrapper = styled.div`
  .left-nav {
    max-width: 190px;
  }
  .sc-htpNat {
    width: 0px !important;
  }
  .comments-wrapper {
    max-width: 86%;
    margin-left: 190px;
    float: left;
    flex: auto;
    ${media.xl`
    max-width: 90%;
    `}
  }
  .containerClass {
    padding-top: 0;
    width: 100%;
    max-width: 100%;
  }  

  .grid-role-heading ul li {
    text-align: left;
    float: left;
  }

  div.heatmap-sticky::-webkit-scrollbar {
    background: white;    
    width: 8px;
}
div.heatmap-sticky::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}
div.heatmap-sticky::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
div.heatmap-sticky {
    max-height: 400px;
    border: 0.1px solid transparent;
    overflow: overlay;
    scrollbar-width: thin;
    scrollbar-color: blue orange;
}

div.heatmap-sticky:hover::-webkit-scrollbar {
    background: lightgrey;
}

div.heatmap-sticky::-moz-scrollbar {
  background: white;    
  width: 8px;
}
div.heatmap-sticky::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
div.heatmap-sticky::-moz-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
div.heatmap-sticky {
  max-height: 400px;      
  overflow: overlay;
}
div.heatmap-sticky:hover::-moz-scrollbar {
  background: lightgrey;
}
`;

/*** Mat School ***/
export const PaginationWrapper = styled.div`
  width: 75%;
  margin: auto;
  text-align: center;
`;

/*** New Group Survey Form ***/
export const ExtendedFieldWrapper = styled(WrapperMain)`
  float: left;
  width: 100%;
  position: relative;
  .floating-label {
    margin-bottom: 11px;
  }
`;

export const MatPresentationWrapper = styled.div``;

export const MatReportWrapper = styled.div`
  width: 100%;

  h3, h2{
    width: 100%;
    float:left;    
  }

  .center-text {
    text-align:center;
    font-size:20px;
    font-weight:700;
    padding-bottom:10px;
  }
  .bottom-text{
    text-align:left;
    font-size:12px;
    font-weight:700;
    padding-bottom:10px;
  }
  .section-bottom {      
    float: left;
    font-size: 13px;
    position: absolute;
    bottom: 27px;
    padding: 0;
    margin: 0;
  }
  .table-of-content{
    width:100%;
    ol > li{
      margin-bottom: 10px;
      font-weight: 500;      
    } 
    a{
      color: #000000;
      text-decoration: none;      
      :hover{
        text-decoration: underline;
      }
    }   
  } 
  .report-section{    
    width:100%;   
    float: left;     
    background: #FFFFFF;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    padding: 20px 60px 60px 60px;
    font-size:16px;
    font-weight: 400;
    margin-top:30px;
    @media only screen and (max-width: 1000px){
      padding: 20px 30px 60px 30px;
    }
  }
  .report-heading{
    font-size: 32px;
    padding: 40px 0 10px 0px;
    text-align: left;    
    width: 100%;
    font-weight: 900;
    float:left;
    margin: 0;    
  }

  .matpres-title{
    font-size: 30px;
    color: ${purple};
    text-align: left;
    margin: 0 0 10px 0;
  }

  .pres-title{
    font-size: 28px;
    color: ${blackColor};
    text-align: left;
    margin: 0 0 10px 0;
  }
  .mgt{
    padding: 0!important;
    margin: 0 !important;
    .pres-text{
      padding-left: 40px;
      padding-right: 40px;
    }
    .mgt-title{
      color: ${whiteColor};
      margin: 0 0 10px 0;      
      padding: 20px 0 20px 40px;
    }
    .demand-title{      
      background: linear-gradient(90deg, #46338C 23.82%, rgba(70, 51, 140, 0.00) 100%);
    }
    .control-title{      
      background: linear-gradient(90deg, #E84F6B 23.82%, rgba(232, 79, 107, 0.00) 100%);
    }
    .manager-title{      
      background: linear-gradient(90deg, #007A7B 23.82%, rgba(0, 122, 123, 0.00) 100%);
    }
    .peer-title{      
      background: linear-gradient(90deg, #FF5939 23.82%, rgba(255, 89, 57, 0.00) 100%);
    }
    .relationship-title{      
      background: linear-gradient(90deg, #54B5E0 23.82%, rgba(84, 181, 224, 0.00) 100%);
    }
    .role-title{      
      background: linear-gradient(90deg, #F5ABCC 23.82%, rgba(245, 171, 204, 0.00) 100%);
    }
    .change-title{      
      background: linear-gradient(90deg, #FFB106 23.82%, rgba(255, 177, 6, 0.00) 100%);
    }
    .mgt-logo{
      filter: brightness(10);
      width: 250px;
    }
  }
  .mat-presentation-section{
    width:100%;   
    float: left;     
    background: #FFFFFF;    
    padding: 0 40px 40px 36px;
    font-size:16px;    
    margin-top:30px; 
    text-align: left;
    .stats-table{
      height: 48vh;
      overflow: hidden;                 
    }  
    .score-table{
      height: 27vh;
      overflow: hidden;
      .scrollbar-view > div{
        overflow-x: hidden !important;
      }      
    }  

    .graph-wrapper{
      height: 68vh;
      overflow: hidden;     
    }

    .question-table{
      height: 61vh;
      overflow: hidden;
      width: 100%;
      float: left;    
      font-size: 13px;    
      margin-bottom:20px;
      .scrollbar-view > div{
        overflow-x: hidden !important;
      }      
    }
    .additional-question-table{
      height: 190px;      
      overflow: hidden;
      width: 100%;
      float: left;    
      font-size: 13px;    
      margin-bottom:20px;
      .scrollbar-view > div{
        overflow-x: hidden !important;
      }      
    }
    

    .left-section{
      width: 100%;
      h1{
        display: flex;
        align-items: center;
        font-size: 48px;
        color: ${purple};
        text-align: center;
        width: 70%;
        margin: 0 auto;
        height: 100%;
      }
      .app-logo{
        text-align: right;
        position: absolute;
        bottom: 50px;
        right: 0;
      }
      .matlogo{
        position: absolute;
        right: 0;
        text-align: right;
        img{
          width: 100px;
        }
      }
    }

    .app-logo{
      text-align: right;
      position: absolute;      
      right: 0;
      bottom: 26px;
    }

    .management-standards{      
      .management-scores{
        display: flex;
        justify-content: center;
        gap: 30px;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  .stats-table, .graph-wrapper{  
    width: 100%;
    float: left;    
    font-size: 13px;    
    margin-bottom:20px;   
    .scrollbar-view > div{
      overflow-x: hidden !important;
    } 
    @media only screen and (max-width: 1000px){
      overflow-x: auto;
    }
  }

  .stats-title-cell{
    background: rgba(83, 181, 224, 0.25);
    border: 1px solid rgba(83, 181, 224, 0.32);
    width:30%;
    float:left;    
    height: 40px !important;
    line-height: normal;
    padding-left: 12px;
    margin: 0 0 4px 4px;
    font-weight: 700;    
    font-size: 13px;
    align-items: center;
    justify-content: center;
    text-align: left; 
    display: inline-flex;
    align-items: center;   
  }
  .stats-role-cell{    
    background: rgba(83, 181, 224, 0.25);
    border: 1px solid rgba(83, 181, 224, 0.32);
    height: 40px; 
    font-size: 13px;       
    margin: 0 0 4px 4px;
    font-weight: 700;    
    flex-grow: 1;
    flex-basis: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center; 
    line-height: initial;      
  }   

  .job-role-container{
    width:100%;
    float:left;  
    display: flex;
    flex-direction: row; 
    height: auto !important;
  }
  
  .job-role-title-cell{
    width:30%;
    border: 1px solid #e2e2e2;
    height: 32px !important;        
    padding-left: 12px;
    margin: 0 0 4px 4px;
    font-weight: 700;
    font-size: 13px;
    display: inline-flex;
    align-items: center;    
    text-align: left;
    line-height: normal;    
  }
  
  .job-role-cell{    
    border: 1px solid #e2e2e2;
    height: 32px;
    line-height: 32px;    
    margin: 0 0 4px 4px;
    font-weight: 700;
    flex-grow: 1;
    flex-basis: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    @media only screen and (max-width: 1024px){      
      line-height: 11px;
    }
  }

  .score-superscript{
    display: inline-block;
    padding: 0 0 0 2px;
    color: ${blackColor};
    font-size: 10px;
  }
  
  .table-title{
    text-align:center;
    font-weight: 700;
    width: 100%;
    float: left;
    margin: 10px 0 7px 0;
  } 

  
  .section{  
    width:100%;
    float:left;
    line-height: 26px;
    padding: 0;
    margin:0 0 20px 0;
    ul > li{
      margin-bottom: 10px;
    }
  }

  .pres-text{  
    width:100%;
    float:left;
    line-height: 26px;
    padding: 0;
    margin:0 0 13px 0;
    ul > li{
      margin-bottom: 10px;
    }
  }

  .center-text{
    text-align:center;
  }

 
.heatmap-title{
  border: 1px solid rgba(83, 181, 224, 0.32);
  padding-left: 12px;
  width: 100%;
  float: left;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 900;
  background-color: rgba(83,181,224,0.25);        
  height: 45px !important;
  line-height: 45px !important;
  margin: 0 0 4px 4px;   
}
.width-10{    
    border:1px solid #e2e2e2;
    text-align:center;
    font-size: 13px;
    font-weight: 700;
    margin: 0px 0px 4px 4px;
    background-color: #ffffff;
    height: 32px;  
    line-height:28px;  
    flex-grow: 1;
    flex-basis: 0;
    justify-content: center;
    align-items: center;      
}
.title-txt{
  display: inline-flex;
  line-height: normal;
}
.width-20-red{    
    border:1px solid #e2e2e2;
    text-align:center;
    font-size: 13px;
    font-weight: 700;
    margin: 0px 0px 4px 4px;    
    background-color: rgb(247, 178, 165);
    height: 32px;
    line-height:28px;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: center;
    align-items: center;    
}
.width-20-green{    
    border:1px solid #e2e2e2;
    text-align:center;
    font-size: 13px;
    font-weight: 700;
    margin: 0px 0px 4px 4px;    
    background-color: #84C1C3;
    height: 32px;
    line-height:28px;
    flex-grow: 1;
    flex-basis: 0;
    justify-content: center;
    align-items: center;     
}
.width-20-grey{
  border: 1px solid #e2e2e2;
  font-size: 13px;
  font-weight: 700;
  background-color: #f2f2f2;
  margin:0 0 4px 4px;
  height: 32px !important;
  line-height: 32px;
  padding-left: 12px;
  flex-grow: 1;
  flex-basis: 0;
  text-align:left;  
}
.width-60-grey{
    border: 1px solid #e2e2e2;    
    font-size: 13px;
    font-weight: 700;
    margin: 0 0 4px 4px;
    height: 32px;
    line-height: 32px;
    padding-left: 12px;
    flex-grow: 5;
    flex-basis: 0;    
}
.width-18-grey{
    border: 1px solid #e2e2e2;        
    font-size: 13px;
    font-weight: 700;
    margin: 0 0 4px 4px;
    height: 32px;
    line-height: 32px;
    padding-left: 12px;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;    
}

@media only screen and (max-width: 1440px){
  .width-20-green, .width-20-red, .width-10, .job-role-title-cell, .job-role-cell, .stats-role-cell, .stats-title-cell{
    font-size: 12px;
  }
  .width-18-grey, .width-60-grey, .width-20-grey, .heatmap-title{
    line-height: 11px !important;
    font-size: 11px;  
    display: inline-flex;
    align-items: center;   
  }
}
@media only screen and (max-width: 1280px){
  .width-20-green, .width-20-red, .width-10, .job-role-title-cell, .job-role-cell, .stats-role-cell, .stats-title-cell{
    font-size: 11px;
  }  
}

@media only screen and (max-width: 1000px){
  .width-20-green, .width-20-red, .width-10, .job-role-title-cell, .job-role-cell, .stats-role-cell, .stats-title-cell{
    font-size: 10px;
    // flex-basis: 10%;
    // flex-shrink: 0;
  }
  .width-20-grey, .empty-cell{  
    // flex-basis: 30%;
    // flex-shrink: 0;
  }
  .width-60-grey{  
    // flex-basis: 88%;
    // flex-shrink: 0;
  }
  .questions{  
    flex-basis: 119%;
    flex-shrink: 0;
  }
  .deciles{  
    flex-basis: 20%;
    flex-shrink: 0;
  }
  .width-18-grey{  
    flex-basis: 12%;
    flex-shrink: 0;
  }

}
`;

const MatStyle = {
  MatReportWrapper,
  ExtendedNewSurveyInputWrapper,
  SchoolSurveyWrapper,
  SchoolSurveyInnerMain,
  SchoolSurveyInner,
  RegisterInner,
  ExtendedAssitanceForm,
  InputWrapper,
  AssistanceWrapperHeadingExt,
  SuperExtendExtendedSubContainer,
  ExtendedAssistanceWrapper,
  CommentsMainWrapper,
  PaginationWrapper,
  ExtendedFieldWrapper,
};
export default MatStyle;
