import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { WrapperMain } from '../../resources/styling/formStyle';
import { media } from 'styled-bootstrap-grid';

const {
    barleyWhite, whiteColor, orient, teal, mineShaft, lighterGrey,
    pictonBlue, purple
} = colors;

/*** New Survey Form ***/
export const ExtendedFieldWrapper = styled(WrapperMain)`
  float: left;
  width: 100%;
  position: relative;
  .floating-label {
    margin-bottom: 11px;
  }  
`;
export const ExtendedDateWrapper = styled(ExtendedFieldWrapper)`
  float: left;
  width: 100%;
  position: relative;
  .floating-label {
    margin-bottom: 11px;
  }
`;
export const ExtendedHourWrapper = styled(ExtendedFieldWrapper)`
  float: left;
  width: 49%;
  position: relative;
  .floating-label {
    margin-bottom: 11px;
  }
    .options{
    position:fixed;
    z-index:999;}
}
`;
export const ExtendedMinWrapper = styled(ExtendedFieldWrapper)`
  float: right;
  width: 49%;
  position: relative;
  .floating-label {
    margin-bottom: 11px;
  }
    .options{
    position:fixed;
    z-index:999;}
}
`;
/*** Survey ***/
export const SurveyListWarpper = styled.div`
  padding: 0 20px;
  box-sizing: border-box;

  a {
    color: ${pictonBlue};
    font-weight: 500;
  }
`;
export const EmptySurveysDiv = styled.div`
  padding: 20px;
`;
export const SurveyListMainWrapper = styled.div`
  background: ${whiteColor};
  box-shadow: 4px 5px 16px -10px ${orient};
  h1 {
    background: ${teal};
    color: ${whiteColor};
    font-size: 17px;
    padding: 20px;
    font-weight: 900;
    span {
      cursor: pointer;
      background: #e84f6b;
      padding: 21px 24px;
      margin: -20px;
    }
  }
  .list-heading {
    font-weight: 900;
    font-size: 13px;
    color: ${mineShaft};
  }
  .list-heading:nth-child(3) {
    text-align: left;
  }
  ul {
    padding: 5px 20px;
    margin: 0;
    list-style: none;
    width: 100%;
    display: table;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 1px solid ${lighterGrey};
    ul:nth-child(1) {
      padding-top: 0;
    }
    li {
      display: table-cell;
      font-size: 13px;
      padding: 6px 2px 0 2px;
      color: ${mineShaft};
      word-break: break-word;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        word-break: break-all;
      }      
      button {
        margin: 0 0 2px 1px;
        padding: 11px 10px;
        border-radius: 20px;
        box-shadow: none;
        font-size: 11px;
        ${media.lg`
        padding: 11px 10px;
        font-size: 11px;
        `}
      }
    }
    li:nth-child(1) {
      width: 19%;
    }
    li:nth-child(2) {
      width: 6.6%;
    }
    li:nth-child(3) {
      width: 13.6%;
    }
    li:nth-child(4) {
      width: 16.6%;
    }
    li:nth-child(5) {
      width: 10%;
    }

    li:last-child {
      width: 25%;
      text-align: right;
    }
    li:first-child {
      width: 19%;
    }
  }
  .manualSendOuter {
    .toolTipText {
      display: none;
      max-width: 200px;
      background-color: ${pictonBlue};
      color: ${whiteColor};
      font-weight: 400;
      font-size: 13px;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
    }
    :hover .toolTipText {
      display: block;
    }
  }

  .table{
	  font-size: 14px;	  
	  td, th{
		  vertical-align: middle;
		  padding: 13px;
	  }
	  tr > th{
		  border-bottom: none !important;
	  }
	  button{
		  margin-bottom: 0px;
		  padding: 9px 18px;
		  margin-right: 5px;
      border-radius: 50px;
	  }
  }
  .open-close-btn {        
    display: inline-block !important;
  }
  button{
    margin-bottom: 0px;   
    margin-right: 8px;
    border-radius: 50px;
  }
`;

const SchoolSurveyStyle = {
    ExtendedFieldWrapper,
    ExtendedDateWrapper,
    ExtendedHourWrapper,
    ExtendedMinWrapper,    
    SurveyListWarpper,
    EmptySurveysDiv,
    SurveyListMainWrapper
}
export default SchoolSurveyStyle;