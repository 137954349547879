import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import { SearchFilterWrapper, SubContainer, AssistanceWrapper, MediaQuery } from '../../resources/styling/appStyle';
import {
    iconStaff,    
    unfillarrownext,
    fillarrownext,
    unfillarrowprev,
    fillarrowprev,    
    flagactive,
    flaginactive,
    flagdisabled,
    replyactive,
    replyinactive,
    replydisabled,
    replyunread,
    flaginactivewithoutcircle,
    flagactivewithoutcircle,
} from '../../resources/images/index';

const {
    mineShaft, pictonBlue, teal, lighterGrey, illusion,    
    whiteColor, mandy, blackColor, bordergrey
} = colors;

/*** Comments Reply Common ***/
export const CommonRepplyWrapper = styled.div`
  .search-inner-container { 
    margin-bottom: 20px;
    font-size: 14px;
    width: 100%;    
  }
  .search-inner-sub-container {
    hr.horizontal-ruler {
      color: ${lighterGrey};
      border-top: 1px solid ${lighterGrey};
      margin-bottom: 30px;
    }
    .reply-section {
      padding: 0 40px;
    }
    textarea {      
      font-size: 16px;
    }
    padding: 20px 0;
    float: left;
    width: 100%;
    border-radius: 6px;
    background: ${whiteColor};
    border: 1px solid ${bordergrey};

    h3 {
      font-size: 12px;
      color: ${mineShaft};
    }
    h4 {
      font-size: 12px;
      color: ${mineShaft};
    }

    span {
      font-size: 12px;
      color: ${teal};
    }
    .comments-body {
      font-size: 16px;
      margin-top: 10px;
      font-weight: 400;
      line-height: 24px;
      padding-right: 50px;
      margin-bottom: 25px;
      color: ${teal};
    }
    .send-button {
      button {
        float: right;
        margin-bottom: 15px;
      }
    }

    .your-reply {
      .comments-body {
        color: ${mandy};
      }
    }
    .your-reply,
    .support-reply {
      .comments-body {
        font-size: 16px;
      }

      color: ${mandy};
      h3 {
        margin-bottom: 0;
        color: ${mandy};
        font-weight: 400;
      }
    }
    .support-reply {
      color: ${teal};
      h3 {
        color: ${teal};
      }
    }
  }
`;

/*** Head Teacher Comments Reply ***/
export const ExtendedSubContainer = styled(SubContainer)`
  .subcontainer-inner {    
    .comments-back-button {
      font-size: 14px;
      font-weight: 500;
      background: url(${unfillarrowprev});
      background-repeat: no-repeat;
      background-position: 9px 7px;
      padding: 12px 15px 12px 37px;
      cursor: pointer;
      float: left;
      border-right: 1px solid rgba(83, 181, 224, 0.5);      
      color: ${pictonBlue};
    }
  }
  margin: 0 10px 10px 0;
  padding: 0px 0px 0 0;
  min-height: 100vh;

  .search-data-container {
    width: 100%;
    float: left;
  }
  .search-inner-container {    
    margin: auto;    
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 14px;
  }

  @media only screen and (min-width: 800px) and (max-width: 1024px){
    .search-inner-container{
      width: 700px;
    }
    .subcontainer-inner{
      width: 195px;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 768px){
    .search-inner-container{
      width: 100%;
    }
    .subcontainer-inner{
      width: 195px;
    }
  }
`;
export const CommentsMainWrapper = styled.div`
  .left-nav {
    max-width: 190px;
  }
  mark {
    background: transparent !important;
    color: ${mandy} !important;
    font-weight: 900;
  }

  .carousel .slider-wrapper {
    width: 63%;
    line-height: 23px;
    margin-bottom: 0px;
  }

  .carousel .control-disabled.control-arrow {
    display: block !important;
    opacity: 1;
    pointer-events: none;
  }

  .carousel .control-next.control-arrow {
    background: url(${fillarrownext});
    background-repeat: no-repeat;
    opacity: 1;
    height: 32px;
    :hover {
      background: url(${fillarrownext});
      background-repeat: no-repeat;
    }
  }
  .carousel .control-next.control-disabled {
    background: url(${unfillarrownext});
    background-repeat: no-repeat;
  }

  .carousel .control-prev.control-arrow {
    background: url(${fillarrowprev});
    background-repeat: no-repeat;
    opacity: 1;
    height: 32px;
    :hover {
      background: url(${fillarrowprev});
      background-repeat: no-repeat;
    }
  }
  .carousel .control-prev.control-disabled {
    background: url(${unfillarrowprev});
    background-repeat: no-repeat;
  }
  .carousel .control-prev.control-disabled {
    background: url(${unfillarrowprev});
    background-repeat: no-repeat;
  }
  .carousel .control-next.control-arrow:before {
    border: 0;
  }

  .carousel .control-prev.control-arrow:before {
    border: 0;
    background: url(${unfillarrowprev});
    background-repeat: no-repeat;
  }

  .carousel .slide {
    background: transparent;
  }

  .comments-wrapper {
    max-width: 86%;
    padding-top:50px;
    float: left;
    flex: auto;

    ${media.xl`
    max-width: 90%;

    `}
  }
  .containerClass {
    padding-top: 0;
    width: 100%;
    max-width: 100%;
  }
  float: left;
  width: 100%;

  ${media.xs`
    float: none;
  `}
  ${media.sm`
    float: none;
  `}
 

  input[type=text] {
    width: 200px;
    padding: 8px 10px;
  }
  .sc-htpNat {
    width: 190px !important;
}
`;
export const ExtendedAssistanceWrapper = styled(AssistanceWrapper)`

`;

export const ExtendedSearchFilterWrapper = styled(SearchFilterWrapper)`
  .flag-filter {
    width: 8% !important;
    border-right: 0;    
    height: 40px;
    background: url(${flaginactivewithoutcircle}) no-repeat center center;
    background-size: contain;
    cursor: pointer;
    color: ${blackColor};
    :hover {     
      background-size: contain;
    }
    img {
      width: 38px;
      margin-top: 5px;
    }
  }
  .active {
    background: url(${flagactivewithoutcircle}) no-repeat center center;
    background-size: contain;
  }
  .search-filters {
    width: 25%;
    .selected-option {
      background-image: url(${iconStaff});      
    }    
  }
`;

/*** Staff Comments Reply ***/
export const WelcomeHeaderWrapper = styled.div`
  > div {
    display: block;
  }
`;
export const SurveyExtendedSubContainer = styled(SubContainer)`
  .subcontainer-inner {    
    .search-sorting {
      width: 23%;
      float: left;      
    }
  }
  table {
    ${media.md`
    width: 100%;
     `}
  }
  tbody {
    td {
      word-break: break-word;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        word-break: break-all;
      }
    }
  }
  .search-data-container {
    width: 100%;
    float: left;
    padding-bottom: 20px;
  }
  .search-inner-container {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 14px;
    ${media.md`
    width: 100%;
     `}
     ${media.sm`
     width: 100%;
      `}
      ${media.xs`
      width: 100%;
       `}
  }
  .search-inner-sub-container {
    padding: 0;
    float: left;
    width: 100%;
    border-radius: 6px;
    background: ${whiteColor};
    border: 1px solid ${bordergrey};

    .carousel-outer {
      padding: 40px;
      ${media.xs`
        padding: 15px ;
      `}
    }

    .searched-data-wrapper {
      width: 100%;
      position: relative;
      z-index: 1;
      margin-bottom: 0;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid ${lighterGrey};

      .no-message-text {
        padding: 0 40px;
        text-align:center;
      }
      .search-date {
        padding: 0 0 0 40px;
      }
      .comment-data {
        padding: 0 0 0 40px;
        color: ${mineShaft};
      }
      span {
        padding: 0 0 0 40px;
        display: block;
      }
      .data-icons {
        position: absolute;
        right: 25px;
        padding: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0;
        z-index: 2;
        .unread-reply-active {
          width: 24px;
          height: 24px;
          border: none;
          margin-right: 12px;
          background: url(${replyunread});
          background-repeat: no-repeat;
          border-radius: 50%;
          :hover {
            background: url(${replyunread});
            background-repeat: no-repeat;
            background-size: 100%;
            -webkit-box-shadow: 2px 2px 7px 0px #f5abcc;
            -moz-box-shadow: 2px 2px 7px 0px #f5abcc;
            box-shadow: 2px 2px 7px 0px #f5abcc;
          }
        }

        .unread-reply,
        .reply-icon,
        .flag-icon {
          border: 0;
          width: 24px;
          height: 24px;
          cursor: pointer;
          padding: 15px;
          display: inline-block;
          background-repeat: no-repeat;
          background-size: 100% !important;
          transition: all 0.1s linear;
          :hover {
            background-repeat: no-repeat !important;
            background-size: 100% !important;
          }
        }
        .active-flag {
          width: 24px;
          height: 24px;
          cursor: pointer;
          padding: 15px;
          border: 0;
          display: inline-block;
          background-repeat: no-repeat;
          background-size: 100% !important;
          transition: all 0.1s linear;
          margin-right: 12px;
          background: url(${flagactive});
        }

        .reply-icon {
          background: url(${replyinactive});
          background-repeat: no-repeat;
          :hover {
            background: url(${replyactive});
          }
        }
        .flag-icon {
          width: 24px;
          height: 24px;
          border: none;
          margin-right: 12px;
          background: url(${flaginactive});
          background-repeat: no-repeat;
          :hover {
            background: url(${flagactive});
          }
        }
        .disabled-replay-btn, .disabled-flag-btn{
          background: url(${replydisabled});
          background-color: ${lighterGrey};
          cursor: auto;
          position: relative;
          border-radius: 50%;
          :hover {
            background: url(${replydisabled});
            background-color: ${lighterGrey};
            background-repeat: no-repeat;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
          }
        }

        .disabled-flag-btn{
          background: url(${flagdisabled});
          background-color: ${lighterGrey};
          :hover {
            background: url(${flagdisabled});
            background-color: ${lighterGrey};
          }
        }
        .tooltip{
          position: relative;
          .tooltiptext {
            display: none;
            width: 120px;
            background-color: ${pictonBlue};
            color: ${whiteColor};
            font-weight: 400;
            font-size: 13px;
            text-align: center;
            border-radius: 6px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            bottom: 90%;
            left: -45%;
            opacity: 0;
            transition: opacity 0.7s;
            :after {
              content: '';
              position: absolute;
              top: 100%;
              left: 20%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: ${pictonBlue} transparent transparent transparent;
            }
          }
          :hover .tooltiptext {
            display: inline-block;
            opacity: 1;
          }
        }
      }
      .new-message-label {
        position: absolute;
        left: 0;
        padding: 0;
        top: 0;
        height: 100%;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 23px;
        background: ${illusion};
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .search-heighlight {
        padding: 0 200px 0 40px;
        mark {
          padding: 0 6px;
        }
        span {
          display: contents;
          padding: 0;
        }
        pre{
          overflow: hidden;
          white-space: normal;
          margin-bottom: 0;
        }
        code{
          color: ${blackColor};
          font-family: 'Avenir LT Pro' !important;
          font-weight: 500 !important;
          font-size: 14px !important;
        }
      }
    }

    @media only screen and (max-width: 700px){
       .data-icons{
        display: block !important;
        position: relative !important;
        right: 0 !important;
        left: 0 !important;
        margin-left: 20px !important;
        margin-top: 10px !important;
       }
       .search-heighlight,
       .search-date{
           padding: 0 10px 0 20px !important;
       }
       .searched-data-wrapper {
           padding-left: 15px;
       }
    }
  }

  @media only screen and (max-width: 768px){
    .search-sorting{
      width: 30% !important;
    }
    .subcontainer-inner{
      width: 560px;
      font-size: 14px !important;
    }
    .search-filed{
      width: 35.5% !important;
    }
  }

  @media only screen and (max-width: 700px){
    .search-sorting{      
      float: none !important;
    }
    
  }



  @media only screen and (min-width: 800px) and (max-width: 1024px){    
    .subcontainer-inner{      
      font-size: 14px !important;
    }  
    .search-filed{
      width: 32% !important;
    }  
  }
`;

const SurveyCommentStyle = {
    CommonRepplyWrapper,
    ExtendedSubContainer,
    CommentsMainWrapper,
    ExtendedAssistanceWrapper,
    ExtendedSearchFilterWrapper,
    WelcomeHeaderWrapper,
    SurveyExtendedSubContainer
}
export default SurveyCommentStyle;