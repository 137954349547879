import request from '../request';
import { welbeeVoiceAPI, schoolStaffAPI, schoolAPI } from './urls';

export const getConverstion = (id) => {
  return request({
    url: welbeeVoiceAPI.getSchoolConverstion(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const getSurveyVoiceByToken = (token, pageNo) => {
  return request({
    url: welbeeVoiceAPI.getSurveyVoiceByToken(token, pageNo),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const schoolStaffExist = (id, schoolToken = null) => {
  schoolToken = schoolToken === null ? '' : schoolToken;
  return request({
    url: schoolStaffAPI.SchoolStaffExist(id, schoolToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getConversationComments = (id) => {
  return request({
    url: welbeeVoiceAPI.getConversationComments(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const getSurveyVoiceNameAndToken = (id) => {
  return request({
    url: welbeeVoiceAPI.getSurveyVoiceNameAndToken(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const GetConversationList = (schoolId) => {
  return request({
    url: welbeeVoiceAPI.GetConversationList(schoolId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const createConverstion = (data) => {
  return request({
    url: welbeeVoiceAPI.api,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const setVoiceConversationArchive = (data) => {
  return request({
    url: welbeeVoiceAPI.setVoiceConversationArchive,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const setManualInviteForSurveyVoice = (data) => {
  return request({
    url: welbeeVoiceAPI.setManualInviteForSurveyVoice,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const submitHeadTeacherVoiceReply = (data) => {
  return request({
    url: welbeeVoiceAPI.submitHeadTeacherVoiceReply,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};

const setVoiceConversationAsImportant = (model) => {
  return request({
    url: welbeeVoiceAPI.setVoiceConversationAsImportant,
    method: 'PUT',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};

const setVoiceConversationIsRead = (id) => {
  return request({
    url: welbeeVoiceAPI.setVoiceConversationIsRead(id),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((error) => error);
};

const setFirstTimeVoiceLoggedInSuccessful = (id) => {
  return request({
    url: welbeeVoiceAPI.setFirstTimeVoiceLoggedInSuccessful(id),
    method: 'PUT',
  })
    .then((response) => response)
    .catch((error) => error);
};

export const getSurveyVoice = (id) => {
  return request({
    url: welbeeVoiceAPI.getSurveyVoice(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const getFreePeriodEnd = (id) => {
  return request({
    url: schoolAPI.getFreePeriodEnd(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
export const searchVoiceConversation = (schoolId,keywords, getAll) => {
    return request({
        url: welbeeVoiceAPI.searchVoiceConversation(schoolId,keywords, getAll),
        method: 'GET',
    })
        .then((response) => response)
        .catch((response) => response);
};

const GetPDFComments = (surveyToken) => {
    return request({
        url: welbeeVoiceAPI.GetPDFComments(surveyToken),
        method: 'GET',
        responseType: 'blob',
    })
        .then((response) => response)
        .catch((error) => error);
};

const GetExcelComments = (surveyToken) => {
    return request({
        url: welbeeVoiceAPI.GetExcelComments(surveyToken),
        method: 'GET',
        responseType: 'blob',
    })
        .then((response) => response)
        .catch((error) => error);
};
const welbeeVoiceService = {
  getConverstion,
  createConverstion,
  getSurveyVoiceByToken,
  setVoiceConversationAsImportant,
  setVoiceConversationIsRead,
  setVoiceConversationArchive,
  getConversationComments,
  submitHeadTeacherVoiceReply,
  getSurveyVoiceNameAndToken,
  setManualInviteForSurveyVoice,
  schoolStaffExist,
  setFirstTimeVoiceLoggedInSuccessful,
  GetConversationList,
  getSurveyVoice,
  getFreePeriodEnd,
  searchVoiceConversation,
  GetPDFComments,
  GetExcelComments,
};

export default welbeeVoiceService;
