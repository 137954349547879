import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { DirectorLeftMenu, MainMATHeader } from '../../components';
import { loaderAction } from '../../store/loaderStore';
import { WebStorageNames, MenuItems } from '../../utils';
import { navigationActions } from '../../store/headerStore';
import { storageService } from '../../services';
import {
  AnalyticsChartWrapper,
  AnalyticsChartPopupWrapper,
  ChartWrapperError,
} from '../../resources/styling/appStyle';
import { ExtendedWrapperMain } from '../../resources/styling/formStyle';
import { MatCycleMultiSelect, MATSchoolsMultiSelect } from '../../components';
import {
  CategoryList,
  ChartType,
  ShowChartType,
  MATCharacteristics,
  MATCharacteristicsValues,
  sortFilters,
} from '../../utils/Constants';
import { matAnalyticsService, matCycleService } from '../../services';
import MATAnalyticsLineChart from '../../components/Charts/MATAnalyticsLineChart';
import MATAnlyticeBarChart from '../../components/Charts/MATAnlyticeBarChart';
import AnalyticsStackedBarChart from '../../components/Charts/AnalyticsStackedBarChart';
import MATCharateristicsTypeAnalytics from './MATCharateristicsTypeAnalytics';
import { CircularProgress, Tooltip } from '@mui/material';
import { chartColors } from '../../utils/CommonFunctions';
import GraphPopup from '../dashboard/GraphPopup';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { AnalyticsDropdown } from '../../components';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { withTranslation } from 'react-i18next';
import MATMgtStandardAnalytics from './MATMgtStandardAnalytics';
import GroupedBarChart from '../../components/Charts/GroupedBarChart';
class MATAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessLevel: 0,
      characteristicsTypes: [],
      selectedMATCharateristicsType: {
        value: 0,
        label: 'Overall MAT Score',
      },
      selectMATCharateristics: 0,
      allStaffSchoolAnalyticsData: [],
      GraphDataSearched: [],
      selectedCategory: {
        value: 0,
        label: 'All',
      },
      selectedCategoryId: 0,
      categorylable: 'All Management Standards',
      showAnalytics: 0,
      matCharacteristicsTypeValues: [],
      selectedMATCharacteristicsTypeValues: {
        value: 0,
        label: 'All',
      },
      selectedChartType: {
        value: 2,
        label: 'Bar Chart',
      },
      isChartType: 2,
      PageColumnSize: 6,
      searchedCharateristValue: 0,
      graphColors: chartColors(0),
      Chartlabel: '',
      isPopupShowing: false,
      isSchools: false,
      isCycles: true,
      selectedCycle_Options: [],
      matAllSchools: [],
      datasets: [],
      datalables: [],
      labelMATCharacteristics: '',
      showSchoolDropDown: false,
      showCharValueDropDown: false,
      selectedSchools: [],
      cycleIds: [],
      showAdditionalFilterAnalytics: '',
      additionalFiltersSchools: [],
      matSchoolsFilters: [],
      emptyMessage: null,
      isEmptyMessage: false,
      isMatfilters: false,
      SerachByCharacteristicsValue: 0,
      showSurveyDatesDropDown: false,
      isSurveys: false,
      surveyCount: 1,
      matCyclesList: [],
      cycleReload: 0,
      selectedMATcharacteristicsTypeLabel: 'All Staff MAT Score',
      categoryPercentage: 0.9,
      barPercentage: 0.9,
      isSchoolAddFilters: false,
      CoreFiltersJobTitleDeptPhase: [],
      chartHeight: '',
      isSingleCycle: false,
      isLoading: true,
      groupType: this.props.t('MATAnalytics'),
      demandAnalytics: [],
      controlAnalytics: [],
      managerSupportAnalytics: [],
      peerSupportAnalytics: [],
      relationshipAnalytics: [],
      roleAnalytics: [],
      changeAnalytics: [],
      loading: false,
    };
  }
  async componentDidMount() {
    let groupType = storageService.getItem('groupType');
    if (groupType) {
      this.setState({
        groupType: `${groupType} Analytics`,
      });
    }
    const { loaderAction, navigationActions } = this.props;
    loaderAction.dataLoading(true);
    const SchoolId = storageService.getItem(WebStorageNames.SchoolId);
    const accessLevel = storageService.getItem(WebStorageNames.AccessLevel);
    const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    let userToken = storageService.getItem(WebStorageNames.Token);

    const model = {
      SchoolId: SchoolId,
      CategoryId: this.state.selectedCategoryId,
      ChartType: this.state.isChartType,
      token: userToken,
      matGroupId: matGroupId,
      matCycleId: 0,
      matCharacteristics: this.state.selectMATCharateristics,
    };
    await this.getMatCyclesSurvey(model);
    navigationActions.activeItemAction({
      item: MenuItems.MATAnalytics,
    });
    this.setState({ graphColors: chartColors(0) });
    this.setState({ accessLevel: accessLevel });
    await this.GetMatSchools();
    await this.GetMATAnalytcsData(model);
    if (this.state.matCyclesList?.length <= 1) {
      this.setState({ isSingleCycle: true });
    }
  }

  GetMATAnalytcsData = async (model) => {
    const { loaderAction } = this.props;
    this.setState({ isLoading: true });
    loaderAction.dataLoading(true);
    this.setState({ datasets: [] });
    this.setState({ datalables: [] });
    await matAnalyticsService
      .getMATAnalyticsData(model)
      .then((response) => {
        const { success, data, message } = response;
        if (success) {
          this.setState({ isEmptyMessage: false });
          if (data.drawChart[0]?.CoreFilters) {
            this.setState({
              matCharacteristicsTypeValues: data.drawChart[0]?.CoreFilters,
            });
            this.state.matCharacteristicsTypeValues.unshift({
              value: 0,
              label: 'All',
            });
          }
          this.setState({ allStaffSchoolAnalyticsData: data.drawChart });
          this.setState({ datasets: data.drawChart[0].datasets });
          this.setState({ datalables: data.drawChart[0].labels });
          this.setState({ Chartlabel: data.drawChart[0].mainTitle });

          if (
            data.drawChart[0].MatFilterValues !== null &&
            this.state.isMatfilters === true
          ) {
            this.setState({ isSchoolAddFilters: false });
            this.SetMATFilltersValues(data.drawChart[0].MatFilterValues);
          } else if (
            data.drawChart[0].SchoolFilterValues !== null &&
            data.drawChart[0].SchoolFilterValues.length &&
            this.state.isMatfilters === false
          ) {
            this.setState({ isSchoolAddFilters: true });
            this.setState({
              matCharacteristicsTypeValues: this.SetSchoolFilltersValues(
                data.drawChart[0].SchoolFilterValues
              ),
            });
          }
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
          if (
            message === '' &&
            this.state.matCharacteristicsTypeValues.length <= 1 &&
            this.state.showAnalytics !== 0
          ) {
            this.setState({ isEmptyMessage: false });
          } else {
            this.setState({ isEmptyMessage: true });
            this.setState({
              emptyMessage: message
                ? message
                : 'Your analytics will be available as soon as you run and close your survey.',
            });
          }
        }
        loaderAction.dataLoading(false);
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        loaderAction.dataLoading(false);
        this.setState({ isLoading: false });
      });
  };
  GetMatSchools = async () => {
    let matId = storageService.getItem(WebStorageNames.MatGroupId);
    matId = matId === null || matId === undefined ? 0 : matId;
    await matAnalyticsService.getMatSchools(matId).then((response) => {
      if (response.success) {
        if (response.data) {
          this.setState({ matAllSchools: response.data.matSchools });
          this.setState({ matSchoolsFilters: response.data.matSchoolsFilters });
          if (this.state.matSchoolsFilters.length) {
            this.state.matSchoolsFilters.map((item, i) => {
              if (
                MATCharacteristics.filter((a) => a.id === item).length === 0
              ) {
                MATCharacteristics.push({
                  id: item,
                  value: item,
                  label: item,
                });
              }
            });
          }
        }
      }
    });
  };
  getMatCyclesSurvey = async (model) => {
    let matId = storageService.getItem(WebStorageNames.MatGroupId);
    matId = matId === null || matId === undefined ? 0 : matId;
    await matCycleService.getMatSurveys(model).then((response) => {
      if (response.success) {
        if (response.data) {
          this.setState({ matCyclesList: response.data });
        }
      }
    });
  };
  getLatestSurvey = async (data, value) => {
    let selectedOptions = [];
    let selectedOptionId = Math.max(...data.map((x) => x.Id));
    if (
      value === MATCharacteristicsValues.OverallMATScore ||
      this.state.isChartType === ChartType.LineChart
    ) {
      this.setState({ cycleIds: selectedOptions });
    } else {
      const selectedValues = data.filter(
        (option) => option.Id === selectedOptionId
      );
      this.setState({ selectedCycle_Options: selectedValues });
      selectedOptions.push(selectedOptionId);
      this.setState({ cycleIds: selectedOptions });
      this.setState({ surveyCount: selectedOptions.length });
    }
  };
  handlerOnSelectedSchools = async (selectedList) => {
    this.setState({ loading: true });
    var columnSize =
      selectedList?.length === 1 || this.state?.matCyclesList?.length > 3
        ? 12
        : 6;
    this.setState({ PageColumnSize: columnSize });
    this.SetSelectectSchoolStates(selectedList);
    this.setState({
      selectedMATCharacteristicsTypeValues: {
        value: 0,
        label: 'All',
      },
    });

    let filters = [];
    if (this.state.showAdditionalFilterAnalytics === this.state.showAnalytics) {
      if (selectedList.length === 0) {
        this.setState({ SerachByCharacteristicsValue: 0 });
        this.setState({
          labelMATCharacteristics: 'MAT Addtitional Filter values',
        });
        this.setState({ isMatfilters: true });
        const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
        let userToken = storageService.getItem(WebStorageNames.Token);
        const model = {
          CategoryId: this.state.selectedCategoryId,
          ChartType: this.state.isChartType,
          token: userToken,
          matGroupId: matGroupId,
          matCycleId: 0,
          matCharacteristics:
            this.state.selectMATCharateristics > -1
              ? this.state.selectMATCharateristics
              : -1,
          cycleIds: this.state.cycleIds,
          Attribute: this.state.showAdditionalFilterAnalytics,
          IsMatfilters: true,
        };
        await this.GetMATAnalytcsData(model);
      } else {
        this.setState({ isMatfilters: false });
        this.setState({ isSchoolAddFilters: true });
        this.setState({ selectedSchools: selectedList });
        this.setState({
          labelMATCharacteristics: 'Schools Addtitional Filter values',
        });
        const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
        let userToken = storageService.getItem(WebStorageNames.Token);
        const model = {
          CategoryId: this.state.selectedCategoryId,
          ChartType: this.state.isChartType,
          token: userToken,
          matGroupId: matGroupId,
          matCycleId: 0,
          matCharacteristics:
            this.state.selectMATCharateristics > -1
              ? this.state.selectMATCharateristics
              : -1,
          cycleIds: this.state.cycleIds,
          Attribute: this.state.showAdditionalFilterAnalytics,
          SchoolIds: selectedList.map((x) => x.id),
          IsMatfilters: false,
        };
        new Promise((resolve, reject) => {
          this.GetMATAnalytcsData(model);
        })
          .catch(function (error) {})
          .then(
            () =>
              function GetSchoolFilters() {
                selectedList.map((item, i) => {
                  this.state.allStaffSchoolAnalyticsData[0].SchoolFilters.filter(
                    (x) => x.SchoolId === item.id
                  ).map((f) => {
                    filters.push(f.Id);
                  });
                });
                let sFilteredValues =
                  this.state.allStaffSchoolAnalyticsData[0].SchoolFilterValues.filter(
                    (f) => filters.includes(f.SchoolFilterAttributeId)
                  );
                this.setState({
                  matCharacteristicsTypeValues:
                    this.SetSchoolFilltersValues(sFilteredValues),
                });
                this.setState({ selectedSchools: selectedList });
              }
          );
      }
    } else if (this.state.isChartType === ShowChartType.BarChart) {
      if (selectedList.length === 0) {
        this.setState({ selectedSchools: selectedList });
        const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
        let userToken = storageService.getItem(WebStorageNames.Token);
        const model = {
          CategoryId: this.state.selectedCategoryId,
          ChartType: this.state.isChartType,
          token: userToken,
          matGroupId: matGroupId,
          matCharacteristics:
            this.state.selectMATCharateristics > -1
              ? this.state.selectMATCharateristics
              : -1,
          cycleIds: this.state.cycleIds,
          Attribute: this.state.showAdditionalFilterAnalytics,
          IsMatfilters: this.state.isMatfilters,
          Search:
            this.state.SerachByCharacteristicsValue === 0
              ? null
              : this.state.SerachByCharacteristicsValue,
        };
        await this.GetMATAnalytcsData(model);
      } else {
        this.setState({ selectedSchools: selectedList });
        const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
        let userToken = storageService.getItem(WebStorageNames.Token);
        const model = {
          CategoryId: this.state.selectedCategoryId,
          ChartType: this.state.isChartType,
          token: userToken,
          matGroupId: matGroupId,
          matCharacteristics:
            this.state.selectMATCharateristics > -1
              ? this.state.selectMATCharateristics
              : -1,
          Attribute: this.state.showAdditionalFilterAnalytics,
          IsMatfilters: this.state.isMatfilters,
          Search:
            this.state.SerachByCharacteristicsValue === 0
              ? null
              : this.state.SerachByCharacteristicsValue,
          SchoolIds: selectedList.map((x) => x.id),
        };
        await this.GetMATAnalytcsData(model);
      }
    }
  };
  SetSelectectSchoolStates = (selectedList) => {
    let selectedOptions = [];
    if (selectedList.length) {
      this.setState({ showSurveyDatesDropDown: false });
      selectedList.map((item, i) => {
        selectedOptions.push({
          value: item.id,
          label: item.title,
        });
      });
      this.setState({ matCharacteristicsTypeValues: selectedOptions });
      this.setState({ selectedSchools: selectedList });
    } else {
      this.setState({ selectedSchools: selectedOptions });
      this.setState({ showSurveyDatesDropDown: true });
      this.setState({ matCharacteristicsTypeValues: this.state.matAllSchools });
    }
  };
  handlerSelectedMATCharacteristicsType = async (option) => {
    this.setState({ loading: true });
    loaderAction.dataLoading(true);
    this.setState({ cycleReload: option.value });
    await this.getLatestSurvey(this.state.matCyclesList, option.value);
    this.setState({
      selectedMATCharacteristicsTypeValues: {
        value: 0,
        label: 'All',
      },
    });
    while (this.state.selectedSchools.length) {
      this.setState({ selectedSchools: this.state.selectedSchools.pop() });
    }
    this.setState({ searchedCharateristValue: 0 });
    const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    let userToken = storageService.getItem(WebStorageNames.Token);
    const model = {
      CategoryId: this.state.selectedCategoryId,
      ChartType: this.state.isChartType,
      token: userToken,
      matGroupId: matGroupId,
      matCharacteristics: option.value > -1 ? option.value : -1,
      cycleIds: this.state.cycleIds,
      Attribute: option.value,
      IsMatfilters: option.value > -1 ? this.state.isMatfilters : true,
      Search:
        this.state.SerachByCharacteristicsValue === 0
          ? null
          : this.state.SerachByCharacteristicsValue,
      SchoolIds: this.state.selectedSchools?.map((x) => x.id),
    };
    if (this.state.isChartType === ShowChartType.MgtStandardChart) {
      await this.fetchMgtStandardAnalytics(model);
    } else {
      await this.GetMATAnalytcsData(model);
    }

    await this.getMatCyclesSurvey(model);
    
    this.setState({ graphColors: chartColors(0) });
    this.setState({ categorylable: 'All Management Standards' });
  };
  MATCharacteristicsDropdownSwitch = async (value, chartType) => {
    value = value > -1 ? value : -1; // -1 using for Additional Filters
    if (chartType === ShowChartType.MgtStandardChart) {
      if (value === MATCharacteristicsValues.OverallMATScore) {
        this.setState({ isSurveys: false });
        this.setState({ showSchoolDropDown: false });
        this.setState({ showCharValueDropDown: false });
        this.setState({ showSurveyDatesDropDown: false });
      } else if (
        value === MATCharacteristicsValues.JobRoleScoresAcrossAllSchools ||
        value === MATCharacteristicsValues.EmploymentTypeAcrossAllSchools ||
        value === MATCharacteristicsValues.TimeAtSchoolAcrossAllSchools ||
        value === MATCharacteristicsValues.JobTitle ||
        value === MATCharacteristicsValues.Department ||
        value === MATCharacteristicsValues.PhaseOrYear
      ) {
        this.setState({ isSurveys: false });
        this.setState({ showSchoolDropDown: false });
        this.setState({ showCharValueDropDown: false });
        this.setState({ showSurveyDatesDropDown: true });
      } else if (value === -1) {
        this.setState({ isSurveys: false });
        this.setState({ isMatfilters: true });
        this.setState({
          labelMATCharacteristics: 'MAT Addtitional Filter values',
        });
        this.setState({ showSchoolDropDown: false });
        this.setState({ showCharValueDropDown: true });
        this.setState({ showSurveyDatesDropDown: true });
      } else {
        this.setState({ isSurveys: true });
        this.setState({ showSchoolDropDown: false });
        this.setState({ showCharValueDropDown: false });
        this.setState({ showSurveyDatesDropDown: true });
      }
    } else {
      if (value === MATCharacteristicsValues.OverallMATScore) {
        this.setState({ showSchoolDropDown: false });
        this.setState({ showCharValueDropDown: false });
        this.setState({ showSurveyDatesDropDown: false });
        this.setState({ isSurveys: false });
        this.setState({ chartHeight: '110' });
      } else if (
        value === MATCharacteristicsValues.JobRoleScoresAcrossAllSchools
      ) {
        this.setState({ labelMATCharacteristics: 'Job Roles' });
        this.setState({ showSchoolDropDown: false });
        this.setState({ showCharValueDropDown: true });
        if (chartType === ShowChartType.BarChart) {
          this.setState({ showSurveyDatesDropDown: true });
        }
        this.setState({ isSurveys: false });
        this.setState({ chartHeight: 200 });
      } else if (
        value === MATCharacteristicsValues.EmploymentTypeAcrossAllSchools
      ) {
        this.setState({ chartHeight: 110 });
        this.setState({ labelMATCharacteristics: 'Employment Types' });
        this.setState({ showSchoolDropDown: false });
        this.setState({ showCharValueDropDown: true });
        if (chartType === ShowChartType.BarChart) {
          this.setState({ showSurveyDatesDropDown: true });
        }
        this.setState({ isSurveys: false });
        this.setState({ chartHeight: '110' });
      } else if (
        value === MATCharacteristicsValues.TimeAtSchoolAcrossAllSchools
      ) {
        this.setState({ labelMATCharacteristics: 'Experience' });
        this.setState({ showSchoolDropDown: false });
        this.setState({ showCharValueDropDown: true });
        if (chartType === ShowChartType.BarChart) {
          this.setState({ showSurveyDatesDropDown: true });
        }
        this.setState({ isSurveys: false });
        this.setState({ chartHeight: 800 });
      } else if (value === MATCharacteristicsValues.JobTitle) {
        this.setState({ chartHeight: 3500 });
        this.setState({ SerachByCharacteristicsValue: 0 });
        this.setState({ labelMATCharacteristics: 'Job Titles' });
        this.setState({ showSchoolDropDown: false });
        this.setState({ showCharValueDropDown: true });
        if (chartType === ShowChartType.BarChart) {
          this.setState({ showSurveyDatesDropDown: true });
        }
        this.setState({ isSurveys: false });
      } else if (value === MATCharacteristicsValues.Department) {
        this.setState({ chartHeight: 3500 });
        this.setState({ labelMATCharacteristics: 'Department' });
        this.setState({ showSchoolDropDown: false });
        this.setState({ showCharValueDropDown: true });
        if (chartType === ShowChartType.BarChart) {
          this.setState({ showSurveyDatesDropDown: true });
        }

        this.setState({ isSurveys: false });
      } else if (value === MATCharacteristicsValues.PhaseOrYear) {
        this.setState({ chartHeight: 3500 });
        this.setState({ labelMATCharacteristics: 'Phase Or Years' });
        this.setState({ showSchoolDropDown: false });
        this.setState({ showCharValueDropDown: true });
        if (chartType === ShowChartType.BarChart) {
          this.setState({ showSurveyDatesDropDown: true });
        }
        this.setState({ isSurveys: false });
      } else if (value === -1) {
        this.setState({ isMatfilters: true });
        this.setState({
          labelMATCharacteristics: 'MAT Addtitional Filter values',
        });
        this.setState({ showSchoolDropDown: true });
        this.setState({ showCharValueDropDown: true });
        if (chartType === ShowChartType.BarChart) {
          this.setState({ showSurveyDatesDropDown: true });
        }
        this.setState({ isSurveys: false });
        this.setState({ chartHeight: 3500 });
      } else {
        this.setState({
          matCharacteristicsTypeValues: this.state.matAllSchools,
        });
        this.setState({ showSchoolDropDown: true });
        this.setState({ showCharValueDropDown: false });
        if (chartType === ShowChartType.BarChart) {
          this.setState({ showSurveyDatesDropDown: true });
        }
        this.setState({ isSurveys: true });
        this.setState({ chartHeight: 3500 });
      }
    }
  };

  handlerCategorySearch = async (option) => {
    let searchData = [];
    searchData = this.state.allStaffSchoolAnalyticsData;
    this.setState({ selectedCategoryId: option.value });
    option.value === 0
      ? this.setState({ categoryPercentage: 0.9 })
      : this.setState({ categoryPercentage: 0.25 });
    if (this.state.isChartType === ShowChartType.LineChart) {
      if (option.value !== 0) {
        this.setState({ datalables: searchData[0].labels });
        searchData = searchData[0].datasets
          .filter((x) => x.CategoryId === option.value)
          .map((data) => data);
        this.setState({ datasets: searchData });
      } else {
        this.setState({
          datasets: this.state.allStaffSchoolAnalyticsData[0].datasets,
        });
        this.setState({
          datalables: this.state.allStaffSchoolAnalyticsData[0].labels,
        });
        this.setState({ categorylable: 'All Management Standards' });
      }
    } else if (this.state.isChartType === ShowChartType.BarChart) {
      const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
      let userToken = storageService.getItem(WebStorageNames.Token);
      const model = {
        CategoryId: option.value,
        ChartType: this.state.isChartType,
        token: userToken,
        matGroupId: matGroupId,
        matCharacteristics:
          this.state.selectMATCharateristics > -1
            ? this.state.selectMATCharateristics
            : -1,
        cycleIds: this.state.cycleIds,
        Attribute: this.state.showAdditionalFilterAnalytics,
        IsMatfilters: this.state.isMatfilters,
        Search:
          this.state.SerachByCharacteristicsValue === 0
            ? null
            : this.state.SerachByCharacteristicsValue,
        SchoolIds: this.state.selectedSchools?.map((x) => x.id),
      };
      await this.GetMATAnalytcsData(model);
    }

    this.setState({ selectedCategory: option });
  };
  handlerSelectChartType = async (option) => {
    loaderAction.dataLoading(true);
    this.setState({ loading: true });
    this.setState({ selectedChartType: option });
    //this.setState({ isChartType: this.state.selectedChartType?.value });
    await this.MATCharacteristicsDropdownSwitch(
      this.state.selectMATCharateristics,
      option.value
    );
    if (option.value === ShowChartType.LineChart) {
      this.setState({ showSurveyDatesDropDown: false });
    }
    const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
    let userToken = storageService.getItem(WebStorageNames.Token);
    const model = {
      CategoryId: this.state.selectedCategoryId,
      ChartType: option.value,
      token: userToken,
      matGroupId: matGroupId,
      cycleIds: this.state.cycleIds,
      matCharacteristics:
        this.state.selectMATCharateristics > -1
          ? this.state.selectMATCharateristics
          : -1,
      Attribute: this.state.showAdditionalFilterAnalytics,
      IsMatfilters: this.state.isMatfilters,
      Search:
        this.state.SerachByCharacteristicsValue === 0
          ? null
          : this.state.SerachByCharacteristicsValue,
      SchoolIds: this.state.selectedSchools?.map((x) => x.id),
    };
    if (option.value === ShowChartType.MgtStandardChart) {
      await this.fetchMgtStandardAnalytics(model);
    } else {
      await this.GetMATAnalytcsData(model);
    }
    loaderAction.dataLoading(false);
    this.setState({ loading: false });
  };
  handleSchoolList = (data) => {
    this.setState({ matAllSchools: data });
  };

  GetTitlesForBarChart = (data) => {
    const value = data
      .filter((d) => d)
      .map((x) => x.label)
      .join(', ');
    return value;
  };
  handlerJobRoleEmploymentExperienceSearch = async (option) => {
    let showSurveys = false;
    option.value === 0 ? (showSurveys = true) : (showSurveys = false);
    this.setState({ showSurveyDatesDropDown: showSurveys });
    if (this.state.isChartType === ShowChartType.LineChart) {
      this.setState({ showSurveyDatesDropDown: false });
      let searchData = [];
      searchData = this.state.allStaffSchoolAnalyticsData;
      if (option.value !== 0) {
        searchData = searchData[0].datasets
          .filter((x) => x.characteristicsType === option.value)
          .map((data) => data);

        if (this.state.selectedCategoryId !== 0) {
          searchData = searchData[0].datasets
            .filter((x) => x.CategoryId === option.value)
            .map((data) => data);
        }
        this.setState({ datasets: searchData });
        this.setState({
          datalables: this.state.allStaffSchoolAnalyticsData[0].labels,
        });
      } else {
        this.setState({
          datasets: this.state.allStaffSchoolAnalyticsData[0].datasets,
        });
        this.setState({
          datalables: this.state.allStaffSchoolAnalyticsData[0].labels,
        });
      }

    this.setState({ selectedCharacteristicsValues: option });
    } else {
      let emptyCycle = [];
      const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
      let userToken = storageService.getItem(WebStorageNames.Token);
      console.log(option);
      const model = {
        CategoryId: this.state.selectedCategoryId,
        ChartType: this.state.isChartType,
        token: userToken,
        matGroupId: matGroupId,
        matCharacteristics:
          this.state.selectMATCharateristics > -1
            ? this.state.selectMATCharateristics
            : -1,
        cycleIds: option.value === 0 ? this.state.cycleIds : emptyCycle,
        Attribute: this.state.showAdditionalFilterAnalytics,
        IsMatfilters: this.state.isMatfilters,
        Search: option.value === 0 ? null : option.value,
        SchoolIds: this.state.selectedSchools?.map((x) => x.id),
      };
      await this.GetMATAnalytcsData(model);
      this.setState({ selectedCharacteristicsValues: option });
    }
    this.setState({ SerachByCharacteristicsValue: option.value });
  };
  handlerSetColumnSize = (option) => {
    if (option.value === 0) {
      this.setState({ PageColumnSize: 6 });
      this.setState({ searchedCharateristValue: 0 });
    } else {
      this.setState({ searchedCharateristValue: option.value });
      this.setState({ PageColumnSize: 12 });
    }
  };
  handlerOnSelectedCycles = async (selectedCycles) => {
    this.setState({ loading: true });
    let selectedOptions = [];
    this.setState({ surveyCount: selectedCycles?.length });
    if (selectedCycles.length) {
      selectedCycles.map((item, i) => {
        selectedOptions.push(item.Id);
      });
      this.setState({ selectedCycle_Options: selectedCycles });
      this.setState({ cycleIds: selectedOptions });
    } else {
      this.setState({ cycleIds: selectedOptions });
    }
    if (!selectedCycles.length) {
      this.setState({ isEmptyMessage: true });
      this.setState({
        emptyMessage:
          "Please choose the survey's from the survey comparison filter.",
      });
    } else {
      const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
      let userToken = storageService.getItem(WebStorageNames.Token);
      const model = {
        CategoryId: this.state.selectedCategoryId,
        ChartType: this.state.isChartType,
        token: userToken,
        matGroupId: matGroupId,
        matCharacteristics:
          this.state.selectMATCharateristics > -1
            ? this.state.selectMATCharateristics
            : -1,
        cycleIds: selectedOptions,
        Attribute: this.state.showAdditionalFilterAnalytics,
        IsMatfilters: this.state.isMatfilters,
        Search:
          this.state.SerachByCharacteristicsValue === 0
            ? null
            : this.state.SerachByCharacteristicsValue,
        SchoolIds: this.state.selectedSchools?.map((x) => x.id),
      };
      if (this.state.isChartType === ShowChartType.MgtStandardChart) {
        await this.fetchMgtStandardAnalytics(model);
      } else {
        await this.GetMATAnalytcsData(model);
      }
    }
    this.setState({ loading: false });
  };
  SetSchoolFilltersValues = (data) => {
    let filterValues = [
      {
        value: 0,
        label: 'All',
      },
    ];
    if (data.length) {
      data.map((item, i) => {
        filterValues.push({
          value: item.Value,
          label: item.Value,
        });
      });
    }
    return filterValues;
  };
  SetMATFilltersValues = (data) => {
    let filterValues = [
      {
        value: 0,
        label: 'All',
      },
    ];
    if (data.length) {
      data.map((item, i) => {
        filterValues.push({
          value: item,
          label: item,
        });
      });
    }
    this.setState({ matCharacteristicsTypeValues: filterValues });
    return filterValues;
  };
  GetLabelText = (data, id) => {
    const text = data.filter((d) => d.value === id).map((x) => x.label);
    return text[0];
  };
  showGraphPopup = () => {
    this.setState({ isPopupShowing: true });
  };

  closePopup = () => {
    this.setState({ isPopupShowing: false });
  };

  fetchMgtStandardAnalytics = async (model) => {
    this.setState({ loading: true });
    loaderAction.dataLoading(true);
    this.setState({ isEmptyMessage: false });
    this.setState({ emptyMessage: '' });
    await matAnalyticsService
      .getMATCategoriesAnalytics(model)
      .then((response) => {
        const { success, data, message } = response;
        if (success) {
          this.setState({
            demandAnalytics: data.drawChart[0].datasets,
            controlAnalytics: data.drawChart[1].datasets,
            managerSupportAnalytics: data.drawChart[2].datasets,
            peerSupportAnalytics: data.drawChart[3].datasets,
            relationshipAnalytics: data.drawChart[4].datasets,
            roleAnalytics: data.drawChart[5].datasets,
            changeAnalytics: data.drawChart[6].datasets,
            Chartlabel: data.drawChart[0].mainTitle,
          });
          this.setState({ loading: false });
          loaderAction.dataLoading(false);
        } else {
          this.setState({ isEmptyMessage: true });
          this.setState({ emptyMessage: message });
          this.setState({ loading: false });
          loaderAction.dataLoading(false);
        }
        loaderAction.dataLoading(false);
      })
      .catch((error) => {
        this.setState({ isEmptyMessage: true });
        this.setState({ emptyMessage: error });
        this.setState({ loading: false });
        loaderAction.dataLoading(false);
      });
  };

  render() {
    const { t } = this.props;
    const {
      loading,
      allStaffSchoolAnalyticsData,
      selectedCategory,
      selectedMATCharateristicsType,
      categorylable,
      showAnalytics,
      matCharacteristicsTypeValues,
      selectedMATCharacteristicsTypeValues,
      PageColumnSize,
      searchedCharateristValue,
      selectedChartType,
      isChartType,
      graphColors,
      characteristicsTypeLabel,
      selectedCycle_Options,
      matAllSchools,
      datalables,
      datasets,
      labelMATCharacteristics,
      showSchoolDropDown,
      showCharValueDropDown,
      isPopupShowing,
      selectedSchools,
      emptyMessage,
      isEmptyMessage,
      Chartlabel,
      SerachByCharacteristicsValue,
      showSurveyDatesDropDown,
      isSurveys,
      surveyCount,
      matCyclesList,
      cycleReload,
      categoryPercentage,
      barPercentage,
      isSchoolAddFilters,
      chartHeight,
      isSingleCycle,
      isLoading,
      selectMATCharateristics,
      demandAnalytics,
      controlAnalytics,
      managerSupportAnalytics,
      peerSupportAnalytics,
      relationshipAnalytics,
      roleAnalytics,
      changeAnalytics,
      selectedCategoryId,
    } = this.state;
    return (
      <div>
        <div className='containerClass'>
          <div className='left-container'>
            <DirectorLeftMenu />
          </div>
          <div className='right-container'>
            <Grid container>
              <Grid item lg={12} md={12} xs={12}>
                <MainMATHeader />
              </Grid>
              <Grid item lg={12} xs={12} md={12}>
                <h2 style={{ margin: '0' }}>{this.state.groupType}</h2>
                <p style={{ marginTop: '5px' }}>
                  Compare survey results across all characteristics, but note
                  that scores are only reported where there are 5 or more
                  participants in that category.
                </p>
              </Grid>
              <Grid item lg={12} md={12} xs={12} mt='15px'>
                <ExtendedWrapperMain className='form-inner'>
                  <div className='mat-analytics-dropdown analytics-dropdown'>
                    <AnalyticsDropdown
                      name='MATCharacteristicsTypes'
                      label='MAT Characteristics Types'
                      options={MATCharacteristics}
                      value={selectedMATCharateristicsType.value}
                      onChange={(option) => {
                        this.setState({
                          SerachByCharacteristicsValue:0,
                          showAdditionalFilterAnalytics: this.GetLabelText(
                            MATCharacteristics,
                            option.target.value
                          ),
                          selectMATCharateristics: option.target.value,
                          showAnalytics: option.target.value
                        });
                        this.MATCharacteristicsDropdownSwitch(
                          option.target.value,
                          isChartType
                        );
                        this.handlerSelectedMATCharacteristicsType(
                          option.target
                        );

                        this.setState({
                          selectedMATCharateristicsType: option.target,
                        });
                        this.setState({
                          characteristicsTypeLabel: this.GetLabelText(
                            MATCharacteristics,
                            option.target.value
                          ),
                        });
                        this.setState({
                          selectedMATcharacteristicsTypeLabel:
                            this.GetLabelText(
                              MATCharacteristics,
                              option.target.value
                            ),
                        });
                      }}
                    />
                  </div>
                  <div className='analytics-dropdown'>
                    <AnalyticsDropdown
                      name='categoriesName'
                      label='Management Standards'
                      options={CategoryList.filter(
                        (option) => option.value !== 8
                      )}
                      value={selectedCategory.value}
                      onChange={(option) => {
                        this.handlerCategorySearch(option.target);
                        this.setState({
                          categorylable: this.GetLabelText(
                            CategoryList,
                            option.target.value
                          ),
                        });
                      }}
                    />
                  </div>
                  <div className='analytics-dropdown'>
                    <AnalyticsDropdown
                      name='ChartType'
                      label='Chart Type'
                      options={sortFilters(ChartType)}
                      value={selectedChartType.value}
                      onChange={(option) => {
                        this.setState({ isChartType: option.target.value });
                        this.handlerSelectChartType(option.target);
                      }}
                    />
                  </div>
                </ExtendedWrapperMain>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <ExtendedWrapperMain className='form-inner'>
                  {showSchoolDropDown === true && (
                    <div className='analytics-dropdown mat-multi-select'>
                      <MATSchoolsMultiSelect
                        onSelect={this.handlerOnSelectedSchools}
                        selectedValues={selectedSchools}
                        matSchoolList={matAllSchools}
                      />
                    </div>
                  )}
                  {showCharValueDropDown === true && (
                    <div className='analytics-dropdown'>
                      <AnalyticsDropdown
                        name='MATCharacteristicsTypeValue'
                        label={labelMATCharacteristics}
                        options={matCharacteristicsTypeValues}
                        value={selectedMATCharacteristicsTypeValues?.value}
                        onChange={(option) => {

                          this.setState({
                            selectedMATCharacteristicsTypeValues: option.target,
                          });
                          this.handlerSetColumnSize(option.target);
                           this.handlerJobRoleEmploymentExperienceSearch(option.target);
                        }}
                      />
                    </div>
                  )}
                  
                  {showSurveyDatesDropDown === true &&
                    isSingleCycle === false && (
                      <div className='analytics-dropdown'>
                        <MatCycleMultiSelect
                          onSelect={this.handlerOnSelectedCycles}
                          selectedValues={selectedCycle_Options}
                          data={matCyclesList}
                          isSurveys={isSurveys}
                          cycleReload={cycleReload}
                        />
                      </div>
                    )}
                </ExtendedWrapperMain>
              </Grid>
              <Grid item xs={12} md={12}>
                {isEmptyMessage ? (
                  <ChartWrapperError>
                    <span className='nodata'>{emptyMessage}</span>
                  </ChartWrapperError>
                ) : (
                  <>
                    {isChartType === ShowChartType.MgtStandardChart && (
                      <>
                        {loading ? (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircularProgress />
                            <span>
                              Your Information will be displayed shortly...
                            </span>
                          </div>
                        ) : (
                          <MATMgtStandardAnalytics
                            demandAnalytics={demandAnalytics}
                            controlAnalytics={controlAnalytics}
                            managerSupportAnalytics={managerSupportAnalytics}
                            peerSupportAnalytics={peerSupportAnalytics}
                            relationshipAnalytics={relationshipAnalytics}
                            roleAnalytics={roleAnalytics}
                            changeAnalytics={changeAnalytics}
                            isEmptyMessage={isEmptyMessage}
                            emptyMessage={emptyMessage}
                            selectedMATcharacteristicsTypeLabel={
                              this.state.Chartlabel
                            }
                            CharacteristicValue={selectMATCharateristics}
                            selectedCategory={selectedCategoryId}
                            surveyCount={surveyCount}
                          />
                        )}
                      </>
                    )}
                    {isChartType === ShowChartType.BarChart && (
                      <>
                        {showAnalytics === 0 ? ( // show column/vertical bar chart
                          <AnalyticsChartWrapper>
                            <Box
                              sx={{ marginBottom: '30px', textAlign: 'center' }}
                            >
                              {/* <h5
                                style={{ display: 'inline-block', margin: '0' }}
                              >
                                {this.state.selectedMATcharacteristicsTypeLabel}
                              </h5> */}
                              <Tooltip title='Full Screen View' arrow>
                                <FullscreenIcon
                                  class='large-graph-btn'
                                  style={{
                                    display: 'inline-block',
                                    float: 'right',
                                  }}
                                  fontSize='inherit'
                                  onClick={this.showGraphPopup}
                                />
                              </Tooltip>
                            </Box>
                            <MATAnlyticeBarChart
                              AnalyticsBarChartData={
                                allStaffSchoolAnalyticsData
                              }
                              colorValue={graphColors}
                              Chartlabel={Chartlabel}
                              datalables={datalables}
                              datasets={datasets}
                              height={'110'}
                              categoryPercentage={categoryPercentage}
                              barPercentage={barPercentage}
                              isSchoolTitle={false}
                              id={'barChart'}
                              mainLabel={this.state.selectedMATcharacteristicsTypeLabel}
                            />
                          </AnalyticsChartWrapper>
                        ) : (
                          <>
                            {SerachByCharacteristicsValue === 0 &&
                            selectedSchools.length === 0 ? ( // show horizental bar chart
                              <>
                                {loading ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {''}
                                  </div>
                                ) : (
                                  <AnalyticsChartWrapper>
                                    <Box
                                      sx={{
                                        marginBottom: '30px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <Tooltip title='Full Screen View' arrow>
                                        <FullscreenIcon
                                          class='large-graph-btn'
                                          style={{
                                            display: 'inline-block',
                                            float: 'right',
                                          }}
                                          fontSize='inherit'
                                          onClick={this.showGraphPopup}
                                        />
                                      </Tooltip>
                                    </Box>

                                    <GroupedBarChart
                                      data={datasets}
                                      surveyCount={surveyCount}
                                      category={selectedCategoryId}
                                      title ={this.state.selectedMATcharacteristicsTypeLabel}
                                    />
                                  </AnalyticsChartWrapper>
                                )}
                              </>
                            ) : (
                              <MATCharateristicsTypeAnalytics
                                matCharacteristicsTypeValues={
                                  matCharacteristicsTypeValues
                                }
                                allStaffSchoolAnalyticsData={
                                  allStaffSchoolAnalyticsData
                                }
                                categoryLabel={categorylable}
                                matSchools={matAllSchools}
                                pageColumnSize={PageColumnSize}
                                SearchTypeValue={searchedCharateristValue}
                                chartType={isChartType}
                                colorsValue={graphColors}
                                characteristicsTypeLabel={
                                  characteristicsTypeLabel
                                }
                                datalables={datalables}
                                datasets={datasets}
                                categoryPercentage={categoryPercentage}
                                barPercentage={barPercentage}
                                isSchoolAddFilters={isSchoolAddFilters}
                                isFitlerbySchool={showSchoolDropDown}
                                Chartlabel={Chartlabel}
                                mainLabel={this.state.selectedMATcharacteristicsTypeLabel}
                                id={1}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                    {isChartType === ShowChartType.LineChart && (
                      <>
                        {showAnalytics === 0 ? (
                          <AnalyticsChartWrapper>
                            <Box
                              sx={{ marginBottom: '30px', textAlign: 'center' }}
                            >
                              <h5
                                style={{ display: 'inline-block', margin: '0' }}
                              >
                                {this.state.selectedMATcharacteristicsTypeLabel}
                              </h5>
                              <Tooltip title='Full Screen View' arrow>
                                <FullscreenIcon
                                  class='large-graph-btn'
                                  style={{
                                    display: 'inline-block',
                                    float: 'right',
                                  }}
                                  fontSize='inherit'
                                  onClick={this.showGraphPopup}
                                />
                              </Tooltip>
                            </Box>
                            <MATAnalyticsLineChart
                              AnalyticsLineChartData={
                                allStaffSchoolAnalyticsData
                              }
                              datalables={datalables}
                              datasets={datasets}
                              colorValue={graphColors}
                              height={'110'}
                              id={'lineChart'}
                            />
                          </AnalyticsChartWrapper>
                        ) : (
                          <MATCharateristicsTypeAnalytics
                            matCharacteristicsTypeValues={
                              matCharacteristicsTypeValues
                            }
                            allStaffSchoolAnalyticsData={
                              allStaffSchoolAnalyticsData
                            }
                            categoryLabel={categorylable}
                            matSchools={matAllSchools}
                            pageColumnSize={PageColumnSize}
                            SearchTypeValue={searchedCharateristValue}
                            chartType={isChartType}
                            colorsValue={graphColors}
                            characteristicsTypeLabel={characteristicsTypeLabel}
                            datalables={datalables}
                            datasets={datasets}
                            categoryPercentage={categoryPercentage}
                            barPercentage={barPercentage}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
        {isPopupShowing ? (
          <GraphPopup>
            <div className='graph-container' id='large-graph'>
              <div className='redCrossButtom'>
                <CloseIcon className='closeIcon' onClick={this.closePopup} />
              </div>
              <>
                {isChartType === ShowChartType.LineChart && (
                  <AnalyticsChartPopupWrapper>
                    <div className='mb-3' style={{ textAlign: 'center' }}>
                      <h5 className='d-inline-block'>
                        {this.state.selectedMATcharacteristicsTypeLabel}
                      </h5>
                    </div>
                    <MATAnalyticsLineChart
                      AnalyticsLineChartData={allStaffSchoolAnalyticsData}
                      datalables={datalables}
                      datasets={datasets}
                      colorValue={graphColors}
                    />
                  </AnalyticsChartPopupWrapper>
                )}
                {isChartType === ShowChartType.BarChart && (
                  <>
                    {showAnalytics === 0 ? ( // show column/vertical bar chart
                      <AnalyticsChartPopupWrapper>
                        <>
                       
                          <MATAnlyticeBarChart
                            AnalyticsBarChartData={allStaffSchoolAnalyticsData}
                            colorValue={graphColors}
                            Chartlabel={Chartlabel}
                            datalables={datalables}
                            datasets={datasets}
                            height={'110'}
                            categoryPercentage={categoryPercentage}
                            barPercentage={barPercentage}
                            isSchoolTitle={false}
                            id={'barChart'}
                            mainLabel={this.state.selectedMATcharacteristicsTypeLabel}
                          />
                        </>
                      </AnalyticsChartPopupWrapper>
                    ) : (
                      <>
                        {SerachByCharacteristicsValue === 0 &&
                        selectedSchools.length === 0 ? ( // show horizental bar chart
                          <>
                            {loading ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {''}
                              </div>
                            ) : (
                              <AnalyticsChartPopupWrapper>
                               
                              
                                  <GroupedBarChart
                                    data={datasets}
                                    surveyCount={surveyCount}
                                    category={selectedCategoryId}
                                    title ={this.state.selectedMATcharacteristicsTypeLabel}
                                  />
                                
                              </AnalyticsChartPopupWrapper>
                            )}
                          </>
                        ) : (
                          <MATCharateristicsTypeAnalytics
                            matCharacteristicsTypeValues={
                              matCharacteristicsTypeValues
                            }
                            allStaffSchoolAnalyticsData={
                              allStaffSchoolAnalyticsData
                            }
                            categoryLabel={categorylable}
                            matSchools={matAllSchools}
                            pageColumnSize={PageColumnSize}
                            SearchTypeValue={searchedCharateristValue}
                            chartType={isChartType}
                            colorsValue={graphColors}
                            characteristicsTypeLabel={characteristicsTypeLabel}
                            datalables={datalables}
                            datasets={datasets}
                            categoryPercentage={categoryPercentage}
                            barPercentage={barPercentage}
                            isSchoolAddFilters={isSchoolAddFilters}
                            isFitlerbySchool={showSchoolDropDown}
                            Chartlabel={Chartlabel}
                            mainLabel={this.state.selectedMATcharacteristicsTypeLabel}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </div>
          </GraphPopup>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
  loaderAction: bindActionCreators(loaderAction, dispatch),
});
export default connect(
  null,
  mapDispatchToProps
)(withRouter(withTranslation()(MATAnalytics)));
