import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { HeaderWrapper, SchoolName } from '../resources/styling/appStyle';

import { welbeeLogo } from '../resources/images';



class Header extends Component {
  render() {
    return (
      <HeaderWrapper>
        <img src={welbeeLogo} alt="Welbee Logo" style={{ cursor: 'default' }} />        
        <SchoolName>
          {this.props.SchoolName ? this.props.SchoolName : ''}
        </SchoolName>
      </HeaderWrapper>
    );
  }
}
const mapStateToProps = state => {
  const { surveyRecord } = state.survey;
  return surveyRecord;
};
export default connect(
  mapStateToProps,
  null
)(withRouter(Header));
