import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderAction } from '../../store/loaderStore';
import { firstLoginAction } from '../../store/userStore';
import { useHistory, withRouter } from 'react-router-dom';
import { accountService, storageService } from '../../services';
import { routesObj } from '../../routes';
import {
  WebStorageNames,
  Roles,
  toggleOption,
  HeadTeacherAccess,
  WelbeeTypes,
} from '../../utils/Constants';
import { parseJwt } from '../../utils/CommonFunctions';
import { Redirect } from 'react-router-dom';
import { usePermission } from 'react-permission-role';
import { useSelector } from 'react-redux';

function HomeRedirect(props) {
  
  const { setUser } = usePermission();
  const history = useHistory();
  const user = useSelector(state => state.auth.user);

  const loadHomePage = (user) => {
    accountService.login(user).then((response) => {
      const { success, message } = response;
      props.loaderActions.dataLoading(false);
      if (success) {
        storageService.setToken({
          token: response.data.access_token,
        });
        storageService.setItem(
          WebStorageNames.IsDisplayMatHeatMap,
          response.data.IsDisplayMatHeatMap
        );
        storageService.setItem(
          WebStorageNames.SubscriptionId,
          response.data.SubscriptionId
        );

        const userInfo = parseJwt(response.data.access_token);
        storageService.setItem(
          WebStorageNames.UserInfo,
          response.data.userInfo
        );
        storageService.setItem(
          WebStorageNames.SelectedTab,
          response.data.SubscriptionId ===
            WelbeeTypes.WelbeeVoiceFree ||
            response.data.SubscriptionId === WelbeeTypes.WelbeeVoicePro
            ? toggleOption.WelbeeVoice
            : toggleOption.WelbeeSurvey
        );
        if (userInfo.role === Roles.GroupDirector) {
          storageService.setItem(WebStorageNames.IsMat, true);
          storageService.setItem(
            WebStorageNames.MatGroupId,
            response.data.MatGroupId
          );
          props.history.push(routesObj.GroupDashboard.path);
          storageService.setItem(
            WebStorageNames.Role,
            Roles.GroupDirector
          );
        }
        storageService.setItem(
          WebStorageNames.SchoolId,
          response.data.schoolId
        );
        storageService.setItem(
          WebStorageNames.SchoolName,
          response.data.schoolName
        );
        const token = storageService.getItem(WebStorageNames.Token);
        if (!token) {
          return <Redirect to={routesObj.Login.path} />;
        }
        // Settings permissions
        setUser({
          id: userInfo?.id,
          roles: [userInfo?.role],
          permissions: response?.data?.Permissions
        })

        if (userInfo.role === Roles.SuperAdmin) {
          if (storageService.getItem(WebStorageNames.IsMatOrSchool) === 'School')
            props.history.push(routesObj.Dashboard.path + storageService.getItem(WebStorageNames.SuperadminSchoolToken));
          if (storageService.getItem(WebStorageNames.IsMatOrSchool) === 'Group')
            props.history.push(routesObj.GroupDashboard.path + storageService.getItem(WebStorageNames.SuperadminGroupToken));
        }
        
        storageService.setItem(
          WebStorageNames.AccessLevel,
          response.data.AccessLevel
        );
        storageService.setItem(
          WebStorageNames.IsStaff,
          response.data.IsStaff
        );
        storageService.setItem(
          WebStorageNames.WelbeeVoiceAccountType,
          response.data.WelbeeVoiceAccountType
        );
        storageService.setItem(
          WebStorageNames.IsFirstLogin,
          response.data.IsFirstLogin
        );
        storageService.setItem(
          WebStorageNames.IsVoiceFirstLogin,
          response.data.IsVoiceFirstLogin
        );
        storageService.setItem(
          WebStorageNames.FreeTrialEndDate,
          response.data.FreeTrialEndDate
        );
        storageService.setItem(
          WebStorageNames.SelectedTab,

          response.data.SubscriptionId ===
            WelbeeTypes.WelbeeVoiceFree ||
            response.data.SubscriptionId === WelbeeTypes.WelbeeVoicePro
            ? toggleOption.WelbeeVoice
            : toggleOption.WelbeeSurvey
        );
        storageService.setItem(
          WebStorageNames.IsUpgradeDisplay,
          response.data.IsUpgradeDisplay
        );
        storageService.setItem(
          WebStorageNames.IsUpgradBannderDisplayed,
          false
        );
        if (userInfo.role === Roles.HeadTeacher) {
          storageService.setItem(
            WebStorageNames.WelbeeType,
            response.data.WelbeeType
          );
          storageService.setItem(
            WebStorageNames.IsMat,
            response.data.MatGroupId !== null
          );
          storageService.setItem(
            WebStorageNames.MatGroupId,
            response.data.MatGroupId
          );
          storageService.setItem(
            WebStorageNames.Role,
            Roles.HeadTeacher
          );
          if (response.data.AccessLevel === HeadTeacherAccess.Admin) {
            if (
              response.data.SubscriptionId ===
              WelbeeTypes.WelbeeVoiceFree ||
              response.data.SubscriptionId ===
              WelbeeTypes.WelbeeVoicePro
            ) {
              props.history.push(routesObj.Account.path);
            } else {
              props.history.push(routesObj.AdminDashboard.path);
            }
          } else {
            if (
              response.data.SubscriptionId ===
              WelbeeTypes.WelbeeVoiceFree ||
              response.data.SubscriptionId ===
              WelbeeTypes.WelbeeVoicePro
            )
              props.history.push(routesObj.WelbeeVoice.path);
            else props.history.push(routesObj.Dashboard.path + '?schoolToken=' + response.data.SchoolToken);
          }
        }
      } else {

      }
    })
    .catch((error) => console.log(error));
  }; 
  
  useEffect(() => {
    loadHomePage(user);
  }, [history])

  return (
    <div>Loading</div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});
export default connect(null, mapDispatchToProps)(withRouter(HomeRedirect));

