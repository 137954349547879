import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    borderColor: '#45b1e8 !important;',
  },
  select: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Avenir LT Pro',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#53B5E0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#53B5E0',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#53B5E0',
    },
  },
  menuitem: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Avenir LT Pro',
  },
  menuPaper: {
    maxHeight: 400,
  },
}));
export default function AnalyticsDropdown(props) {
  const classes = useStyles();
  const { name, label, value, error = null, onChange, options } = props;

  return (
    <>
      <FormControl
        className={classes.formControl}
        variant='outlined'
        size='medium'
        {...(error && { error: true })}
      >
        <InputLabel className={classes.menuitem}>{label}</InputLabel>
        <Select
          variant='outlined'
          className={classes.select}
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          MenuProps={{ classes: { paper: classes.menuPaper } }}
        >
          <MenuItem value=''></MenuItem>
          {options?.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              className={classes.menuitem}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </>
  );
}
