
import { storageService } from '../services';
import { WebStorageNames, WelbeeTypes } from '../utils/Constants';
import { SchoolSubscriptionAPI } from '../services/api/urls';
import request from '../services/request';

export const IsActionAllowed = (actionName) => {
    return request({
        url: SchoolSubscriptionAPI.IsActionAllowed(storageService.getItem(WebStorageNames.SchoolId), actionName),
        method: 'GET',
    })
        .then((response) => response)
        .catch((error) => error);
}