import request from '../request';
import { filterLibraryAPI } from './urls';

export const getFilterLibrary = (model) => {
  return request({
    url: filterLibraryAPI.getFilterLibrary,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((response) => response);
};

const deleteFilter = (id) => {
  console.log(id);
  return request({
    url: filterLibraryAPI.deleteFilter(id),
    method: 'DELETE',
  })
    .then((response) => response)
    .catch((error) => error);
};

const getById = (id) => {
  return request({
    url: filterLibraryAPI.getById(id),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
const saveFilter = (model) => {
  return request({
    url: filterLibraryAPI.saveFilter,
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((error) => error);
};


const filterLibraryService = {
  getFilterLibrary,
  getById,
  deleteFilter,
  saveFilter,
};

export default filterLibraryService;
