import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { AppButton } from '../../../resources/styling';
import { colors } from '../../../resources/theme/colors';
import { matCycleService } from '../../../services';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { AdminHeader } from '../../../components';
import { SchoolListMainWrapper } from '../../../resources/styling/appStyle';
import SortIcon from '@mui/icons-material/ArrowDownward';
import DataTable from 'react-data-table-component';
import EditCycleSurvey from './EditCycleSurvey';
import AddCycleSchool from './AddCycleSchool';
import { withRouter } from 'react-router-dom';
import ConfirmPopupForm from '../../../components/common/ConfirmPopupForm';
import { customStyles, SchoolSurveyInner, SchoolSurveyInnerMain, SchoolSurveyWrapper } from './MatCycleStyle';

const { purple, teal, mandy, outrageousOrange } =
  colors;

const DetailCycle = (props) => {
  const location = useLocation();
  const id = location.state.id;
  const [schoolId, setSchoolId] = useState('');
  const [surveyId, setSurveyId] = useState('');
  const [matGroupId, setMatGroupId] = useState('');
  const [matCycleSchoolId, setMatCycleSchoolId] = useState('');
  const [matCycleId, setMatCycleId] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [cycleName, setCycleName] = useState('');
  const [matGroupName, setMatGroupName] = useState('');
  const [data, setData] = useState([]);
  const [isShowEditCycleSurvey, setIsShowEditCycleSurvey] = useState(false);
  const [isShowAddCycleSchool, setIsShowAddCycleSchool] = useState(false);
  const [isRemovePopupOpen, setRemovePopupOpen] = useState(false);
  const [isTogglePopupOpen, setTogglePopupOpen] = useState(false);
  const [toggleMessage, setToggleMessage] = useState('');

  useEffect(() => {
    getMatCycle(id);
  }, {});

  const getMatCycle = (id) => {
    matCycleService
      .getById(id)
      .then((response) => {
        const { success } = response;
        if (success) {
          setMatCycleId(response.data.Id);
          setMatGroupName(response.data.MatGroup.Name);
          setMatGroupId(response.data.MatGroup.Id);
          setData(response.data.MatCycleSchools);
          setCycleName(response.data.Name);
        }
      })
      .catch((error) => {
        toast.error('Error loading mat cycles.');
      });
  };

  const handleSubmit = () => {
    setIsShowEditCycleSurvey(false);
    setIsShowAddCycleSchool(false);
    getMatCycle(id);
  };

  const removeCycleSchool = () => {
    matCycleService
      .removeCycleSchool(matCycleSchoolId)
      .then((response) => {
        const { success } = response;
        setRemovePopupOpen(false);
        toast.success('School from cycle  remove sucessfully');
        if (success) {
          getMatCycle(id);
        }
      })
      .catch(() => {
        setRemovePopupOpen(false);
        toast.error('Error removing School from cycle.');
      });
  };

  const handleToggleSchool = (schoolCycleId) => {
    matCycleService
      .toggleSchool(schoolCycleId)
      .then((response) => {
        const { success } = response;
        if (success) {
          setTogglePopupOpen(false);
          toast.success('school updated sucessfully');
          getMatCycle(id);
        }
      })
      .catch((error) => {
        toast.error('Error updating selection critera');
      });
  };

  const columns = [
    {
      name: 'School',
      selector: (d) => {
        return d.School !== null ? d.School.Name : null;
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Survey',
      selector: (d) => {
        return d.Survey !== null ? d.Survey.Name : null;
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Action',
      width: 100,
      align: 'left',
      sortable: false,
      cell: (record) => {
        return (
          <div>
            <AppButton
              color={record.IsIncluded ? teal : purple}
              type='submit'
              onClick={() => {
                setTogglePopupOpen(true);
                setMatCycleSchoolId(record.Id);
                record.IsIncluded
                  ? setToggleMessage(
                      'Are you sure to exclude this school from cycle?'
                    )
                  : setToggleMessage(
                      'Are you sure to include this school from cycle?'
                    );
              }}
            >
              {record.IsIncluded ? 'Exclude' : 'Include'}
            </AppButton>
            &nbsp;
            <AppButton
              color={mandy}
              type='submit'
              onClick={() => {
                setSchoolName(record.School.Name);
                setMatCycleSchoolId(record.Id);
                setSchoolId(record.SchoolId);
                setSurveyId(record.SurveyId);
                setIsShowEditCycleSurvey(true);
              }}
            >
              Edit
            </AppButton>
            &nbsp;
            <AppButton
              color={mandy}
              type='submit'
              onClick={() => {
                setRemovePopupOpen(true);
                setMatCycleSchoolId(record.Id);
              }}
            >
              Remove
            </AppButton>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <AdminHeader />
      <SchoolSurveyWrapper>
        <SchoolSurveyInnerMain>
          <SchoolSurveyInner>
            <SchoolListMainWrapper>
              <h1>
                <span
                  className='back-btn'
                  onClick={() => {
                    props.history.push('/mat-cycles');
                  }}
                >
                  Back
                </span>
                <span className='heading'>{cycleName} Detail</span>
                <span
                  className='create-school'
                  onClick={() => setIsShowAddCycleSchool(true)}
                >
                  Add School
                </span>
              </h1>
              
              <h4 className='dashboard-school-name ms-lg-4 ms-xxl-4 my-4'>
                {matGroupName}
              </h4>

              <DataTable
                columns={columns}
                data={data}                
                sortIcon={<SortIcon />}
                striped                
                customStyles={customStyles}
              />
            </SchoolListMainWrapper>
          </SchoolSurveyInner>
        </SchoolSurveyInnerMain>
      </SchoolSurveyWrapper>

      <div>
        <EditCycleSurvey
          isOpen={isShowEditCycleSurvey}
          onClose={() => {
            setIsShowEditCycleSurvey(false);
          }}
          handleSubmit={handleSubmit}
          matCycleId={id}
          matCycleSchoolId={matCycleSchoolId}
          schoolName={schoolName}
          schoolId={schoolId}
          surveyId={surveyId}
        />
      </div>

      <div>
        <AddCycleSchool
          isOpen={isShowAddCycleSchool}
          onClose={() => {
            setIsShowAddCycleSchool(false);
          }}
          handleSubmit={handleSubmit}
          matCycleId={id}
          matGroupId={matGroupId}
          data={data}
        />
      </div>
      <div>
        <ConfirmPopupForm
          isOpen={isRemovePopupOpen}
          onClose={() => {
            setRemovePopupOpen(false);
            setMatCycleId(null);
          }}
          onOk={() => removeCycleSchool()}
          successPopupMessage='Are you sure to remove this school from cycle?'
        />
      </div>

      {/* Togggle School Popup */}
      <div>
        <ConfirmPopupForm
          isOpen={isTogglePopupOpen}
          onClose={() => {
            setTogglePopupOpen(false);
          }}
          onOk={() => handleToggleSchool(matCycleSchoolId)}
          successPopupMessage={toggleMessage}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ superAdmin: { SetSchoolsListFlag } }) => ({
  SetSchoolsListFlag,
});
export default connect(mapStateToProps, null)(withRouter(DetailCycle));
