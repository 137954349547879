import React from 'react';
import styled from 'styled-components';
import { colors } from '../../resources/theme/colors';
import ChartPercentLegend from './ChartPercentLegend';
import { ChartWrapper } from '../../resources/styling/appStyle';

const { never, seldom, sometimes, often, always } = colors;

const ChartBar = styled.div`
  width: ${({ width }) => width + '%'};
  display: inline-block;
  background: ${({ color }) => color};
  text-align: center;
  padding: 18px 0;
`;

const AppChart = ({ options, index, data }) => (
  <ChartWrapper>
    <div className="main-wrapper">
      <span>
        <i>Results</i>
      </span>
      <div className="main">
        {data.NeverPercentage > 0 ? (
          <ChartBar width={data.NeverPercentage} color={never}>
            <span>{data.NeverPercentage}%</span>
          </ChartBar>
        ) : null}
        {data.SeldomPercentage > 0 ? (
          <ChartBar width={data.SeldomPercentage} color={seldom}>
            <span>{data.SeldomPercentage}%</span>
          </ChartBar>
        ) : null}
        {data.SometimesPercentage > 0 ? (
          <ChartBar width={data.SometimesPercentage} color={sometimes}>
            <span>{data.SometimesPercentage}%</span>
          </ChartBar>
        ) : null}
        {data.OftenPercentage > 0 ? (
          <ChartBar width={data.OftenPercentage} color={often}>
            <span>{data.OftenPercentage}%</span>
          </ChartBar>
        ) : null}
        {data.AlwaysPercentage > 0 ? (
          <ChartBar width={data.AlwaysPercentage} color={always}>
            <span>{data.AlwaysPercentage}%</span>
          </ChartBar>
        ) : null}
      </div>
      <ChartPercentLegend />
    </div>
  </ChartWrapper>
);

export default AppChart;
