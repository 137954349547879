import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { storageService, accountService } from '../../services';
import { toast } from 'react-toastify';
import { AppButton, RegisterInnerMain } from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme/colors';
import { loaderAction } from '../../store/loaderStore';
import { firstLoginAction } from '../../store/userStore';
import { RegisterInner, ExtendedAssitanceForm } from './SchoolRegisterStyle';

const { mandy } = colors;

function RegisterConfirmEmailSent(props) {
    let [Email, setEmail] = useState('');
    let [AccType, setAccType] = useState('');
    useEffect(() => {
        setEmail(props.Email);
        setAccType(props.AccType);
        sendEmailConfirmation();
    });
    const sendEmailAgain = () => {
        toast.success('Welcome email has been re-sent');
        sendEmailConfirmation();
    };
    const sendEmailConfirmation = () => {
        accountService
            .sendRegistrationEmail(JSON.stringify(Email))
            .then((response) => {
                if (response.success) {
                    console.log("send email", response);
                }
            })
            .catch((error) => { })};

    return (
        <div>
            <Grid container>
                <Grid item
                    lg={12}
                    sm={12}
                    md={12}
                    xs={12}>
                    <RegisterInnerMain>
                        <RegisterInner>
                            <ExtendedAssitanceForm>
                                <h2>
                                    Thank you for joining Welbee.
                                 </h2>
                                <p className='fee-detail'>
                                    Your welcome email has been sent to {Email}, and may take a few minutes to arrive.  
                                    Please click on the link in the welcome email to create your password and access your Welbee account.
                                </p>
                            </ExtendedAssitanceForm>
                        </RegisterInner>
                    </RegisterInnerMain>
                </Grid>
            
            <Grid item lg={12} sm={12} xs={12} md={12}>
                <AppButton
                    color={mandy}
                    type='button'
                    onClick={sendEmailAgain}
                >Send Welcome Email Again
                      </AppButton>
            </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    loaderActions: bindActionCreators(loaderAction, dispatch),
    userAction: bindActionCreators(firstLoginAction, dispatch),
});
export default connect(
    null,
    mapDispatchToProps
)(withRouter(RegisterConfirmEmailSent));
