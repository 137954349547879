import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { amplitudeLog } from '../../utils/CommonFunctions';
import {
  AppButton,
  LoginFormWrapper,
  ErrorWrapper
} from '../../resources/styling/appStyle';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme/colors';
import { AppInputField, EmailToLinkButton, FloatingInputLabel } from '../../components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { accountService, storageService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import LoginWrapper from '../../components/common/LoginWrapper';
import ConfirmPopupForm from '../../components/common/ConfirmPopupForm';
import { routesObj } from '../../routes';
import {
  LoginFormInner,
  InputWrapper,
  FieldWrapper
} from '../../components/common/loginStyle';
import { WrapperMain } from '../../resources/styling/formStyle';

const { mandy } = colors;

function ForgotPasswordForm(props) {
  let [emailErrorMessage, setEmailErrorMessage] = useState('');
  let [isButtonDisabled, setIsButtonDisabled] = useState(false);
  let [successMessage, setSuccessMessage] = useState('');
  let [isPopupShow, setIsPopupShow] = useState(false);
  let [isEmailWrong , setEmailWrong] = useState(false);

  useEffect(() => {
    amplitudeLog('PageView', 'ForgotPassword', '');
    storageService.clear();
  }, {});

  function redirectToLogin() {
    props.history.push(routesObj.Login.path);
  }

  return (
    <Formik
      initialValues={{
        Email: '',
        WebUrl: window.location.origin
      }}
      onSubmit={(values, actions) => {
        setEmailErrorMessage('');
        setIsButtonDisabled(true);
        props.loaderActions.dataLoading(true);

        setTimeout(() => {
          actions.setSubmitting(false);
          accountService
            .forgotPassword(values)
            .then(response => {
              const { success, message } = response;
              props.loaderActions.dataLoading(false);
              
              if (success) {
                setIsPopupShow(true);
                setSuccessMessage(message);
                setEmailWrong(false);
              } else {
                setIsButtonDisabled(false);
                setEmailErrorMessage(message);
                setEmailWrong(true);
                amplitudeLog('ForgotPassword', 'Forgot Password failure', {
                  UserName: values.Email
                });
              }
            })
            .catch(error => {setEmailErrorMessage(error.data.message); setEmailWrong(true);});
        }, 1000);
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        Email: Yup.string()
          .trim()
          .email('Please enter a valid email')
          .required('Email is required.')
      })}
      render={({ values, handleChange, touched, errors, handleSubmit }) => (
        <LoginWrapper>
          <form
            onSubmit={handleSubmit}
            onKeyDown={e => {
              if (e.keyCode === 13) handleSubmit();
            }}
          >
            <LoginFormWrapper>
              <LoginFormInner>
                <h2>Reset your Password</h2>
                <span>
                  Please enter your email address. we will send you an email to
                  reset your password
                </span>
                <InputWrapper>
                  <WrapperMain>
                    <FloatingInputLabel
                      label='Email'
                      placeholder='Email'
                      autoComplete='new-email'
                      name='Email'
                      value={values.Email}
                      onChange={handleChange}
                      onKeyDown={key => console.log(key)}
                      type='email'
                    />
                    <ErrorWrapper>
                     {touched.Email && errors.Email}  
                     {isEmailWrong ?  (
                         <div>{emailErrorMessage} <EmailToLinkButton  mailto ='mailto:support@welbee.co.uk' label ='support@welbee.co.uk' /> </div>
                          ) : (
                        <div />
                      )}                     
                    </ErrorWrapper>
                  </WrapperMain>

                  <FieldWrapper>                    
                        <AppButton
                          color={mandy}
                          type='submit'
                          onClick={handleSubmit}
                          disabled={isButtonDisabled}
                          isDisable={isButtonDisabled}
                        >
                          Submit
                        </AppButton>
                  </FieldWrapper>
                </InputWrapper>
              </LoginFormInner>
            </LoginFormWrapper>
          </form>
          <ConfirmPopupForm
            isOpen={isPopupShow}
            successPopupMessage={successMessage}
            onOk={() => redirectToLogin()}
            onClose={() => redirectToLogin()}
          />
        </LoginWrapper>
      )}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  loaderActions: bindActionCreators(loaderAction, dispatch)
});
export default connect(
  null,
  mapDispatchToProps
)(withRouter(ForgotPasswordForm));
