import request from '../request';
import { schoolProductAPI } from './urls';

export const get = (schoolId, matId) => {
  return request({
    url: schoolProductAPI.get(schoolId, matId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const requestProduct = (school, product) => {
  return request({
    url: schoolProductAPI.requestProduct(school, product),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const hasPulseSurvey = (schoolId, matId) => {
  return request({
    url: schoolProductAPI.hasPulseSurvey(schoolId, matId),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
const schoolProductService = {
  get,
  requestProduct,
  hasPulseSurvey,
};

export default schoolProductService;
