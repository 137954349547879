import React from 'react';
import {
  CompareToStaffInOtherSchools,
  NoWeaknessesTextSchool,
  NoWeaknessesTextHse,
} from '../../views/surveyReport/Constants';
import { useTranslation } from 'react-i18next';

const ComparativeWeakness = ({ SchoolWeaknesses, HseWeaknesses }) => {
  const { t } = useTranslation();
  return (
    <div>
      {SchoolWeaknesses[0] !== NoWeaknessesTextSchool ||
      HseWeaknesses[0] !== NoWeaknessesTextHse ? (
        <div>
          <div>
            <p className='sub-heading report-list-wrapper'>
              {CompareToStaffInOtherSchools}
            </p>
            <ul className='report-list-wrapper'>
              {SchoolWeaknesses.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
          <div>
            <p className='sub-heading report-list-wrapper'>
              {t('CompareToStaffInOtherOrganisations')}
            </p>
            <ul className='report-list-wrapper'>
              {HseWeaknesses.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <p className='sub-heading-message'>{t('AreasForReviewEmptyMessage')}</p>
      )}
    </div>
  );
};
export default ComparativeWeakness;
