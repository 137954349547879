import React from 'react';
import { Dropdown } from '../';
import TextField from '@mui/material/TextField';
import { LatestSurveyDropdown } from '../../resources/styling/appStyle';

const SurveyDropDown = ({
  latestSurveyOptions,
  latestSurveyOption,
  handleSurveyValueChange,
}) => {
  return (
    <div>
      <LatestSurveyDropdown>
        <div className='latest-survey'>
          <Dropdown
            name='LatestSurvey'
            options={latestSurveyOptions}
            selectedOption={latestSurveyOption}
            onChange={handleSurveyValueChange}
            value={latestSurveyOption}
          />
        </div>
      </LatestSurveyDropdown>
    </div>
  );
};

export default SurveyDropDown;
