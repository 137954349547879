import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { navigationActions } from '../../store/headerStore';
import { amplitudeLog, parseJwt, getQueryStringVal } from '../../utils/CommonFunctions';
import { storageService } from '../../services';
import { WebStorageNames, Roles, QueryStringTokens } from '../../utils/Constants';
import { AssistanceWrapper, SearchFilterWrapper } from '../../resources/styling/appStyle';
import { LeftMenu, LearnMoreDropdown, DirectorLeftMenu } from '../../components';
import {
  LearnActions,
  LearnComments,
  LearnFeedback,
  LearnWelbee,
  LearnObtaining,
  LearnPresentation,
  LearnResults,
  LearnReports,
  LearnRunning,
  LearnSchool,
  LearnStaff,
  LearnSteps,
  LearnSupport,
  LearnClosing,
  LearnInvitations,
} from './';
import RequestAssistanceButton from '../../components/common/RequestAssistanceButton';
import { LearnMoreMainWrapper, ExtendedSubContainer, LearnMoreWrapper, LearnMoreInnerWrapper } from './LearnMoreStyle';

class LearnMore extends Component {
  constructor() {
    super();
    this.state = {
      LearnWelbee: { value: -1, label: 'What is welbee?' },
      LearnStaff: {
        value: -1,
        label: 'What is staff wellbeing and how can you best measure it?',
      },
      LearnSchool: { value: -1, label: 'Management Standards' },
      LearnRunning: { value: -1, label: 'Running your survey' },
      LearnObtaining: { value: -1, label: 'Obtaining a High Response Rate' },
      //LearnPositioning: {
      //  value: -1,
      //  label: 'Positioning the survey with your staff'
      //},
      LearnClosing: {
        value: -1,
        label: 'Closing Your Survey',
      },
      LearnInvitations: {
        value: -1,
        label: 'Issuing Survey Invitations',
      },
      LearnResults: { value: -1, label: 'Your Results and On-line Dashboard' },
      LearnMenu: { value: -1, label: 'The Menu' },
      LearnReports: { value: -1, label: 'Your Report' },
      LearnSteps: { value: -1, label: 'Next Steps' },
      LearnComments: { value: -1, label: 'Staff Comments' },
      LearnActions: {
        value: -1,
        label: 'Action',
      },
      LearnPresentation: { value: -1, label: 'Presentation' },
      LearnSupport: { value: -1, label: 'Support' },
      LearnFeedback: { value: -1, label: 'Your Feedback' },
      isDirectorLoggedIn: false,
    };
    this.handleChannge = this.handleChannge.bind(this);
  }

  componentDidMount() {
    const token = storageService.getItem(WebStorageNames.Token);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    let userToken = getQueryStringVal(QueryStringTokens.token);

    if (token !== null) {
      const userInfo = parseJwt(token);
      this.setState({
        isDirectorLoggedIn:
          (userInfo.role === Roles.GroupDirector && schoolToken === null) ||
          userToken !== null,
      });
    }
    this.props.navigationActions.activeItemAction({
      item: 'learnMore',
    });
    window.scrollTo(0, 0);
    amplitudeLog('PageView', 'LearnMore', '');
  }

  redirectToRoute(routeName) {
    this.props.history.push(routeName);
  }

  handleChannge() {}

  render() {
    return (
      <div>
        <LearnMoreMainWrapper>
          <div className='containerClass '>
            
              <div className='left-container'>
                {this.state.isDirectorLoggedIn ? (
                  <DirectorLeftMenu />
                ) : (
                  <LeftMenu />
                )}
              </div>
              <div className='right-container'>
              <AssistanceWrapper>
                <div
                  className='action-heading-wrapper'
                  style={{
                    float: 'left',
                    fontSize: '24px',
                    letterSpacing: '1.8px',
                    fontWeight: '900',
                  }}
                >
                  <span className='action-heading'>Learn more</span>
                </div>
                <RequestAssistanceButton />
              </AssistanceWrapper>
              
                <LearnMoreWrapper>
                  <div id='learn-more-wrapper'>
                    <SearchFilterWrapper>
                      <div className='learn-more-sub-heading'>
                        <span>Section 1: </span>Information &amp; pre-survey
                        action
                      </div>
                    </SearchFilterWrapper>
                    <LearnMoreInnerWrapper>
                      <ExtendedSubContainer>
                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnWelbee}
                          content={LearnWelbee}
                        />

                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnStaff}
                          content={LearnStaff}
                        />

                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnSchool}
                          content={LearnSchool}
                        />
                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnRunning}
                          content={LearnRunning}
                        />
                        {/*<LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnPositioning}
                          content={LearnPositioning}
                        />*/}

                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnObtaining}
                          content={LearnObtaining}
                        />
                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnInvitations}
                          content={LearnInvitations}
                        />
                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnClosing}
                          content={LearnClosing}
                        />
                      </ExtendedSubContainer>
                    </LearnMoreInnerWrapper>

                    <SearchFilterWrapper>
                      <div className='learn-more-sub-heading'>
                        <span>Section 2: </span>Receiving your results
                      </div>
                    </SearchFilterWrapper>

                    <LearnMoreInnerWrapper>
                      <ExtendedSubContainer>
                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnResults}
                          content={LearnResults}
                        />

                        {/* <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnMenu}
                          content={LearnMenu}
                        /> */}

                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnReports}
                          content={LearnReports}
                        />
                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnSteps}
                          content={LearnSteps}
                        />

                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnComments}
                          content={LearnComments}
                        />

                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnActions}
                          content={LearnActions}
                        />
                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnPresentation}
                          content={LearnPresentation}
                        />

                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnSupport}
                          content={LearnSupport}
                        />

                        <LearnMoreDropdown
                          name='LearnMore'
                          onChange={this.handleChannge}
                          selectedOption={this.state.LearnFeedback}
                          content={LearnFeedback}
                        />
                      </ExtendedSubContainer>
                    </LearnMoreInnerWrapper>
                  </div>
                </LearnMoreWrapper>
              </div>
            
          </div>
        </LearnMoreMainWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(LearnMore));
