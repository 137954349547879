import request from '../request';
import { superAdminAPI } from './urls';

function getAdminDashboardInfo() {
  return request({ url: superAdminAPI.getDashboardInfo, method: 'GET' })
    .then(response => response)
    .catch(error => error);
}

const sendRegistrationCompletionEmail = schoolId => {
  return request({
    url: superAdminAPI.sendRegistrationCompletionEmail(schoolId),
    method: 'POST'
  });
};

const superAdminService = {
  getAdminDashboardInfo,
  sendRegistrationCompletionEmail
};

export default superAdminService;
