import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { wellbeing, bullet1, bullet2 } from '../../resources/images/index';
import { media } from 'styled-bootstrap-grid';
import { MediaQuery, SubContainer } from '../../resources/styling/appStyle';

const { mineShaft, pictonBlue, whiteColor, teal, illusion } = colors;

/*** Learn More ***/
export const LearnMoreMainWrapper = styled.div`
  .learnmore-positioning {
    font-weight: 500;
    display: block;
    padding-top: 10px;
    .email-anchor a {
      padding: 0 5px 0 0;
      display: inline-block;
      float: none;
    }
    .italic-text {
      font-weight: 600 !important;
      font-style: italic;
    }
    p {
      margin-bottom: 25px;
      line-height: 22px;
      font-size: 15px;
      float: left;
      margin-top: 0px;
      padding: 0px 40px;
    }
    ol {
      padding: 11px 0 0 56px;
      float: left;
      width: 100%;
      margin: 0;
      line-height: 30px;
      li {
        font-size: 14px;
        color: ${mineShaft};
      }
    }
    a {
      padding: 11px 0 0 39px;
      font-size: 14px;
      float: left;
    }
  }
  .learn-invitation {
    a {
      padding: 11px 0 0 2px;
      font-size: 14px;
      float: none;
    }
      ol {
      padding: 0 0 0 56px;
      float: left;
      width: 88%;
      margin: 0;
      line-height: 25px;
      list-style: none;
      li {
        font-size: 15px;
        color: ${mineShaft};
        margin: 0 0 25px 0;
      }
    }
      ul {
      padding: 0 0 0 56px;
      float: left;
      width: 88%;
      margin: 0;
      line-height: 25px;
       list-style: none;
      li {
        font-size: 15px;
        color: ${mineShaft};
margin: 0 0 25px 0;
p{
  padding: 0;
  margin: 25px 0 25px 0;
  float: none;
}
      }
    }
  }
  a {
    color: ${pictonBlue};
  }

  .sub-heading {
    font-weight: 600;
  }

  .custom-bullet, .costom-sub-bullet {
    background: url(${bullet1}) no-repeat top left;
        padding-left: 24px !important;
    margin-left: 40px;
    width: 95%;
    background-position-y: 5px;
  }
  .costom-sub-bullet {
     background: url(${bullet2}) no-repeat top left;
        margin-left: 64px;
    width: 90%;
     background-position-y: 5px;
  }

  .copy-link, .copy-link-date {
    text-decoration: none;
    color: ${mineShaft};
    margin-top: 30px !important;
    font-weight: 500;
  }
  .copy-link-date{
    margin-top: 0px !important;
  }
  .invite-email {
    position: relative;
    float: left;
    /* background: url(${wellbeing}) no-repeat center center; */
    border-left: 24px solid #46338d;
    border-right: 24px solid ${illusion};
    margin: 0 43px;
    border-top: 24px solid #46338d;
     border-bottom: 24px solid ${illusion};
     margin-bottom: 30px;
    padding: 20px 0 0 0;
  }
  .invite-email:after {

    border-left: 24px solid ${illusion};
    content: '';
        position: absolute;
    top: -24px;
    right: -50px;
    z-index: 99999;
    width: 50px;
    height: 24px;
  }
   .invite-email:before {

    border-left: 24px solid #46338d;
    content: '';
          position: absolute;
    bottom: -24px;
    left: -24px;
    z-index: 99999;
    width: 50px;
    height: 24px;
  }
  .colleague-message {
    float: left;
    background: #f7f7f7;
    margin: 0 42px;
    padding: 20px 0;
  }
  .school-management-list {
    float: left;
    padding: 0 0 0 60px;
    margin: 0;
    list-style: disc;
    li {
      color: ${mineShaft};
      font-size: 15px;
      font-weight: 500;
      padding: 0 33px 0 0;
     margin-bottom: 8px;
    line-height: 26px;

      ul {
        float: left;
        width: 100%;
        padding: 0 0 0 20px;
        margin: 13px 0 4px 0;
        list-style: circle;
      }
    }
  }
  .learn-more-dropdown {
    border: 0;
    margin-bottom: 0;

    > div {
      background: transparent;
    }
    p {
      font-size: 14px;
      padding: 0 40px;
      float: left;
      margin-bottom: 0;
    }
    .screenshots {
      margin: auto;
      float: none;
      width: 90%;
      text-align: initial;
      padding: 0 40px;
    }
    .report-pictures {
      width: 100%;
      float: left;
      text-align: center;
      margin: 20px 0;
    }
    .selected-option {
      color: ${mineShaft};
      font-size: 17px;
      font-weight: 900;
      padding: 0 40px 25px 40px;
    }
  }
  .containerClass {
    padding-top: 0;
    width: 100%;
    max-width: 100%;
  }
  float: left;
  width: 100%;
  ${MediaQuery}
`;

export const ExtendedSubContainer = styled(SubContainer)`
  background: ${whiteColor};
  padding: 20px 0;
  width: 800px;
  margin: auto;
  float: none;
  border-radius: 6px;
  box-shadow: 3px 3px 24px rgba(0, 122, 122, 0.12);
`;

export const LearnMoreWrapper = styled.div`
  .learn-more-sub-heading {
    display: block;
    padding: 18px 20px;
    text-align: center;
    color: ${teal};
    font-size: 14px;
    span {
      font-weight: 900;
    }
  }
  display: block;
  width: 100%;
  margin: 54px auto;

  ${media.xl`
width: 100%;
margin: 111px auto;
`}
  h2 {
    color: ${mineShaft};
    font-size: 48px;
    font-weight: 900;
    width: 72%;
    margin-top: 0;
    ${media.xs`
       width: 90%;
       font-size: 24px;
     `}
  }
  p {
    color: ${mineShaft};
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin: 23px 0 11px 0;
    ${media.xs`
       width: 90%;
       line-height: 31px;
       font-size:18px;
     `}
    span {
      font-weight: 600;
      color: ${mineShaft};
    }
  }
`;

export const LearnMoreInnerWrapper = styled.div`
  width: 100%;
  float: left;
  padding: 0 45px 55px 45px;
  .secondaryList {
    ul {
      width: 100%;
      list-style: none;

      padding-left: 0;
      margin-top: 20px;
    }
  }
  display: block;

  h2 {
    color: teal;
  }
`;

const LearnMoreStyle = {
  LearnMoreMainWrapper,
  ExtendedSubContainer,
  LearnMoreWrapper,
  LearnMoreInnerWrapper
}
export default LearnMoreStyle;