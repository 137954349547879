import React from 'react';
import { AppLogo } from '../../../components';
import { colors } from '../../../resources/theme';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../MatStyle';
import { useTranslation } from 'react-i18next';
import { storageService } from '../../../services';

const StaffParticipSummaryPpt = (props) => {
  console.log(props?.reportData);
  const { t } = useTranslation();
  let groupType = storageService.getItem('groupType');
  const matGroupType = groupType || t('MAT');
  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section' id='summery-score'>
          <span className='app-logo'>
            <img src={props.welbeeslideslogo} alt='' />
          </span>
          {/* <AppLogo type="overview" color={colors.bittersweet} width="100" /> */}
          <h1 className='matpres-title'>{props.title}</h1>
          <p className='section'>
            {props.NoOfStaffParticipated} members of staff from across your{' '}
            {matGroupType} participated in the survey, which is{' '}
            {(
              (props.NoOfStaffParticipated / props.TotalMatStaff) *
              100
            ).toFixed(2)}
            % of all staff. The breakdown of this across your schools and core
            filters is shown in the table below. Where no number is shown, this
            is because less than 5 participants took part for that job role. The
            participation numbers for each filter may not add to the total if
            people have multiple job titles and because participation below 5 is
            not shown.
          </p>
          <p className='table-title'>Table 1: Staff Participation</p>
          <div className='stats-table'>
            <Scrollbars className='scrollbar-view'>
              <div className='job-role-container'>
                <div className='stats-title-cell'>Organisation</div>
                <div className='stats-role-cell'>Support Staff (Classroom)</div>
                <div className='stats-role-cell'>
                  Support Staff (Non-Classroom)
                </div>
                <div className='stats-role-cell'>
                  Teaching Staff (Classroom)
                </div>
                <div className='stats-role-cell'>
                  Teaching Staff (Non-Classroom)
                </div>
                <div className='stats-role-cell'>Middle Leaders</div>
                <div className='stats-role-cell'>Senior Leaders</div>
                <div className='stats-role-cell'>Total</div>
              </div>
              {props?.MatSchoolStaffCount?.map((row) => {
                return (
                  <div className='job-role-container'>
                    <div className='job-role-title-cell'>{row.SchoolName}</div>
                    <div className='job-role-cell'>
                      {row.ResponseCountByJob.SupportStaffWorkingInside > 4
                        ? row.ResponseCountByJob.SupportStaffWorkingInside +
                          (' (' +
                            (
                              (row.ResponseCountByJob
                                .SupportStaffWorkingInside /
                                row.ResponseCountByJob
                                  .totalSupportStaffClassRoom) *
                              100
                            ).toFixed(2) +
                            '%)')
                        : '-'}
                    </div>
                    <div className='job-role-cell'>
                      {row.ResponseCountByJob.SupportStaffWorkingOutside > 4
                        ? row.ResponseCountByJob.SupportStaffWorkingOutside +
                          (' (' +
                            (
                              (row.ResponseCountByJob
                                .SupportStaffWorkingOutside /
                                row.ResponseCountByJob
                                  .totalSupportStaffNonClassRoom) *
                              100
                            ).toFixed(2) +
                            '%)')
                        : '-'}
                    </div>
                    <div className='job-role-cell'>
                      {row.ResponseCountByJob.TeacherWorkingInside > 4
                        ? row.ResponseCountByJob.TeacherWorkingInside +
                          (' (' +
                            (
                              (row.ResponseCountByJob.TeacherWorkingInside /
                                row.ResponseCountByJob
                                  .totalTeachingStaffClassRoom) *
                              100
                            ).toFixed(2) +
                            '%)')
                        : '-'}
                    </div>
                    <div className='job-role-cell'>
                      {row.ResponseCountByJob.TeacherWorkingOutside > 4
                        ? row.ResponseCountByJob.TeacherWorkingOutside +
                          (' (' +
                            (
                              (row.ResponseCountByJob.TeacherWorkingOutside /
                                row.ResponseCountByJob
                                  .totalTeachingStaffNonClassRoom) *
                              100
                            ).toFixed(2) +
                            '%)')
                        : '-'}
                    </div>
                    <div className='job-role-cell'>
                      {row.ResponseCountByJob.MiddleLeader > 4
                        ? row.ResponseCountByJob.MiddleLeader +
                          (' (' +
                            (
                              (row.ResponseCountByJob.MiddleLeader /
                                row.ResponseCountByJob.totalMiddlerLeader) *
                              100
                            ).toFixed(2) +
                            '%)')
                        : '-'}
                    </div>
                    <div className='job-role-cell'>
                      {row.ResponseCountByJob.SeniorLeader > 4
                        ? row.ResponseCountByJob.SeniorLeader +
                          (' (' +
                            (
                              (row.ResponseCountByJob.SeniorLeader /
                                row.ResponseCountByJob.totalLeader) *
                              100
                            ).toFixed(2) +
                            '%)')
                        : '-'}
                    </div>
                    <div className='job-role-cell'>
                      {row.NoOfStaffParticipated > 0
                        ? row.NoOfStaffParticipated +
                          (' (' +
                            (
                              (row.NoOfStaffParticipated /
                                row.TotalSchoolStaffSurveySent) *
                              100
                            ).toFixed(2) +
                            '%)')
                        : '-'}
                    </div>
                  </div>
                );
              })}
              <div className='job-role-container'>
                <div className='job-role-title-cell'>Total</div>
                <div className='job-role-cell'>
                  {props.SupportStaffInsideCount > 0
                    ? props.SupportStaffInsideCount +
                      ' (' +
                      (
                        (props.SupportStaffInsideCount /
                          props?.reportData?.TotalSupportStaffInside) *
                        100
                      ).toFixed(2) +
                      '%)'
                    : '-'}
                </div>
                <div className='job-role-cell'>
                  {props.SupportStaffOutsideCount > 0
                    ? props.SupportStaffOutsideCount +
                      ' (' +
                      (
                        (props.SupportStaffOutsideCount /
                          props?.reportData?.TotalSupportStaffOutside) *
                        100
                      ).toFixed(2) +
                      '%)'
                    : '-'}
                </div>
                <div className='job-role-cell'>
                  {props.TeachingStaffInsideCount > 0
                    ? props.TeachingStaffInsideCount +
                      ' (' +
                      (
                        (props.TeachingStaffInsideCount /
                          props?.reportData?.TotalTeachingStaffInside) *
                        100
                      ).toFixed(2) +
                      '%)'
                    : '-'}
                </div>
                <div className='job-role-cell'>
                  {props.TeachingStaffOutsideCount > 0
                    ? props.TeachingStaffOutsideCount +
                      ' (' +
                      (
                        (props.TeachingStaffOutsideCount /
                          props?.reportData?.TotalTeachingStaffOutside) *
                        100
                      ).toFixed(2) +
                      '%)'
                    : '-'}
                </div>
                <div className='job-role-cell'>
                  {props.MiddleLeaderCount > 0
                    ? props.MiddleLeaderCount +
                      ' (' +
                      (
                        (props.MiddleLeaderCount /
                          props?.reportData?.TotalMiddleLeader) *
                        100
                      ).toFixed(2) +
                      '%)'
                    : '-'}
                </div>
                <div className='job-role-cell'>
                  {props.SeniorLearderCount > 0
                    ? props.SeniorLearderCount +
                      ' (' +
                      (
                        (props.SeniorLearderCount /
                          props?.reportData?.TotalSeniorLearder) *
                        100
                      ).toFixed(2) +
                      '%)'
                    : '-'}
                </div>
                <div className='job-role-cell'>
                  {props.NoOfStaffParticipated > 0
                    ? props.NoOfStaffParticipated +
                      ' (' +
                      (
                        (props.NoOfStaffParticipated / props.TotalMatStaff) *
                        100
                      ).toFixed(2) +
                      '%)'
                    : '-'}
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default StaffParticipSummaryPpt;
