import styled from 'styled-components';
import { colors } from '../.././resources/theme/colors';
import { media } from 'styled-bootstrap-grid';
import { MediaQuery } from '../../resources/styling/appStyle';
import { RegisterInnerMain } from '../../resources/styling';

const {
  pictonBlue, barleyWhite, purple
} = colors;

/*** Request Assistance ***/
export const RequestAssistanceWrapper = styled.div `
    .containerClass {
      padding-top: 0;
      width: 100%;
      max-width: 100%;
    }
    float: left;
    width: 100%;

    ${media.xs`
      float: none;
    `}
    ${media.sm`
      float: none;
    `}
`;
export const RequestAssistanceInnerMain = styled(RegisterInnerMain)`
  min-height: auto;
`;
export const RequestAssistanceInner = styled.div `
    display: table-cell;
    vertical-align: middle;
`;
export const AssitanceForm = styled.div `
  
  label {
      color: ${pictonBlue};
  }
  .floating-input,
    .floating-select {
      border: 2px solid ${pictonBlue};
      color: ${pictonBlue};
      margin-bottom: 0;
    }
  
  .floating-input label {
      color: ${pictonBlue};
  }
  
    .floating-input:focus ~ label,
    .floating-input:not(:placeholder-shown) ~ label {
  
      color: ${pictonBlue};
  
    }
  
    .floating-select:focus ~ label,
    .floating-select:not([value='']):valid ~ label {
  
      color: ${pictonBlue};
  
    }
    margin: auto;    
`;
export const FormWrapper = styled.form `
    padding: 10px;
`;

const OtherSuggestionStyle = {    
    RequestAssistanceWrapper,
    RequestAssistanceInnerMain,
    RequestAssistanceInner,
    AssitanceForm,
    FormWrapper
}
export default OtherSuggestionStyle;