import request from '../request';
import { surveyResultAPI } from './urls';

const getSurveyResult = (surveyToken) => {
  return request({
    url: surveyResultAPI.surveyResult(surveyToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const getHeadTeacherSurveyResult = (surveyToken) => {
  return request({
    url: surveyResultAPI.headTeacherSurveyResult(surveyToken),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const ReSendSurveyInvitation = (model) => {
  return request({
    url: surveyResultAPI.reSendSurveyInvitation(),
    method: 'POST',
    data:model
  })
    .then((response) => response)
    .catch((response) => response);
};

const GetPDFSurveyReport = (surveyToken, schoolToken) => {
  return request({
    url: surveyResultAPI.GetPDFSurveyReport(surveyToken, schoolToken),
    method: 'GET',
    responseType:'blob'
  })
    .then((response) => response)
    .catch((error) => error);
};

const surveyResultService = {
  getSurveyResult,
  getHeadTeacherSurveyResult,
  GetPDFSurveyReport,
  reSendSurveyInvitation: ReSendSurveyInvitation,
};

export default surveyResultService;
