import React from 'react';
import {
  CompareToStaffInOtherSchools,
  CompareToStaffInOtherOrganisations,
  ComparativeStrengths,
  NoStrengthsTextSchool,
  NoStrengthsTextHse,
} from '../../views/surveyReport/Constants';
import { useTranslation } from 'react-i18next';

const ComparativeStrength = ({ SchoolStrengths, HseStrengths }) => {
  const { t } = useTranslation();
  return (
    <div>
      {SchoolStrengths[0] !== NoStrengthsTextSchool ||
      HseStrengths[0] !== NoStrengthsTextHse ? (
        <div>
          <h3 className='undeline-title report-list-wrapper'>
            {ComparativeStrengths}
          </h3>
          <p className='sub-heading report-list-wrapper'>
            {CompareToStaffInOtherSchools}
          </p>
          <ul className='report-list-wrapper'>
            {SchoolStrengths.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
          <p className='sub-heading report-list-wrapper'>
            {t('CompareToStaffInOtherOrganisations')}
          </p>
          <ul className='report-list-wrapper'>
            {HseStrengths.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
export default ComparativeStrength;
