import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { colors } from '../../resources/theme';
import { SingleSurveyStandard } from '../../resources/styling/appStyle';
import AssociatedActions from './AssociatedActions';
import { CalculationsStyle } from './DashboardStyle';
const { mineShaft } = colors;



class SingleSurveyManagementStandard extends Component {
  constructor(props) {
      super(props);
    }

  render() {
    const { individualResults, catogorySurveyAverage ,isAllowed=false} = this.props;
    return (
      <SingleSurveyStandard
        className="inner-sub-container"
        value={`${
          catogorySurveyAverage != null
            ? catogorySurveyAverage.CategoryColorName
            : mineShaft
        }`}
        >
        <div className="survey-progress-action-wrapper">
          <div className="progress-chart ">
            <h3>
              {catogorySurveyAverage !== null
                ? catogorySurveyAverage.CategoryName
                : ''}
            </h3>
            <span className="header-text">
              {individualResults !== null
                ? individualResults.CategoryTitle
                : ''}
            </span>
            <CalculationsStyle>
              <ul className="all-score">
                <li>
                    {catogorySurveyAverage !== null
                        ? catogorySurveyAverage.OverallCategoryAverage
                        : ''}
                    <span>Overall Score</span>
                </li>
                {(catogorySurveyAverage !== null && catogorySurveyAverage.Category === 8) ? null : (
                <li>
                  {isAllowed ?(catogorySurveyAverage !== null ? catogorySurveyAverage.SchoolDecilesCategoryAverage 
                    : ''):'---'}
                    <span>School <br /> Decile</span>
                </li>
                )}
                {(catogorySurveyAverage !== null && catogorySurveyAverage.Category === 8) ? null : (
                <li>
                  {isAllowed ? (catogorySurveyAverage !== null ? catogorySurveyAverage.HseDecilesCategoryAverage
                    : '') : '---'}
                    <span>HSE <br />Decile</span>
                </li>
                )}
                {isAllowed ? (<li>
                    {catogorySurveyAverage !== null
                        ? catogorySurveyAverage.SupportStaffCategoryAverage
                        : ''}
                    <span>Support Staff</span>
                </li>) : ''}
                {isAllowed ? (
                <li>
                  {catogorySurveyAverage !== null ? catogorySurveyAverage.TeachingStaffCategoryAverage : ''}
                  <span>Teaching Staff</span>
                </li>):''}
              </ul>
            </CalculationsStyle>
          </div>
          <AssociatedActions/>
        </div>    {!isAllowed ? (<b className='header-text' style={{ paddingBottom: '20px' }} >Upgrade your account to display your comparative scores against the School and Health and Safety Executive benchmarks for each question.  The table of scores below will also show your comparative scores against Schools as a heatmap.
                    </b>) : (<></>)}
      </SingleSurveyStandard>
    );
  }
}

export default withRouter(SingleSurveyManagementStandard);
