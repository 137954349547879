import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import AnalyticsLineChart from '../../components/Charts/MATAnalyticsLineChart';
import AnalyticsBarChart from '../../components/Charts/AnalyticsBarChart';
import { AnalyticsChartWrapper, ChartWrapperError, AnalyticsChartPopupWrapper } from '../../resources/styling/appStyle';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { ShowChartType } from '../../utils/Constants';
import GraphPopup from '../dashboard/GraphPopup';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
export default function CharateristicsTypeAnalytics(props) {
    const [isPopupShowing, setisPopupShowing] = useState(false);
    const [characteristicsTypeValue, setcharacteristicsTypeValue] = useState(0);
    const showGraphPopup = (id) => {
        setcharacteristicsTypeValue(id);
        setisPopupShowing(true);
    };
    const closePopup = () => {
        setcharacteristicsTypeValue(0);
        setisPopupShowing(false);
    };
console.log('CharateristicsTypeAnalytics props', props.pageColumnSize);
    return (
        <Box sx={{ width: '100%' }}>
            <div className='mb-2'>
                <h5 className='d-inline-block'>{props.characteristicsTypeLabel}: {props.categoryLabel}</h5>
            </div>
            {props.characteristicsTypeValues.length <= 1 ? (
                <ChartWrapperError>
                    <span className='nodata'>There were insufficient filtered responses to display this information.</span>
                </ChartWrapperError>
            ) : (
                <Grid container columnSpacing={{ xs: 2, md: 2 }} rowSpacing={2} >
                    {props.SearchTypeValue === 0 ? (
                        props.characteristicsTypeValues.filter(x => x.value !== 0).map((item) =>
                            <Grid item md={props.characteristicsTypeValues.length === 2 ? 12 : props.pageColumnSize} xs={12} key={item.value}>
                                <AnalyticsChartWrapper>
                                    <div className='large-btn'>
                                        <Tooltip title="Full Screen View" arrow>
                                            <IconButton
                                                key={item.value} onClick={() => showGraphPopup(item.value)}
                                            > <FullscreenIcon class="large-graph-btn d-inline-block" fontSize='inherit' /></IconButton>
                                        </Tooltip>
                                    </div>
                                    {props.chartType === ShowChartType.LineChart &&
                                        <AnalyticsLineChart datasets={props.datasets.filter(x => x.characteristicsType === item.value).map(data => (data))}
                                            datalables={props.datasets.filter(x => x.characteristicsType === item.value).map(data => (data.labels))[0]} Chartlabel={item.label}
                                            colorValue={props.colorsValue}
                                            height={props.characteristicsTypeValues.length === 2 ? '110' : '200'}
                                           
                                            id={item.value} />
                                    }
                                    {props.chartType === ShowChartType.BarChart &&
                                        <AnalyticsBarChart datasets={props.datasets.filter(x => x.characteristicsType === item.value).map(data => (data))}
                                            datalables={props.datasets.filter(x => x.characteristicsType === item.value).map(data => (data.labels))[0]}
                                            Chartlabel={item.label}
                                            colorValue={props.colorsValue}
                                            height={props.characteristicsTypeValues.length === 2 ? '110' : '120'}
                                            mainTitle={props.mainTitle}
                                            id={item.value}                                            
                                        />
                                    }
                                </AnalyticsChartWrapper>
                            </Grid>
                        )
                    ) : (
                        <Grid item md={props.pageColumnSize} xs={12}>
                            <AnalyticsChartWrapper>
                                <div className='large-btn'>
                                    <Tooltip title="Full Screen View" arrow>
                                        <IconButton
                                            onClick={() => showGraphPopup(props.SearchTypeValue)}
                                        > <FullscreenIcon class="large-graph-btn d-inline-block" fontSize='inherit' /></IconButton>
                                    </Tooltip>
                                </div>
                                {props.chartType === ShowChartType.LineChart &&
                                    <AnalyticsLineChart datasets={props.datasets.filter(x => x.characteristicsType === props.SearchTypeValue).map(data => (data))}
                                        datalables={props.datasets.filter(x => x.characteristicsType === props.SearchTypeValue).map(data => (data.labels))[0]}
                                        Chartlabel={props.characteristicsTypeValues.filter(x => x.value === props.SearchTypeValue).map((x => x.label))}
                                        colorValue={props.colorsValue}
                                        
                                        height={'110'}
                                        id={props.SearchTypeValue} />
                                }
                                {props.chartType === ShowChartType.BarChart &&
                                    <AnalyticsBarChart datasets={props.datasets.filter(x => x.characteristicsType === props.SearchTypeValue).map(data => (data))}
                                        datalables={props.datasets.filter(x => x.characteristicsType === props.SearchTypeValue).map(data => (data.labels))[0]}
                                        Chartlabel={props.characteristicsTypeValues.filter(x => x.value === props.SearchTypeValue).map((x => x.label))}
                                        colorValue={props.colorsValue}
                                        mainTitle={props.mainTitle}
                                        height={'110'}
                                        id={props.SearchTypeValue}
                                    />
                                }
                            </AnalyticsChartWrapper>
                        </Grid>)}
                    {props.children}
                </Grid>
            )}

            {isPopupShowing ? (
                <GraphPopup>
                    <div className='graph-container' id='large-graph'>
                        <div className='redCrossButtom'>
                            <CloseIcon className='closeIcon' onClick={closePopup} />
                        </div>
                        <Grid item md={12} xs={12}>
                            <AnalyticsChartPopupWrapper>
                                {props.chartType === ShowChartType.LineChart &&
                                    <AnalyticsLineChart datasets={props.datasets.filter(x => x.characteristicsType === characteristicsTypeValue).map(data => (data))}
                                        Chartlabel={props.characteristicsTypeValues.filter(x => x.value === characteristicsTypeValue).map((x => x.label))}
                                        colorValue={props.colorsValue}
                                        datalables={props.datasets.filter(x => x.characteristicsType === characteristicsTypeValue).map(data => (data.labels))[0]}
                                        height={'110'}
                                       
                                        id={characteristicsTypeValue} />
                                }
                                {props.chartType === ShowChartType.BarChart &&
                                    <AnalyticsBarChart datasets={props.datasets.filter(x => x.characteristicsType === characteristicsTypeValue).map(data => (data))}
                                        Chartlabel={props.characteristicsTypeValues.filter(x => x.value === characteristicsTypeValue).map((x => x.label))}
                                        colorValue={props.colorsValue}
                                        datalables={props.datasets.filter(x => x.characteristicsType === characteristicsTypeValue).map(data => (data.labels))[0]}
                                        height={'110'}
                                        mainTitle={props.mainTitle}
                                        id={characteristicsTypeValue}
                                    />
                                }
                            </AnalyticsChartPopupWrapper>
                        </Grid>
                    </div>
                </GraphPopup>
            ) : (
                ''
            )}
        </Box>
    );
}