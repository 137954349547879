import React from 'react';
import {
  WrapperStyle,
  InnerWrapperStyle,
  ManagementStandardStyle,
  RiskFactorsStyle
} from '../../resources/styling/otherSuggestionStyle';
import { useTranslation } from 'react-i18next';
const SupportSuggestion = () => {
  const {t} = useTranslation();
  return (
    <div>
      <WrapperStyle>
        <InnerWrapperStyle>
          <h2>Further actions to address risks to your management standards</h2>
          <h3>3. Support</h3>
          <h3>{t('managerCW')}</h3>
          <ManagementStandardStyle>
            <li>
              Staff indicate they do not feel encouraged by their {t('linemanager')}
              when compared to all {t('organisations')} provides the opportunity for a
              quick win. Focus leaders and {t('linemanagers')} on giving more regular
              and authentic praise and recognition and making sure there is more
              positive than negative feedback.
              <p>
                While praise must be justified, schools ten to focus more on
                what has gone wrong (the pupils misbehaving, the not so good
                lesson, the poor performing staff member and what hasn't been
                done) and on occasions take those performing well for granted.
                As a result, some staff sometimes feel whatever they do it is
                never enough.
              </p>
              <p>
                Building a 'praise and recognition' culture will improve this
                area and further improve wellbeing across the Trust. Asking
                leaders to set themselves a goal each day of catching people
                doing things right is a good way to start. Can they spot three
                things every day?
              </p>
              <p>
                While schools often have more formal processes for those going
                beyond what is expected or delivering something significant, for
                example praising them in a briefing or a thank you card, it is
                more about busy leaders noticing this in the moment and having
                their heads up, despite being so busy.
              </p>
              <p>
                When taking further steps it is also important for leaders to
                demonstrate they have gone out of their way - a card to home or
                visiting where they are working is so much more powerful than a
                quick e-mail).
              </p>
            </li>

            <li>
              Leaders need to have regular formal and informal 1 to 1s and time
              spent effectively with their team members. While we know 
              {t('linemanagers')} are very busy, this is a key requirement. They should
              also check in regularly – simply asking ‘how are you’ and
              listening will give significant payback.
              <p>
                They should also plan their ‘visibility’ so team members see
                them around the school and feel they have opportunities to
                engage and provide feedback. Having or developing the right
                skills to manage performance and hold difficult conversations is
                also important.
              </p>
              <p>
                Having or developing the right skills to manage performance and
                hold difficult conversations is also important.
              </p>
            </li>
            <li>
              Leaders regularly demonstrating they know what is going on for
              team members out of school and following up on this is also a good
              {t('behviour')}. For example, if they know a family member is ill, make
              sure leaders diarise and follow up to ask how they are?
            </li>
            <li>
              Make sure leaders are aware of and demonstrate those competencies
              proven to prevent and reduce staff stress (these are available for
              you to use and further details are included in next steps at the
              end of this report). It is often how {t('linemanagers')} approach
              workload, for example how deadlines are set, the empathy they
              show, and how regularly they check in with team members that makes
              a big difference.
            </li>
            <li>
              Establish an effective appraisal and performance management system
              to effectively support and challenge all staff to deliver their
              best and fulfil their potential. This should cover both what staff
              deliver and how they deliver it as well as understanding their
              ambition and development needs.
              <p>
                It has to be an ongoing process, rather than annual and needs
                leaders and their team members to fully participate. This is an
                integral part of any people strategy and will underpin all
                aspects of a staff members lifecycle, from attraction through to
                farewell.
              </p>
              <p>
                Further details and advice can be provided on establishing an
                effective process, if one is not in place.
              </p>
            </li>
          </ManagementStandardStyle>
          <h3>Peers</h3>
          <ManagementStandardStyle>
            <li>
              Supportive colleagues that create a collaborative workplace
              enhance their and others wellbeing and deliver higher performance.
              Yet you can’t always rely on individuals to work well together and
              when there are challenges or high demands this can show up through
              lower peer to peer support. Steps you can take as a leadership
              team include:
              <div class="secondaryList">
                <RiskFactorsStyle>
                  <li>
                    Making it part of your performance management process and
                    objectives for individuals.
                  </li>
                  <li>
                    Rewarding the supportive {t('behviour')} you want to see. This
                    could be as simple as praising it in the moment and
                    recognising it during team or other briefings and meetings.
                  </li>
                  <li>
                    Having a mechanism for sharing or shouting this out, such as
                    a board where staff members highlight others that have
                    supported or helped them.
                  </li>
                  <li>
                    Building it into the school’s values and being clear on the
                    {t('behviours')}s that will be seen each day, for example
                    developing {t('behviours')}s around ‘pay-it-forward’.
                  </li>
                  <li>
                    Identify any obstacles that could be getting in the way.
                  </li>
                  <li>
                    Encourage team interaction both within the school and in
                    creating opportunities for staff to socialise outside
                    school.
                  </li>
                </RiskFactorsStyle>
              </div>
            </li>

            <li>
              Improving the respect staff show each other and how they listen to
              one another has clear links to the school values. You should
              revisit your values and it is important to define the {t('behviours')}
              that should be seen each day if these are to be delivered. For
              respect these are likely to include staff:
              <div class="secondaryList">
                <RiskFactorsStyle>
                  <li>
                    Being good mannered, tolerant, showing patience and
                    understanding.
                  </li>
                  <li>Meeting deadlines.</li>
                  <li>Attending meetings, prepared and on time.</li>
                  <li>Meetings starting and finishing on time.</li>
                  <li>Meetings starting and finishing on time.</li>
                  <li>
                    Effectively and actively listening to one another and being
                    prepared to listen to the views of others.
                  </li>
                  <li>Not starting or repeating gossip about others.</li>
                  <li>Remaining calm, even when annoyed.</li>
                  <li>
                    Having empathy and accepting everyone has an important role
                    to play.
                  </li>
                </RiskFactorsStyle>
              </div>
            </li>

            <p>
              By defining and agreeing these, {t('linemanagers')} and staff can then
              praise those delivering against them, while holding those to
              account who behave in contravention of them.
            </p>
          </ManagementStandardStyle>
        </InnerWrapperStyle>
      </WrapperStyle>
    </div>
  );
};

export default SupportSuggestion;
