import React from 'react';
import { AppLogo } from '../../../components';
import { colors } from '../../../resources/theme';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../MatStyle';

const MathomePpt = (props) => {
	return (
		<SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
			<MatReportWrapper>
				<div className='mat-presentation-section'>
					<div className='left-section'>						
						<span className='matlogo'>
							<img src={props.matLogo} alt=""/>
						</span>
						<h1>{props.SchoolName} {props.surveydate}</h1>
						
						<span className='app-logo'><img src={props.welbeeslideslogo} alt=""/></span>
					</div>
				</div>
			</MatReportWrapper>
		</SurveyGreetingPresentationStyle>
	);
};

export default MathomePpt;
