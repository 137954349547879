import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { routesObj, ProtectedRoute } from '../routes';
import { colors } from '../resources/theme/colors';
import { PageWrapper } from '../resources/styling/appStyle';
import { BaseCSS } from 'styled-bootstrap-grid';
import AuthHOC from '../components/hoc/AuthHOC';
import { UserGuideSurveyLink, UserGuideVoiceLink } from '../utils/Constants';
import { storageService } from '../services';
import { WebStorageNames } from '../utils';

const { pictonBlue, mineShaft, whiteColor } = colors;

const MainWrapper = styled.div`
  label {
    color: ${pictonBlue} !important;
  }
  input::-webkit-input-placeholder {
    color: ${pictonBlue} !important;
  }
  .floating-input,
  .floating-select {
    border: 1px solid ${pictonBlue} !important;
    color: ${mineShaft} !important;
    margin-bottom: 0;
    background: ${whiteColor};
  }

  .floating-input:focus ~ label,
  .floating-input:not(:placeholder-shown) ~ label {
    color: ${pictonBlue} !important;
  }

  .floating-select:focus ~ label,
  .floating-select:not([value='']):valid ~ label {
    color: ${pictonBlue};
  }
  > div {
    > div {
      max-height: 100vh !important;
      /*overflow-x: hidden !important; */
    }
  }
`;

const customCSS = `
@-ms-viewport {
  width: device-width;
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
.main-outer-wrapper {
  float: left;
  width: 100%;
}

.welcome-content{
  display: flex;
  align-items: center;
}
.school-name {
  font-weight: 600;
  color: ${pictonBlue} !important;
}
.mobile-wrapper {
  padding: 0;
  padding-bottom: 3.5rem;

}
`;

class Routes extends Component {
  render() {
    return (
      <MainWrapper>
        <Router>
          <Switch>
            {/* ***************** Public Routes */}
            <PublicRoute
              exact
              path={routesObj.Root.path}
              render={() => routesObj.Login.component}
            />
            <PublicRoute
              exact
              path={routesObj.NotSubscribed.path}
              render={() => routesObj.NotSubscribed.component}
            />
            <PublicRoute
              exact
              path={routesObj.Login.path}
              render={() => routesObj.Login.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.Survey.path}
              render={() => routesObj.Survey.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SurveyWelcome.path}
              render={() => routesObj.SurveyWelcome.component}
            />
            {/* <PublicRoute
              exact
              path={routesObj.Register.path}
              render={() => routesObj.Register.component}
            /> */}
            <PublicRoute
              exact
              path={routesObj.StaffRegister.path}
              render={() => routesObj.StaffRegister.component}
            />
            <PublicRoute
              exact
              path={routesObj.ThankYouMessage.path}
              render={() => routesObj.ThankYouMessage.component}
            />
            <PublicRoute
              exact
              path={routesObj.RequestAssistance.path}
              render={() => routesObj.RequestAssistance.component}
              //component={AuthHOC(routesObj.RequestAssistance)}
            />
            <PublicRoute
              exact
              path={routesObj.SurveyComplete.path}
              render={() => routesObj.SurveyComplete.component}
            />
            {/* ****************** Head Teacher Routes */}
            <ProtectedRoute
              exact
              path={routesObj.SurveyResult.path}
              render={() => routesObj.SurveyResult.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SurveyComments.path}
              render={() => routesObj.SurveyComments.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SurveyReport.path}
              render={() => routesObj.SurveyReport.component}
            />
            <PublicRoute
              exact
              path={routesObj.SurveyUserGuide.path}
              component={() => {
                window.location.href = UserGuideSurveyLink;
                return null;
              }}
            />
            <PublicRoute
              exact
              path={routesObj.OtherSuggestion.path}
              render={() => routesObj.OtherSuggestion.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.StaffPresentation.path}
              render={() => routesObj.StaffPresentation.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.Dashboard.path}
              render={() => routesObj.Dashboard.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SchoolDetail.path}
              render={() => routesObj.SchoolDetail.component}
            />
            {/* Super Admin Routes */}
            <ProtectedRoute
              exact
              path={routesObj.MatAccounts.path}
              render={() => routesObj.MatAccounts.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.RegisterMat.path}
              render={() => routesObj.RegisterMat.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.MatCycles.path}
              render={() => routesObj.MatCycles.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.MatCycleDetail.path}
              render={() => routesObj.MatCycleDetail.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.Schools.path}
              render={() => routesObj.Schools.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SchoolSurveyList.path}
              render={() => routesObj.SchoolSurveyList.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.MultiSurveyDashboard.path}
              render={() => routesObj.MultiSurveyDashboard.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.ActionPlan.path}
              render={() => routesObj.ActionPlan.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.ManagementStandards.path}
              render={() => routesObj.ManagementStandards.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.GroupManagementStandards.path}
              render={() => routesObj.GroupManagementStandards.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.ForgotPassword.path}
              render={() => routesObj.ForgotPassword.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.ResetPassword.path}
              render={() => routesObj.ResetPassword.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.ForgotPassword.path}
              render={() => routesObj.ForgotPassword.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.ResetPassword.path}
              render={() => routesObj.ResetPassword.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.HeadTeacherSurveyResult.path}
              render={() => routesObj.HeadTeacherSurveyResult.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SuperAdminDashboard.path}
              render={() => routesObj.SuperAdminDashboard.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SchoolDetails.path}
              render={() => routesObj.SchoolDetails.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.Account.path}
              render={() => routesObj.Account.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SurveyCommentsReply.path}
              render={() => routesObj.SurveyCommentsReply.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.HeadTeacherCommentsReply.path}
              render={() => routesObj.HeadTeacherCommentsReply.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.StaffResults.path}
              render={() => routesObj.StaffResults.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.StaffRoleHeatmap.path}
              render={() => routesObj.StaffRoleHeatmap.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.VoiceDashboard.path}
              render={() => routesObj.VoiceDashboard.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.WelbeeVoice.path}
              render={() => routesObj.WelbeeVoice.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.HeadteacherVoiceConversation.path}
              render={() => routesObj.HeadteacherVoiceConversation.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.StaffVoiceConversation.path}
              render={() => routesObj.StaffVoiceConversation.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SurveyVoiceStaffReply.path}
              render={() => routesObj.SurveyVoiceStaffReply.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.VoiceUpgrade.path}
              render={() => routesObj.VoiceUpgrade.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.VoiceUserGuide.path}
              component={() => {
                var win = window.open(UserGuideVoiceLink, '_blank');
                win.focus();
                return null;
              }}
            />
            <ProtectedRoute
              exact
              path={routesObj.StaffManagement.path}
              render={() => routesObj.StaffManagement.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.MatAccount.path}
              render={() => routesObj.MatAccount.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.WelbeeVoiceNotification.path}
              render={() => routesObj.WelbeeVoiceNotification.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.AdminDashboard.path}
              render={() => routesObj.AdminDashboard.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SchoolDetailForm.path}
              render={() => routesObj.SchoolDetailForm.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SurveyPremiumUrl.path}
              render={() => routesObj.SurveyPremiumUrl.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SurveyProUrl.path}
              render={() => routesObj.SurveyProUrl.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.VoiceProUrl.path}
              render={() => routesObj.VoiceProUrl.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SurveyFreeUrl.path}
              render={() => routesObj.SurveyFreeUrl.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.VoiceFreeUrl.path}
              render={() => routesObj.VoiceFreeUrl.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.GroupDashboard.path}
              render={() => routesObj.GroupDashboard.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.RegisterDirector.path}
              render={() => routesObj.RegisterDirector.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.MatSchools.path}
              render={() => routesObj.MatSchools.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.MatHeatMap.path}
              render={() => routesObj.MatHeatMap.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.EditMat.path}
              render={() => routesObj.EditMat.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.MatPresentation.path}
              render={() => routesObj.MatPresentation.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.MatStaffManagement.path}
              render={() => routesObj.MatStaffManagement.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.MatReport.path}
              render={() => routesObj.MatReport.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SummaryReport.path}
              render={() => routesObj.SummaryReport.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.AccountRegistrations.path}
              render={() => routesObj.AccountRegistrations.component}
            />

            <ProtectedRoute
              exact
              path={routesObj.AdditionalQuestions.path}
              render={() => routesObj.AdditionalQuestions.component}
            />

            <ProtectedRoute
              exact
              path={routesObj.SubscriptionNotAvailable.path}
              render={() => routesObj.SubscriptionNotAvailable.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.Invoices.path}
              render={() => routesObj.Invoices.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SchoolAnalytics.path}
              render={() => routesObj.SchoolAnalytics.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.MATAnalytics.path}
              render={() => routesObj.MATAnalytics.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.AccountSubscription.path}
              render={() => routesObj.AccountSubscription.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SentEmails.path}
              render={() => routesObj.SentEmails.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SchoolSubscriptions.path}
              render={() => routesObj.SchoolSubscriptions.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.MatAdditionalQuestions.path}
              render={() => routesObj.MatAdditionalQuestions.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.FilterLibrary.path}
              render={() => routesObj.FilterLibrary.component}
            />
            <PublicRoute
              exact
              path={routesObj.LoginRedirect.path}
              render={() => routesObj.LoginRedirect.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.HomeRedirect.path}
              render={() => routesObj.HomeRedirect.component}
            />
            <ProtectedRoute
              exact
              path={routesObj.SuperDashboard.path}
              render={() => routesObj.SuperDashboard.component}
            />
            <PublicRoute exact render={() => routesObj.NotFound.component} />
          </Switch>
        </Router>
      </MainWrapper>
    );
  }
}
const PublicRoute = (props) => {
  const skipStorage = ["/login", "/login-redirect"];
  if (props.location && props.location.search && !skipStorage.includes(props.location.pathname)) {
    var url = props.location.pathname + props.location.search;
    storageService.setItem(WebStorageNames.SuperadminRedirectUrl, url);
  }
  return (
    <PageWrapper>
      <BaseCSS css={customCSS} />
      <Route {...props} />
    </PageWrapper>
  );
};

export default Routes;
