import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    borderColor: '#45b1e8 !important;',
  },
  select: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Avenir LT Pro',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#53B5E0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#53B5E0',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#53B5E0',
    },
  },  
}));
const MuiDropDown = (props) => {
  const classes = useStyles();
  const { id, labelId, name, label, value, onChange } = props;
  return (
    <FormControl className={classes.formControl}>     
      <InputLabel id={labelId}>
        {label}
      </InputLabel>
      <Select          
        className={classes.select}
        labelId={labelId}
        id={id}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
      >
        {props.children}
      </Select>      
    </FormControl>
  );
};

export default MuiDropDown;
