import React from 'react';
import {
  WrapperStyle,
  InnerWrapperStyle,
  ManagementStandardStyle,
  RiskFactorsStyle,
} from '../../resources/styling/otherSuggestionStyle';
import { useTranslation } from 'react-i18next';

const ControlSuggestion = () => {
  const { t } = useTranslation();
  return (
    <div>
      <WrapperStyle>
        <InnerWrapperStyle>
          <h2>Further actions to address risks to your management standards</h2>
          <h3>2. Control</h3>
          <ManagementStandardStyle>
            <li>
              Helping staff feel they have greater choice is supported by
              leaders adopting a coaching and more consultative leadership
              style.
              <p>
                We are not suggesting that {t('linemanagers')} become ‘coaches’
                as this isn’t their role, though if they understand and can
                apply the principles of coaching it will enhance their
                leadership and when done well leads to greater team wellbeing,
                performance and results.
              </p>
              <p>
                Coaching in education tends to be used more as a remedial
                activity, whereas in other sectors it is more usually linked
                with better performers.
              </p>
              <p>
                Yet leaders who coach understand the benefits to all team
                members. They are also aware of the impact on staff between when
                they ‘tell’ and ‘ask’ and the long term difference of providing
                solutions rather than seeking to grow people by asking them to
                find their own.
              </p>
              <p>
                A lack of time is often given as the reason why instruction is
                predominantly used. While it is true that it can take more time
                to ask a question rather than provide an answer, in the long
                term it will save a lot of time if all staff can think
                independently and are able to solve more of their own
                challenges.
              </p>
              <p>
                It does take training and {t('practise')} to become effective
                and an investment in this area is one that will provide a
                significant return. If you would like to know more about this
                then we can provide information.
              </p>
            </li>
            <li>
              Most staff are unable to choose when to take a break, and this is
              particularly true for those in the classroom. The most important
              focus should be on helping to make sure that staff do take a break
              as without these there is likely to be an impact on their health
              and performance.
              <p>
                This means {t('linemanagers')} being role models and through 1
                to 1s coaching their team to ensure they are taking enough
                opportunities to switch tasks and recharge each day.
              </p>
              <p>
                Ensuring breaks are long enough and coffee/tea is made and ready
                at break times and clearing up is done for staff will also help
                ensure staff do take a needed break.
              </p>
            </li>
            <li>
              You will already provide choice for staff by providing
              self-directed time, and if you are able to increase the amount
              provided this will help.
              <p>
                However, often workload dictates how they use the time available
                and even where they have choice, staff may not acknowledge this.
                You should take opportunities when communicating and in 1 to 1s
                to regularly let them know about any flexibility and choices
                they have.
              </p>
              <p>
                In addition if you do provide additional opportunities then
                think carefully how to name them. As an example a number of
                schools we work with provide one day’s time for planning and
                preparation during exam times.
              </p>
              <p>
                They changed the name from a planning and preparation day to a
                ‘staff day’ and allowed staff to choose what to do, including
                not coming into school.
              </p>
              <p>
                They have found that a majority of staff continue to do planning
                and preparation but it is now their choice.
              </p>
            </li>
            <li>
              Where possible, give staff some choice over events, meetings and
              activities that they should attend and make sure that expectations
              on staff are fair and equally spread. This also means that
              meetings and extra activities should only involve those needed to
              be there and if there is an impact from holding them.
              <p>
                Review the meetings and events that are held in your school.
                Below are some good practices you can use:
              </p>
              <div class='secondaryList'>
                <RiskFactorsStyle>
                  <li>
                    Is this the best way to deliver what is needed or
                    communicate information or could this be done in other ways?
                  </li>
                  <li>Does everyone who is attending need to be there?</li>
                  <li>
                    Is there a clear reason for attendance and what outcome do
                    we want?
                  </li>
                  <li>
                    Is the meeting or event going to cover what is needed,
                    effectively and in the time available?
                  </li>
                  <li>
                    Was the intended outcome delivered? Asking this is often a
                    good way to finish and to take any learning into future
                    meetings and events.
                  </li>

                  <li>
                    Did the meeting or event start and finish on time? As well
                    as being good manners, this is important for people who have
                    planned their workload or have other plans.
                  </li>
                  <li>
                    Is everyone prepared for the meeting or event? This means
                    that any important information needs to be distributed in
                    advance and in a succinct but effective way and staff need
                    the time to review it.
                  </li>
                </RiskFactorsStyle>
              </div>
            </li>

            <li>
              Provide more opportunities for flexible working. Depending on the
              role of staff this might mean allowing them to:
              <div class='secondaryList'>
                <RiskFactorsStyle>
                  <li>Work different patterns.</li>
                  <li>
                    Work from home on some occasions and particularly if they
                    are out of school to attend something else or for their PPA
                    time.
                  </li>
                  <li>Move to part-time work or job share.</li>
                  <li>
                    It is not always possible to meet requests, including those
                    from staff returning from maternity leave. Yet with current
                    recruitment challenges, increases in flexible working are
                    likely to be significant over the coming five or ten years.{' '}
                  </li>
                </RiskFactorsStyle>
              </div>
              <p>
                Those schools that adopt a more flexible approach are likely to
                see significant benefits. Think about what can be done, rather
                than what can’t.
              </p>
              <p>
                It will need support between staff and has to be delivered
                fairly.
              </p>
            </li>

            <li>
              Ensuring reasons and explanations are provided when staff are not
              consulted or their views are not considered or agreed to. It is
              important to staff that they feel engaged and listened to and even
              when their ideas are not accepted, they are likely to remain
              engaged if they understand why.
            </li>
          </ManagementStandardStyle>
        </InnerWrapperStyle>
      </WrapperStyle>
    </div>
  );
};

export default ControlSuggestion;
