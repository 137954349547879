import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { surveyResultService, storageService } from '../../services';
import { AdminHeader } from '../../components';
import { loaderAction } from '../../store/loaderStore';
import { SubContainer, MainContainer, SurveyResultTables, TableStyle } from '../../resources/styling/appStyle';
import { welbeeDashboardLogo } from '../../resources/images/index';

class HeadTeacherSurveyResult extends Component {
  constructor(props) {
    super(props);
    this.SURVEY_INITIAL_STATE = {
      OverAllAverage: {
        NoOfSurveysCompleted: '',
        NoOfSurveysSupportStaff: '',
        NoOfTeachingStaff: '',
        OverallSurveyAverage: ''
      }
    };
    this.state = {
      surveyResult: this.SURVEY_INITIAL_STATE,
      error: 'Loading...'
    };
  }

  componentWillMount() {
    if (!isEmpty(window.location.search))
      storageService.setItem('surveyToken', window.location.search);
  }

  componentDidMount() {
    const surveyToken = window.location.search;
    const { action } = this.props;
    action.dataLoading(true);
    surveyResultService
      .getHeadTeacherSurveyResult(surveyToken)
      .then(response => {
        const surveyResult = response.data
          ? response.data
          : this.SURVEY_INITIAL_STATE;
        this.setState({
          surveyResult,
          error: response.message
        });
        action.dataLoading(false);
      })
      .catch(response => {
        action.dataLoading(false);
        return response;
      });
  }

  render() {
    const { surveyResult, error } = this.state;
    const OverAllAverage = surveyResult.OverAllAverage;
    return (
      <div>
        <AdminHeader />

        <div>
          <MainContainer>
            <div style={{marginBottom: '10px'}}>
              <img src={welbeeDashboardLogo} alt='' />
            </div>

            <SubContainer>
              <TableStyle>
                <table border='0' cellPadding='0' cellSpacing='0'>
                  <thead>
                    <tr data-test-id='surveyCompletedAverage'>
                      <td>No. of surveys completed</td>
                      <td>No. of Support staff</td>
                      <td>No. of Teaching staff</td>
                    </tr>
                  </thead>
                  {OverAllAverage.NoOfSurveysCompleted !== '' ? (
                    <tbody>
                      <tr>
                        <td>{OverAllAverage.NoOfSurveysCompleted}</td>
                        <td>{OverAllAverage.NoOfSurveysSupportStaff}</td>
                        <td>{OverAllAverage.NoOfTeachingStaff}</td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td>{error}</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </TableStyle>
            </SubContainer>
          </MainContainer>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  action: bindActionCreators(loaderAction, dispatch)
});
export default connect(null, mapDispatchToProps)(HeadTeacherSurveyResult);
