import React, {useRef,useEffect,useState} from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { Tooltip } from '@mui/material';
import { MATCharacteristicsValues } from '../../utils/Constants';
import { border } from '@mui/system';
Chart.register(ChartDataLabels);

const downloadChart = (id) => {
  let chartId = "barChart" + id;
  const imageLink = document.createElement('a');
  const canvas = document.getElementById(chartId);

  if (!canvas) {
    console.error(`Canvas element with id '${chartId}' not found.`);
    return;
  }
  const padding = 10; 

  const newCanvas = document.createElement('canvas');
  const context = newCanvas.getContext('2d');
  newCanvas.width = canvas.width + 2 * padding;
  newCanvas.height = canvas.height + 2 * padding;
  context.fillStyle = '#ffffff'; // Set the background color to white
  context.fillRect(0, 0, newCanvas.width, newCanvas.height);

  context.drawImage(canvas, padding, padding);

  imageLink.download = 'BarChart.png';
  imageLink.href = newCanvas.toDataURL('image/png');

  imageLink.click();
};




const calculateHeight = (value, length) => {
  switch (value) {
    case MATCharacteristicsValues.OverallMATScore:
      return 200;
    case MATCharacteristicsValues.EmploymentTypeAcrossAllSchools:
      return 5;
    case MATCharacteristicsValues.OverallScoresBySchools:
    case MATCharacteristicsValues.JobRoleScoresAcrossAllSchools:
    case MATCharacteristicsValues.TimeAtSchoolAcrossAllSchools:
    case MATCharacteristicsValues.SupportStaffClassroomScoresBySchools:
    case MATCharacteristicsValues.SupportStaffNonClassroomScoresBySchools:
    case MATCharacteristicsValues.TeachingStaffClassroomScoresBySchools:
    case MATCharacteristicsValues.TeachingStaffNonClassroomScoresBySchools:
    case MATCharacteristicsValues.MiddleLeaderScoresBySchools:
    case MATCharacteristicsValues.SeniorLeaderScoresBySchools:
    case MATCharacteristicsValues.StaffWithLessThan1YrServiceScoresBySchools:
    case MATCharacteristicsValues.StaffWithOneToThreeYrsServiceScoresBySchool:
    case MATCharacteristicsValues.StaffWithFourToYearServiceScoresBySchool:
    case MATCharacteristicsValues.StaffWith6To10YrsServiceScoresBySchool:
    case MATCharacteristicsValues.StaffWith11To15YrsServiceScoresBySchool:
    case MATCharacteristicsValues.StaffWith16To20YrsServiceScoresBySchool:
    case MATCharacteristicsValues.StaffWithGreaterThan20YrsServiceScoresBySchool:
    case MATCharacteristicsValues.FullTimeStaffScoresBySchool:
    case MATCharacteristicsValues.PartTimeStaffScoresBySchool:
    case MATCharacteristicsValues.AdditionalFiltersScoresAcrossAllSchools:
    case MATCharacteristicsValues.JobTitle:
    case MATCharacteristicsValues.Department:
    case MATCharacteristicsValues.PhaseOrYear:
      return (12.5 * length) === 100 ? 110 : (12.5 * length);
    default:
      return 200;
  }
};

function AnalyticsStackedBarChart(props) {
  var seriesCount = props?.Count === 2 ? 3 : props.Count;
  const titleValue = props?.Chartlabel;
 const labelLength = props.datalables?.length || 1;
  const dataLabels = props.datalables || [];
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth);
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const Chartheight = calculateHeight(
    props?.CharacteristicValue,
    parseInt(labelLength)
  );
   const modifiedDatasets = [];
   const modifiedLabels = [];

  if (parseInt(props.Count) === 2) {
    props.datasets.forEach((dataset, index) => {
      const modifiedData = [];
      const modifieDecile = [];
      const originalData = dataset.data;
      const originalDecile = dataset.decileData;
      for (let i = 0; i < originalData.length; i++) {
        modifiedData.push(originalData[i]);
        if ((i + 1) % 2 === 0) {
          modifiedData.push(null);
        }
      }

      for (let i = 0; i < originalDecile.length; i++) {
        modifieDecile.push(originalDecile[i]);

        if ((i + 1) % 2 === 0) {
          modifieDecile.push(null);
        }
      }
      modifiedDatasets.push({
        ...dataset,
        data: modifiedData,
        decileData: modifieDecile,
      });
    });

    dataLabels.forEach((label, index) => {
      modifiedLabels.push(label);
      if ((index + 1) % 2 === 0) {
        modifiedLabels.push(null);
      }
    });
  }
   const data = {
    labels: parseInt(props.Count) === 2 ? modifiedLabels : props.datalables,
    datasets: parseInt(props.Count) === 2 ? modifiedDatasets : props.datasets,
   };
  const options = {
    indexAxis: 'y',
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      x: {
        display: false,
        stacked: true,
      },
      y: {
        stacked: true,
        align: 'center',
        grid: {
          display: false,
        },
        ticks: {
          align: 'center',
          callback: function (val, index) {
            const value = index % seriesCount === 0 ? this.getLabelForValue(val) : '';
            return value ? value.split('\n') : value;
          },
          precision: 0,
          stepSize: 0.5,
          color: '#000000',
          font: {
            family: 'Avenir LT Pro',
            weight: 'bold',
          },
        },
      },
    },
    plugins: {
      title: {
        font: {
          size: 14,
          family: 'Avenir LT Pro',
        },
        color: '#000000',
        display: true,
        text: titleValue,
      },
      datalabels: {
        responsive: true,
        color: '#000',
        labels: {
          title: {
            clamp: true,
            anchor: 'start',
            align: 'right',
            color: '#fff',
            font: {
              family: 'Avenir LT Pro',
              weight: 'bold',
              align: 'center',
              size: '12',
            },
            formatter: function (value, context) {
              return value?.toFixed(2);
            },
            padding: {
              right: 10,
            },
          },
          value: {
            clamp: true,
            font: {
              family: 'Avenir LT Pro',
              weight: 'bold',
              align: 'center',
              size: '12',
            },
            align: 'right',
            anchor: 'start',
            color: '#fff',
            formatter: function (value, context) {
              return context.chart.data.datasets[context.datasetIndex]
                .decileData[context.dataIndex];
            },
            padding: {
              right: 66,
            },
          },
        },
      },
      legend: {
        display: true,
        align: isSmallScreen <= 1024 || props?.ppt===true? 'center' :'start',
        position: isSmallScreen <= 1024  || props?.ppt===true? 'top' : 'right',
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label(context) {
            const score = parseFloat(context.formattedValue).toFixed(2);
            return (
              context.chart.data.datasets[context.datasetIndex].label +
              ': ' +
              score +
              ' Decile (' +
              context.chart.data.datasets[context.datasetIndex].decileData[
                context.dataIndex
              ] +
              ')'
            );
          },
        },
      },      
    },
  };
  if (window.innerWidth <= 1024) {
    options.plugins.datalabels.labels.title.font.size = '9'; 
    options.plugins.datalabels.labels.value.font.size = '9';
    options.plugins.datalabels.labels.title.padding.right = 2;
    options.plugins.datalabels.labels.value.padding.right = 43;
  }
  if (isSmallScreen < 1536) {
    options.barPercentage = 0.9;
    options.categoryPercentage = 1.0;
  }else{
    options.barThickness = '30';
    options.maxBarThickness = '30';
    options.barPercentage= 0.98;
    options.categoryPercentage= 1.0;    
  }

  return (
    <>
      {props.children}
      {props.isDownload && (
        <div className='large-download-btn'>
          <Tooltip title='Download Chart' arrow>
            <ArrowCircleDownIcon
              className='large-graph-btn'
              fontSize='inherit'
              onClick={() => downloadChart(props?.id)}
            />
          </Tooltip>
        </div>
      )}

      {props.datasets && props.datalables ? (
        <Bar
          type='bar'
          id={'barChart' + props.id}
          responsive={true}
          height={Chartheight < 50 ? 50 : Chartheight}
          data={data}
          options={options}
        />
      ) : (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default AnalyticsStackedBarChart;
