import React from 'react';
import Chartkick, { LineChart } from 'react-chartkick';
import 'chartkick/chart.js';

export default function ProgressGraph(props) {  

  Chartkick.options = {
   
    min: null,
    height: '500px',
    curve: false,
    download: true,
    discrete: true,
    responsive: true,
    points: 0,
    colors: [
      '#45338c',
      '#e84f6b',
      '#007a7a',
      '#53b5e0',
      '#fd92c2',
      '#ffb205',
      '#ff5939',
      '#E3BDA3',
    ],
    library: {
      plugins: {
        datalabels: {
          display: false,
          color: "black"
        }
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 20
        },
        scales: {
          yAxes: [{
              ticks: {
                precision: 0.5
              }
          }]
        }
      }
    }
  };  

  return (
      <LineChart legend="bottom"
      data={props.progressGraphData}
      max={props.highestScore}
    />
  );
}
