import React, { useState } from 'react';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import {
  AppButton,
  FooterButtonsWrapper,
  ModalInnerWrapper,
  NewSurveyInnerMain,
  NewSurveyInner,
  AssitanceForm,
  NewSurveyWrapper,
} from '../../resources/styling';
import styled from 'styled-components';
import { AppInputField, FloatingInputLabel } from '../../components';
import { colors } from '../../resources/theme/colors';
import Grid from '@mui/material/Grid';
import { WrapperMain } from '../../resources/styling/formStyle';

const { purple, outrageousOrange, pictonBlue, teal, whiteColor, lightGray, blackColor } = colors;

const InputWrapper = styled.form`  
  .mat-options {
    border: 1px solid ${pictonBlue};
    border-radius: 6px;
    float: left;    
    width: 100%;
    padding: 0;
    list-style: none;
    max-height: 150px;
    overflow-y: auto;

    li {
      padding: 10px;
      color: ${blackColor};
      cursor: pointer;
      font-size: 14px;
      &:hover {
        background: ${teal};
        color: ${whiteColor};
      }
    }
  }
`;

const AddSchoolInGroup = ({ isOpen, onClose, onOk, schoolsList = [] }) => {
  const [filteredList, setFilteredList] = useState(schoolsList);
  const [selectedSchoolId, setSelectedSchoolId] = useState(0);
  const [schoolName, setSchoolName] = useState('');
  const [isListShow, setIsListShow] = useState(false);
  function onSchoolNameChange(name) {
    setSchoolName(name);
    setFilteredList(schoolsList.filter((x) => x.Name.toLowerCase().indexOf(name.toLowerCase()) >= 0));
    setIsListShow(name.length > 0);
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{}}
      validationSchema={Yup.object().shape({})}
      render={() => {
        return (
          <AppModel
            open={isOpen}
            onClose={onClose}
            titleText='Add School'
            bodyText={
              <ModalInnerWrapper>
                <NewSurveyWrapper>
                  <Grid container className='form-inner-wrapper'>
                    <Grid item lg={12}
                      sm={12}
                      md={12}
                      xs={12}
                      mt='15px'
                    >
                      <AssitanceForm>
                        <InputWrapper>
                          <WrapperMain>

                            <FloatingInputLabel
                              name='School'
                              label='School Name'
                              autoComplete='new-school'
                              value={schoolName}
                              onChange={(e) => {
                                onSchoolNameChange(e.target.value);
                              }}
                            />
                            {isListShow &&
                              <ul className='options mat-options'>
                                {filteredList.map((schoolObj, index) => {
                                  let className;
                                  if (index === 1) {
                                    //activeOption
                                    className = 'option-active';
                                  }
                                  return (
                                    <li
                                      className={className}
                                      value={schoolObj.Id}
                                      key={schoolObj.Id}
                                      onClick={(e) => {
                                        setSelectedSchoolId(schoolObj.Id);
                                        setSchoolName(schoolObj.Name);
                                        setIsListShow(false);
                                      }}
                                    >
                                      {schoolObj.Name}
                                    </li>
                                  );
                                })}
                              </ul>}
                          </WrapperMain>
                        </InputWrapper>
                      </AssitanceForm>
                    </Grid>
                  </Grid>
                </NewSurveyWrapper>
              </ModalInnerWrapper>
            }
            footer={
              <FooterButtonsWrapper>
                <AppButton
                  color={selectedSchoolId > 0 ? outrageousOrange : lightGray} type='button'
                  onClick={() => {
                    onOk(selectedSchoolId);
                    setSchoolName('');
                  }}
                  disabled={selectedSchoolId === 0}
                  style={{ cursor: selectedSchoolId === 0 ? 'auto' : 'pointer' }}
                >
                  Submit
                </AppButton>
              </FooterButtonsWrapper>
            }
          />
        );
      }}
    />
  );
};

export default AddSchoolInGroup;
