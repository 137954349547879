import request from '../request';
import { AnalyticsAPI,  } from './urls';

export const getSchoolAnalyticsData = (model) => {
  return request({
    url: AnalyticsAPI.GetSchoolAnalyticsData(),
    method: 'POST',
    data: model,
  })
    .then((response) => response)
    .catch((response) => response);
};
  export const getSchoolCharacteristicTypes = (schoolId) => {
    return request({
      url: AnalyticsAPI.GetSchoolCharacteristicTypes(schoolId),
      method: 'GET',
    })
      .then((response) => response)
      .catch((response) => response);
  };
  
  const analyticsService = {
    getSchoolAnalyticsData,
    getSchoolCharacteristicTypes
  };

  export default analyticsService