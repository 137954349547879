import styled from 'styled-components';
import { colors } from '../../../resources/theme/colors';
import { RegisterBackground } from '../../../resources/images/index';
import { MainWrapper, LoginFormInner } from '../../../components/common/loginStyle';

const {
    whiteColor
} = colors;

/*** Upgrade Invoce Left Panel ***/
export const ExtendedMainWrapper = styled(MainWrapper)`
  .welcome-right-panel {
    background: ${whiteColor};
    margin-left: 49%;
  }
  .login-left-panel {
    background: url(${RegisterBackground});
    background-size: 100%;
    background-position: center top;
    align-items: center;
    width: 40%;
    position: fixed;
    height: 100%;
    img {
      /* margin-top: 50%; */
    }
    .login-button {
      position: absolute;
      left: 23px;
      top: 16px;
    }
      button {
        background: transparent;
        box-shadow: none;
        border: 1px solid ${whiteColor};
        padding: 11px 30px;
        font-weight: 400;
      }
    }
  }

`;

/*** Upgrade Price Form ***/
export const ExtendedLoginFormInner = styled(LoginFormInner)`
  width: 95% !important;
`;

const UpgradePriceStyle = {
    ExtendedMainWrapper,
    ExtendedLoginFormInner
}
export default UpgradePriceStyle;