import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AppButton } from '../../../resources/styling';
import { WebStorageNames } from '../../../utils/Constants';
import { isEmpty } from 'lodash';
import { loaderAction } from '../../../store/loaderStore';
import { amplitudeLog } from '../../../utils/CommonFunctions';
import { Header, AnalyticsDropdown } from '../../../components';
import {
  ErrorWrapper,
  FieldWrapper,
  WelbeeTextField,
} from '../../../resources/styling/appStyle';
import { colors } from '../../../resources/theme/colors';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import { withTranslation } from 'react-i18next';
import { ExtendedWrapperMain } from '../../../resources/styling/formStyle';
import { matGroupService, storageService } from '../../../services';

const { mandy } = colors;

class MatAccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      MatRecord: null,
      emailErrorMessage: '',
      duplicateSchoolErrorMessage: '',
      passwordValidation: '',
      IsUpdateEnabled: false,
      matLogoUrl: '',
      groupDirector: {
        firstName: '',
        lastName: '',
        email: '',
      },
    };
    this.splitName = this.splitName.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  splitName = (fileName) => {
    var splitFileName = fileName.split('.');
    var imageName = splitFileName[0];
    var trancateString =
      imageName.length > 23
        ? imageName.substr(imageName, 23, 0) + '...'
        : fileName;
    return trancateString;
  };
  onChangeHandler = (event) => {
    console.log(event.target.files[0]);
  };

  duplicationErrorMeassageHandle = (message) => {
    if (message === 'Email is already in use, please choose another email.') {
      this.setState({
        emailErrorMessage: message,
        duplicateSchoolErrorMessage: '',
      });
    } else if (message === 'Group name already exist.') {
      this.setState({
        duplicateSchoolErrorMessage: message,
        emailErrorMessage: '',
      });
    }
  };

  getGroupDirectorData = (MatRecord) => {
    const userId = storageService.getItem(WebStorageNames.UserInfo)?.Id;
    const user = MatRecord?.GroupDirectors?.find((x) => {
      return (x) => x.UserId === userId;
    })?.User;

    if (user) {
      const { Email, FirstName, LastName } = user;

      this.setState({
        groupDirector: {
          firstName: FirstName,
          lastName: LastName,
          email: Email,
        },
      });
    }
  };

  componentWillReceiveProps(props) {
    if (props.MatRecord) {
      this.getGroupDirectorData(props.MatRecord);
      this.setState({
        MatRecord: props.MatRecord,
        matLogoUrl:
          props.MatRecord && props.MatRecord?.Logo
            ? props.MatRecord?.Logo.FileUrl
            : '',
        isLogoUpdated: false,
      });
    }
  }

  render() {
    const { t, MatRecord } = this.props;
    const { IsUpdateEnabled, matLogoUrl, isLogoUpdated, groupDirector } =
      this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          Id: MatRecord ? MatRecord?.Id : 0,
          GroupName:
            MatRecord && MatRecord?.GroupName ? MatRecord.GroupName : '',
          Name: MatRecord && MatRecord?.Name ? MatRecord.Name : '',
          UserName: MatRecord && MatRecord?.Username ? MatRecord.Username : '',
          FileName: MatRecord
            ? MatRecord?.Logo != null
              ? MatRecord?.Logo.FileName
              : ''
            : '',
          TrancateFileName: MatRecord
            ? MatRecord?.Logo != null
              ? this.splitName(MatRecord?.Logo.FileName)
              : ''
            : '',
          IsLogoUpdate: true,
          Email: groupDirector.email,
          FirstName: groupDirector.firstName,
          LastName: groupDirector.lastName,
        }}
        onSubmit={(values, actions) => {
          const { loaderActions } = this.props;
          matGroupService
            .updateMatUsers(values)
            .then((response) => {
              if (response.success) {
                toast.success(
                  'Group information has been updated successfully.'
                );
                if (values.File) {
                  const logo = `${
                    MatRecord?.Logo.FileUrl.split('?')[0]
                  }?fileName=${response.data}`;

                  this.setState({
                    matLogoUrl: logo,
                    isLogoUpdated: true,
                  });

                  storageService.setItem(WebStorageNames.matLogoUrl, logo);
                  window.location.reload();
                } else {
                  window.location.reload();
                  loaderActions.dataLoading(false);
                }
              }
            })
            .catch((error) => {
              window.location.reload();
              loaderActions.dataLoading(false);
            });
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={Yup.object().shape({
          Name: Yup.string()
            .trim()
            //.max(50, 'School name must be at most 50 characters.')
            .required('Group name is required.'),
        })}
        render={({
          values,
          handleChange,
          touched,
          errors,
          handleSubmit,
          setFieldValue,
        }) => (
          <div>
            <Header />

            <h2> Group information</h2>
            <div>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label={t('GroupName')}
                      name='Group name'
                      value={values.Name}
                      onChange={(e) => {
                        setFieldValue('Name', e.target.value);
                        this.setState({ IsUpdateEnabled: true });
                      }}
                    />
                    <ErrorWrapper>
                      {(touched.Name && errors.Name) ||
                        this.state.duplicateSchoolErrorMessage}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>

                <Grid item md={6} xs={12}>
                  <ExtendedWrapperMain>
                    <WelbeeTextField
                      fullWidth
                      label={t('UserName')}
                      name='User name'
                      value={values.UserName}
                      onChange={(e) => {
                        setFieldValue('UserName', e.target.value);
                        this.setState({ IsUpdateEnabled: true });
                      }}
                    />
                    <ErrorWrapper>
                      {(touched.UserName && errors.UserName) ||
                        this.state.duplicateSchoolErrorMessage}
                    </ErrorWrapper>
                  </ExtendedWrapperMain>
                </Grid>

                <Grid item md={12} xs={12}>
                  <ExtendedWrapperMain>
                    <FieldWrapper className=''>
                      <div className='box'>
                        <input
                          type='file'
                          name='file-7[]'
                          id='file-7'
                          accept='image/x-png,image/jpeg'
                          className='inputfile inputfile-6'
                          data-multiple-caption='{count} files selected'
                          onChange={(event) => {
                            var files = event.target.files;
                            var file = files[0];
                            var trancateString = this.splitName(file.name);
                            if (files && file) {
                              var reader = new FileReader();
                              reader.onload = function (readerEvt) {
                                var binaryString = readerEvt.target.result;
                                setFieldValue('File', btoa(binaryString));
                                setFieldValue('FileName', file.name);
                                setFieldValue(
                                  'TrancateFileName',
                                  trancateString
                                );
                              };
                              this.setState({
                                IsUpdateEnabled: true,
                              });
                              reader.readAsBinaryString(file);
                            }
                          }}
                        />
                        <label>
                          <span className='no-file-upload '>
                            {values.TrancateFileName
                              ? values.TrancateFileName
                              : 'Upload a logo'}
                          </span>
                          <strong>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='20'
                              height='17'
                              viewBox='0 0 20 17'
                            >
                              <path d='M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z' />
                            </svg>{' '}
                          </strong>
                        </label>
                      </div>
                    </FieldWrapper>
                    {matLogoUrl && (isEmpty(values.File) || isLogoUpdated) ? (
                      <div className='accountImage'>
                        <img src={matLogoUrl} alt='group logo' />
                      </div>
                    ) : (
                      <Fragment />
                    )}
                  </ExtendedWrapperMain>
                </Grid>
                <Grid item md={6} xs={12} style={{ paddingTop: '0' }}>
                  <ExtendedWrapperMain>
                    <FieldWrapper>
                      <AppButton
                        color={IsUpdateEnabled ? mandy : ''}
                        type='submit'
                        onClick={IsUpdateEnabled ? handleSubmit : {}}
                        style={{ marginTop: '0' }}
                      >
                        Update
                      </AppButton>
                    </FieldWrapper>
                  </ExtendedWrapperMain>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(matGroupService, dispatch),
  loaderActions: bindActionCreators(loaderAction, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withTranslation()(MatAccountForm)));
