import React, { useState, useEffect } from 'react';
import { SurveyDropDown } from '..';
import { surveyService, storageService } from '../../services';
import { SurveyTypes, WebStorageNames, Roles } from '../../utils/Constants';
import {
  parseJwt,
  getTransformedSurveyRecordList,
  getQueryStringVal,
} from '../../utils/CommonFunctions';
import { QueryStringTokens } from '../../utils';

function SurveyListDropDown(props) {
  const token = storageService.getItem(WebStorageNames.Token);
  const userInfo = parseJwt(token);

  const [latestSurveyOption, setLatestSurveyOption] = useState({
    value: '',
    label: 'Latest survey',
  });
  const [latestSurveyOptions, seLatestSurveyOptions] = useState([
    { value: SurveyTypes.any, label: 'Latest survey' },
  ]);
  const isRole = userInfo.role;

  useEffect(() => {
    const token = window.location.search.split('?token=')[1];
    surveyService
      .getSurveysNameAndToken(token)
      .then((response) => {
        const surveyRecordList = getTransformedSurveyRecordList(response.data);
        seLatestSurveyOptions(surveyRecordList);

        const token = window.location.search;
        var selectedRecord = surveyRecordList.filter(
          (item) => token.indexOf(item.value) > -1
        );
        if (selectedRecord.length > 0) {
          setLatestSurveyOption(selectedRecord[0]);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const handleSurveyValueChange = (latestSurveyOption) => {
    const role = storageService.getItem(WebStorageNames.Role);
    let schoolToken = getQueryStringVal(QueryStringTokens.schoolToken);
    setLatestSurveyOption(latestSurveyOption);
    if (role === Roles.GroupDirector || schoolToken !== null) {
      window.location.search =
        '?token=' + latestSurveyOption.value + '&schoolToken=' + schoolToken;
    } else window.location.search = '?token=' + latestSurveyOption.value;
  };

  return (
    <SurveyDropDown
      isRole={isRole}
      latestSurveyOptions={latestSurveyOptions}
      latestSurveyOption={latestSurveyOption}
      handleSurveyValueChange={handleSurveyValueChange}
    />
  );
}

export default SurveyListDropDown;
