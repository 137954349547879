import React from 'react';
import { AppLogo } from '../../../components';
import { colors } from '../../../resources/theme';
import { SurveyGreetingPresentationStyle } from '../../surveyReportPresentation/SurveyReportPresentationStyle';
import { MatReportWrapper } from '../MatStyle';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  AnalyticsChartWrapper,
  ChartWrapperError,
} from '../../../resources/styling/appStyle';

import PresentationChart from '../../../components/Charts/PresentationChart';

const GraphScoresbySchoolsPpt = (props) => {

  return (
    <SurveyGreetingPresentationStyle className='survey-greeting' id={props.id}>
      <MatReportWrapper>
        <div className='mat-presentation-section'>    
        <span className='app-logo'><img src={props.welbeeslideslogo} alt=""/></span>
          <h1 className='matpres-title'>{props.title}</h1>
          <div>
            {props.AllSchoolGraph === null ? (
              <ChartWrapperError>
                <span className='nodata'>{`There were insufficient filtered responses to display this information.`}</span>
              </ChartWrapperError>
            ) : (
              <>
                <div className='graph-wrapper'>
                  <Scrollbars className='scrollbar-view'>
                  <PresentationChart data ={props.AllSchoolGraph} surveyCount={props?.surveyCount}  name={props.name} />
                  </Scrollbars>
                </div>
              </>
            )}
          </div>       
        </div>
      </MatReportWrapper>
    </SurveyGreetingPresentationStyle>
  );
};

export default GraphScoresbySchoolsPpt;
