import request from '../request';
import { accountAPI, schoolAPI } from './urls';

export const login = (data) => {
  return request({
    url: accountAPI.api,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};
export const setPasswordForRegisteredSchool = (data, token, userId) => {
  return request({
    url: accountAPI.setPasswordForRegisteredSchool(token, userId),
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};
export const SchoolRegister = (data) => {
  return request({
    url: schoolAPI.api,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const forgotPassword = (data) => {
  return request({
    url: accountAPI.forgotPassword,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const resetPassword = (data) => {
  return request({
    url: accountAPI.resetPassword,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((error) => error);
};

export const StaffRegister = (data) => {
  return request({
    url: accountAPI.RegisterSchoolStaff,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const registerDirector = (data) => {
  return request({
    url: accountAPI.registerDirectorAccount,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const HeadTecherSchooldetail = (data) => {
  return request({
    url: schoolAPI.addHeadTeacherDetail,
    method: 'POST',
    data: data,
  })
    .then((response) => response)
    .catch((response) => response);
};

export const IsRegistrationEnable = (token) => {
  return request({
    url: accountAPI.IsRegistrationEnable(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

export const isDirectorRegistrationEnable = (token) => {
  return request({
    url: accountAPI.isDirectorRegistrationEnable(token),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};

const sendRegistrationEmail = (email) => {
    return request({
        url: schoolAPI.sendRegistrationEmail(),
        method: 'POST',
        data:email
    })
        .then((response) => response)
        .catch((error) => error);
};
const VerifyEmailConfirmationToken = (userId) => {
    return request({
        url: schoolAPI.VerifyEmailConfirmationToken(userId),
        method: 'GET',
    })
        .then((response) => response)
        .catch((error) => error);
};
const getInCompleteRegistrationLink = (schoolId) => {
  return request({
    url: accountAPI.GetInCompleteRegistrationLink(schoolId),
    method: 'GET',
})
    .then((response) => response)
    .catch((error) => error);
}
export const checkCurrentRegion = () => {
  return request({
    url: accountAPI.CheckCurrentRegion(),
    method: 'GET',
  })
    .then((response) => response)
    .catch((response) => response);
};
const accountService = {
  VerifyEmailConfirmationToken,
  login,
  forgotPassword,
  resetPassword,
  SchoolRegister,
  setPasswordForRegisteredSchool,
  StaffRegister,
  HeadTecherSchooldetail,
  IsRegistrationEnable,
  registerDirector,
  isDirectorRegistrationEnable,
  sendRegistrationEmail,
  getInCompleteRegistrationLink,
  checkCurrentRegion, 
};

export default accountService;
