import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { schoolStaffService } from '../../services';
import { AppModel } from '../../components';
import {
  AppButton,
  InputWrapper,
  customStyles,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme';
import { loaderAction } from '../../store/loaderStore';

const { purple } = colors;

const RevokeAccess = (props) => {
  const [matSchool, setMatSchool] = useState('');
  const [defaultLevel, setDefaultLevel] = useState('');

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        accessLevel: '',
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values, formProps) => {
        schoolStaffService
          .revokeStaffAccessLevel(props.staffId)
          .then((response) => {
            toast.success('permission revoked successfully');
            props.handleClose();
            if (!props.isMat) props.getStaff();
            else props.getMatStaff();
          });
      }}
      render={({ values, touched, errors, handleSubmit, setFieldValue }) => {
        return (
          <Box className='right-container' spacing={6} sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
              <AppModel
                onClose={props.handleClose}
                styles={customStyles}
                open={true}
                titleText={'Revoke Access'}
                bodyText={
                  <div>
                    <p></p>
                    <Box
                      component='form'
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '100%' },
                      }}
                      noValidate
                      autoComplete='off'
                    >
                      <InputWrapper>
                        <AppButton
                          color={purple}
                          type='button'
                          onClick={handleSubmit}
                        >
                          Revoke
                        </AppButton>
                      </InputWrapper>
                    </Box>
                  </div>
                }
              />
            </Grid>
          </Box>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(RevokeAccess);
